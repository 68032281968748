import editDeal from '../../../../api/deals/editDeal';
import setStep from '../../../../api/deals/setStep';
import HookForm from '../../../../components/HookForm/HookForm';
import Select from '../../../../components/Select/Select';
import {
  DEAL_STATUS_WAITING_FOR_HALYK,
  DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
  DEAL_STEPS,
  DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
  DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
  DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
  DOCUMENT_TYPES_CONTRACT,
  DOCUMENT_TYPES_GUARANTEE_LETTER,
  DOCUMENT_TYPES_INVOICE,
  DOCUMENT_TYPES_POLES,
  DOCUMENT_TYPES_POWER_OF_ATTORNEY,
  DOCUMENT_TYPES_SPOUSE_CONSENT,
  DOCUMENT_TYPES_TECH_PASSPORT,
} from '../../../../constants';
import alert from '../../../../helpers/alert';
import generateAllowedDocumentsJson from '../../../../utils/generateAllowedDocumentsJson';
const CarRegistration = ({
  loading,
  statusOption = [
    {
      value: DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
      label: 'Ожидает постановки ТС на учет',
    },
    {
      value: DEAL_STATUS_WAITING_FOR_HALYK,
      label: 'Машина поставлена на учет',
    },
  ],
  deal,
  setLoading,
  onSuccess,
}) => {
  const onCarDeliverySubmit = async (values) => {
    if (!values?.status) {
        alert.error('Необходимо выбрать статус сделки');
        return;
      }
      try {
        await editDeal({
          id: deal?.id,
          dealId: deal?.id,
          statusId: values?.status?.value,
          ...generateAllowedDocumentsJson([
            DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
            DOCUMENT_TYPES_GUARANTEE_LETTER,
            DOCUMENT_TYPES_INVOICE,
            DOCUMENT_TYPES_TECH_PASSPORT,
            DOCUMENT_TYPES_CONTRACT,
            DOCUMENT_TYPES_POLES,
            DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
            DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
            DOCUMENT_TYPES_POWER_OF_ATTORNEY,
            DOCUMENT_TYPES_SPOUSE_CONSENT,
          ]),
        });
        if (values?.status?.value === DEAL_STATUS_WAITING_FOR_HALYK) {
          await setStep({ dealId: deal?.id, step: DEAL_STEPS.FORTE.CAR_DELIVERY });
        }
  
        return onSuccess();
      } catch (e) {
        alert.error('Ошибка при смене статуса сделки');
        setLoading(false);
        return;
      }
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <HookForm
          title={'Регистрация ТС'}
          grid={{ display: 'grid', gap: 20 }}
          action={'Сохранить и продолжить'}
          inputs={[
            {
              label: 'Статус сделки',
              name: 'status',
              component: Select,
              options: statusOption,
              required: true,
            },
          ]}
          onSubmit={onCarDeliverySubmit}
          loading={loading}
        />
      </div>
    </>
  );
};

export default CarRegistration;
