import api from '../../plugins/api';

const addAdditionalServicesToDeal = (orderId, additionalServices = [], equipments = []) => {
  return api.post('/api/bucket/addAdditionalServices', {
    orderId,
    equipments,
    additionalServices,
    seperately: true,
  });
};

export default addAdditionalServicesToDeal;
