import createNumberMask from '../../../utils/createNumberMask';
import Input from './Input';

const InputNumeric = (props) => {
  return (
    <Input
      {...props}
      mask={createNumberMask({
        allowDecimal: true,
        prefix: '',
        thousandsSeparatorSymbol: ' ',
        decimalLimit: 0,
      })}
      onChange={(e) => {
        const value = e.target.value?.replace(/\s/g, '');
        props.onChange && props.onChange(value);
      }}
    />
  );
};

export default InputNumeric;
