import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalFilter, usePagination, useTable, useSortBy } from 'react-table';
import { CLIENTS } from '../../constants/columns';
import { clientOptions } from '../../constants/filterOptions';
import { setFilters } from '../../utils/setFilters';
import { sortClientsParams } from '../../constants/sortParams';

// components
import Empty from '../../components/Empty/Empty';
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import TableTabs from '../../components/TableTabs/TableTabs';
import HookForm from '../../components/HookForm/HookForm';

// icons
import getClients from '../../api/clients/getClients';
import clientsIcon from '../../assets/sidebar/clients.svg';
import ClientsFilter from '../../components/Filters/ClientsFilter';
import { ROLE_USER } from '../../constants/roles';
const tableStyle = { backgroundColor: 'white' };

const Clients = () => {
  const [columns, setColumns] = React.useState(CLIENTS);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = React.useState([]);
  const [isFetchAll, setFetchAll] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    pageSize: 10,
  });

  const fetchData = (params) => {
    setLoading(true);
    getClients(params)
      .then((res) => {
        setClients(res.data.users);
        setPagination({
          ...res.data.pagination,
          page: res.data.pagination?.pageNumber,
          pageSize: pagination.pageSize,
        });
        setDataCount(res.data.pagination?.count);
      })
      .finally(() => setLoading(false));
  };

  const table = useTable(
    {
      columns,
      data: clients,
      manualPagination: true,
      pageCount: pagination.totalPages,
      page: pagination.page - 1,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { setGlobalFilter, page, setPageSize } = table;

  const [value, setValue] = useState('');
  const [queryParams, setQueryParams] = useState({});

  React.useEffect(() => {
    if (!isFetchAll) {
      fetchData({ roleId: ROLE_USER, page: pagination.page, pageSize: pagination.pageSize, ...queryParams });
    }
  }, [pagination.page, pagination.pageSize, queryParams]);

  const addClient = () => {
    navigate('/client/create');
  };

  const handleNavigate = (index) => {
    const client = clients.filter((item) => item.id === index);
    navigate(`client/${client[0].id}/main-data`, { state: client[0] });
  };

  const sortBy = (value) => {
    if (!sortClientsParams.get(value)) return;
    if (sortClientsParams.get(value) === queryParams.sortBy) {
      queryParams.sortBy = '';
    } else {
      queryParams.sortBy = sortClientsParams.get(value);
    }
    setFetchAll(false);
    setQueryParams({ ...queryParams });
  };

  const onFastSearch = (value) => {
    //check if value is phone number
    if ((value.length === 18 && value[0] === '+') || (value.length === 17 && value[0] === '7')) {
      value = value.replace(/\D/g, '');
    }

    setQueryParams({
      ...queryParams,
      all: value,
    });
  };

  return (
    <>
      <Header title="Клиенты" icon={clientsIcon} />
      <br />
      <br />
      <br />
      <br />
      <TableTabs
        setFilter={onFastSearch}
        value={value}
        setValue={setValue}
        filterComponent={
          <HookForm
            inputs={clientOptions}
            grid={{
              display: 'grid',
              gap: '10px',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
            }}
            style={{ backgroundColor: 'transparent' }}
          />
        }
        handleSearchParams={(resetFilters) =>
          setFilters(resetFilters, setFetchAll, setPageSize, setPagination, setQueryParams, clientOptions)
        }
        navigate={addClient}
        button={'Добавить клиента'}
        inputPlaceholder={'Быстрый поиск по клиентам'}
      />
      <br />
      {!page.length && !loading ? (
        <Empty />
      ) : (
        <Table
          p={pagination}
          table={table}
          style={tableStyle}
          handleNavigate={handleNavigate}
          pagination
          sort
          setPagination={setPagination}
          setFetchAll={setFetchAll}
          isFetchAll={isFetchAll}
          setData={setClients}
          loading={loading}
          setLoading={setLoading}
          columns={columns}
          setColumns={setColumns}
          columnsSettingsKey="clients"
          queryParams={queryParams}
          api={'/api/crm/clients/getClients'}
          response={'users'}
          isSortable
          dataCount={dataCount}
          handleSortTable={sortBy}
        />
      )}
    </>
  );
};

export default Clients;
