import React from 'react';
import { Outlet } from 'react-router-dom';

// components
import SideBar from './SideBar/SideBar';

import { colors } from '../constants/colors';

const Layout = () => {
  return (
    <div style={{ display: 'flex', minHeight: '100vh', position: 'relative' }}>
      <SideBar />
      <div
        className="main-content"
        style={{
          width: '100%',
          maxHeight: '100vh',
          overflowY: 'auto',
          padding: '52px 20px 0 20px',
          backgroundColor: colors.backgroung,
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
