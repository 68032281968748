import Modal from '../../../components/Modal/Modal';
import Cars from '../../Car/Cars';
import Equipments from '../../Equipments/Equipments';

const DealCreateEquipmentsModal = ({ isOpen, onClose, onSubmit, onCancel, containerId, carId, cityId }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel}>
      <Equipments
        header={false}
        onSubmit={onSubmit}
        onCancel={onCancel}
        withCount
        withCheckboxes={false}
        edit={false}
        submitButtonLabel="Добавить оборудование"
        containerId={containerId}
        carId={carId}
        cityId={cityId}
      />
    </Modal>
  );
};

export default DealCreateEquipmentsModal;
