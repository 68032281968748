const FormHeading = ({ children }) => {
  return (
    <p
      style={{
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '20px',
        color: 'rgb(73, 65, 54)',
        marginBottom: '20px',
      }}
    >
      {children}
    </p>
  );
};

export default FormHeading;
