import React from 'react';
import { Link } from 'react-router-dom';

import { colors } from '../constants/colors';

const Breadcrumbs = ({ navigate, link, currentPage }) => {
  return (
    <div
      style={{
        display: 'flex',
        columnGap: '5px',
        marginTop: '31px',
        marginBottom: '38px',
        fontWeight: '500',
        fontSize: '11px',
        lineHeight: '13px',
      }}
    >
      <Link to={navigate} style={{ color: colors.blackPearl }}>
        {link}
      </Link>
      <p>/</p>
      <p style={{ color: colors.lightGrey }}>{currentPage}</p>
    </div>
  );
};

export default Breadcrumbs;
