import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import axios from '../../../plugins/axios';

// components
import Header from '../../../components/Header/Header';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Navbar from '../../../components/Navbar/Navbar';

// icons
import newClient from '../../../assets/header/newClient.svg';
import getClientsNavigationCount from '../../../api/clients/getClientsNavigationCount';

const NotaryClientsNavigation = () => {
  const { id } = useParams();
  const [client, setClient] = React.useState({});

  const init = async () => {
    // await axios
    //   .get('/api/crm/clients/getClients', { params: { id: id } })
    //   .then((res) => {
    //     setClient(...res.data.users);
    //   })
    //   .catch((error) => console.log(error));
    // let object = [...items];
    // await getClientsNavigationCount({ userId: id }).then((res) => {
    //   object[2].count = res.data.deals;
    //   object[3].count = res.data.services;
    // });
    // setItems([...object]);
  };

  React.useEffect(() => {
    init();
  }, []);

  const [items, setItems] = useState([
    { text: 'Ожидает проверки ', navigate: '/notary/clients?notarial_verification=1' },
    { text: 'Прошел проверку', navigate: '/notary/clients?notarial_verification=2' },
    { text: 'Не прошел проверку', navigate: '/notary/clients?notarial_verification=3', count: 0 },
  ]);

  return (
    <>
      <Header title={id ? `${client.surname} ${client.name} - ID ${client.id}` : 'Новый клиент'} icon={newClient} />
      <Breadcrumbs
        navigate={'/'}
        link={'Клиенты'}
        currentPage={id ? `${client.surname} ${client.name} - ID ${client.id}` : 'Новый клиент'}
      />
      <Navbar items={items} />
      <Outlet context={[client, setClient]} />
    </>
  );
};

export default NotaryClientsNavigation;
