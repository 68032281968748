import ChatUser from '../ChatUser';
import styles from './chatList.module.css';
import check from '../../../assets/chat/check-circle.svg';

const ChatListItem = ({ name, lastMessage, seen, active }) => {
  const classNames = `${active ? styles.ChatListItem_active : ''} ${styles.ChatListItem}`;

  return (
    <div className={classNames}>
      <div className={styles.ChatListItem__wrapper}><ChatUser /></div>
      {seen && <img src={check}/>}
    </div>
  );
};

export default ChatListItem;
