import React from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import styles from './navbar.module.css';

// components
import Button from '../Button/Button';

const Navbar = ({ to, items }) => {
  const navigate = useNavigate();
  const { pathname } = useResolvedPath(to);
  const fullpath = pathname + window.location.search;  

  const handleNavigate = (to) => {
    navigate(to);
  };

  return (
    <div className={styles.navbar}>
      {items.map((item, index) => (
        !item?.disabled &&
        <Button
          text={item.text}
          count={item.count}
          newLabel={item?.new}
          onClick={() => handleNavigate(item.navigate)}
          fill={pathname === item.navigate || fullpath === item.navigate ? true : false}
          key={index}
        />
      ))}
    </div>
  );
};

export default Navbar;
