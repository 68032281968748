import api from '../../plugins/api';

const carRegistration = ({ reg_num, carId, chunkId, bankId }) => {
  return api.post('/api/crm/cars/registration', {
    reg_num,
    carId,
    chunkId,
    bankId,
  });
};

export default carRegistration;
