import React, { useEffect, useRef } from 'react';
import styles from './popover.module.css';
import Permission from '../Permission';

const Popover = ({
  actions,
  rowIndex,
  deleteType,
  itemId,
  handleActionPopover,
  status,
  setActions,
  popoverActions,
  setOpen,
  row,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (status === 'новая') {
      setActions(popoverActions);
      ref.current.style.top = `-${ref.current.offsetHeight / actions.length + 75}px`;
    } else if (status === 'подтверждена') {
      setActions(popoverActions.filter((item) => item.title !== 'Подтвердить запись'));
      ref.current.style.top = `-${ref.current.offsetHeight / actions.length + 50}px`;
    } else if (status === 'отменена') {
      setActions(
        popoverActions.filter((item) => item.title !== 'Подтвердить запись' && item.title !== 'Редактировать запись')
      );
      ref.current.style.top = `-${ref.current.offsetHeight / actions.length + 20}px`;
    } else if (status === 'успешно завершена') {
      setActions(popoverActions.filter((item) => item.title === 'Просмотреть запись'));
      ref.current.style.top = `${0}px`;
    }
  }, []);

  return (
    <div className={styles.wrapper} ref={ref}>
      {actions.map((item, index) => (
        <Permission permission={item?.permission}>
          <div
            className={styles.row}
            key={index}
            onClick={() => {
              handleActionPopover && handleActionPopover(rowIndex, index, item.title, itemId, deleteType);
              item?.onClick && item?.onClick(row);
              setOpen(false);
            }}
          >
            <div>
              <img src={item.icon} alt="Icon" />
            </div>
            <div>
              <p>{item.title}</p>
            </div>
          </div>
        </Permission>
      ))}
    </div>
  );
};

export default Popover;
