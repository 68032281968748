import { useCallback, useState } from 'react';
import getDealPaymentTypes from '../../api/deals/getDealPaymentTypes';
import Select from '../Select/Select';

const SelectDealPaymentType = ({ handleSetSelect, handleSetState, style }) => {
  const [data, setData] = useState([]);
  const onFocus = useCallback(() => {
    getDealPaymentTypes().then((res) => {
      const types = res.data.map((item) => {
        return { value: item.id, label: item.title };
      });
      setData(types);
    });
  }, []);

  return (
    <Select
      handleSetSelect={(val) => handleSetSelect && handleSetSelect(val)}
      handleSetState={handleSetState}
      options={data}
      title={'Форма оплаты'}
      placeholder={'Выберите из списка'}
      onFocus={onFocus}
      style={style}
    />
  );
};

export default SelectDealPaymentType;
