import React from 'react';
import { Link } from 'react-router-dom';
import styles from './sideBarItem.module.css';

import { textStyles } from '../../constants/textStyles';

const SideBarItem = ({ to, ...props }) => {
  return (
    <div className={props.isRecord ? styles.record : styles.item}>
      <div className={styles.title}>
        {props.icon && <img src={props.icon} alt="Icon" className={styles.icon} />}
        <p style={textStyles.sideBarItem}>{props.title}</p>
      </div>
      <div className={styles.countContainer}>
        {props.count_1 > 0 && <p style={textStyles.sideBarCount}>{props.count_1}</p>}
        {props.count_2 > 0 && <p className={styles.circle}>{props.count_2}</p>}
      </div>
    </div>
  );
};

export default SideBarItem;
