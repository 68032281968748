import React, { useState, useEffect, useLayoutEffect } from 'react';
import getClientDocuments from '../../api/clients/getClientDocuments';
import uploadFileToClient from '../../api/clients/uploadFileToClient';
import { useParams } from 'react-router-dom';

// components
import ModalAccept from '../../components/ModalAccept/ModalAccept';
import HookForm from '../../components/HookForm/HookForm';
import SelectClienDocumentTypes from '../../components/Selects/SelectClientDocumentTypes';
import DocsInput from '../../components/Inputs/DocsInput/DocsInput';
import DeleteButton from '../../components/DeleteButton/DeleteButton';
import AddButton from '../../components/AddButton/AddButton';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';

const formStyles = {
  documents: { display: 'flex', flexWrap: 'wrap', gap: '20px' },
};

const ClientsDocs = () => {
  const [clientDocs, setClientDocs] = useState([]);
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [documentIndex, setDocumentIndex] = useState(null);
  const [deletedDocument, setDeletedDocument] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAddForm = (form, setForm) => {
    form.push([
      {
        name: `documentType_${form.length + 1}`,
        component: SelectClienDocumentTypes,
        label: 'Тип документа',
        placeholder: 'Выберите из списка',
        style: { width: '49%' },
      },
      {
        name: `document_${form.length + 1}`,
        component: DocsInput,
        text: 'Файл документа',
        label: 'Загрузить документ',
        style: { width: '49%', marginTop: 'auto' },
        id: `${form.length + 1}`,
        dependency: `documentType_${form.length + 1}`,
        dependencyAccessor: (obj) => obj?.value,
      },
    ]);
    setForm([...form]);
  };

  const handleDeleteForm = (formIndex) => {
    const filteredArray = clientDocs.filter(
      (item, idx) => idx !== formIndex && idx !== formIndex + 1 && idx !== formIndex - 1
    );
    setClientDocs(filteredArray);
    setShowModal(false);
  };

  const handleShowModal = (e, index, docName) => {
    e.preventDefault();
    e.stopPropagation();
    setDeletedDocument(docName ? docName.split('/').at(-1) : '');
    setDocumentIndex(index);
    setShowModal(true);
  };
  // Удостоверение личности (скан)
  // Паспорт (скан)
  // Водительское удостоверение (скан)
  useEffect(() => {
    getClientDocuments({ userId: id })
      .then((res) => {
        res.data.forEach((item) => {
          clientDocs.push([
            {
              name: `documentType`,
              component: SelectClienDocumentTypes,
              label: 'Тип документа',
              style: { width: '46.2%' },
              defaultValue:
                item.typeId === 1
                  ? { value: 1, label: 'Удостоверение личности (скан)' }
                  : item.typeId === 2
                  ? { value: 2, label: 'Паспорт (скан)' }
                  : { value: 3, label: 'Водительское удостоверение (скан)' },
              disabled: true,
            },
            {
              name: `document`,
              component: DocsInput,
              text: 'Файл документа',
              label: 'Загрузить документ',
              style: { width: '46.2%', marginTop: 'auto' },
              id: item.id,
              defaultValue: item.url,
            },
            {
              component: DeleteButton,
              name: `deleteBtn`,
              style: { marginTop: 'auto' },
              onClick: (e) => handleShowModal(e, item.id, item.url),
            },
          ]);
          setClientDocs([...clientDocs]);
        });
      })
      .finally(() => setLoading(false));
  }, []);

  useLayoutEffect(() => {
    setLoading(true);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ padding: '10px 20px', backgroundColor: 'white', marginTop: '20px', border: '1px solid #E0E0E0' }}>
          {!clientDocs.length && (
            <p style={{ fontWeight: 400, fontSize: '16px', lineHeight: '20px', marginTop: '30px' }}>
              У этого клиента нет документов
            </p>
          )}
          {clientDocs.length > 0 &&
            clientDocs.map((item) => {
              return <HookForm inputs={item} grid={formStyles.documents} />;
            })}

          {!clientDocs.length ? (
            <Button text={'Добавить документ'} fill onClick={() => handleAddForm(clientDocs, setClientDocs)} />
          ) : (
            <AddButton text={'Добавить еще документ'} onClick={() => handleAddForm(clientDocs, setClientDocs)} />
          )}
          {showModal && (
            <ModalAccept
              document={deletedDocument}
              closeModal={() => setShowModal(false)}
              handleDeleteForm={() => handleDeleteForm(documentIndex)}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ClientsDocs;
