import styles from './chat.module.css';
import user from '../../assets/chat/user-line.svg';

const ChatUserAvatar = (props) => {
  return (
    <div className={styles.ChatUser__avatar} {...props}>
      <img src={user} />
    </div>
  );
};

export default ChatUserAvatar;
