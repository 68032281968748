import { Routes, Route } from 'react-router-dom';

import CarNavigation from './CarNavigation';
import CarMainData from './CarMainData';
import CarDocs from './CarDocs';
import CarDeal from './CarDeal';
import NewCarDocs from './NewCar/NewCarDocs';
import NewCarDeal from './NewCar/NewCarDeal';
import CarCreate from './NewCar/CarCreate';
import CarContainers from './Containers/CarContainers';
import CarComplectationsCreate from './Complectations/CarComplectationsCreate';
import CarContainersCreate from './Containers/CarContainersCreate';
import CarMarks from './Marks/CarMarks';
import CarModels from './Marks/CarModels';
import CarMarksCreate from './Marks/CarMarksCreate';
import CarComplectations from './Complectations/CarCompectations';
import CarModelsCreate from './Marks/CarModelsCreate';
import CarOptions from './Options/CarOptions';
import CarOptionsCreate from './Options/CarOptionsCreate';
import CarContainersCard from './Containers/CarContainersCard';
import CarEquipments from './CarEquipments';
import EditEquipment from '../Equipments/EditEquimpent';
import CarContainerEdit from './Containers/CarContainerEdit';
import CarComplectationsEdit from './Complectations/CarCompectationsEdit';

const CarRoutes = () => {
  return (
    <Routes>
      <Route element={<CarNavigation />}>
        <Route path="car/:id/main-data" element={<CarMainData />} />
        <Route path="car/:id/equipments" element={<CarEquipments />} />
        <Route path="car/:containerId/equipments/:equipmentId/edit" element={<EditEquipment />} />
        <Route path="car/:id/documents" element={<CarDocs />} />
        <Route path="car/:id/deals" element={<CarDeal />} />
        <Route path="documents" element={<NewCarDocs />} />
        <Route path="deals" element={<NewCarDeal />} />
        <Route path="create" element={<CarCreate />} />
      </Route>
      <Route path="containers" element={<CarContainers />} />
      <Route path="containers/:id" element={<CarContainersCard />} />
      <Route path="containers/:id/edit" element={<CarContainerEdit />} />
      <Route path="containers/create" element={<CarContainersCreate />} />
      <Route path="marks" element={<CarMarks />} />
      <Route path="models" element={<CarModels />} />
      <Route path="marks/create" element={<CarMarksCreate />} />
      <Route path="models/create" element={<CarModelsCreate />} />
      <Route path="complectations" element={<CarComplectations />} />
      <Route path="complectations/:containerId/:complectationId" element={<CarComplectationsEdit />} />
      <Route path="complectations/create" element={<CarComplectationsCreate />} />
      <Route path="options" element={<CarOptions />} />
      <Route path="options/create" element={<CarOptionsCreate />} />
    </Routes>
  );
};

export default CarRoutes;
