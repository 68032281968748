import { useCallback, useState } from 'react';
import getEngineCar from '../../api/cars/getEngineCar';
import Select from '../Select/Select';

const SelectEngine = ({ handleSetSelect, handleSetState, style, ...props }) => {
  const [data, setData] = useState([]);
  const onFocus = useCallback(() => {
    getEngineCar().then((res) => {
      const engine = res.data.map((item) => {
        return { value: item.id, label: item.title };
      });
      setData(engine);
    });
  }, []);

  return (
    <Select
      handleSetSelect={(val) => handleSetSelect && handleSetSelect(val)}
      handleSetState={handleSetState}
      options={data}
      title={'Тип топлива'}
      placeholder={'Выберите из списка'}
      onFocus={onFocus}
      style={style}
      {...props}
    />
  );
};

export default SelectEngine;
