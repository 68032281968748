import React, { useState, useEffect } from 'react';
import styles from './docsInput.module.css';
import { textStyles } from '../../../constants/textStyles';
import { colors } from '../../../constants/colors';
import Hint from '../../Hint';

const DocsInputNew = ({ text, label, onChange, placeholder, value, style = {}, id, required, error, hint, accept="*/*", showImage, imageUrl, ...props }) => {
  const [previewUrl, setPreviewUrl] = useState(null);

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const handleFileChange = (file) => {
    if (file) {
      const newPreviewUrl = URL.createObjectURL(file);
      setPreviewUrl(newPreviewUrl);
    } else {
      setPreviewUrl(null);
    }
    onChange(file);
  };

  const removeFile = (e) => {
    e.stopPropagation();
    handleFileChange(null);
  };

  return (
    <div className={styles.input} style={style}>
      <p style={textStyles.fieldLabel}>
        {text} {required && <span style={{ color: colors.red }}>*</span>}
        {hint && <Hint text={hint} />}
      </p>
      <div className={styles.container}>
        <label htmlFor={id} style={{ borderColor: error ? colors.red : colors.alto }}>
          {value?.name || label}
        </label>
        {value?.name && (
          <svg
            onClick={removeFile}
            className={styles.deleteBtn}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 4H20V6H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V6H0V4H5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4ZM16 6H4V18H16V6ZM7 9H9V15H7V9ZM11 9H13V15H11V9ZM7 2V4H13V2H7Z"
              fill="#BABABA"
            />
          </svg>
        )}
      </div>
      <input
        type="file"
        id={id}
        placeholder={placeholder}
        onChange={(e) => {
          handleFileChange(e.target.files[0]);
        }}
        onClick={(e) => (e.target.value = null)}
        hidden
        accept={accept}
        {...props}
      />
      {showImage && (previewUrl || imageUrl) && (
        <img
          src={previewUrl || imageUrl}
          style={{
            width: 200,
            margin: '0 auto'
          }}
          alt='preview'
        />
      )}
    </div>
  );
};

export default DocsInputNew;