import { useState, useEffect, useCallback } from 'react';
import getModels from '../../api/cars/getModels';
import getLeadStatuses from '../../api/leads/getLeadStatuses';
import Select from '../Select/Select';

const SelectStatuses = ({ handleSetSelect, handleSetState, style, ...props }) => {
  const [data, setData] = useState([]);
  const onFocus = useCallback(() => {
    getLeadStatuses().then((res) => {
      const statuses = res.data.map((item) => {
        return { value: item.id, label: item.title };
      });
      setData(statuses);
    });
  }, []);

  return (
    <Select
      handleSetSelect={(val) => handleSetSelect && handleSetSelect(val)}
      handleSetState={handleSetState}
      options={data}
      placeholder={'Выберите из списка'}
      onFocus={onFocus}
      style={style}
      {...props}
    />
  );
};

export default SelectStatuses;
