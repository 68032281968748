import React, { useEffect, useState } from 'react';
import { MARKS } from '../../../constants/columns';
import { carsOptions } from '../../../constants/filterOptions';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { useNavigate } from 'react-router-dom';

// components
import Header from '../../../components/Header/Header';
import Table from '../../../components/Table/Table';
import TableTabs from '../../../components/TableTabs/TableTabs';
import Empty from '../../../components/Empty/Empty';

// icons
import icon from '../../../assets/sidebar/cars.svg';
import axios from '../../../plugins/axios';
import getComplectations from '../../../api/cars/getComplectations';

const columns = MARKS;
const data = [];

const CarComplectations = () => {
  const navigate = useNavigate();

  const table = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );
  const { setGlobalFilter, page } = table;

  const [value, setValue] = useState('');
  const [values, setValues] = useState({
    fio: '',
    iin: '',
    vip: '',
    company: '',
    role: '',
    phone: '',
    email: '',
    broker: '',
  });

  const handleNavigate = () => {
    navigate('/cars/complectations/create');
  };

  useEffect(() => {
    getComplectations().then((res) => console.log(res.data));
  }, []);

  return (
    <>
      <Header title="Комплектации" icon={icon} />
      <TableTabs
        setFilter={setGlobalFilter}
        options={carsOptions}
        value={value}
        setValue={setValue}
        values={values}
        setValues={setValues}
        navigate={handleNavigate}
        button={'Добавить комплектацию'}
        inputPlaceholder={'Быстрый поиск по комплектациям'}
      />
      <br />
      {!page.length ? <Empty /> : <Table table={table} />}
    </>
  );
};

export default CarComplectations;
