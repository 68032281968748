import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './recordsCalendar.module.css';
import { getMonthData } from '../../../plugins/calendar';
import CalendarDay from './CalendarDay/CalendarDay';
import getServiceRecords from '../../../api/services/getServiceRecords';
import dayjs from 'dayjs';

// icons
import arrow from '../../../assets/arrow.svg';

const date = new Date();
const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];
const days = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];

const RecordsCalendar = ({ route, typeId, setShowModal, setSelectedDayRecords, setSelectedDay }) => {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date(date.getFullYear(), date.getMonth()));
  const [monthData, setMonthData] = useState(getMonthData(selectedMonth.getFullYear(), selectedMonth.getMonth()));

  const handlePrevMonthButtonClick = () => {
    const month = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() - 1);
    setSelectedMonth(month);
    setMonthData(getMonthData(month.getFullYear(), month.getMonth()));
  };

  const handleNextMonthButtonClick = () => {
    const month = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1);
    setSelectedMonth(month);
    setMonthData(getMonthData(month.getFullYear(), month.getMonth()));
  };

  const handleDayClick = (date, records) => {
    setShowModal(true);
    setSelectedDayRecords(records);
    setSelectedDay(date);
  };

  const getServicesRecords = () => {
    getServiceRecords({ typeId })
      .then((res) => {
        setRecords(res.data.services);
        const filtered = res.data.services.filter(
          (item) =>
            new Date(item.date).toLocaleDateString().split('.').splice(1).join('.') ==
            selectedMonth.toLocaleDateString().split('.').splice(1).join('.')
        );
        setFilteredRecords(filtered);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getServicesRecords();
  }, []);

  useEffect(() => {
    const filtered = records.filter(
      (item) =>
        new Date(item.date).toLocaleDateString().split('.').splice(1).join('.') ==
        selectedMonth.toLocaleDateString().split('.').splice(1).join('.')
    );
    setFilteredRecords(filtered);
  }, [selectedMonth]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.calendarHeader}>
          <div className={styles.leftArrow} onClick={handlePrevMonthButtonClick}>
            <img src={arrow} alt="Icon" />
          </div>
          <div style={{ display: 'flex', columnGap: '5px' }}>
            <p>{months[selectedMonth.getMonth()]}</p>
            <p>{selectedMonth.getFullYear()}</p>
          </div>
          <div className={styles.rightArrow} onClick={handleNextMonthButtonClick}>
            <img src={arrow} alt="Icon" />
          </div>
        </div>
        <div className={styles.grid}>
          {days.map((item, index) => (
            <div key={index} className={styles.day}>
              <p>{item}</p>
            </div>
          ))}
          {monthData.map((week) => {
            return week.map((date, index) =>
              date ? (
                <CalendarDay
                  key={index}
                  day={date}
                  month={selectedMonth.getMonth()}
                  year={selectedMonth.getFullYear()}
                  typeId={typeId}
                  onClick={handleDayClick}
                  records={filteredRecords}
                />
              ) : (
                <div key={index} />
              )
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RecordsCalendar;
