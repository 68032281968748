import api from '../../plugins/api';

const verifyClient = ({
  iin,
  phone,
  name,
  surname,
  middlename,
  born,
  document_number,
  document_type_id,
  document_date_end,
  document_date,
  document_issuer,
}) => {
  return api.post('/api/verification', {
    iin,
    phone,
    name,
    surname,
    middlename,
    born,
    document_number,
    document_type_id,
    document_date_end,
    document_date,
    document_issuer,
  });
};

export default verifyClient;
