import React from 'react';
import styles from './sort.module.css';

const Sort = ({ all, count }) => {
  return (
    <div className={styles.sort}>
      <div className={styles.column}>
        <p>Всего {all}:</p>
        <span>{count}</span>
      </div>
      {/* <div className={styles.column}>
        <p>Сортировать по:</p>
        <span>дате обновления</span>
      </div> */}
    </div>
  );
};

export default Sort;
