import React from 'react';
import styles from './selectPhoto.module.css';

// icons
import profileIcon from '../../../../../assets/profileIcon.svg';
import bucket from '../../../../../assets/deletePhoto.svg';
import { colors } from '../../../../../constants/colors';

const SelectPhoto = ({ photo, setPhoto, setUploadPhoto, disabled, style }) => {
  const onImageChange = (event) => {
    let reader = new FileReader();
    if (event.target.files && event.target.files[0]) {
      reader.onload = (e) => {
        setPhoto(e.target.result);
        setUploadPhoto(event.target.files[0]);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <div className={styles.photo} style={!photo ? { border: `2px dashed ${colors.lightGrey}` } : style}>
      <label className={!photo ? styles.label : null}>
        <input type="file" accept="image/*" onChange={onImageChange} disabled={disabled} />
        <img src={photo ? photo : profileIcon} alt={'user photo'} className={photo ? styles.selectedImg : null} />
        {!photo && (
          <>
            <p>Загрузить фото с пк</p>
            <span>Изображение в формате PNG или JPEG не более 200 Кб</span>
          </>
        )}
      </label>
      {photo && !disabled && (
        <button
          className={styles.delete}
          onClick={() => {
            setPhoto(undefined);
            setUploadPhoto(undefined);
          }}
        >
          <img src={bucket} alt={'Icon'} />
        </button>
      )}
    </div>
  );
};

export default SelectPhoto;
