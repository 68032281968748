import dayjs, { extend } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

extend(utc);
extend(timezone);
const DEAFULT_TIMEZONE = "Asia/Almaty";

function fixDateTimezone(date, timezone = DEAFULT_TIMEZONE) {
  const utcDate = dayjs(date).utc();
  const newDate = utcDate.tz(timezone);

  return newDate;
}

export default fixDateTimezone;