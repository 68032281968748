import React from 'react';
import styles from './addButton.module.css';

// icons
import plus from '../../assets/buttonIcons/plus.svg';

const AddButton = ({ text, onClick, style }) => {
  return (
    <button className={styles.button} style={style} onClick={onClick}>
      <img src={plus} alt="Icon" />
      <span>{text}</span>
    </button>
  );
};

export default AddButton;
