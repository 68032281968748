import DealCredits from '../DealCredits';

const DealCreateBanks = ({deal, onSubmit, backHandler, services}) => {
  return (
    <>
      <DealCredits dealId={deal?.id} deal={deal} onSubmit={onSubmit} backHandler={backHandler} services={services}/>
    </>
  );
};

export default DealCreateBanks;
