import React, { useState } from 'react';
import { REPORTS } from '../../constants/columns';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { reportOptions } from '../../constants/filterOptions';
import { useNavigate } from 'react-router-dom';

// components
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import TableTabs from '../../components/TableTabs/TableTabs';
import Empty from '../../components/Empty/Empty';

// icons
import icon from '../../assets/sidebar/report.svg';

const columns = REPORTS;
const tableStyle = { backgroundColor: 'white' };

const Cars = () => {
  const navigate = useNavigate();
  const [reports, setReports] = useState([
    {
      id: 1234,
      date: '05.05.2022',
      name: 'Обработка лидов Апрель 2022',
      period: '01.04.2022 - 30.04.2022',
      report: 'Отчет.PDF',
    },
    {
      id: 3242,
      date: '05.05.2022',
      name: 'Обработка лидов Апрель 2022',
      period: '01.04.2022 - 30.04.2022',
      report: 'Отчет.PDF',
    },
    {
      id: 2123,
      date: '05.05.2022',
      name: 'Обработка лидов Апрель 2022',
      period: '01.04.2022 - 30.04.2022',
      report: 'Отчет.PDF',
    },
    {
      id: 9201,
      date: '05.05.2022',
      name: 'Обработка лидов Апрель 2022',
      period: '01.04.2022 - 30.04.2022',
      report: 'Отчет.PDF',
    },
  ]);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
  });

  const table = useTable(
    {
      columns,
      data: reports,
    },
    useGlobalFilter,
    usePagination
  );
  const { setGlobalFilter, page } = table;

  const [value, setValue] = useState('');
  const [values, setValues] = useState({
    fio: '',
    iin: '',
    vip: '',
    company: '',
    role: '',
    phone: '',
    email: '',
    broker: '',
  });

  const addReport = () => {
    navigate('/reports/create');
  };

  return (
    <>
      <Header title="Мои отчеты" icon={icon} />
      <br />
      <br />
      <br />
      <br />
      <TableTabs
        setFilter={setGlobalFilter}
        options={reportOptions}
        value={value}
        setValue={setValue}
        values={values}
        setValues={setValues}
        navigate={addReport}
        button={'Сформировать новый отчет'}
        inputPlaceholder={'Быстрый поиск по отчетам'}
      />
      <br />
      {!page.length ? (
        <Empty />
      ) : (
        <Table table={table} style={tableStyle} pagination setPagination={setPagination} p={pagination} />
      )}
    </>
  );
};

export default Cars;
