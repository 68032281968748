import React from 'react';
import Select from '../Select/Select';

const options = [
  { value: true, label: 'Да' },
  { value: false, label: 'Нет' },
];

const SelectStockCar = ({ ...props }) => {
  return <Select options={options} placeholder={'Выберите из списка'} {...props} />;
};

export default SelectStockCar;
