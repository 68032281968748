import React, { useState } from 'react';

// components
import ModalForm from '../../../components/ModalForm/ModalForm';
import EmptyData from '../../../components/EmptyData/EmptyData';

// icons
import icon from '../../../assets/buttonIcons/leadCreate.svg';

const formStyles = {
  dealInfo: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a m m' 'b b b b' 'c c c c'` },
  autoForm: { display: 'grid', gap: '20px', gridTemplateColumns: `auto 44px` },
};

const dealInfo = [
  { type: 'input', value: '01.11.2022', label: 'Дата', disabled: true },
  { type: 'input', label: 'ID', value: '35567809', disabled: true },
  { type: 'select', label: 'Клиент', placeholder: 'Выберите из списка', style: { gridArea: 'm' } },
  { type: 'select', label: 'Статус сделки', placeholder: 'Выберите из списка' },
  { type: 'select', label: 'Форма оплаты', placeholder: 'Выберите из списка' },
  { type: 'select', label: 'Статус оплаты', placeholder: 'Выберите из списка' },
  { type: 'input', label: 'Сумма сделки, KZT', placeholder: 'Автоматический расчет', disabled: true },
  { type: 'input', label: 'Заметки/комментарии о сделке', placeholder: 'Ваш комментарий', style: { gridArea: 'c' } },
];

const NewLeadClient = () => {
  const [showModal, setShowModal] = useState(false);

  const [autoForm, setAutoForm] = useState({
    title: 'Автомобиль',
    style: formStyles.autoForm,
    action: 'Добавить еще автомобиль',
    form: [],
    add: [{ type: 'select', value: '', label: 'Автомобиль', placeholder: 'Выберите из списка' }, { type: 'delete' }],
  });

  const [equipmentForm, setEquipmentForm] = useState({
    title: 'Аксессуары',
    style: formStyles.autoForm,
    action: 'Добавить еще оборудование',
    form: [],
    add: [{ type: 'select', value: '', label: 'Оборудование', placeholder: 'Выберите из списка' }, { type: 'delete' }],
  });

  const [serviceForm, setServiceForm] = useState({
    title: 'Дополнительные услуги',
    style: formStyles.autoForm,
    action: 'Добавить еще дополнительную услугу',
    form: [],
    add: [{ type: 'select', value: '', label: 'Услуга', placeholder: 'Выберите из списка' }, { type: 'delete' }],
  });

  const [documentForm, setDocumentForm] = useState({
    title: 'Документы',
    style: formStyles.autoForm,
    action: 'Добавить еще документ к сделке',
    form: [],
    add: [{ type: 'select', value: 'ЫЫЫЫ', label: 'Услуга', placeholder: 'Выберите из списка' }, { type: 'delete' }],
  });

  const [actions, setActions] = useState([
    { show: true, text: 'Добавить автомобиль' },
    { show: true, text: 'Добавить аксессуары' },
    { show: true, text: 'Добавить дополнительную услугу' },
    { show: true, text: 'Добавить документ к сделке' },
  ]);

  return (
    <>
      <EmptyData
        title={'Клиент'}
        subtitle={'Это лид еще не конвертирован в клиента...'}
        action="Создать клиента из лида"
        icon={icon}
        func={() => setShowModal(true)}
      />
      {showModal && (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            display: 'flex',
          }}
        >
          <div style={{ width: '316px' }} />
          <ModalForm
            title={'Новая сделка с клиентом'}
            closeModal={() => setShowModal(false)}
            mainForm={dealInfo}
            formTitle={'Информация о сделке'}
            grid={formStyles.dealInfo}
            forms={[autoForm, equipmentForm, serviceForm, documentForm]}
            actions={actions}
            setActions={setActions}
            setForm={[setAutoForm, setEquipmentForm, setServiceForm, setDocumentForm]}
            button={'Сохранить сделку'}
          />
        </div>
      )}
    </>
  );
};

export default NewLeadClient;
