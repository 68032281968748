import { useState } from 'react';
import getDealPaymentStatuses from '../../api/deals/getDealPaymentStatuses';
import Select from '../Select/Select';

const SelectDealPaymentStatuses = ({ handleSetSelect, handleSetState, style, ...props }) => {
  // const [data, setData] = useState([]);
  // const onFocus = () => {
  //   getDealPaymentStatuses().then((res) => {
  //     const statuses = res.data.map((item) => {
  //       return { value: item.id, label: item.title };
  //     });
  //     setData(statuses);
  //   });
  // };

  const options = [
    { value: 1, label: 'Оплачен' },
    { value: 2, label: 'Не оплачен' },
  ];

  return (
    <Select
      handleSetSelect={(val) => handleSetSelect && handleSetSelect(val)}
      handleSetState={handleSetState}
      options={options}
      title={'Статус оплаты'}
      placeholder={'Выберите из списка'}
      // onFocus={onFocus}
      style={style}
			{...props}
    />
  );
};

export default SelectDealPaymentStatuses;
