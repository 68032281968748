import React from 'react';
import styles from './iconButton.module.css';

const IconButton = ({ icon, text, width, onClick }) => {
  return (
    <button className={styles.button} onClick={onClick} style={{ width: width }}>
      {icon && <img src={icon} alt="Icon" />}
      {text}
    </button>
  );
};

export default IconButton;
