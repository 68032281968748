import { useState } from 'react';
import Modal from '../../components/Modal/Modal';
import Select from '../../components/Select/Select';
import Button from '../../components/Button/Button';
import editDeal from '../../api/deals/editDeal';
import { DEAL_STATUS_REJECTED } from '../../constants';
import alert from '../../helpers/alert';
import LoaderInline from '../../components/Loader/LoaderInline';
import setStep from '../../api/deals/setStep';

const REJECT_REASONS = [
  { label: 'Отказали все банки', value: 'rejected_all_banks' },
  { label: 'Отказал нужный банк', value: 'rejected_specific_bank' },
  { label: 'Клиент отказался покупать авто', value: 'client_refused_to_buy_car' },
  { label: 'У клиента нет денег на ПВ', value: 'client_has_no_money_for_PV' },
];

const RejectDealModal = ({ isOpen, onClose, dealId, onSuccess }) => {
  const [reason, setReason] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!reason) {
      return setError('Поле обязательно для заполнения');
    } else {
      setError(null);
    }

    try {
      setLoading(true);
      await editDeal({
        id: dealId,
        statusId: DEAL_STATUS_REJECTED,
        step: 10,
        cancelDescription: reason?.label,
      });
      await setStep({dealId, step: 10});

      alert.success("Сделка успешно отклонена")
      onClose && onClose(false);
      onSuccess && onSuccess();
    } catch (e) {
      alert.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={`Укажите причину, по которой сделка №${dealId} считается неудачной`}
      onRequestClose={() => onClose(false)}
      contentStyle={{
        top: '20%',
        height: 'fit-content',
        width: 500,
      }}
    >
      {loading ? (
        <LoaderInline />
      ) : (
        <div
          style={{
            display: 'grid',
            gap: 20,
          }}
        >
          <div>
            <Select
              error={error}
              placeholder="Выберите значение"
              label="Причина"
              required
              options={REJECT_REASONS}
              onChange={setReason}
            />
            {error && (
              <div
                style={{
                  fontWeight: 300,
                  fontSize: '11px',
                  lineHeight: '20px',
                  color: '#EB5757',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  marginTop: '4px',
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <g clipPath="url(#clip0_3320_14643)">
                    <path
                      d="M8.00016 14.6666C4.31816 14.6666 1.3335 11.682 1.3335 7.99998C1.3335 4.31798 4.31816 1.33331 8.00016 1.33331C11.6822 1.33331 14.6668 4.31798 14.6668 7.99998C14.6668 11.682 11.6822 14.6666 8.00016 14.6666ZM8.00016 13.3333C9.41465 13.3333 10.7712 12.7714 11.7714 11.7712C12.7716 10.771 13.3335 9.41447 13.3335 7.99998C13.3335 6.58549 12.7716 5.22894 11.7714 4.22874C10.7712 3.22855 9.41465 2.66665 8.00016 2.66665C6.58567 2.66665 5.22912 3.22855 4.22893 4.22874C3.22873 5.22894 2.66683 6.58549 2.66683 7.99998C2.66683 9.41447 3.22873 10.771 4.22893 11.7712C5.22912 12.7714 6.58567 13.3333 8.00016 13.3333ZM7.3335 9.99998H8.66683V11.3333H7.3335V9.99998ZM7.3335 4.66665H8.66683V8.66665H7.3335V4.66665Z"
                      fill="#EB5757"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3320_14643">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>{error}</span>
              </div>
            )}
          </div>
          <Button fill full onClick={handleSubmit}>
            Подтвердить
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default RejectDealModal;
