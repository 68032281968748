import { useState } from "react";
import Modal from "./Modal";

const ModalRelativeToParent = ({ children, isOpen, onRequestClose, parentElement, contentWidth, contentHeight }) => {
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  
    const positionModal = () => {
      let { top, left } = parentElement.getBoundingClientRect();
  
      top += parentElement.offsetHeight + 20;
      left -= 100;
  
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
  
      if (left + contentWidth > screenWidth) {
        left = screenWidth - contentHeight;
      }
  
      if (top + contentHeight > screenHeight) {
        top = screenHeight - contentHeight;
      }
  
      setModalPosition({ top, left });
    };
  
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={{
          overlay: {
            backgroundColor: 'transparent',
            zIndex: 9999,
          },
          content: {
            top: modalPosition.top,
            left: modalPosition.left,
            width: contentWidth,
            height: contentHeight,
          },
          wrapper: {
            height: '100%'
          }
        }}
        onAfterOpen={positionModal}
      >
        {children}
      </Modal>
    );
  };

  export default ModalRelativeToParent;