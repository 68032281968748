import React, { useState } from 'react';
import styles from './checkbox.module.css';

const Checkbox = ({ value, label, id, onChange = () => null, onClick, style, disabled }) => {
  return (
    <div className={styles.checkbox} style={style ? style : null}>
      <input
        type="checkbox"
        id={id}
        defaultChecked={value}
        checked={value}
        onChange={() => onChange(id, !value)}
        onClick={onClick}
        disabled={disabled}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Checkbox;
