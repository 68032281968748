import { useState } from 'react';
import getDealStatuses from '../../api/deals/getDealStatuses';
import Select from '../Select/Select';

const SelectNotaryDealStatuses = ({ handleSetSelect, handleSetState, style, ...props }) => {
  const [data, setData] = useState([]);
  const onFocus = () => {};

  return (
    <Select
      handleSetSelect={(val) => handleSetSelect && handleSetSelect(val)}
      handleSetState={handleSetState}
      options={[
        { value: '7', label: 'Нотариальные документы оформлены' },
        { value: '8', label: 'Ожидает нотариальных документов' },
        { value: '9', label: 'Необходимо оформить доверенность на постановку на учет' },
      ]}
      title={'Статус сделки'}
      placeholder={'Выберите из списка'}
      onFocus={onFocus}
      style={style}
      {...props}
    />
  );
};

export default SelectNotaryDealStatuses;
