import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import MaskedTextInput from 'react-text-mask';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import { ru } from 'date-fns/esm/locale';
import styles from './customCalendar.css';

registerLocale('ru', ru);

const CustomCalendar = () => {
  const [startDate, setStartDate] = useState('');

  return (
    <div>
      <ReactDatePicker
        locale={'ru'}
        dateFormat="dd.MM.yyyy"
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        inline
      />
    </div>
  );
};

export default CustomCalendar;
