import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getDealCars from '../../api/deals/getDealCars';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import CheckboxSelectAdditionalServices from '../Selects/CheckboxSelectAdditionalServices';
import Select from '../Select/Select';
import SelectCars from '../Selects/SelectCars';
import LoaderInline from '../Loader/LoaderInline';

const SelectCarModal = ({ isOpen, onClose, onSubmit = () => null, loading }) => {
  const [cars, setCars] = useState({});

  const handleSubmit = () => {
    onSubmit(cars);
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Добавить автомобиль"
      onRequestClose={() => onClose(false)}
      width={600}
      contentStyle={{
        height: "100%",
        maxHeight: 500
      }}
    >
      {loading ? (
        <LoaderInline />
      ) : (
        <div
          style={{
            padding: '0 16px',
            marginBottom: 24,
          }}
        >
          <SelectCars handleSetState={setCars} />
        </div>
      )}
      <Button
        fill
        text="Добавить"
        onClick={handleSubmit}
        style={{ maxWidth: 250, width: '100%', margin: '0 auto', display: 'block' }}
      />
    </Modal>
  );
};

SelectCarModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  dealId: PropTypes.number.isRequired,
};

export default SelectCarModal;
