import api from '../../plugins/api';

const editClient = ({
  id,
  name,
  surname,
  middlename,
  iin,
  born,
  document_number,
  document_date,
  document_issuer,
  address,
  email,
  url,
}) => {
  return api.post('/editProfile', {
    id,
    name,
    surname,
    middlename,
    iin,
    born,
    document_number,
    document_date,
    document_issuer,
    address,
    email,
    url,
  });
};

export default editClient;
