import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import getItems from '../../api/cars/getItems';
import useDebounce from '../../hooks/useDebounce';
import formatNumber from '../../utils/formatNumber';
import Select from '../Select/Select';

const SelectCars = ({ handleSetSelect, handleSetState, style, ...props }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [car, setCar] = useState('');
  const debouncedSearchTerm = useDebounce(car, 500);

  const onFocus = useCallback(() => {
    if (page != 1 && page > totalCount) return;
    getItems({ page }).then((res) => {
      const cars = res.data.cars.map((item) => {
        return {
          value: item.id,
          price: item?.price,
          label: `${item.mark} ${item.model} (VIN ${item?.vin}, ${item?.complectation?.title}, ${item?.complectation?.engine?.title}, ${formatNumber(item?.container?.toPrice)} ₸)`,
        };
      });
      setData((prev) => [...prev, ...cars]);
      setTotalCount(res.data.pagination.totalPages);
      setPage((prev) => prev + 1);
    });
  }, [page, totalCount]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      getItems({ markAndModel: car }).then((res) => {
        const cars = res.data.cars.map((item) => ({
          value: item.id,
          label: `${item.mark} ${item.model} (VIN ${item?.vin}, ${item?.complectation?.title}, ${item?.complectation?.engine?.title}, ${formatNumber(item?.container?.toPrice)} ₸)`,
        }));
        car.length ? setData(cars) : setData((prev) => prev);
        setPage(1);
      });
    } else {
      setData([]);
    }
  }, [debouncedSearchTerm]);

  return (
    <Select
      handleSetSelect={(val) => setCar(val)}
      handleSetState={handleSetState}
      options={data}
      title={'Автомобиль'}
      placeholder={'Выберите из списка'}
      onFocus={onFocus}
      style={style}
      isSearchable
      isMulti
      {...props}
    />
  );
};

export default SelectCars;
