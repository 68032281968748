import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import MaskedTextInput from 'react-text-mask';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import { ru } from 'date-fns/esm/locale';
import styles from './datepicker.module.css';

// icons
import calendar from '../../../assets/input/calendar.svg';

import { textStyles } from '../../../constants/textStyles';
import { colors } from '../../../constants/colors';
import dayjs from 'dayjs';

registerLocale('ru', ru);

const Datepicker = ({ label, onChange, placeholder, value, defaultValue, disabled, required }) => {
  const [startDate, setStartDate] = useState(
    defaultValue ? (new Date(defaultValue) == 'Invalid Date' ? "" : new Date(defaultValue)) : ""
  );

  return (
    <div className={styles.input}>
      <label style={textStyles.fieldLabel}>{label} {required && <span style={{ color: colors.red }}>*</span>}</label>
      <ReactDatePicker
        customInput={<MaskedTextInput placeholder='ДД.ММ.ГГГГ' type="text" mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]} />}
        locale={'ru'}
        dateFormat="dd.MM.yyyy"
        selected={startDate}
        shouldCloseOnSelect={true}
        onChange={(date) => {
          date = dayjs(date).hour(12).toDate()
          setStartDate(date);
          onChange(date);
        }}
        placeholderText={placeholder}
        disabled={disabled}
      />
      <img src={calendar} alt="Icon" />
    </div>
  );
};

export default Datepicker;
