import React, { useState } from 'react';
import { LEADS } from '../../constants/columns';
import MOCK_DATA from '../../MOCK_DATA.json';
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { leadsOptions } from '../../constants/filterOptions';
import { useNavigate } from 'react-router-dom';
import axios from '../../plugins/axios';
import { setFilters } from '../../utils/setFilters';
import { sortLeadsParams } from '../../constants/sortParams';

// components
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import Empty from '../../components/Empty/Empty';
import TableTabs from '../../components/TableTabs/TableTabs';
import ClientPopup from '../../components/ClientPopup/ClientPopup';
import HookForm from '../../components/HookForm/HookForm';
import EditLeadModal from '../../components/Modals/EditLeadModal';
import SelectManager from '../../components/Selects/SelectManager';

// icons
import icon from '../../assets/sidebar/leads.svg';
import eye from '../../assets/popover/eye.svg';
import bucket from '../../assets/popover/delete.svg';
import edit from '../../assets/popover/edit.svg';
import set from '../../assets/popover/set.svg';
import LeadsFilter from '../../components/Filters/LeadsFilter';
import { PERMISSION_CAN_CREATE_LEADS } from '../../constants/roles';

const tableStyle = { backgroundColor: 'white' };

const popover = [
  { title: 'Просмотреть лид', icon: eye },
  { title: 'Редактировать лид', icon: edit },
  { title: 'Назначить ответственного', icon: set },
  { title: 'Удалить лид', icon: bucket },
];

const formStyles = {
  record: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a k k' 'b b b b' 'c c v v' 'd d d d' 'f f f f'` },
};

const Leads = () => {
  const [columns, setColumns] = React.useState(LEADS);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    pageSize: 10,
  });
  const [isFetchAll, setFetchAll] = useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const table = useTable(
    {
      columns,
      data: leads,
      initialState: {
        pageSize: pagination.pageSize,
      },
      manualPagination: true,
      pageCount: pagination.totalPages,
      page: pagination.page - 1,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { setGlobalFilter, page, setPageSize } = table;

  const [value, setValue] = useState('');
  const [queryParams, setQueryParams] = useState({});

  const record = [{ name: 'record', component: SelectManager, label: 'Статус записи' }];
  const [currentLead, setCurrentLead] = useState(null);

  const handleNavigate = (index) => {
    navigate(`lead/${leads.find((item) => item.id === index).id}/main-info`, {
      state: leads.find((item) => item.id === index),
    });
  };

  const addLead = () => {
    navigate('/leads/create');
  };

  const getLeads = async (params) => {
    setLoading(true);
    await axios
      .get('/api/crm/leads/getLeads', { params: { ...params } })
      .then((res) => {
        setLeads(res.data.leads);
        setPagination({
          ...res.data.pagination,
          page: res.data.pagination?.pageNumber,
          pageSize: pagination.pageSize,
        });
        setDataCount(res.data.pagination?.count);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (!isFetchAll) {
      getLeads({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams });
    }
  }, [pagination.page, pagination.pageSize, queryParams]);

  const handleActionPopover = async (leadIndex, actionIndex, title, id) => {
    setCurrentLead(leadIndex);
    if (actionIndex === 0) {
      navigate(`lead/${leads[leadIndex].id}/main-info`, { state: leads[leadIndex] });
    }
    if (actionIndex === 1) {
      navigate(`lead/edit/${leads[leadIndex].id}`, { state: leads[leadIndex] });
    }
    if (actionIndex === 2) {
      setShowModal(true);
    }
    if (actionIndex === 3) {
      await axios
        .get('/api/crm/leads/deleteLead', { params: { leadId: leads[leadIndex].id } })
        .then((res) => {
          getLeads({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams });
        })
        .catch((error) => {});
    }
  };

  const editLead = async (val) => {
    if (record[0]?.value?.value) {
      await axios
        .post('/api/crm/leads/editLead', { managerId: record[0]?.value?.value, id: leads[currentLead].id })
        .then((res) => {
          getLeads({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams });
        })
        .finally(() => {
          setShowModal(false);
        });
    }
  };

  const [showClientPopup, setShowClientPopup] = React.useState(false);
  const [clientId, setClientId] = React.useState(null);

  const sortBy = (value) => {
    if (!sortLeadsParams.get(value)) return;
    if (sortLeadsParams.get(value) === queryParams.sortBy) {
      queryParams.sortBy = '';
    } else {
      queryParams.sortBy = sortLeadsParams.get(value);
    }
    setFetchAll(false);
    setQueryParams({ ...queryParams });
  };

  return (
    <>
      {showClientPopup && <ClientPopup isOpen={showClientPopup} setOpen={setShowClientPopup} clientId={clientId} />}
      <Header title="Лиды" icon={icon} />
      <br />
      <br />
      <br />
      <br />
      <TableTabs
        setFilter={setGlobalFilter}
        value={value}
        setValue={setValue}
        filterComponent={
          <HookForm
            inputs={leadsOptions}
            grid={{
              display: 'grid',
              gap: '10px',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
            }}
            style={{ backgroundColor: 'transparent' }}
          />
        }
        handleSearchParams={(resetFilters) =>
          setFilters(resetFilters, setFetchAll, setPageSize, setPagination, setQueryParams, leadsOptions)
        }
        navigate={addLead}
        button={'Добавить лид'}
        permission={PERMISSION_CAN_CREATE_LEADS}
        inputPlaceholder={'Быстрый поиск по лидам'}
      />
      <br />
      {!page.length && !loading ? (
        <Empty />
      ) : (
        <div>
          <EditLeadModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            title={'Назначить ответственного лид ID - ' + leads[currentLead]?.id}
            handleSubmit={editLead}
            inputs={record}
          />
          <Table
            setPageSize={(value) => {
              setPageSize(value);
            }}
            table={table}
            all={'лидов'}
            style={tableStyle}
            popover={popover}
            handleNavigate={handleNavigate}
            handleActionPopover={handleActionPopover}
            pagination
            sort
            setPagination={setPagination}
            setFetchAll={setFetchAll}
            isFetchAll={isFetchAll}
            setData={setLeads}
            p={pagination}
            setClientId={setClientId}
            setOpenClientPopup={setShowClientPopup}
            loading={loading}
            setLoading={setLoading}
            columns={columns}
            setColumns={setColumns}
            columnsSettingsKey="records"
            queryParams={queryParams}
            api={'/api/crm/leads/getLeads'}
            response={'leads'}
            isSortable
            dataCount={dataCount}
            handleSortTable={sortBy}
          />
        </div>
      )}
    </>
  );
};

export default Leads;
