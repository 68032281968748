import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import styles from './tableTabs.module.css';

// components
import IconButton from '../IconButton/IconButton';
import Filters from '../Filters';
import Button from '../Button/Button';

// icons
import fastsearch from '../../assets/fastSearch.svg';
import advancedSearchCameo from '../../assets/advancedSearch.svg';
import advancedSearchDark from '../../assets/advancedSearchDark.svg';
import plus from '../../assets/buttonIcons/plus.svg';
import calendar from '../../assets/buttonIcons/calendar.svg';
import Permission from '../Permission';
import { PERMISSION_CAN_CREATE_LEADS } from '../../constants/roles';

const TableTabs = ({
  to,
  setFilter,
  value,
  handleSearchParams,
  handleFastSearchParams,
  filterComponent,
  setValue,
  navigate,
  button,
  buttons,
  inputPlaceholder,
  permission = 'all',
}) => {
  const navigation = useNavigate();
  const { pathname } = useResolvedPath(to);
  const [showFilters, setShowFilters] = useState(false);

  const fastSearch = (e) => {
    if (e.keyCode === 13 || e.type === 'click') {
      setFilter(value);
      // handleFastSearchParams(value);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.firstRow}>
        <div
          style={{
            display: 'flex',
            gap: 15,
          }}
        >
          <Permission permission={permission}>
            <IconButton text={button} icon={plus} onClick={navigate} />
          </Permission>
          {buttons &&
            buttons?.map((button) => (
              <IconButton text={button.text} icon={button.icon || plus} onClick={button.onClick} />
            ))}
        </div>
        <div className={styles.input}>
          <img src={fastsearch} alt="Icon" />
          <input
            value={value}
            type="text"
            placeholder={inputPlaceholder}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={fastSearch}
          />
        </div>
        <Button text={'Найти'} onClick={fastSearch} fill={true} />
        <button
          style={pathname === '/records' ? { marginRight: 'auto' } : null}
          className={styles.advancedSearch}
          onClick={() => setShowFilters((prev) => !prev)}
        >
          <img src={showFilters ? advancedSearchDark : advancedSearchCameo} alt="Search" />
          <p style={showFilters ? { color: '#494136' } : null}>Расширенный поиск</p>
        </button>
        {pathname === '/records' && (
          <IconButton text={'Открыть календарь'} icon={calendar} onClick={() => navigation('/records/service')} />
        )}
      </div>
      {showFilters && filterComponent}
      {showFilters && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button text={'Сбросить все'} onClick={() => handleSearchParams(true)} />
          <Button text={'Найти'} onClick={() => handleSearchParams(false)} fill={true} />
        </div>
      )}
    </div>
  );
};

export default TableTabs;
