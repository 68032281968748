import { useState, useEffect, useMemo, useCallback, memo } from 'react';
import getModels from '../../api/cars/getModels';
import Select from '../Select/Select';

const SelectModelsOld = ({
  handleSetSelect,
  handleSetState,
  params = () => null,
  style,
  dependantValue,
  dependencyAccessor = (obj) => obj,
  ...props
}) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const depValue = useMemo(() => dependencyAccessor(dependantValue), [dependantValue, dependencyAccessor]);

  const onFocus = useCallback(() => {
    if (!params()) return;
    getModels({ markId: depValue || params(), page }).then((res) => {
      const models = res.data.models.map((item) => {
        return { value: item.id, label: item.name };
      });
      setData((prev) => [...models]);
    });
  }, [params]);

  useEffect(() => {
    setData([]);
    setPage(1);
    setTotalCount(0);
  }, [depValue]);

  return (
    <Select
      handleSetSelect={(val) => handleSetSelect && handleSetSelect(val)}
      handleSetState={handleSetState}
      options={data}
      title={'Модель'}
      placeholder={'Выберите из списка'}
      onFocus={onFocus}
      style={style}
      {...props}
    />
  );
};

export default SelectModelsOld;
