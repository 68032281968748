import { BANKS, BANKS_OPTIONS } from '../../constants';
import Checkbox from '../Inputs/Checkbox/Checkbox';
import { useEffect, useState } from 'react';
import styles from '../../pages/Deal/deal.module.css';

const BanksPicker = ({ onChange }) => {
  const [data, setData] = useState(BANKS_OPTIONS.reduce((acc, bank) => ({ ...acc, [bank.value]: true }), {}));
  const handleChange = (id, value) => {
    setData({
      ...data,
      [id]: value,
    });
  };

  useEffect(() => {
    onChange(Object.keys(data).filter(key => data[key]).map(Number));
  }, [data, onChange])

  return (
    <div>
      {BANKS_OPTIONS.map((bank) => (
        <label htmlFor={bank.value} className={styles.bank} style={{ padding: '5px 20px', marginBottom: 2, display: 'block', cursor: 'pointer' }}>
          <div className={styles.bank__row} style={{ marginBottom: 0 }}>
            <p className={styles.bank_name}>{bank.label}</p>
            <p>
              <Checkbox id={bank.value} value={data[bank.value]} defaultChecked={data[bank.value]} onChange={handleChange} />
            </p>
          </div>
        </label>
      ))}
    </div>
  );
};

export default BanksPicker;
