import React, { useState } from 'react';

// components
import Header from '../../components/Header/Header';
import Form from '../../components/Form/Form';

// icons
import icon from '../../assets/sidebar/profile.svg';
import { useSelector } from 'react-redux';

const Profile = () => {
  const [profile, setProfile] = useState([]);
  const user = useSelector((state) => state.user);
  React.useEffect(() => {
    setProfile([
      { type: 'input', label: 'Фамилия', value: user.surname, disabled: true },
      { type: 'input', label: 'Имя', value: user.name, disabled: true },
      { type: 'input', label: 'Отчество', value: user.middlename, disabled: true },
      { type: 'input', label: 'Регион', value: user.city?.title, disabled: true },
      { type: 'input', label: 'Роль', value: user.role?.title, disabled: true },
      { type: 'input', label: 'Автосалон', value: 'Kia DOSCAR: ул. Елебекова, 10/1', disabled: true },
    ]);
  }, [user]);
  return (
    <>
      <Header title="Мой профиль" icon={icon} />
      <div style={{ marginTop: '80px', display: 'flex', columnGap: '20px', alignItems: 'center' }}>
        <div style={{ width: '278px', height: '278px' }}>
          <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={user.photo} alt="User photo" />
        </div>
        <div style={{ flex: 1 }}>
          <Form
            inputs={profile}
            title={'Данные профиля'}
            grid={{ display: 'grid', gap: '20px', gridTemplateColumns: 'repeat(3, auto)' }}
            border
          />
        </div>
      </div>
    </>
  );
};

export default Profile;
