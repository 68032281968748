import api from '../../plugins/api';

const phoneBelongsIinValidate = async ({ phone, iin }) => {
  const response = await api.get('/api/bmg/phoneBelongsIinValidate', { phone, iin });
  try {
    const status = JSON.parse(response?.data?.succes)?.status;
    return status != "ERROR";
  } catch (e) {
    return response?.data === true;
  }
};

export default phoneBelongsIinValidate;
