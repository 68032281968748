import ChatDateLabel from './ChatDateLabel';
import ChatMessage from './ChatMessage';
import styles from './chatPersonal.module.css';
const ChatHistory = () => {
    return(<div className={styles.ChatHistory}>
        <ChatDateLabel date="Сегодня"/>
        <ChatMessage/>
    </div>)
}

export default ChatHistory;