import Button from '../../../../components/Button/Button';
import HookForm from '../../../../components/HookForm/HookForm';
import LoaderInline from '../../../../components/Loader/LoaderInline';

const Reregistration = ({ onSubmit, loading }) => {
  if (loading) {
    return <LoaderInline />;
  }
  return (
    <>
      {/* <HookForm
        title={'Переоформление ТС'}
        grid={{ display: 'grid', gap: 20 }}
        action={'Сохранить и продолжить'}
        inputs={[
          {
            label: 'VIN код',
            name: 'reg_num',
            required: true,
          },
        ]}
        onSubmit={onSubmit}
        loading={loading}
      /> */}
      <Button text="Подтвердить переоформление ТС" onClick={onSubmit} />
    </>
  );
};

export default Reregistration;
