import Modal from '../Modal/Modal';
import ChatList from './ChatList/ChatList';
import ChatPersonal from './ChatPersonal/ChatPersonal';

const Chat = ({ ...props }) => {
  return (
    <Modal
      {...props}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          right: 0,
          top: 0,
          left: 'auto',
          width: 'auto',
          height: '100%',
          padding: 0,
        },
      }}
      wrapperStyle={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
      }}
    >
      <ChatList />
      <ChatPersonal />
    </Modal>
  );
};

export default Chat;
