export const setFilters = (resetFilters, setFetchAll, setPageSize, setPagination, setQueryParams, options) => {
  setFetchAll(false);
  setPageSize(10);
  if (resetFilters) {
    setPagination({
      page: 1,
      totalPages: 1,
      hasPreviousPage: false,
      hasNextPage: false,
      pageSize: 10,
    });
    setQueryParams({});
    return;
  }

  const keys = options.map((item) => item.name);
  const payload = keys.reduce(
    (acc, key, index) => ({ ...acc, [key]: options[index]?.value?.value ?? options[index]?.value }),
    {}
  );
  const mark = options?.find((item) => item.name === 'markId');
  const model = options?.find((item) => item.name === 'modelId');

  if (mark || model) {
    payload.markAndModel = mark?.value?.label + ' ' + model?.value?.label;
  }

  setPagination({
    page: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    pageSize: 10,
  });
  setQueryParams(payload);
};
