import React from 'react';

const Status = ({ status, color, backgroundColor }) => {
  return (
    <div
      style={{
        backgroundColor: backgroundColor || 'white',
        border: `1px solid ${color}`,
        borderRadius: '2px',
        padding: '3px 12px',
        width: '100%',
      }}
    >
      <p
        style={{
          textTransform: 'uppercase',
          color: color,
          fontFamily: 'Exo 2',
          fontWeight: 600,
          fontSize: '10px',
          lineHeight: '15px',
          textAlign: 'center',
        }}
      >
        {status}
      </p>
    </div>
  );
};

export default Status;
