import React, { useEffect, useState } from 'react';
import HookForm from '../../../components/HookForm/HookForm';
import DealCreateEquipmentsModal from './DealCreateEquipmentsModal';
import { textStyles } from '../../../constants/textStyles';
import styles from './deal.module.css';
import formatNumber from '../../../utils/formatNumber';
import getDealCars from '../../../api/deals/getDealCars';
import Select from '../../../components/Select/Select';
import ButtonDelete from '../../../components/Button/ButtonDelete';
import removeItemsToDeal from '../../../api/deals/removeItemsToDeal';

const inputs = [];

const DealCreateEquipment = ({
  onSubmit,
  backHandler,
  deal,
  orders,
  equipments,
  update,
  setUnsavedEquipments,
  dealCars,
}) => {
  const [carsModal, setCarsModal] = useState(false);
  const openCarsModal = () => {
    setCarsModal(true);
  };

  const [cars, setCars] = useState([]);
  const onCarsFormSubmit = (equipments) => {
    equipments = equipments?.filter((item) => item?.n);
    setCarsModal(false);
    setCars([...cars, ...equipments]);
  };
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    console.log('ORDER', orders);
    if (orders?.length > 0) {
      setSelectedOrder(orders[0]);
    }
  }, [orders]);
  useEffect(() => {
    setCars(equipments || []);
  }, [equipments]);

  const handleEquipmentDelete = (item) => {
    if (item?.n) {
      const newCars = cars.filter((car) => car.id !== item.id);
      console.log(newCars);
      setCars(newCars);
    } else {
      removeItemsToDeal({
        equipments: [{ value: item?.id }],
        dealId: deal?.id,
      }).then(() => {
        update();
      });
    }
  };

  useEffect(() => {
    setUnsavedEquipments(cars?.filter((item) => item?.n));
  }, [cars, setUnsavedEquipments]);

  return (
    <>
      {/* <p style={{ ...textStyles.formTitle, marginBottom: 20 }}>Основная информация о сделке</p> */}
      {/* {orders.length > 1 && (
        <div style={{ marginBottom: 20 }}>
          <Select title="Автомобиль" onChange={setSelectedOrder} options={orders} value={selectedOrder} />
        </div>
      )} */}
      <HookForm
        inputs={inputs}
        title={'Аксессуары'}
        grid={{ display: 'grid', gap: 20 }}
        onSubmit={() =>
          onSubmit(
            cars?.filter((item) => item?.n)?.map((item) => ({ value: item?.id, count: item?.n })),
            selectedOrder?.value
          )
        }
        action={'Сохранить и продолжить'}
        customData={() => (
          <div style={{ display: 'grid', gap: 10 }}>
            {cars.map((item) => {
              return (
                <div
                  key={item.id}
                  style={{ display: 'grid', gridTemplateColumns: '1fr auto 50px', alignItems: 'center', gap: 10 }}
                >
                  <p style={textStyles.buttonText}>{item.title}</p>
                  <p>{item?.n || item?.count}</p>
                  <ButtonDelete onClick={() => handleEquipmentDelete(item)} />
                </div>
              );
            })}

            {cars?.length > 0 && (
              <>
                <hr style={{ border: '1px solid #D9D9D9', margin: '20px 0' }} />
                <p className={styles.stepsRow__value} style={{ ...textStyles.buttonText, color: '#05141F' }}>
                  {formatNumber(cars?.reduce((acc, car) => acc + car?.price * (car?.n || car?.count), 0))} KZT
                </p>
              </>
            )}
          </div>
        )}
        addCustomItemTitle="Добавить аксессуары"
        handleAddCustomItem={openCarsModal}
        backHandler={backHandler}
      />
      <DealCreateEquipmentsModal
        isOpen={carsModal}
        onRequestClose={() => setCarsModal(false)}
        onSubmit={(_, equipments) => {
          onCarsFormSubmit(Object.keys(equipments).map((key) => equipments[key]));
        }}
        onCancel={() => setCarsModal(false)}
        //TODO хардкод пока не продуман интерфейс для кейса с несколькими машинами в сделке
        containerId={dealCars[0]?.container?.id}
        carId={dealCars[0]?.id}
        cityId={dealCars[0]?.cityId}
      />
    </>
  );
};

export default DealCreateEquipment;
