import React, { useState } from 'react';
import createRecord from '../../../api/services/createRecord';
import { useNavigate } from 'react-router-dom';
import getSources from '../../../api/resources/getSources';
import getServiceStatuses from '../../../api/services/getServiceStatuses';

// components
import Header from '../../../components/Header/Header';
import Form from '../../../components/Form/Form';
import Button from '../../../components/Button/Button';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Loader from '../../../components/Loader/Loader';
import Alert from '../../../components/Alert/Alert';
import SelectClients from '../../../components/Selects/SelectClients';
import SelectManagers from '../../../components/Selects/SelectManagers';
import SelectServiceStatuses from '../../../components/Selects/SelectServiceStatuses';
import Datepicker from '../../../components/Inputs/Datepicker/Datepicker';
import SelectSources from '../../../components/Selects/SelectSources';
import SelectServices from '../../../components/Selects/SelectServices';
import Select from '../../../components/Select/Select';
import HookForm from '../../../components/HookForm/HookForm';

// icons
import icon from '../../../assets/sidebar/records.svg';
import alert from '../../../helpers/alert';

const formStyles = {
  record: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a k k' 'b b b b' 'c c c c' 'd d n n' 'f f f f'` },
};

const AddRecordService = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = React.useState({});
  const [succes, setSucces] = React.useState({});
  const [service, setService] = useState([
    { name: 'disabled_date', type: 'input', label: 'Дата', value: '01.11.2022', disabled: true },
    { name: 'id', type: 'input', label: 'ID', value: '35567809', disabled: true },
    {
      name: 'userId',
      component: SelectClients,
      label: 'Клиент',
      placeholder: 'Выберите из списка',
      style: { gridArea: 'k' },
      rules: { required: true },
    },
    {
      name: 'statuse',
      component: SelectServiceStatuses,
      label: 'Статус записи',
      placeholder: 'Выберите из списка',
      rules: { required: true },
    },
    {
      name: 'sourceId',
      label: 'Источник',
      placeholder: 'Выберите из списка',
      component: SelectSources,
      rules: { required: true },
    },
    {
      name: 'created_at',
      type: 'date',
      value: new Date(),
      label: 'Дата',
      component: Datepicker,
      rules: { required: true },
    },
    {
      name: 'time',
      component: Select,
      label: 'Время',
      placeholder: 'Выберите из списка',
      options: [
        { value: '09:00', label: '09:00' },
        { value: '10:30', label: '10:30' },
        { value: '12:00', label: '12:00' },
        { value: '13:30', label: '13:30' },
        { value: '15:00', label: '15:00' },
        { value: '16:30', label: '16:30' },
      ],
      rules: { required: true },
    },
    {
      name: 'services',
      component: SelectServices,
      label: 'Услуга',
      placeholder: 'Выберите из списка',
      style: { gridArea: 'c' },
      isMulti: true,
      isSearchable: true,
      rules: { required: true },
    },
    {
      name: 'address',
      component: Select,
      label: 'Адрес дилерского центра',
      options: [{ value: 'Адрес', label: 'Адрес' }],
      placeholder: 'Выберите из списка',
      style: { gridArea: 'n' },
    },
    {
      name: 'managerId',
      component: SelectManagers,
      label: 'Ответственный менеджер',
      placeholder: 'Выберите из списка',
      style: { gridArea: 'd' },
    },
    {
      name: 'comment',
      type: 'input',
      label: 'Заметки/комментарии о записи',
      placeholder: 'Оставьте ваш комментарий',
      style: { gridArea: 'f' },
    },
  ]);

  const addRecord = (data) => {
    setLoading(true);
    const day = new Date(data?.created_at).toLocaleDateString().split('.').reverse().join('-');
    const time = data?.time?.value;
    const payload = {
      sourceId: data?.sourceId?.value,
      services: data?.services?.map((item) => ({ id: item.value })),
      date:
        day === 'Invalid Date'
          ? new Date().toLocaleDateString().split('.').reverse().join('-') + ' ' + time + ':00'
          : day + ' ' + time + ':00',
      typeId: 3,
      userId: data?.userId?.value,
    };

    createRecord(payload)
      .then((res) => {
        setSucces({ message: 'Запись добавлена' });
        setTimeout(() => {
          navigate('/records');
        }, 1000);
      })
      .catch((error) => {
        alert.error(error.response.data.errorMessage ?? JSON.stringify(error.response))
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading && <Loader />}
      <div style={{ paddingBottom: '20px' }}>
        <Header title="Новая запись на сервис" icon={icon} />
        <Breadcrumbs navigate={'/records'} link={'Записи'} currentPage={'Новая запись'} />
        <HookForm
          inputs={service}
          handlerSetState={setService}
          title={'Информация о записи'}
          grid={formStyles.record}
          border
          action={'Сохранить запись'}
          onSubmit={addRecord}
        />
      </div>
      {error.message && <Alert message={error.message} onClick={setError} />}
      {succes.message && <Alert message={succes.message} />}
    </>
  );
};

export default AddRecordService;
