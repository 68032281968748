import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import axios from '../../plugins/axios';

// components
import Header from '../../components/Header/Header';
import Breadcrumbs from '../../components/Breadcrumbs';
import Navbar from '../../components/Navbar/Navbar';

// icons
import newClient from '../../assets/header/newClient.svg';
import getClientsNavigationCount from '../../api/clients/getClientsNavigationCount';

const ClientsNavigation = () => {
  const { id } = useParams();
  const [client, setClient] = React.useState({});

  const init = async () => {
    await axios
      .get('/api/crm/clients/getClients', { params: { id: id } })
      .then((res) => {
        setClient(...res.data.users);
      })
      .catch((error) => console.log(error));
    await getClientsNavigationCount({ userId: id }).then((res) => {
      setItems([
        { text: 'Основные данные', navigate: id ? `/client/${id}/main-data` : '/client/create' },
        { text: 'Документы', navigate: id ? `/client/${id}/documents` : '/client/documents' },
        { text: 'Сделки', navigate: id ? `/client/${id}/deals` : '/client/deals', count: res.data.deals },
        { text: 'Записи', navigate: id ? `/client/${id}/records` : '/client/records', count: res.data.services },
      ]);
    });
  };

  React.useEffect(() => {
    if (!id) {
      setItems([{ text: 'Основные данные', navigate: id ? `/client/${id}/main-data` : '/client/create' }]);
    } else {
      setItems([
        { text: 'Основные данные', navigate: id ? `/client/${id}/main-data` : '/client/create' },
        { text: 'Документы', navigate: id ? `/client/${id}/documents` : '/client/documents' },
        { text: 'Сделки', navigate: id ? `/client/${id}/deals` : '/client/deals', count: 0 },
        { text: 'Записи', navigate: id ? `/client/${id}/records` : '/client/records', count: 0 },
      ]);
    }
    init();
  }, []);

  const [items, setItems] = useState([
    { text: 'Основные данные', navigate: id ? `/client/${id}/main-data` : '/client/create' },
  ]);

  return (
    <>
      <Header title={id ? `${client.surname} ${client.name} - ID ${client.id}` : 'Новый клиент'} icon={newClient} />
      <Breadcrumbs
        navigate={'/'}
        link={'Клиенты'}
        currentPage={id ? `${client.surname} ${client.name} - ID ${client.id}` : 'Новый клиент'}
      />
      <Navbar items={items} />
      <Outlet context={[client, setClient]} />
    </>
  );
};

export default ClientsNavigation;
