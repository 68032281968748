import React, { useEffect, useState } from 'react';
import { CONTAINERS } from '../../../constants/columns';
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { carsOptions } from '../../../constants/filterOptions';
import { useNavigate } from 'react-router-dom';
import getContainers from '../../../api/cars/getContainers';

// components
import Header from '../../../components/Header/Header';
import Table from '../../../components/Table/Table';
import TableTabs from '../../../components/TableTabs/TableTabs';
import Empty from '../../../components/Empty/Empty';

// icons
import icon from '../../../assets/sidebar/cars.svg';

const columns = CONTAINERS;

const CarContainers = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    pageSize: 10,
  });
  const [isFetchAll, setFetchAll] = useState(false);

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: pagination.pageSize,
      },
      manualPagination: true,
      pageCount: pagination.totalPages,
      page: pagination.page - 1,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { setGlobalFilter, page, setPageSize } = table;

  const [value, setValue] = useState('');
  const [queryParams, setQueryParams] = useState({});

  const handleNavigate = () => {
    navigate('/cars/containers/create');
  };

  const goToCard = (index) => {
    navigate('/cars/containers/' + index);
  };

  const init = (page) => {
    setLoading(true);
    getContainers({ ...page })
      .then((res) => {
        setData(res.data.containers);
        setPagination({
          ...res.data.pagination,
          page: res.data.pagination?.pageNumber,
          pageSize: pagination.pageSize,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!isFetchAll) {
      init({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams });
    }
  }, [pagination.page, pagination.pageSize, queryParams]);

  return (
    <>
      <Header title="Марка и модель" icon={icon} />
      <TableTabs
        setFilter={setGlobalFilter}
        options={carsOptions}
        value={value}
        setValue={setValue}
        navigate={handleNavigate}
        button={'Добавить контейнер'}
        inputPlaceholder={'Быстрый поиск по маркам и моделям'}
      />
      <br />
      {!page.length && !loading ? (
        <Empty />
      ) : (
        <Table
          table={table}
          handleNavigate={goToCard}
          all={'марок'}
          p={pagination}
          loading={loading}
          pagination
          setPagination={setPagination}
          setFetchAll={setFetchAll}
          isFetchAll={isFetchAll}
          setData={setData}
          setLoading={setLoading}
          queryParams={queryParams}
          api={'/api/shop/getContainers'}
          response={'containers'}
        />
      )}
    </>
  );
};

export default CarContainers;
