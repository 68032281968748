import React, { useEffect, useState } from 'react';

// components
import Header from '../../components/Header/Header';
import Breadcrumbs from '../../components/Breadcrumbs';

// icons
import icon from '../../assets/sidebar/equipment.svg';
import { useNavigate, useParams } from 'react-router-dom';
import HookForm from '../../components/HookForm/HookForm';
import InputNumeric from '../../components/Inputs/Input/InputNumeric';
import SelectEquipmentType from '../../components/Selects/SelectEquipmentType';
import getEquipments from '../../api/deals/getEquipments';
import LoaderInline from '../../components/Loader/LoaderInline';
import editEquipments from '../../api/equipments/editEquipment';
import SelectCity from '../../components/Selects/SelectCity';

const formStyles = {
  equipment: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a a a' 'b b c c' 'e e e e' 'd d d d'` },
  other: { display: 'grid', gap: '20px' },
};

const EditEquipment = () => {
  const navigate = useNavigate();
  const { containerId, equipmentId } = useParams();
  const [data, setData] = useState({});
  const equipment = [
    {
      type: 'input',
      name: 'title',
      label: 'Название',
      placeholder: 'Укажите название дополнительного оборудования',
      style: { gridArea: 'a' },
      rules: { required: true },
      defaultValue: data?.title,
    },
    {
      component: SelectEquipmentType,
      name: 'typeId',
      label: 'Единица измерения',
      placeholder: 'Выберите из списка',
      rules: { required: true },
      defaultValue: { value: data?.typeId, label: data?.typeId === 1 ? 'Штука' : 'Комплект' },
    },
    {
      component: InputNumeric,
      name: 'count',
      label: 'Количество',
      placeholder: 'Укажите количество',
      rules: { required: true },
      defaultValue: data?.count,
    },
    {
      component: InputNumeric,
      name: 'price',
      label: 'Цена, KZT',
      placeholder: 'Укажите цену',
      style: { gridArea: 'c' },
      rules: { required: true },
      defaultValue: data?.price,
    },
    {
      component: SelectCity,
      name: 'cityId',
      label: 'Город',
      placeholder: 'Выберите из списка',
      style: { gridArea: 'e' },
      rules: { required: true },
      defaultValue: { value: data?.cityId, },
    },
    {
      name: 'comment',
      label: 'Заметки/комментарии о доп.оборудовании',
      placeholder: 'Оставьте комментарий об оборудовании',
      style: { gridArea: 'd' },
      defaultValue: data?.comment,
    },
  ];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getEquipments({ containerId, id: equipmentId }).then((res) => {
      const equipment = res?.data?.equipments?.[0];

      setData(equipment);
      setLoading(false);
    });
  }, [equipmentId, containerId]);

  const onSave = (values) => {
    setLoading(true);
    editEquipments({
      ...values,
      id: equipmentId,
      containerId,
      typeId: values.typeId?.value || values.typeId,
    })
      .then(() => {
        navigate(-1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) return <LoaderInline />;

  return (
    <HookForm
      inputs={equipment}
      title={'Информация о дополнительном оборудовании'}
      grid={formStyles.equipment}
      border
      onSubmit={onSave}
      action={'Сохранить'}
    />
  );
};

export default EditEquipment;
