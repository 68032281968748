import React, { useState } from 'react';

// components
import EmptyData from '../../../components/EmptyData/EmptyData';

// icons
import icon from '../../../assets/buttonIcons/addDeal.svg';

const NewCarDeal = () => {
  return (
    <>
      <EmptyData
        title={'Сделка'}
        subtitle={'Это автомобиль пока не участвует ни в одной сделке'}
        action="Создать сделку с автомобилем"
        icon={icon}
      />
    </>
  );
};

export default NewCarDeal;
