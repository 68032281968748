import { useState } from 'react';
import Input from '../../components/Inputs/Input/Input';
import { MASK } from '../../constants/mask';
import LoaderInline from '../../components/Loader/LoaderInline';
import { textStyles } from '../../constants/textStyles';
import Button from '../../components/Button/Button';
import sendVerifyCode from '../../api/resources/sendVerifyCode';
import alert from '../../helpers/alert';
import accessVerifyCode from '../../api/resources/accessVerifyCode';
import HookForm from '../../components/HookForm/HookForm';
import deleteAccount from '../../api/auth/deleteAccount';

const TIMEOUT_FOR_RESEND = 30;

const DeleteAccount = () => {
  const [loading, setLoading] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [phone, setPhone] = useState(null);
  const [countDown, setCountDown] = useState(TIMEOUT_FOR_RESEND);
  const [countDownInterval, setCountDownInterval] = useState(null);
  const sendOtp = () => {
    setLoading(true);
    sendVerifyCode({
      phone,
    })
      .then(() => {
        setIsCodeSent(true);
        clearInterval(countDownInterval);
        setCountDown(TIMEOUT_FOR_RESEND);

        const interval = setInterval(() => {
          setCountDown((prev) => {
            if (prev === 0) {
              clearInterval(countDownInterval);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
        setCountDownInterval(interval);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkCode = (values) => {
    setLoading(true);
    accessVerifyCode({
      phone,
      code: values.code,
    }).then((res) => {
      if (res?.data) {
        deleteAccount
          .then(() => {
            setIsFinished(true);
          })
          .catch(() => {
            alert.error('Произошла ошибка. Повторите попытку.');
          })
          .finished(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
        alert.error('Неверный код');
      }
    });
  };

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '90vh',
        }}
      >
        <LoaderInline />
      </div>
    );
  }

  if (isFinished) {
    return (
      <div
        style={{
          padding: '50px 15px',
          maxWidth: 500,
          margin: '0 auto',
        }}
      >
        <h1
          style={{
            ...textStyles.formTitle,
            marginBottom: 20,
          }}
        >
          Личный кабинет пользователя {phone} успешно удален.
        </h1>
      </div>
    );
  }

  return (
    <div
      style={{
        padding: '50px 15px',
        maxWidth: 500,
        margin: '0 auto',
      }}
    >
      <h1
        style={{
          ...textStyles.formTitle,
          marginBottom: 20,
        }}
      >
        Удаление учетной записи пользователя приложения Doscar
      </h1>
      <Input
        mask={MASK.PHONE}
        label="Номер телефона"
        value={phone}
        onChange={(e) => {
          setPhone(e?.target?.value);
        }}
        disabled={isCodeSent}
      />
      {isCodeSent ? (
        <>
          <HookForm
            grid={{
              display: 'grid',
              gap: '10px',
              gridTemplateColumns: '1fr',
            }}
            onSubmit={checkCode}
            inputs={[
              {
                name: 'code',
                label: 'Код из СМС',
                type: 'text',
                required: true,
                rules: {
                  required: true,
                },
                validate: (value) => {
                  if (value.length !== 4) {
                    return 'Неверный формат кода';
                  }
                  return true;
                },
              },
            ]}
            customData={(submit) => (
              <>
                {countDown ? (
                  <p style={{ textAlign: 'center' }}>Отправить код повторно через {countDown} секунд</p>
                ) : (
                  <Button text={'Отправить код повторно'} onClick={sendOtp} />
                )}
                <br />
                <Button text="Подтвердить" fill full onClick={submit} />
              </>
            )}
          />
        </>
      ) : null}
      {!isCodeSent ? (
        <>
          <br />
          <Button fill full text={'Подтвердить'} onClick={sendOtp} />
        </>
      ) : null}
    </div>
  );
};

export default DeleteAccount;
