import Modal from '../../../components/Modal/Modal';
import Cars from '../../Car/Cars';

const DealCreateCarsModal = ({ isOpen, onClose, onSubmit, onCancel, defaultQueryParams }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel}>
      <Cars
        header={false}
        onSubmit={onSubmit}
        onCancel={onCancel}
        withCheckboxes
        defaultQueryParams={defaultQueryParams || { 
          // stock: true
        }}
      />
    </Modal>
  );
};

export default DealCreateCarsModal;
