import React, { useState } from 'react';
import styles from './calendarDay.module.css';
import { getDaysInMonth } from '../../../../plugins/calendar';
import { isLeapYear } from '../../../../plugins/calendar';
import { colors } from '../../../../constants/colors';

const months = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];
const time = [
  { from: '09:00', to: '10:30 ' },
  { from: '10:30', to: '12:00 ' },
  { from: '12:00', to: '13:30 ' },
  { from: '13:30', to: '15:00 ' },
  { from: '15:00', to: '16:30' },
];

const CalendarDay = ({ day, month, onClick, records }) => {
  const currentDayRecords = records.filter(
    (item) => new Date(item.date).toLocaleDateString() === day.toLocaleDateString()
  );

  return (
    <div className={styles.wrapper} onClick={() => onClick(day, currentDayRecords)}>
      <p className={styles.day}>
        {new Date(day).getDate()} {months[month]}
      </p>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {time.map((time, index) => (
          <div className={styles.time} key={index}>
            <p style={{ flex: 1, textAlign: 'left' }}>{`${time.from} - ${time.to}`}</p>
            <p style={{ color: colors.red }}>
              {
                currentDayRecords.filter(
                  (item) =>
                    (item.date.split('T')[1].substring(0, 5) === time.from ||
                      item.date.split('T')[1].substring(0, 5) === time.to) &&
                    item?.status?.title === 'Завершена не успешно'
                ).length
              }
            </p>
            <p style={{ color: '#daaf05', flex: 0.2 }}>
              {
                currentDayRecords.filter(
                  (item) =>
                    (item.date.split('T')[1].substring(0, 5) === time.from ||
                      item.date.split('T')[1].substring(0, 5) === time.to) &&
                    item?.status?.id === 1
                ).length
              }
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarDay;
