import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import getClients from '../../api/clients/getClients';
import useDebounce from '../../hooks/useDebounce';
import Select from '../Select/Select';

const SelectClients = ({ handleSetSelect, handleSetState, style, ...props }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);

  const [totalCount, setTotalCount] = useState(0);
  const [client, setClient] = useState('');
  const debouncedSearchTerm = useDebounce(client, 500);
  const getUriParams = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const onFocus = useCallback(() => {
    if (page != 1 && page > totalCount) return;
    let object = { page: page };
    object.id = getUriParams.userId;
    setLoading(true);
    getClients(object).then((res) => {
      const clients = res.data.users.map((item) => {
        return { value: item.id, label: item.name + ' ' + item.surname + ' (ИИН: ' + item.iin + ')'};
      });

      setData((prev) => [...prev, ...clients]);
      setTotalCount(res.data.pagination.totalPages);
      setPage((prev) => prev + 1);
    }).finally(() => setLoading(false));
  }, [getUriParams.userId, page, totalCount]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      if(client?.length < 1) {
        return onFocus();
      }
      setLoading(true);
      getClients({ fio: client }).then((res) => {
        const clients = res.data.users.map((item) => ({ value: item.id, label: item.name + ' ' + item.surname }));
        setData(clients)
        setPage(1);
      }).finally(() => setLoading(false));
    }
  }, [debouncedSearchTerm]);

  return (
    <Select
      handleSetSelect={(val) => setClient(val)}
      handleSetState={handleSetState}
      options={data}
      title={'Клиент'}
      placeholder={'Выберите из списка'}
      onFocus={onFocus}
      style={style}
      isSearchable
      isLoading={loading}
      {...props}
    />
  );
};

export default SelectClients;
