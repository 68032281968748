import React, { useState } from 'react';
import axios from '../../plugins/axios';
import styles from '../Client/ClientMainData/clientMainData.module.css';
import getBirthDateFromIIN from '../../utils/getBirthDateFromIIN';
import { get } from 'react-hook-form';
import editProfile from '../../api/clients/editProfile';
import api from '../../plugins/api';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import checkCrmSidebarNotifications from '../../api/notifications/checkCrmSidebarNotifications';
import getClients from '../../api/clients/getClients';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import getClientDocuments from '../../api/clients/getClientDocuments';

// components
import ClientCheck from '../../components/ClientCheck/ClientCheck';
import Alert from '../../components/Alert/Alert';
import Loader from '../../components/Loader/Loader';
import NotaryForm from '../../components/NotaryForm/NotaryForm';
import SelectPhoto from '../Client/NewClient/ClientCreate/SelectPhoto/SelectPhoto';
import Checkbox from '../../components/Inputs/Checkbox/Checkbox';

// icons
import phoneIcon from '../../assets/input/phone.svg';
import copyIcon from '../../assets/copy.svg';

import { iin, phone } from '../../constants/mask';
import { textStyles } from '../../constants/textStyles';
import Button from '../../components/Button/Button';
import copyToClipboard from '../../utils/copyToClipboard';
import alert from '../../helpers/alert';
import {
  CLIENT_NOTARIAL_VERIFICATION_APPROVED,
  CLIENT_NOTARIAL_VERIFICATION_OPTIONS,
  CLIENT_NOTARIAL_VERIFICATION_REJECTED,
} from '../../constants';
import { colors } from '../../constants/colors';

const ClientNotary = () => {
  const { id } = useParams();
  const [photo, setPhoto] = useState();
  const [uploadPhoto, setUploadPhoto] = useState();
  const [isDisableSelectPhoto, setDisableSelectPhoto] = useState(true);
  const [mainData, setMainData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [comment, setComment] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [hasPassedVerification, setPassedVerification] = useState(false);
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState();

  const init = () => {
    setLoading(true);
    getClients({ id })
      .then((res) => {
        const client = res.data.users[0];
        console.log(client);
        const [day, month, year] = getBirthDateFromIIN(client.iin).split('.');
        const mainData = [
          {
            name: 'surname',
            label: 'Фамилия',
            placeholder: 'Фамилия клиента',
            defaultValue: client.surname,
          },
          { name: 'name', label: 'Имя', placeholder: 'Имя клиента', defaultValue: client.name },
          {
            name: 'middlename',
            label: 'Отчество',
            placeholder: 'Отчество (если есть)',
            defaultValue: client.middlename,
          },
          {
            name: 'iin',
            label: 'ИИН',
            placeholder: '_ _ _ _ _ _ _ _ _ _ _',
            mask: iin,
            defaultValue: client.iin,
            component: (
              <button
                style={{ position: 'absolute', right: '5px', top: '0px' }}
                className="hover-lighten"
                onClick={() => {
                  copyToClipboard(client.iin);
                  alert.success('ИИН скопирован в буфер обмена');
                }}
              >
                <img src={copyIcon} alt="icon" />
              </button>
            ),
          },
          {
            name: 'born',
            label: 'Дата рождения',
            placeholder: 'дд-мм-гггг',
            defaultValue: new Date(year, month, day).toLocaleDateString(),
          },
          {
            label: 'Язык',
            placeholder: 'Выберите из списка',
            name: 'languageId',
            options: [
              { value: 1, label: 'Казақша' },
              { value: 2, label: 'Русский' },
            ],
            defaultValue: client?.language?.title,
          },
          {
            isCheckbox: true,
            label: client.vip ? 'VIP' : 'Посредник',
            defaultValue: true,
          },
        ];

        const contacts = [
          {
            name: 'phoneNumber',
            label: 'Мобильный телефон',
            placeholder: '+7(___) ___-__-__',
            mask: phone,
            defaultValue:
              client.phone[1] === '7'
                ? formatPhoneNumber(client.phone)
                : `+7 ${formatPhoneNumber(client.phone.substring(1))}`,
            icon: phoneIcon,
          },
          {
            name: 'email',
            label: 'E-mail',
            placeholder: 'Укажите адрес электронной почты клиента',
            defaultValue: client.email,
          },
        ];
        client.phones.forEach((phoneItem, index) => {
          contacts.push({
            name: `phoneNumber_${index}`,
            label: [
              { value: 1, label: 'Мобильный телефон' },
              { value: 2, label: 'Домашний телефон' },
              { value: 3, label: 'Корпоративный телефон' },
            ].filter((item) => item.value === phoneItem.typeId)[0].label,
            defaultValue: `+7 ${formatPhoneNumber(phoneItem.number.substring(1))}`,
          });
        });
        client.addresses.forEach((address, index) => {
          contacts.push({
            name: `addressType_${index}`,
            label: [
              { value: 1, label: 'Адрес регистрации' },
              { value: 2, label: 'Адрес проживания' },
            ].filter((item) => item.value === address.typeId)[0].label,
            defaultValue: address.address,
          });
        });
        client.socials.forEach((social, index) => {
          contacts.push({
            name: `socialType_${index}`,
            label: [
              { value: 1, label: 'Facebook' },
              { value: 2, label: 'VK' },
              { value: 3, label: 'Instagram' },
              { value: 4, label: 'Telegram' },
            ].filter((item) => item.value === social.typeId)[0].label,
            defaultValue: social.value,
          });
        });

        const comments = [];
        client.comments.forEach((comment) => {
          comments.push({
            name: 'comment',
            placeholder: 'Укажите любые заметки или комментарии о клиенте',
            defaultValue: comment.comment,
          });
        });

        setMainData(mainData);
        setContacts(contacts);
        setComment(comments);
        setPhoto(client.photo);
        setPassedVerification(client.notarial_verification === CLIENT_NOTARIAL_VERIFICATION_APPROVED);
        setClient(client);
      })
      .then(() => {
        getClientDocuments({ userId: id }).then((res) => {
          const docs = res.data?.map((doc) => ({
            label: doc?.typeId,
            component: (
              <a
                href={doc?.url}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: colors.cameo,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                Скачать
                <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8 5.5H10V15.5H8V5.5ZM12 9.5H14V15.5H12V9.5ZM4 11.5H6V15.5H4V11.5ZM12 2.5H2V18.5H16V6.5H12V2.5ZM0 1.492C0 0.944 0.447 0.5 0.999 0.5H13L18 5.5V19.493C18.0009 19.6243 17.976 19.7545 17.9266 19.8762C17.8772 19.9979 17.8043 20.1087 17.7121 20.2022C17.6199 20.2957 17.5101 20.3701 17.3892 20.4212C17.2682 20.4723 17.1383 20.4991 17.007 20.5H0.993C0.730378 20.4982 0.479017 20.3931 0.293218 20.2075C0.107418 20.0219 0.00209465 19.7706 0 19.508V1.492Z"
                    fill="#D9BC92"
                  />
                </svg>
              </a>
            ),
          }));

          setDocuments(docs);
        });
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    init();
  }, []);
  const navigate = useNavigate();
  const save = () => {
    setLoading(true);
    editProfile({
      id,
      notarial_verification: hasPassedVerification
        ? CLIENT_NOTARIAL_VERIFICATION_APPROVED
        : CLIENT_NOTARIAL_VERIFICATION_REJECTED,
    })
      .then(() => {
        alert.success('Данные успешно сохранены');
        if (hasPassedVerification) {
          checkCrmSidebarNotifications({
            notificationIds: client?.notifications.map((i) => i?.id),
          });
        }
        navigate('/notary/clients');
      })
      .catch((err) => {
        alert.error('Произошла ошибка при сохранении данных');
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.wrapper}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <SelectPhoto
                photo={photo}
                setPhoto={setPhoto}
                setUploadPhoto={setUploadPhoto}
                style={{ flex: 1 }}
                disabled={isDisableSelectPhoto}
              />
              <p className={styles.date} style={{ margin: '20px 0' }}>
                Клиент зарегистрирован: <span>01.09.2022</span>
              </p>
              <ClientCheck
                list={[
                  {
                    title: 'Нотариус',
                    statusLabel: CLIENT_NOTARIAL_VERIFICATION_OPTIONS?.find(
                      (item) => item?.value === client?.notarial_verification
                    )?.label,
                    color: '#6A6A6A',
                    status:
                      client?.notarial_verification == 1
                        ? 'pending'
                        : client?.notarial_verification == 2
                        ? 'success'
                        : 'reject',
                  },
                ]}
                onRefresh={init}
              />
            </div>
            <div className={styles.forms}>
              <NotaryForm title={'Основные данные'} form={mainData} />
              <NotaryForm title={'Контактные данные'} form={contacts} />
              <NotaryForm title={'Дополнительная информация'} form={comment} />
              <NotaryForm title={'Документы'} form={documents} />
              <div style={{ backgroundColor: 'white', padding: '30px 20px' }}>
                <div>
                  <p style={{ ...textStyles.formTitle, marginBottom: '16px' }}>Нотариальная проверка</p>
                </div>
                <div style={{ display: 'flex', columnGap: '15px' }}>
                  <p>Прошел проверку на возможность регистрационных действий</p>
                  <Checkbox
                    id={'id' + Math.random().toString(16).slice(2)}
                    value={hasPassedVerification ? true : false}
                    onClick={() => setPassedVerification(true)}
                    label={'Да'}
                  />
                  <Checkbox
                    id={'id' + Math.random().toString(16).slice(2)}
                    value={hasPassedVerification ? false : true}
                    onClick={() => setPassedVerification(false)}
                    label={'Нет'}
                  />
                </div>
              </div>
              <Button text={'Сохранить изменения'} onClick={save} fill style={{ width: '280px' }} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ClientNotary;
