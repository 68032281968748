import alert from '../../helpers/alert';
import api from '../../plugins/api';

const sendVerifyCode = ({ phone, dealId }) => {
  return api
    .get('/api/resource/sendVerifyCode', { phone, dealId })
    .then(() => {
      alert.success('Код подтверждения успешно отправлен', {
        autoClose: 3000,
      });
    })
    .catch(() => {
      alert.error('Не удалось отправить код подтверждения');
    });
};

export default sendVerifyCode;
