import React, { useState } from 'react';
import styles from '../deal.module.css';
import { textStyles } from '../../../../constants/textStyles';
import formatNumber from '../../../../utils/formatNumber';
import {
  DEAL_STATUS_CONTRACT_SIGNED,
  DEAL_STATUS_DOCUMENTS_PROCESSING,
  DEAL_STATUS_WAITING_FOR_PAYMENT,
  DOCUMENT_STATUS_SIGNED,
  DOCUMENT_TYPES_CONTRACT,
  PAYMENT_STATUS_PAID,
  PAYMENT_TYPES_CREDIT_CARD,
  PAYMENT_TYPES_CREDIT_INVOICE,
  PAYMENT_TYPES_PREPAYMENT_CARD,
  PAYMENT_TYPES_PREPAYMENT_INVOICE,
} from '../../../../constants';
import { colors } from '../../../../constants/colors';
import DealCarModal from '../../../../components/Modals/DealCarModal';
import ButtonDelete from '../../../../components/Button/ButtonDelete';
import removeItemsToDeal from '../../../../api/deals/removeItemsToDeal';
import Button from '../../../../components/Button/Button';
import setStep from '../../../../api/deals/setStep';
import editDeal from '../../../../api/deals/editDeal';
import SelectDealPaymentStatuses from '../../../../components/Selects/selectDealPaymentStatuses';
import alert from '../../../../helpers/alert';
import LoaderInline from '../../../../components/Loader/LoaderInline';

const DealCreateSummary = ({
  currentStep,
  setCurrentStep,
  state,
  sum,
  deal,
  equipments,
  services,
  cars,
  removeEquipment,
  removeService,
  documents,
  backHandler,
  update,
}) => {
  const [open, setOpen] = useState(false);
  const [containerId, setContainerId] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingSignButton, setLoadingSignButton] = useState(false);
  const [loadingPaymentButton, setLoadingPaymentButton] = useState(false);

  const steps =
    deal?.paymentType?.id == PAYMENT_TYPES_CREDIT_CARD || deal?.paymentType?.id == PAYMENT_TYPES_CREDIT_INVOICE
      ? ['Основная информация', 'Доп.оборудование', 'Доп.услуги', 'Уточнение данных', 'Банк', 'Документы']
      : ['Основная информация', 'Доп.оборудование', 'Доп.услуги', 'Оплата', 'Документы', 'Регистрация ТС'];

  const navigateToClient = (id) => {
    window.open(`/client/${id}/main-data`, '_blank');
  };

  const openCarModal = (containerId) => {
    setContainerId(containerId);
    setOpen(true);
  };

  const closeCarModal = () => {
    setOpen(false);
  };
  const updatePaymentStatus = (status) => {
    setLoading(true);

    const isContractSigned = documents?.find(
      (i) => i?.typeId === DOCUMENT_TYPES_CONTRACT && i?.statusId === DOCUMENT_STATUS_SIGNED
    );
    let newStatus = DEAL_STATUS_DOCUMENTS_PROCESSING;

    if (status === PAYMENT_STATUS_PAID) {
      // для кейсов с возвратом на шаг назад
      // если договор уже подписан - статус сделки "Договор подписан", а не промежуточный "Документы обрабатываются"
      newStatus = isContractSigned ? DEAL_STATUS_CONTRACT_SIGNED : DEAL_STATUS_DOCUMENTS_PROCESSING;
    } else {
      newStatus = DEAL_STATUS_WAITING_FOR_PAYMENT;
    }

    editDeal({
      dealId: deal?.id,
      id: deal?.id,
      statusPaymentId: status,
      clientId: deal?.client?.id,
      paymentTypeId: deal?.paymentType?.id,
      statusId: newStatus,
      ...deal,
    })
      .then(() => {
        alert.success('Статус оплаты успешно обновлен');
        if (status === PAYMENT_STATUS_PAID) {
          setStep({
            dealId: deal?.id,
            step: 10,
          })
            .then(() => {
              update && update();
            })
            .catch(() => {
              alert.error('Ошибка обновления статуса сделки');
            });
        }

        update && update();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [paymentStatus, setPaymentStatus] = useState(null);

  const sendPaymentSms = () => {
    setLoadingPaymentButton(true);
    editDeal({
      dealId: deal?.id,
      id: deal?.id,
      paymentTypeId: PAYMENT_TYPES_PREPAYMENT_CARD,
      statusId: deal?.status?.id,
      generatePaymentLink: true,
      ...deal,
    })
      .then(() => {
        alert.success('СМС успешно отправлено');
      })
      .catch(() => {
        alert.error('Ошибка отправки СМС');
      })
      .finally(() => {
        setLoadingPaymentButton(false);
      });
  };

  if (loading) return <LoaderInline />;

  return (
    <div className={styles.DealSteps}>
      <p style={{ ...textStyles.formTitle, marginBottom: 20 }}>Новая сделка (доп.оборудование)</p>
      {deal && (
        <>
          <p style={{ ...textStyles.formTitle, marginBottom: 20 }}>Основная информация</p>

          <div className={styles.stepsRow} style={{ marginBottom: 20 }}>
            <p className={styles.stepsRow__name}>Клиент:</p>
            <a
              className={styles.stepsRow__value}
              style={{ color: colors?.cameo, textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                navigateToClient(deal?.client?.id);
              }}
            >
              {deal?.client?.name} {deal?.client?.surname}
            </a>

            <p className={styles.stepsRow__name}>Статус сделки:</p>
            <p className={styles.stepsRow__value}>{deal?.status?.title}</p>

            <p className={styles.stepsRow__name}>Способ оплаты:</p>
            <p className={styles.stepsRow__value}>{deal?.paymentType?.title}</p>
            <p className={styles.stepsRow__name}>Статус оплаты:</p>
            <p className={styles.stepsRow__value}>{deal?.statusPayment?.title}</p>
          </div>
        </>
      )}
      {equipments?.length > 0 && (
        <>
          <p style={{ ...textStyles.formTitle, marginBottom: 20 }}>Аксессуары</p>

          <div
            className={styles.stepsRow}
            style={{ marginBottom: 20, gridTemplateColumns: '150px 1fr auto auto', alignItems: 'center' }}
          >
            {equipments?.map((item, index) => {
              return (
                <>
                  <p className={styles.stepsRow__name}>Аксессуары {index + 1}:</p>
                  <p
                    className={styles.stepsRow__value}
                    style={{ ...textStyles.buttonText, textDecoration: 'underline' }}
                  >
                    <div key={item.id}>{item?.title}</div>
                  </p>
                  <p>{item?.count}</p>
                  <ButtonDelete
                    onClick={() => {
                      removeEquipment && removeEquipment(item?.id);
                    }}
                  />
                </>
              );
            })}
          </div>
        </>
      )}
      {services?.length > 0 && (
        <>
          <p style={{ ...textStyles.formTitle, marginBottom: 20 }}>Дополнительные услуги:</p>

          <div
            className={styles.stepsRow}
            style={{ marginBottom: 20, gridTemplateColumns: '150px 1fr auto', alignItems: 'center' }}
          >
            {services?.map((item, index) => {
              return (
                <>
                  <p className={styles.stepsRow__name}>Услуга {index + 1}:</p>
                  <p
                    className={styles.stepsRow__value}
                    style={{ ...textStyles.buttonText, textDecoration: 'underline' }}
                  >
                    <div key={item.id}>{item?.title}</div>
                  </p>

                  <ButtonDelete
                    onClick={() => {
                      removeService && removeService(item?.id);
                    }}
                  />
                </>
              );
            })}
          </div>
        </>
      )}

      {sum > 0 && (
        <>
          <hr style={{ border: '1px solid #D9D9D9', margin: '20px 0' }} />
          <div className={styles.stepsRow}>
            <p className={styles.stepsRow__name}>Сумма сделки, KZT:</p>
            <p className={styles.stepsRow__value} style={{ ...textStyles.buttonText, color: '#05141F' }}>
              {formatNumber(sum)}
            </p>
          </div>
        </>
      )}
      <div style={{ marginBottom: 40 }}>
        {documents?.map((document) => (
          <a
            href={document?.url}
            target="_blank"
            style={{
              gap: 7,
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              alignItems: 'center',
              marginBottom: 20,
            }}
            rel="noreferrer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
              <path
                d="M2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16ZM12 6H17L10 13L3 6H8V0H12V6Z"
                fill="#D9BC92"
              />
            </svg>
            <a style={{ color: '#D9BC92', fontSize: 16, fontWeight: 400 }}>
              {document?.type?.title} - {document?.url?.split('/')?.pop()}
            </a>
            {/* <ButtonDelete /> */}
          </a>
        ))}
      </div>

      {deal?.paymentType?.id === PAYMENT_TYPES_PREPAYMENT_INVOICE && (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
          <SelectDealPaymentStatuses label="Статус оплаты" onChange={setPaymentStatus} />
        </div>
      )}

      {deal?.paymentType?.id === PAYMENT_TYPES_PREPAYMENT_CARD && (
        <>
          <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px', marginTop: 20, marginBottom: 20 }}>
            <p style={textStyles.formTitle}>Оплата счета on-line</p>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
            <Button text="Отправить смс со ссылкой на оплату" fill onClick={sendPaymentSms} />
            <div
              style={{
                border: '1px solid #6A6A6A',
                textTransform: 'uppercase',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...textStyles.fieldLabel,
              }}
            >
              {deal?.statusPayment?.title}
            </div>
          </div>
        </>
      )}

      {/* {deal?.statusPayment?.id == PAYMENT_STATUS_PAID && <p>Ожидает нотариальных документов</p>} */}

      <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
        {deal?.paymentType?.id === PAYMENT_TYPES_PREPAYMENT_INVOICE && (
          <Button text="Сохранить" fill onClick={() => updatePaymentStatus(paymentStatus?.value)} />
        )}
        {backHandler && backHandler()}
      </div>

      <DealCarModal isOpen={open} onClose={closeCarModal} containerId={containerId} />
    </div>
  );
};

export default DealCreateSummary;
