import React, { useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { ADDITIONAL_SERVICES } from '../../constants/columns';
import { useOutletContext, useParams } from 'react-router-dom';

// components
import EmptyData from '../../components/EmptyData/EmptyData';
import Loader from '../../components/Loader/Loader';

// icons
import icon from '../../assets/buttonIcons/addDeal.svg';
import getDealServices from '../../api/deals/getDealServices';
import SelectAdditionalServicesModal from '../../components/Modals/SelectAdditionalServicesModal';
import addAdditionalServicesToDeal from '../../api/deals/addAdditionalServicesToDeal';
import addItemsToDeal from '../../api/deals/addItemsToDeal';
import bucketIcon from '../../assets/popover/delete.svg';
import axios from '../../plugins/axios';

const columns = ADDITIONAL_SERVICES;

const popover = [{ title: 'Удалить услугу', icon: bucketIcon }];

const DealServices = () => {
  const { id } = useParams();
  const [services, setServices] = useState([]);
  const [equipmentsCount, updateCounters] = useOutletContext();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const table = useTable(
    {
      columns,
      data: services,
    },
    usePagination
  );
  const init = async () => {
    let array = [];
    await getDealServices({ id: id })
      .then((res) => {
        res.data.forEach((item) => {
          array.push({
            id: item.additionalServicesId,
            itemId: item.id,
            title: item.name,
            unit: 'услуга',
            price: item.price,
          });
        });
        setServices([...array]);
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    init();
  }, []);

  const handleAddAdditionalServicesSubmit = (orderId, services) => {
    console.log(orderId);
    services = services.map((item) => ({ value: String(item?.id) }));

    addItemsToDeal({
      cars: [{ orderId: String(orderId), value: String(orderId) }],
      dealId: String(id),
      services,
    })
      .then(() => {
        setShowModal(false);
        updateCounters();
        init();
      })
      .catch((err) => {
        alert(err)
      });
  };

  const handleActionPopover = async (dealIndex, actionIndex, title, itemId, type) => {
    if (type === 'services') {
      axios
        .post('/api/crm/deals/removeItemsToDeal', {
          services: [{ value: itemId }],
          equipments: [],
          cars: [],
          dealId: id,
        })
        .then((res) => {
          window.location.href = window.location.href;
        })
        .catch((error) => {});
    }
  };
  const deleteItem = () => {};
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <EmptyData
            title={`Дополнительные услуги к сделке (${services.length})`}
            subtitle={'К этой сделке не добавлено ни одной дополнительной услуги.'}
            action={'Добавить услугу'}
            icon={icon}
            table={table}
            deleteType={'services'}
            data={services}
            func={() => setShowModal(true)}
            popover={popover}
            handleActionPopover={handleActionPopover}
          />
          <SelectAdditionalServicesModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            onSubmit={handleAddAdditionalServicesSubmit}
            dealId={id}
          />
        </>
      )}
    </>
  );
};

export default DealServices;
