import React from 'react';
import styles from './modalAccept.module.css';

// icons
import close from '../../assets/x.svg';
import Button from '../Button/Button';

const ModalAccept = ({ document, closeModal, handleDeleteForm }) => {
  return (
    <div className={styles.container}>
      <div style={{ width: '316px' }} />
      <div className={styles.wrapper}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p className={styles.title}>Удалить документ</p>
          <img src={close} alt="Icon" onClick={closeModal} />
        </div>
        <p className={styles.question}>
          Вы уверены, что хотите удалить документ <br />
          <span>{document}</span>
          <br /> без возможности востановления?
        </p>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Button text={'Да, удалить'} fill onClick={handleDeleteForm} />
          <Button text={'Нет, не удалить'} onClick={closeModal} />
        </div>
      </div>
    </div>
  );
};

export default ModalAccept;
