import React, { useState } from 'react';
import { LEAD_CLIENTS } from '../../constants/columns';
import { useTable, usePagination } from 'react-table';
import { useNavigate } from 'react-router-dom';

// components
import ModalForm from '../../components/ModalForm/ModalForm';
import EmptyData from '../../components/EmptyData/EmptyData';
import Alert from '../../components/Alert/Alert';
import Loader from '../../components/Loader/Loader';

// icons
import icon from '../../assets/buttonIcons/leadCreate.svg';
import getLeadCard from '../../api/leads/getLeadCard';
import { useParams } from 'react-router-dom';
import axios from '../../plugins/axios';
import getDealStatuses from '../../api/deals/getDealStatuses';
import getDealPaymentTypes from '../../api/deals/getDealPaymentTypes';
import getDealPaymentStatuses from '../../api/deals/getDealPaymentStatuses';
import alert from '../../helpers/alert';

const formStyles = {
  dealInfo: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a m m' 'b b b b' 'c c c c'` },
  autoForm: { display: 'grid', gap: '20px', gridTemplateColumns: `auto 44px` },
};

const columns = LEAD_CLIENTS;

const LeadClient = () => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = React.useState({});
  const [succes, setSucces] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [dealInfo, setDealInfo] = React.useState([
    { type: 'input', value: '', label: 'Дата', disabled: true },
    { type: 'input', label: 'ID', value: '', disabled: true },
    { type: 'selectClients', options: [], value: '', style: { gridArea: 'm' }, name: 'clientId' },
    { type: 'selectDealStatuses', value: '', name: 'statusId', options: [] },
    { type: 'selectDealPaymentTypes', value: '', name: 'paymentTypeId', options: [] },
    { type: 'selectDealPaymentStatuses', value: '', name: 'statusPaymentId', options: [] },
    { type: 'input', label: 'Сумма сделки, KZT', value: '', placeholder: 'Автоматический расчет', disabled: true },
    {
      type: 'input',
      label: 'Заметки/комментарии о сделке',
      value: '',
      placeholder: 'Ваш комментарий',
      style: { gridArea: 'c' },
    },
  ]);

  React.useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoading(true);
    await getLeadCard({ params: { id: id } })
      .then((res) => {
        clients[0] = {
          id: res.data.client?.id,
          name: res.data.client?.fullName,
          iin: res.data.client.iin,
          vip: res.data.client.vip,
          company: res.data.client.company,
          employe: res.data.client.employe,
          phone: '+' + res.data.client.phone,
          email: res.data.client.email,
          broker: 'нет',
          language: res.data.client.language,
        };
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
    setClients([...clients]);
  };

  const table = useTable(
    {
      columns,
      data: clients,
    },
    usePagination
  );

  const [autoForm, setAutoForm] = useState({
    title: 'Автомобиль',
    style: formStyles.autoForm,
    name: 'cars',
    form: [],
    value: [],
    add: [{ type: 'selectCars' }, { type: 'delete' }],
  });

  const [equipmentForm, setEquipmentForm] = useState({
    title: 'Аксессуары',
    style: formStyles.autoForm,
    name: 'equipments',
    form: [],
    value: [],
    add: [{ type: 'selectEquipments' }, { type: 'delete' }],
  });

  const [serviceForm, setServiceForm] = useState({
    title: 'Дополнительные услуги',
    style: formStyles.autoForm,
    name: 'additionalServices',
    form: [],
    value: [],
    add: [{ type: 'selectServices' }, { type: 'delete' }],
  });

  const [documentForm, setDocumentForm] = useState({
    title: 'Документы',
    style: formStyles.autoForm,
    action: 'Добавить еще документ к сделке',
    form: [],
    add: [{ type: 'select', value: 'ЫЫЫЫ', label: 'Услуга', placeholder: 'Выберите из списка' }, { type: 'delete' }],
  });

  const [actions, setActions] = useState([
    { show: true, text: 'Добавить автомобиль' },
    { show: true, text: 'Добавить аксессуары' },
    { show: true, text: 'Добавить дополнительную услугу' },
    // { show: true, text: 'Добавить документ к сделке' },
  ]);

  const createDeal = () => {
    setLoading(true);
    let deal = new Object();
    let services = serviceForm.value.length ? serviceForm.value.map((item) => ({ value: item.value })) : [];
    let equipments = equipmentForm.value.length ? equipmentForm.value.map((item) => ({ value: item.value })) : [];
    let cars = autoForm.value.length ? autoForm.value.map((item) => ({ value: item.value })) : [];
    dealInfo.map((item) => {
      if (item.name) deal[item.name] = item.value;
    });

    axios
      .post('/api/crm/deals/createDealFromLead', {
        ...deal,
        services: services,
        equipments: equipments,
        cars: cars,
      })
      .then((res) => {
        setSucces({ message: 'Новая сделка создана' });
        setTimeout(() => {
          navigate('/deals');
        }, 1000);
      })
      .catch((error) => alert.error(error.response.data.errorMessage ?? JSON.stringify(error.response)))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading && <Loader />}
      <EmptyData
        title={'Клиент'}
        subtitle={'Это лид еще не конвертирован в клиента...'}
        action="Создать клиента из лида"
        icon={icon}
        func={() => setShowModal(true)}
        table={table}
        data={clients}
      />
      {showModal && (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            display: 'flex',
          }}
        >
          <div style={{ width: '316px' }} />
          <ModalForm
            handleSubmit={createDeal}
            title={'Новая сделка с клиентом'}
            closeModal={() => setShowModal(false)}
            mainForm={dealInfo}
            handleSetState={setDealInfo}
            handleSetStateAuto={setAutoForm}
            formTitle={'Информация о сделке'}
            grid={formStyles.dealInfo}
            forms={[autoForm, equipmentForm, serviceForm, documentForm]}
            actions={actions}
            setActions={setActions}
            setForm={[setAutoForm, setEquipmentForm, setServiceForm, setDocumentForm]}
            button={'Сохранить сделку'}
            loading={loading}
          />
        </div>
      )}
      {error.message && <Alert message={error.message} onClick={setError} />}
      {succes.message && <Alert message={succes.message} />}
    </>
  );
};

export default LeadClient;
