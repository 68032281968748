import { useCallback, useState } from 'react';
import getDealStatuses from '../../api/deals/getDealStatuses';
import Select from '../Select/Select';
import {
  DEAL_STATUS_CAR_ISSUED,
  DEAL_STATUS_DOCUMENTS_PROCESSING,
  DEAL_STATUS_WAIRING_REGISTRATION,
  DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
  DEAL_STATUS_WAITING_FOR_PAYMENT,
  DEAL_STATUS_WAITING_FOR_SIGNING_CONTRACT,
  PAYMENT_TYPES_PREPAYMENT_CARD,
  PAYMENT_TYPES_PREPAYMENT_INVOICE,
} from '../../constants';

const SelectDealStatuses = ({ handleSetSelect, handleSetState, style, ...props }) => {
  const [data, setData] = useState([]);
  const onFocus = useCallback(() => {
    getDealStatuses().then((res) => {
      let statuses = res.data?.map((item) => ({ value: item.id, label: item.title }));
      if (
        props.defaultValue.paymentTypeId === PAYMENT_TYPES_PREPAYMENT_INVOICE ||
        props.defaultValue.paymentTypeId === PAYMENT_TYPES_PREPAYMENT_CARD
      ) {
        statuses = [
          { value: DEAL_STATUS_DOCUMENTS_PROCESSING, label: 'Оформление док-в' },
          { value: DEAL_STATUS_WAITING_FOR_PAYMENT, label: 'Ожидает оплаты' },
          { value: DEAL_STATUS_WAITING_FOR_SIGNING_CONTRACT, label: 'Ожидает подписания договора' },
          { value: DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS, label: 'Ожидает нотариальных документов' },
          { value: DEAL_STATUS_WAIRING_REGISTRATION, label: 'Ожидает постановки на учет' },
          { value: DEAL_STATUS_CAR_ISSUED, label: 'Машина выдана' },
        ];
      }
      setData(statuses);
    });
  }, [props.defaultValue.paymentTypeId]);

  return (
    <Select
      handleSetSelect={(val) => handleSetSelect && handleSetSelect(val)}
      handleSetState={handleSetState}
      options={data}
      title={'Статус сделки'}
      placeholder={'Выберите из списка'}
      onFocus={onFocus}
      style={style}
      {...props}
    />
  );
};

export default SelectDealStatuses;
