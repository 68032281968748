import React from 'react';
import styles from './permissions.module.css'
import PermissionCell from './PermissionCell';

const PermissionRow = ({ role, state, handleChange }) => {
  return(
    <tr className={styles.row}>
      <td className={styles.cell}>{role.title}</td>
      
      {role.options.map(item => {
        let value = null;
        if(state[role.id] && state[role.id][item.id]) {
          value = state[role.id][item.id];
        } else {
          value = item.active ? {value: 'Да', label:'Да'} : {value: 'Нет', label:'Нет'};
        }

        return(
          <>
          <PermissionCell
            key={item.id}
            permission={item.title}
            value={value}
            options={['Да', 'Нет']}
            handleChange={(value) => handleChange(role.id, item.id, value)}
          /></>
        )
      })}
    </tr>
  )
};

  export default PermissionRow