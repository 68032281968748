import HookForm from '../../../../components/HookForm/HookForm';
import DocsInputNew from '../../../../components/Inputs/DocsInput/DocsInputNew';

const FreedomCarRegistration = ({ onSubmit, loading, backHandler }) => {
  return (
    <div>
      <HookForm
        title={'Документы'}
        grid={{ display: 'grid', gap: 20 }}
        action={'Сохранить и продолжить'}
        inputs={[
          {
            label: 'ГРНЗ',
            name: 'grnz',
            id: 'grnz',
            rules: {
              required: true,
            },
          },
          {
            text: 'Техпаспорт',
            label: 'Загрузить документ',
            name: 'Техпаспорт',
            id: 'Техпаспорт',
            component: DocsInputNew,
            rules: {
              required: true,
            },
          },
        ]}
        onSubmit={onSubmit}
        loading={loading}
        backHandler={backHandler}
      />
    </div>
  );
};

export default FreedomCarRegistration;
