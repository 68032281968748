import { textStyles } from '../../../../constants/textStyles';
import styles from '../deal.module.css';

const DocumentsList = ({ documents }) => {
  return (
    <>
      <p style={{ ...textStyles.formTitle, marginBottom: 20 }}>Документы:</p>

      <div className={styles.stepsRow} style={{ gridTemplateColumns: '150px 1fr', alignItems: 'center' }}>
        {documents?.map((item, index) => {
          return (
            <>
              <p className={styles.stepsRow__name}>Документ {index + 1}:</p>
              <p className={styles.stepsRow__value} style={{ ...textStyles.buttonText, textDecoration: 'underline' }}>
                <div key={item.id}>
                  <a
                    href={item?.url}
                    target="_blank"
                    style={{
                      gap: 7,
                      display: 'grid',
                      gridTemplateColumns: 'auto 1fr',
                      alignItems: 'center',
                      marginBottom: 5,
                    }}
                    rel="noreferrer"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                      <path
                        d="M2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16ZM12 6H17L10 13L3 6H8V0H12V6Z"
                        fill="#D9BC92"
                      />
                    </svg>
                    <a style={{ color: '#D9BC92', fontSize: 16, fontWeight: 400 }}>{item?.type?.title}</a>
                  </a>
                </div>
              </p>
            </>
          );
        })}
      </div>
    </>
  );
};

export default DocumentsList;
