import { useState } from 'react';
import Button from '../../../../components/Button/Button';
import FormHeading from '../../../../components/Typography/FormHeading';
import {
  DEAL_STATUS_DOCUMENTS_PROCESSING,
  DEAL_STATUS_WAITING_FOR_FREEDOM,
  DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
  DEAL_STEPS,
  DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
  DOCUMENT_TYPES_CONTRACT,
  DOCUMENT_TYPES_GUARANTEE_LETTER,
  DOCUMENT_TYPES_INVOICE,
  DOCUMENT_TYPES_POLES,
  DOCUMENT_TYPES_TECH_PASSPORT,
  PAYMENT_STATUS_PAID,
} from '../../../../constants';
import LoaderInline from '../../../../components/Loader/LoaderInline';
import FreedomClientDocuments from './ClientDocuments';
import FreedomPaymentStatus from './PaymentStatus';
import FreedomCarRegistration from './CarRegistration';
import editDeal from '../../../../api/deals/editDeal';
import generateAllowedDocumentsJson from '../../../../utils/generateAllowedDocumentsJson';
import setStep from '../../../../api/deals/setStep';
import uploadDocumentToDeal from '../../../../api/deals/uploadDocumentToDeal';
import sendMarriedDoc from '../../../../api/creditPlatform/sendMarriedDoc';
import alert from '../../../../helpers/alert';
import editCar from '../../../../api/cars/editCar';
import initialFeeCreditCardPayment from '../../../../api/deals/initialFeeCreditCardPayment';

const FreedomFlow = ({ step, deal, documents, backHandler, update, chosenBankAppealId, cars, bankId }) => {
  const [loading, setLoading] = useState(false);
  const onPaymentSubmit = (values) => {
    if (values?.status?.value != PAYMENT_STATUS_PAID) return;

    setLoading(true);
    editDeal({
      id: deal?.id,
      statusPaymentId: values?.status?.value,
      statusId: DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
      ...generateAllowedDocumentsJson([
        DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
        DOCUMENT_TYPES_GUARANTEE_LETTER,
        DOCUMENT_TYPES_INVOICE,
      ]),
    })
      .then(async () => {
        await setStep({ dealId: deal?.id, step: DEAL_STEPS.FREEDOM.AUTO_REGISTRATION });

        alert.success('Статус оплаты успешно обновлен');
        update && update();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendPaymentSms = () => {
    setLoading(true);
    initialFeeCreditCardPayment({
      bankId: bankId,
      chunkId: deal?.chunkId,
    })
      .then(() => {
        alert.success('Смс со ссылкой на оплату отправлено на номер телефона клиента');
      })
      .catch((err) => {
        alert.error(err?.response?.data?.message || 'Не удалось отправить смс со ссылкой на оплату')
      })
      .finally(() => setLoading(false));
  };


  const onRegistrationSubmit = async (values) => {
    try {
      await editCar(cars?.[0]?.id, {
        grnz: values['grnz'],
      });
      await uploadDocumentToDeal({
        dealId: deal?.id,
        fileTypeId: DOCUMENT_TYPES_TECH_PASSPORT,
        file: values['Техпаспорт'],
      });
      await editDeal({
        id: deal?.id,
        statusId: DEAL_STATUS_WAITING_FOR_FREEDOM,
        ...generateAllowedDocumentsJson([
          DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
          DOCUMENT_TYPES_GUARANTEE_LETTER,
          DOCUMENT_TYPES_INVOICE,
          DOCUMENT_TYPES_TECH_PASSPORT,
          DOCUMENT_TYPES_CONTRACT,
          DOCUMENT_TYPES_POLES,
        ]),
      });
      await setStep({ dealId: deal?.id, step: DEAL_STEPS.FREEDOM.WAITING_FOR_FINAL_ACTIONS });
      update && update();
    } catch (e) {
      alert.error('Ошибка при загрузке документа');
    }
  };

  const uploadClientDocuments = (values) => {
    uploadDocumentToDeal({
      dealId: deal?.id,
      fileTypeId: DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
      file: values['doc'],
    }).then(() => {
      sendMarriedDoc({ appealId: chosenBankAppealId })
        .then(() => {
          editDeal({
            id: deal?.id,
            statusId: DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
            ...generateAllowedDocumentsJson([
              DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
              DOCUMENT_TYPES_GUARANTEE_LETTER,
              DOCUMENT_TYPES_INVOICE,
            ]),
          }).then(() => {
            setStep({ dealId: deal?.id, step: DEAL_STEPS.FREEDOM.WAITING_FOR_PAYMENT }).then(() => {
              update();
            });
          });
        })
        .catch(() => {
          alert.error('Произошла ошибка при отправке документов в банк');
        });
    });
  };

  const goBack = (statusId, step) => {
    setLoading(true);
    editDeal({
      id: deal?.id,
      statusId,
      step,
    })
      .then(() => {
        update && update();
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoaderInline />;
  }
  if (step === DEAL_STEPS.FREEDOM.WAITING_FOR_CLIENT_ACTIONS_ON_FREEDOM_SITE) {
    return (
      <>
        <FormHeading>Статус</FormHeading>
        <Button
          text={'Ожидаем завершения действий клиента на сайте Фридом банк'}
          style={{ color: 'rgb(73, 65, 54)', border: '1px solid rgb(73, 65, 54)' }}
        />
        <div style={{ marginTop: 10 }}>
          <Button
            text={'Назад'}
            onClick={() => goBack(DEAL_STATUS_DOCUMENTS_PROCESSING, 5)}
            style={{ color: '#BABABA', border: '1px solid #BABABA', width: '140px' }}
          />
        </div>
        {/* <Button
          onClick={() => {
            setStep({ dealId: deal?.id, step: DEAL_STEPS.FREEDOM.WAITING_FOR_CLIENT_DOCUMENTS }).then(() => {
              update();
            });
          }}
          fill
        >
          Далее
        </Button> */}
      </>
    );
  }
  if (step === DEAL_STEPS.FREEDOM.WAITING_FOR_CLIENT_DOCUMENTS) {
    return (
      <>
        <FreedomClientDocuments documents={documents} onSubmit={uploadClientDocuments} />
        <div style={{ marginTop: 10 }}>
          <Button
            text={'Назад'}
            onClick={() => goBack(deal?.status?.id, DEAL_STEPS.FREEDOM.WAITING_FOR_CLIENT_ACTIONS_ON_FREEDOM_SITE)}
            style={{ color: '#BABABA', border: '1px solid #BABABA', width: '140px' }}
          />
        </div>
      </>
    );
  }
  if (step === DEAL_STEPS.FREEDOM.WAITING_FOR_PAYMENT) {
    return (
      <>
        <FreedomPaymentStatus onSubmit={onPaymentSubmit} documents={documents} sendPaymentSms={sendPaymentSms}/>
        <div style={{ marginTop: 10 }}>
          <Button
            text={'Назад'}
            onClick={() => goBack(deal?.status?.id, DEAL_STEPS.FREEDOM.WAITING_FOR_CLIENT_DOCUMENTS)}
            style={{ color: '#BABABA', border: '1px solid #BABABA', width: '140px' }}
          />
        </div>
      </>
    );
  }
  if (step === DEAL_STEPS.FREEDOM.AUTO_REGISTRATION) {
    return (
      <>
        <FreedomCarRegistration onSubmit={onRegistrationSubmit} />
        <div style={{ marginTop: 10 }}>
          <Button
            text={'Назад'}
            onClick={() => goBack(deal?.status?.id, DEAL_STEPS.FREEDOM.WAITING_FOR_PAYMENT)}
            style={{ color: '#BABABA', border: '1px solid #BABABA', width: '140px' }}
          />
        </div>
      </>
    );
  }

  if (step === DEAL_STEPS.FREEDOM.WAITING_FOR_FINAL_ACTIONS) {
    return (
      <>
        <FormHeading>Статус</FormHeading>
        <Button
          text={'Ожидаем завершения на стороне Фридом банка'}
          style={{ color: 'rgb(73, 65, 54)', border: '1px solid rgb(73, 65, 54)' }}
        />
        <div style={{ marginTop: 10 }}>
          <Button
            text={'Назад'}
            onClick={() => goBack(deal?.status?.id, DEAL_STEPS.FREEDOM.AUTO_REGISTRATION)}
            style={{ color: '#BABABA', border: '1px solid #BABABA', width: '140px' }}
          />
        </div>
      </>
    );
  }
};

export default FreedomFlow;
