import React, { useState, useEffect } from 'react';
import createMark from '../../../api/cars/createMark';
import { useNavigate } from 'react-router-dom';

// components
import Form from '../../../components/Form/Form';
import Header from '../../../components/Header/Header';

// icons
import icon from '../../../assets/sidebar/cars.svg';

const formStyles = {
  marks: { display: 'grid', gap: '20px', gridTemplateColumns: `1fr` },
};

const CarMarksCreate = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState([{ type: 'input', value: undefined, label: 'Марка', placeholder: 'Укажите марку' }]);

  const handleSubmit = () => {
    if (!form[0].value) return;

    let mark = form[0].value;
    mark = mark.charAt(0).toUpperCase() + mark.slice(1);
    createMark({ name: mark })
      .then(() => {
        navigate('/cars/marks');
      })
      .catch((err) => alert(err));
  };

  return (
    <>
      <Header title={'Новая марка'} icon={icon} />
      <div style={{ paddingBottom: '20px' }}>
        <Form
          inputs={form}
          handlerSetState={setForm}
          title={'Марка автомобиля'}
          grid={formStyles.marks}
          border
          action={'Сохранить'}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

export default CarMarksCreate;
