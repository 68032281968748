import dayjs from 'dayjs';

/**
 *
 * @param {string} date - date to format
 * @param {boolean} withTime - if true, format date with time
 * @returns {string} - formatted date
 *
 **/

const formatDate = (date, withTime) => {
  if (!dayjs(date).isValid()) {
    return '';
  }
  
  const newDate = dayjs(date).format(`DD.MM.YYYY${withTime ? ' HH:mm' : ''}`);
  return newDate;
};

export default formatDate;
