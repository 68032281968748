import React from 'react';
import styles from './clientLink.module.css';

const ClientLink = ({ client, id }) => {
  return (
    <p className={styles.client} id={id}>
      {client}
    </p>
  );
};

export default ClientLink;
