import React, { useState, useEffect } from 'react';
import styles from './docsInput.module.css';
import axios from '../../../plugins/axios';
import { useParams } from 'react-router-dom';

// icons
import file from '../../../assets/file.svg';

import { textStyles } from '../../../constants/textStyles';
import alert from '../../../helpers/alert';

const DocsInput = ({
  text,
  label,
  onChange,
  placeholder,
  value,
  id,
  style,
  dependantValue,
  dependencyAccessor = (obj) => obj,
  defaultValue,
}) => {
  const { id: userId } = useParams();
  const [progress, setProgress] = useState(1);
  const [error, setError] = useState(null);

  const uploadDocument = () => {
    if (!dependencyAccessor(dependantValue)) {
      return;
    }
    const payload = new FormData();
    payload.append('json', JSON.stringify({ userId, typeId: dependencyAccessor(dependantValue) }));
    payload.append('file', value);
    axios
      .post('/api/crm/clients/uploadFileToClient', payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (data) => {
          setProgress(Math.round((data.loaded / data.total) * 100));
          if (error) {
            setProgress(null);
          }
        },
      })
      .then(() => setProgress(null))
      .catch(() => {
        alert.error('Не удалось загрузить документ')
        setProgress(null);
      });
  };

  useEffect(() => {
    if (value && dependencyAccessor(dependantValue)) {
      uploadDocument();
    }
  }, [value]);

  if (defaultValue) {
    return (
      <div className={styles.file}>
        <img src={file} alt="Icon" />
        <label htmlFor={id}>
          <a href={defaultValue} target="_blank">
            {defaultValue}
          </a>
        </label>
        <input type="file" id={id} placeholder={placeholder} hidden disabled />
      </div>
    );
  }

  if (value && dependencyAccessor(dependantValue)) {
    return (
      <>
        {progress ? (
          <div className={styles.file}>
            <div className={styles.progress} style={{ width: `${progress}%` }} />
            <p>Загрузка документа...</p>
          </div>
        ) : (
          <>
            {error ? (
              <div className={styles.file}>
                <div className={styles.error} />
                <p style={{ color: '#EB5757', border: '1px solid #EB5757' }}>{error}</p>
              </div>
            ) : (
              <div className={styles.file}>
                <img src={file} alt="Icon" />
                <label htmlFor={id}>{value?.name}</label>
                <input type="file" id={id} placeholder={placeholder} hidden disabled />
              </div>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <div className={styles.input}>
      <p style={textStyles.fieldLabel}>{text}</p>
      <label htmlFor={id}>{label}</label>
      <input
        type="file"
        id={id}
        placeholder={placeholder}
        onChange={(e) => {
          if (dependencyAccessor(dependantValue)) {
            onChange(e.target.files[0]);
          }
        }}
        onClick={(e) => (e.target.value = null)}
        hidden
      />
    </div>
  );
};

export default DocsInput;
