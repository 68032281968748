import React, { useState } from 'react';

// components
import Form from '../../../components/Form/Form';

const formStyles = {
  auto: { display: 'grid', gap: '20px', gridTemplateColumns: `1fr 44px` },
};

const NewDealAuto = () => {
  const [auto, setAuto] = useState({
    form: [[{ type: 'select', label: 'Автомобиль', placeholder: 'Выберите из списка' }, { type: 'delete' }]],
  });

  const handleAddForm = () => {
    setAuto((prev) => ({
      ...prev,
      form: [...prev.form, prev.form[0]],
    }));
  };

  const handleDeleteForm = (e, formIndex) => {
    e.preventDefault();
    if (auto.form.length > 1) {
      const filteredArray = auto.form.filter((item, idx) => idx !== formIndex);
      setAuto((prev) => ({
        ...prev,
        form: filteredArray,
      }));
    }
  };

  return (
    <div style={{ padding: '10px 20px', backgroundColor: 'white', marginTop: '20px', border: '1px solid #E0E0E0' }}>
      {auto.form.map((item, index) => (
        <Form
          key={index}
          inputs={auto.form[index]}
          title={index === 0 ? 'Автомобиль' : false}
          grid={formStyles.auto}
          addFormButton={index === auto.form.length - 1 ? 'Добавить еще автомобиль' : false}
          addForm={handleAddForm}
          deleteForm={(e) => handleDeleteForm(e, index)}
        />
      ))}
    </div>
  );
};

export default NewDealAuto;
