import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import uploadDocumentToCar from '../../api/cars/uploadDocumentToCar';
import getCarDocuments from '../../api/cars/getCarDocuments';
import { CAR_DOCUMENT_TYPES } from '../../constants';

// components
import Form from '../../components/Form/Form';
import HookForm from '../../components/HookForm/HookForm';
import SelectCarDocumentType from '../../components/Selects/SelectCarDocumentType';
import DocsInputNew from '../../components/Inputs/DocsInput/DocsInputNew';
import DeleteButton from '../../components/DeleteButton/DeleteButton';
import AddButton from '../../components/AddButton/AddButton';
import LoaderInline from '../../components/Loader/LoaderInline';
import Button from '../../components/Button/Button';
import DocsInput from '../../components/Inputs/DocsInput/DocsInput';

const formStyles = {
  document: { display: 'grid', gap: '20px', gridTemplateColumns: `1fr 1fr 1fr 44px`, alignItems: 'end' },
};

const CarDocs = () => {
  const { id } = useParams();
  const row = () => [
    { component: SelectCarDocumentType, name: 'typeId' },
    { name: 'name', label: 'Название документа', placeholder: 'Например, “Договор купли-продажи Бекпулатов”' },
    {
      component: DocsInputNew,
      name: 'file',
      label: 'Файл документа',
      text: 'Файл документа',
      id: Math.random(),
    },
    {
      component: DeleteButton,
      name: 'delete',
      onClick: (e, index) => {
        removeRow(e, index);
      },
    },
  ];
  const [form, setForm] = useState([row()]);
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(true);

  const init = async () => {
    setLoading(true);
    const array = [];
    await getCarDocuments({ carId: id }).then((res) => {
      if (res.data) {
        res.data.forEach((item) => {
          array.push([
            {
              component: SelectCarDocumentType,
              name: 'typeId',
              defaultValue: CAR_DOCUMENT_TYPES.find((doc) => doc.value === item.typeId),
              disabled: true,
            },
            { name: 'name', label: 'Название документа', defaultValue: item.name, disabled: true },
            {
              name: 'file',
              component: DocsInput,
              id: item.id,
              defaultValue: item.url,
              disabled: true,
            },
          ]);
        });
      }
    });
    setDocs([...array]);
    setLoading(false);
  };

  const addRow = () => {
    setForm([...form, row()]);
  };

  const removeRow = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    const newForm = form;
    newForm.slice(index, 1);
    setForm(newForm);
  };

  const onSubmit = () => {
    setLoading(true);
    const fields = form.map((i) => i.map((i) => i.value?.value || i.value));
    Promise.all(
      fields.map((field) =>
        uploadDocumentToCar({
          carId: id,
          typeId: field[0],
          name: field[1],
          file: field[2],
        })
      )
    ).finally(() => {
      init();
    });
  };

  useEffect(() => {
    init();
  }, []);

  if (loading) {
    return (
      <div style={{ padding: '10px 20px', backgroundColor: 'white', marginTop: '20px', border: '1px solid #E0E0E0' }}>
        <LoaderInline />
      </div>
    );
  }

  return (
    <div style={{ padding: '10px 20px', backgroundColor: 'white', marginTop: '20px', border: '1px solid #E0E0E0' }}>
      {docs.map((row, index) => (
        <HookForm grid={formStyles.document} inputs={row} style={{ margin: 0 }} index={index} />
      ))}
      {form.map((row, index) => (
        <HookForm grid={formStyles.document} inputs={row} style={{ margin: 0 }} index={index} />
      ))}
      <AddButton text="Добавить еще документ" onClick={addRow} />
      <div
        style={{
          marginTop: 20,
        }}
      >
        <Button text="Сохранить" fill onClick={onSubmit} />
      </div>
    </div>
  );
};

export default CarDocs;
