import React, { useEffect, useState } from 'react';
import { MARKS } from '../../../constants/columns';
import { carsOptions } from '../../../constants/filterOptions';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { useNavigate } from 'react-router-dom';

// components
import Header from '../../../components/Header/Header';
import Table from '../../../components/Table/Table';
import TableTabs from '../../../components/TableTabs/TableTabs';
import Empty from '../../../components/Empty/Empty';

// icons
import icon from '../../../assets/sidebar/cars.svg';
import axios from '../../../plugins/axios';

const columns = MARKS;
const data = [
  {
    id: 1,
    name: 'Опция 1',
  },
  {
    id: 2,
    name: 'Опция 2',
  },
];

const CarOptions = () => {
  const navigate = useNavigate();

  const table = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );
  const { setGlobalFilter, page } = table;

  const [value, setValue] = useState('');
  const [values, setValues] = useState({
    fio: '',
    iin: '',
    vip: '',
    company: '',
    role: '',
    phone: '',
    email: '',
    broker: '',
  });

  const handleNavigate = () => {
    navigate('/cars/options/create');
  };

  useEffect(() => {
    axios.get('/api/crm/cars/getOptions');
  }, []);

  return (
    <>
      <Header title="Опции" icon={icon} />
      <TableTabs
        setFilter={setGlobalFilter}
        options={carsOptions}
        value={value}
        setValue={setValue}
        values={values}
        setValues={setValues}
        navigate={handleNavigate}
        button={'Добавить опцию'}
        inputPlaceholder={'Быстрый поиск по опциям'}
      />
      <br />
      {!page.length ? <Empty /> : <Table table={table} />}
    </>
  );
};

export default CarOptions;
