import React, { useState } from 'react';
import { DEALS } from '../../constants/columns';
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { dealsOptions } from '../../constants/filterOptions';
import { useLocation, useNavigate } from 'react-router-dom';
import { setFilters } from '../../utils/setFilters';
import { sortDealsParams } from '../../constants/sortParams';

// components
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import TableTabs from '../../components/TableTabs/TableTabs';
import Empty from '../../components/Empty/Empty';
import ClientPopup from '../../components/ClientPopup/ClientPopup';
import HookForm from '../../components/HookForm/HookForm';

// icons
import icon from '../../assets/sidebar/deals.svg';
import axios from '../../plugins/axios';
import { PERMISSION_CAN_DELETE_DEALS, PERMISSION_CAN_EDIT_DEALS } from '../../constants/roles';
import DealAutos from './DealAutos';
import Modal from '../../components/Modal/Modal';
import DealCarsModal from './DealCarsModal';
import bucket from '../../assets/popover/delete.svg';
import RejectDealModal from './RejectDealModal';

export const FETCH_ALL_PAGESIZE = 20;
// const columns = DEALS;

const Deals = ({ routerParams = {} }) => {
  const navigate = useNavigate();
  const location = useLocation();


  const [loading, setLoading] = useState(true);
  const tableStyle = { backgroundColor: 'white' };
  const [deals, setDeals] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    pageSize: 10,
  });
  const [isFetchAll, setFetchAll] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);
  const [dealId, setDealId] = useState(null);
  const onCarClick = (id) => {
    setDealId(id);
    setModalOpen(true);
  };

  const [columns, setColumns] = useState(DEALS({ onCarClick }));

  const table = useTable(
    {
      columns,
      data: deals,
      initialState: {
        pageSize: pagination.pageSize,
      },
      manualPagination: true,
      pageCount: pagination.totalPages,
      page: pagination.page - 1,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { setGlobalFilter, page, setPageSize } = table;

  const [value, setValue] = useState('');
  const [queryParams, setQueryParams] = useState({});

  const addDeal = () => {
    navigate('/deals/create');
  };

  const handleNavigate = (index) => {
    navigate(`/deals/deal/${deals.find((item) => item.id === index).id}/main-info`, {
      state: deals.find((item) => item.id === index),
    });
  };

  const getDeals = (params) => {
    setLoading(true);
    axios
      .get('/api/crm/deals/getDeals', { params: { ...params, ...routerParams } })
      .then((res) => {
        setDeals(res.data.deals);
        setPagination({
          ...res.data.pagination,
          page: res.data.pagination?.pageNumber,
          pageSize: pagination.pageSize,
        });
        setDataCount(res.data.pagination?.count);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (!isFetchAll) {
      getDeals({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams });
    }
  }, [pagination.page, pagination.pageSize, queryParams]);
  React.useEffect(() => {
    getDeals({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams });
  }, [location]);

  

  const [showClientPopup, setShowClientPopup] = React.useState(false);
  const [clientId, setClientId] = React.useState(null);

  const sortBy = (value) => {
    if (!sortDealsParams.get(value)) return;
    if (sortDealsParams.get(value) === queryParams.sortBy) {
      queryParams.sortBy = '';
    } else {
      queryParams.sortBy = sortDealsParams.get(value);
    }
    setFetchAll(false);
    setQueryParams({ ...queryParams });
  };

  const popover = [
    {
      title: 'Удалить сделку',
      icon: bucket,
      onClick: async (row) => {
        await axios.post('/api/crm/deals/removeDeal', { id: row?.id }).then((res) => {
          getDeals({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams });
        });
      },
      permission: PERMISSION_CAN_DELETE_DEALS,
    },
  ];

  const onFastSearch = (value) => {
    setQueryParams({
      ...queryParams,
      all: value,
    })
  }

  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [rejectDealId, setRejectDealId] = useState(null);
  const onDropItemToTrash = (deal) => {
    console.log(deal);
    setRejectDealId(deal?.id)
    setOpenRejectModal(true);
  }
  const onDealRejected = () => {
    getDeals({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams });
  }



  return (
    <>
      {showClientPopup && <ClientPopup isOpen={showClientPopup} setOpen={setShowClientPopup} clientId={clientId} />}
      <Header title="Сделки" icon={icon} />
      <br />
      <br />
      <br />
      <br />
      <TableTabs
        setFilter={onFastSearch}
        value={value}
        filterComponent={
          <HookForm
            inputs={dealsOptions}
            grid={{
              display: 'grid',
              gap: '10px',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
            }}
            style={{ backgroundColor: 'transparent' }}
          />
        }
        handleSearchParams={(resetFilters) =>
          setFilters(resetFilters, setFetchAll, setPageSize, setPagination, setQueryParams, dealsOptions)
        }
        setValue={setValue}
        navigate={addDeal}
        button={'Продать авто'}
        buttons={[
          {
            text: 'Продать доп.оборудование',
            onClick: () => navigate('/deals/equipment'),
          },
        ]}
        // permission={PERMISSION_CAN_EDIT_DEALS}
        inputPlaceholder={'Быстрый поиск по сделкам'}
      />
      <br />
      {!page.length && !loading ? (
        <Empty />
      ) : (
        <>
          <Table
            table={table}
            all={'сделок'}
            style={tableStyle}
            handleNavigate={handleNavigate}
            pagination
            sort
            setPagination={setPagination}
            setFetchAll={setFetchAll}
            isFetchAll={isFetchAll}
            setData={setDeals}
            p={pagination}
            setClientId={setClientId}
            setOpenClientPopup={setShowClientPopup}
            loading={loading}
            setLoading={setLoading}
            columns={columns}
            setColumns={setColumns}
            columnsSettingsKey="deals"
            queryParams={queryParams}
            api={'/api/crm/deals/getDeals'}
            response={'deals'}
            isSortable
            dataCount={dataCount}
            minWidth={1400}
            handleSortTable={sortBy}
            popover={popover}
            onDropItemToTrash={onDropItemToTrash}
          />
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setModalOpen(false)}
            contentStyle={{
              height: 'max-content',
            }}
          >
            <DealCarsModal dealId={dealId} />
          </Modal>
        </>
      )}

      <RejectDealModal isOpen={openRejectModal} onClose={setOpenRejectModal} dealId={rejectDealId} onSuccess={onDealRejected}/>
    </>
  );
};

export default Deals;
