import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import styles from './pagination.module.css';
import { FETCH_ALL_PAGESIZE } from '../../pages/Deal/Deals';

const Pagination = (props) => {
  const {
    gotoPage,
    nextPage,
    previousPage,
    currentPage,
    pages,
    pageSize,
    setPageSize,
    data,
    setFetchAll,
    setFetching,
    setLoading,
    setData,
  } = props;

  useEffect(() => {
    const pages = document.querySelectorAll('li');
    const display = document.querySelectorAll('.pageSize');
    // pages.forEach((item) => {
    //   item.style = '';
    //   if (Number(item.textContent) === currentPage) {
    //     item.style.backgroundColor = '#D9BC92';
    //     item.style.color = 'white';
    //   }
    // });
    display.forEach((item) => {
      item.style = '';
      if (Number(item.textContent) === pageSize || (item.textContent === 'Все' && pageSize === FETCH_ALL_PAGESIZE)) {
        item.style.backgroundColor = '#D9BC92';
        item.style.color = 'white';
      }
    });
  }, [currentPage, props.pageSize]);

  const handlePageSize = (e) => {
    const node = e.target;
    if (node.parentNode.childElementCount !== 4) return;

    if (node.textContent === 'Все') {
      setFetchAll(true);
      setFetching(true);
      setData([]);
      setPageSize(FETCH_ALL_PAGESIZE);
      setLoading(true);
    } else {
      setPageSize(Number(node.textContent));
      setFetchAll(false);
    }
  };

  const handlePage = (e) => {
    if (e.isNext) {
      return nextPage();
    }
    if (e.isPrevious) {
      return previousPage();
    }
    return gotoPage(e.nextSelectedPage + 1);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.view}>
        <p>Отображать по:</p>
        <div onClick={handlePageSize}>
          <button style={{ backgroundColor: '#D9BC92', color: 'white' }} className={'pageSize'}>
            10
          </button>
          <button className={'pageSize'}>50</button>
          <button className={'pageSize'}>100</button>
          <button className={'pageSize'}>Все</button>
        </div>
        {pageSize !== FETCH_ALL_PAGESIZE && (
          <>
            <span>|</span>
            <p>Показано:</p>
            <p>
              {currentPage} из {pages?.length}
            </p>
          </>
        )}
      </div>
      {pageSize !== FETCH_ALL_PAGESIZE && (
        <div className={styles.pages}>
          <ReactPaginate
            onClick={handlePage}
            nextLabel="Вперед"
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            forcePage={currentPage - 1}
            pageCount={pages?.length}
            previousLabel="Назад"
            breakLabel="..."
            renderOnZeroPageCount={null}
            activeClassName={styles.page_active}
            className={styles.paginationIndex}
          />
        </div>
      )}
    </div>
  );
};

export default Pagination;
