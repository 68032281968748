import React, { Children, useEffect, useState } from 'react';
import { CARS, CARS_SELL_EQUIPMENT } from '../../../../constants/columns';
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { carsOptions } from '../../../../constants/filterOptions';
import { useNavigate } from 'react-router-dom';
import { setFilters } from '../../../../utils/setFilters';
import { sortCarsParams } from '../../../../constants/sortParams';

// components
import Header from '../../../../components/Header/Header';
import Table from '../../../../components/Table/Table';
import TableTabs from '../../../../components/TableTabs/TableTabs';
import Empty from '../../../../components/Empty/Empty';
import HookForm from '../../../../components/HookForm/HookForm';

// icons
import icon from '../../../../assets/sidebar/cars.svg';
import getItems from '../../../../api/cars/getItems';
import bucketIcon from '../../../../assets/popover/delete.svg';
import { PERMISSION_CAN_EDIT_AUTO_AND_EQUIPMENTS } from '../../../../constants/roles';
import isUserHavePermission from '../../../../utils/isUserHavePermission';
import AddButton from '../../../../components/AddButton/AddButton';
import Modal from '../../../../components/Modal/Modal';
import ModalRelativeToParent from '../../../../components/Modal/ModalRelativeToParent';
import EquipmentsPickList from './EquipmentsPickList';
import SellEqipmentCart from './SellEquipmentCart';

const tableStyle = { backgroundColor: 'white' };

const popover = [{ title: 'Удалить авто', icon: bucketIcon }];

let sortCount = 1;

const PickEquipmentsForSale = ({
  header = true,
  withCheckboxes,
  submitButton,
  cancelButton,
  onSubmit,
  onCancel,
  defaultQueryParams = {},
}) => {
  const [columns, setColumns] = React.useState(CARS_SELL_EQUIPMENT);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [cars, setCars] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    pageSize: 10,
  });
  const [isFetchAll, setFetchAll] = useState(false);

  const table = useTable(
    {
      columns,
      data: cars,
      initialState: {
        pageSize: pagination.pageSize,
      },
      manualPagination: true,
      pageCount: pagination.totalPages,
      page: pagination.page - 1,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { setGlobalFilter, page, setPageSize } = table;

  const [value, setValue] = useState('');
  const [queryParams, setQueryParams] = useState(defaultQueryParams);

  const addCar = () => {
    navigate('/cars/create');
  };

  const handleNavigate = (index) => {
    if (!isUserHavePermission(PERMISSION_CAN_EDIT_AUTO_AND_EQUIPMENTS)) {
      return;
    }

    console.log(cars, index, cars[index - 1]);
    // navigate(`/cars/car/${index}/main-data`, { state: cars.find((item) => item.id === index) });
  };

  const init = async (page) => {
    setLoading(true);
    await getItems({ ...page, ...queryParams })
      .then((res) => {
        setCars(res.data.cars);
        setPagination({
          ...res.data.pagination,
          page: res.data.pagination?.pageNumber,
          pageSize: pagination.pageSize,
        });
        setDataCount(res.data.pagination?.count);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (!isFetchAll) {
      init({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams });
    }
  }, [pagination.page, pagination.pageSize, queryParams]);

  const handleActionPopover = async (carIndex, actionIndex, title, id) => {
    console.log(carIndex, actionIndex);
  };

  const sortBy = (value) => {
    queryParams.sortBy = sortCarsParams.get(value);
    if (!sortCarsParams.get(value) || (sortCount > 1 && sortCarsParams.get(value) != queryParams.sortBy)) {
      sortCount = 1;
      return;
    }
    if (sortCount === 3) {
      sortCount = 1;
    }
    if (sortCarsParams.get(value) === queryParams.sortBy && sortCount === 1) {
      queryParams.sortBy = sortCarsParams.get(value);
      queryParams.sort = 'ask';
      sortCount++;
      console.log('asd');
    } else if (sortCarsParams.get(value) === queryParams.sortBy && sortCount === 2) {
      queryParams.sortBy = sortCarsParams.get(value);
      queryParams.sort = 'desc';
      sortCount++;
      console.log('asd');
    }
    setFetchAll(false);
    setQueryParams({ ...queryParams });
    console.log(sortCount, '[count]');
  };

  const [selectedCarRow, setSelectedCarRow] = useState(null);
  const [equipmentModal, setEquipmentModal] = useState(false);
  const [car, setCar] = useState(null);
  const [cart, setCart] = useState([]);

  const handleEquipmentAddition = ({ car, equipments }) => {
    const updatedCart = cart.map((item) => {
      if (item.id === car.id) {
        const newEquipments = [...item.equipments, ...equipments].reduce((acc, curr) => {
          const existingEquipment = acc.find((item) => item.id === curr.id);
          if (existingEquipment) {
            existingEquipment.counter += curr.counter;

            if (existingEquipment.counter > existingEquipment.count) {
              existingEquipment.counter = existingEquipment.count;
            }

            return acc;
          }
          return [...acc, curr];
        }, []);

        return {
          ...item,
          equipments: newEquipments,
        };
      }
      return item;
    });

    const carExistsInCart = updatedCart.some((item) => item.id === car.id);
    if (!carExistsInCart) {
      const newCartItem = {
        id: car.id,
        carName: `${car.mark} ${car.model} ${car.complectation?.title} ${car.issueYear}`,
        equipments,
        car,
      };
      updatedCart.push(newCartItem);
    }

    setCart(updatedCart);
    setEquipmentModal(false);
  };

  const openEquipmentsModal = (element, car) => {
    setCar(car);
    setSelectedCarRow(element);
    setEquipmentModal(true);
  };

  const removeItemFromCart = (id) => {
    const newCart = cart.filter((item) => item.id !== id);
    setCart(newCart);
  };

  const onCartEquipmentCountChange = (carId, equipmentId, type) => {
    const newCart = cart.map((item) => {
      if (item.id === carId) {
        const newEquipments = item.equipments.map((equipment) => {
          if (equipment.id === equipmentId) {
            let counter = equipment.counter;

            if (type === 'increment' && counter < equipment?.count) {
              counter++;
            } else if (type === 'decrement') {
              counter--;
            }

            return {
              ...equipment,
              counter,
            };
          }
          return equipment;
        });

        return {
          ...item,
          equipments: newEquipments.filter((equipment) => equipment.counter > 0),
        };
      }
      return item;
    });

    const cartWithEquipments = newCart.filter((item) => item.equipments.length > 0);

    setCart(cartWithEquipments);
  };

  useEffect(() => {
    onSubmit(cart);
  }, [cart, onSubmit]);

  return (
    <>
      <TableTabs
        setFilter={setGlobalFilter}
        value={value}
        setValue={setValue}
        filterComponent={
          <HookForm
            inputs={carsOptions}
            grid={{
              display: 'grid',
              gap: '10px',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
            }}
            style={{ backgroundColor: 'transparent' }}
          />
        }
        handleSearchParams={(resetFilters) =>
          setFilters(resetFilters, setFetchAll, setPageSize, setPagination, setQueryParams, carsOptions)
        }
        navigate={addCar}
        button={'Добавить автомобиль'}
        inputPlaceholder={'Быстрый поиск по автомобилям'}
        permission={PERMISSION_CAN_EDIT_AUTO_AND_EQUIPMENTS}
      />
      <br />
      {!page.length && !loading ? (
        <Empty />
      ) : (
        <div
          style={{
            marginBottom: 40,
          }}
        >
          <Table
            table={table}
            all={'автомобилей'}
            style={tableStyle}
            handleNavigate={handleNavigate}
            pagination
            sort
            setPagination={setPagination}
            setFetchAll={setFetchAll}
            isFetchAll={isFetchAll}
            setData={setCars}
            p={pagination}
            loading={loading}
            setLoading={setLoading}
            columns={columns}
            setColumns={setColumns}
            columnsSettingsKey="cars"
            queryParams={queryParams}
            api={'/api/shop/getItems'}
            response={'cars'}
            withCheckboxes={withCheckboxes}
            popover={popover}
            handleActionPopover={handleActionPopover}
            dataCount={dataCount}
            handleSortTable={sortBy}
            edit={false}
            customRow={[
              {
                name: 'custom__equipments',
                component: (row) => (
                  <AddButton
                    text="Выбрать доп оборудование"
                    onClick={(e) => {
                      // openEquipmentsModal(e.target, row);
                      setCar(row);
                      setSelectedCarRow(e.target);
                      setEquipmentModal(true);
                    }}
                    style={{
                      position: 'relative',
                    }}
                  />
                ),
              },
            ]}
          />
        </div>
      )}

      <SellEqipmentCart
        items={cart}
        openEquipmentsModal={openEquipmentsModal}
        remove={removeItemFromCart}
        onCounterChange={onCartEquipmentCountChange}
      />

      <ModalRelativeToParent
        isOpen={equipmentModal}
        onRequestClose={() => setEquipmentModal(false)}
        parentElement={selectedCarRow}
        contentWidth={375}
        contentHeight={500}
      >
        <EquipmentsPickList car={car} onSubmit={handleEquipmentAddition} />
      </ModalRelativeToParent>
    </>
  );
};

export default PickEquipmentsForSale;
