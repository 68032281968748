import { useCallback, useEffect, useState } from 'react';
import Input from '../Inputs/Input/Input';
import useDebounce from '../../hooks/useDebounce';

const InputWithDebounce = ({ onChange, debounceTimer, ...props }) => {
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, debounceTimer);

  useEffect(() => {
    onChange && onChange(debouncedValue);
  }, [debouncedValue]);

  return <Input {...props} value={value} onChange={(e) => setValue(e.target?.value)} />;
};

export default InputWithDebounce;
