import React, { useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { CAR_DEAL } from '../../constants/columns';

// components
import EmptyData from '../../components/EmptyData/EmptyData';
import Loader from '../../components/Loader/Loader';

// icons
import icon from '../../assets/header/newClient.svg';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../plugins/axios';
const columns = CAR_DEAL;

const ClientDeals = () => {
  const [deals, setDeals] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    pageSize: 10,
  });
  const table = useTable(
    {
      columns,
      data: deals,
      initialState: {
        pageSize: pagination.pageSize,
      },
      manualPagination: true,
      pageCount: pagination.totalPages,
      page: pagination.page - 1,
    },
    usePagination
  );

  const init = async () => {
    setLoading(true);
    await axios
      .get('/api/crm/deals/getDeals', { params: { clientId: id, page: pagination?.page } })
      .then((res) => {
        setPagination({
          ...res.data.pagination,
          page: res.data.pagination?.pageNumber,
          pageSize: pagination.pageSize,
        });
        const items = res.data.deals.map((item) => {
          return {
            id: item?.id,
            date: item?.createdAt,
            price: item?.sum,
            payment_method: item?.paymentType?.title,
            payment_status: item?.statusPayment?.title,
            deal_status: item?.status?.title,
            createdAt: item?.createdAt,
          };
        });
        setDeals(items);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));

  };
  React.useEffect(() => {
    init();
  }, [pagination.page]);

  const handleNavigate = (index) => {
    navigate(`/deals/deal/${deals.find((item) => item.id === index).id}/main-info`);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <EmptyData
          title={`Сделки клиента`}
          subtitle={'Этот клиент не участвует пока ни в одной сделке'}
          action={'Добавить новую сделку'}
          func={() => {
            navigate('/deals/create?userId=' + id);
          }}
          icon={icon}
          table={table}
          data={deals}
          navigate={handleNavigate}
          pagination
          setPagination={setPagination}
          p={pagination}
        />
      )}
    </>
  );
};

export default ClientDeals;
