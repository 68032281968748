import React, { useState } from 'react';

// components
import Form from '../../components/Form/Form';
import Button from '../../components/Button/Button';
import getItems from '../../api/cars/getItems';
import { useNavigate, useParams } from 'react-router-dom';
import checkCrmSidebarNotifications from '../../api/notifications/checkCrmSidebarNotifications';
import HookForm from '../../components/HookForm/HookForm';
import SelectMarks from '../../components/Selects/SelectMarks';
import SelectModels from '../../components/Selects/SelectModels';
import SelectComplectations from '../../components/Selects/SelectComplectations';
import Loader from '../../components/Loader/Loader';
import checkStockCar from '../../utils/checkStockCar';
import SelectCarTransmissions from '../../components/Selects/SelectCarTransmissions';
import formatNumber from '../../utils/formatNumber';
import SelectCity from '../../components/Selects/SelectCity';
import editCar from '../../api/cars/editCar';
import alert from '../../helpers/alert';
import dayjs from 'dayjs';

const formStyles = {
  carInfo: { display: 'grid', gap: '20px', gridTemplateColumns: `1fr 1fr 1fr 1fr` },
  dealerInfo: { display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr' },
  other: { display: 'grid', gap: '20px' },
};

const CarMainData = ({ containerId }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [carInfo, setCarInfo] = useState([]);
  const [loading, setLoading] = useState(true);

  const dealerInfo = [
    { type: 'input', value: 'Kia DOSCAR', label: 'Наименование дилерского центра', disabled: true },
    { type: 'input', value: 'г. Алматы, ул. Елебекова 10/1', label: 'Адрес дилерского центра', disabled: true },
    { type: 'input', value: '01.10.2022', label: 'Дата прибытия в дилерский центр', disabled: true },
    { type: 'input', value: '10%', label: 'Скидка дилерского центра', disabled: true },
  ];

  const other = [
    { type: 'input', value: 'Бахытжан Шагабутдинов', label: 'ФИО ответственного менеджера', disabled: true },
    { type: 'input', value: 'Какой-то комментарий', label: 'Заметки/комментарии об автомобиле', disabled: true },
  ];
  const [car, setCar] = useState({});
  const init = () => {
    setLoading(true);
    setCarInfo([]);
    getItems({ id: containerId ?? id })
      .then((res) => {
        const car = res.data.cars[0];
        setCar(car);
        const carInfo = [
          {
            name: 'mark',
            component: SelectMarks,
            label: 'Марка',
            placeholder: 'Выберите из списка',
            defaultValue: { value: car?.container?.mark?.id, label: car?.container?.mark?.name },
          },
          {
            name: 'model',
            component: SelectModels,
            dependency: 'mark',
            dependencyAccessor: (obj) => obj?.value,
            label: 'Модель',
            placeholder: 'Выберите из списка',
            defaultValue: { value: car?.container?.model?.id, label: car?.container?.model?.name },
          },
          {
            name: 'complect',
            component: SelectComplectations,
            label: 'Комплектация',
            placeholder: 'Выберите из списка',
            defaultValue: { value: car?.complectation?.id, label: car?.complectation?.title },
            params: () => car?.container?.id,
          },
          {
            name: 'issueYear',
            label: 'Год выпуска',
            defaultValue: car.issueYear,
            rules: {
              validate: (value) => {
                if (value < 1900 || value > dayjs().year()) {
                  return 'Недопустимый год выпуска';
                }

                return true;
              },
            },
          },
          { name: 'vin', label: 'VIN', defaultValue: car.vin },
          { name: 'color', label: 'Цвет кузова', color: car.color, defaultValue: car.color },
          { name: 'engine_volume', label: 'Объем двигателя', defaultValue: car.engine_volume,
            rules: {
              validate: (value) => {
                if (!value.match(/^[0-9]+$/)) {
                  return 'Недопустимый формат';
                }

                return true;
              },
            }
          },
          { name: 'engine', label: 'Тип двигателя', defaultValue: car?.complectation?.engine?.title },
          {
            name: 'transmission',
            component: SelectCarTransmissions,
            label: 'Тип коробки передач',
            defaultValue: car?.transmission,
          },
          { name: 'price', label: 'РРЦ, KZT', defaultValue: formatNumber(car?.price), format: 'number' },
          {
            component: SelectCity,
            name: 'city',
            label: 'Город',
            defaultValue: { value: car?.city?.id, label: car?.city?.title },
          },
          {
            name: 'status',
            label: 'Статус',
            defaultValue: checkStockCar(car?.checkStock) ? 'В наличии' : 'Нет в наличии',
            disabled: true,
          },
        ];

        setCarInfo(carInfo);
        checkCrmSidebarNotifications({ notificationIds: car?.notifications?.map((i) => i?.id) });
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };
  React.useEffect(() => {
    init();
  }, []);

  const sellCar = () => {
    navigate('/deals/create', { state: { car } });
  };

  const saveCar = (payload) => {
    setLoading(true);
    editCar(id, {
      ...payload,
      price: parseInt(payload?.price?.replace(/\s/g, '')),
      complectationId: payload?.complect?.value,
      transmissionId: payload?.transmission?.value,
      cityId: payload?.city?.value,
      markId: payload?.mark?.value,
      modelId: payload?.model?.value,
    })
      .then((res) => {
        init();
        alert.success('Данные успешно сохранены');
      })
      .catch(() => {
        alert.error('Произошла ошибка при сохранении данных');
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ paddingBottom: '20px' }}>
          {/* <Form inputs={dealerInfo} title={'Информация о дилерском центре'} grid={formStyles.dealerInfo} border />
      <Form inputs={other} title={'Дополнительно'} grid={formStyles.other} border /> */}
          <HookForm inputs={carInfo} border grid={formStyles.carInfo} onSubmit={saveCar} action="Сохранить изменения" />
          {car?.mark && !containerId && checkStockCar(car?.checkStock) ? (
            <Button text={'Продать автомобиль'} fill onClick={sellCar} />
          ) : null}
        </div>
      )}
    </>
  );
};

export default CarMainData;
