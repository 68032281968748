import React, { useState } from 'react';

// components
import Form from '../../../components/Form/Form';

const formStyles = {
  equipment: { display: 'grid', gap: '20px', gridTemplateColumns: `1fr 44px` },
};

const NewDealEquipment = () => {
  const [equipment, setEquipment] = useState({
    form: [
      [{ type: 'select', label: 'Аксессуары', placeholder: 'Выберите из списка' }, { type: 'delete' }],
    ],
  });

  const handleAddForm = () => {
    setEquipment((prev) => ({
      ...prev,
      form: [...prev.form, prev.form[0]],
    }));
  };

  const handleDeleteForm = (e, formIndex) => {
    e.preventDefault();
    if (equipment.form.length > 1) {
      const filteredArray = equipment.form.filter((item, idx) => idx !== formIndex);
      setEquipment((prev) => ({
        ...prev,
        form: filteredArray,
      }));
    }
  };

  return (
    <div style={{ padding: '10px 20px', backgroundColor: 'white', marginTop: '20px', border: '1px solid #E0E0E0' }}>
      {equipment.form.map((item, index) => (
        <Form
          key={index}
          inputs={equipment.form[index]}
          title={index === 0 ? 'Аксессуары' : false}
          grid={formStyles.equipment}
          addFormButton={index === equipment.form.length - 1 ? 'Добавить еще Аксессуары' : false}
          addForm={handleAddForm}
          deleteForm={(e) => handleDeleteForm(e, index)}
        />
      ))}
    </div>
  );
};

export default NewDealEquipment;
