import React, { useState } from 'react';
import createContainer from '../../../api/cars/createContainer';
import { useNavigate } from 'react-router-dom';

// components
import Form from '../../../components/Form/Form';
import Header from '../../../components/Header/Header';

// icons
import icon from '../../../assets/sidebar/cars.svg';

const formStyles = {
  container: { display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr 1fr 1fr' },
};

const CarContainersCreate = () => {
  const navigate = useNavigate();
  const [container, setContainer] = useState([
    { type: 'selectMarks', value: undefined },
    { type: 'selectModelsOld', value: undefined, params: () => container[0].value },
    { type: 'document', value: undefined, text: 'Фото', label: 'Загрузите фото', id: 'photo' },
    { type: 'document', value: undefined, text: 'Прайслист', label: 'Загрузите документ', id: 'document' },
  ]);

  const createMarkModel = () => {
    const json = JSON.stringify({ markId: container[0].value, modelId: container[1].value });
    const data = new FormData();
    data.append('json', json);
    data.append('photo', container[2].value);
    data.append('priceList', container[3].value);

    createContainer(data).then(() => navigate('/cars/containers'));
  };
 
  return (
    <>
      <Header title={'Новая марка и модель'} icon={icon} />
      <div style={{ paddingBottom: '20px' }}>
        <Form
          inputs={container}
          title={'Марка и модель автомобиля'}
          grid={formStyles.container}
          action={'Сохранить'}
          handlerSetState={setContainer}
          handleSubmit={createMarkModel}
          border
        />
      </div>
    </>
  );
};

export default CarContainersCreate;
