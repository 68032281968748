import api from '../../plugins/api';

const editContainer = ({ containerId, photo, priceList }) => {
  const formData = new FormData();
  formData.append('containerId', containerId);
  formData.append('photo', photo);
  formData.append('priceList', priceList);

  return api.post('/api/crm/cars/editContainer', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default editContainer;
