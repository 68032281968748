import React, { useEffect, useState } from 'react';
import SelectClients from '../../../../components/Selects/SelectClients';
import SelectPaymentTypes from '../../../../components/Selects/SelectPaymentTypes';
import DealCreateCarsModal from '../DealCreateCarsModal';
import createDeal from '../../../../api/deals/createDeal';
import { useLocation, useNavigate } from 'react-router-dom';
import editDeal from '../../../../api/deals/editDeal';
import removeItemsToDeal from '../../../../api/deals/removeItemsToDeal';
import setStep from '../../../../api/deals/setStep';
import addItemsToDeal from '../../../../api/deals/addItemsToDeal';
import Button from '../../../../components/Button/Button';
import alert from '../../../../helpers/alert';
import LoaderInline from '../../../../components/Loader/LoaderInline';
import PickEquipmentsForSale from './PickEquipmentsForSale';

const DealCreationMainInfo = ({ nextStep, deal, cars, update }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [carsModal, setCarsModal] = useState(false);
  const [equipmentsModal, setEquipmentsModal] = useState(false);
  const [client, setClient] = useState(null);
  const [car, setCar] = useState(cars);
  const [equipments, setEquipments] = useState([]);
  const [paymentType, setPaymentType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cart, setCart] = useState([]);

  const onCarsFormSubmit = (cart) => {
    setCart(cart);
  };
  const onEquipmentsFormSubmit = (newEquipments) => {
    newEquipments = newEquipments?.map((equipment) => ({ ...equipment, n: equipment?.n || true }));
    setEquipmentsModal(false);
    setEquipments([...equipments, ...newEquipments]);
  };

  const handleSubmit = (values) => {
    if (!isFormValid()) return;

    setLoading(true);
    const payload = {
      only_dop: true,
      deal: {
        clientId: client?.value,
        paymentTypeId: paymentType?.value,
      },
      options: {
        cars: cart?.map((car) => ({ value: car.id })),
        equipments: cart
          ?.map((car) => car.equipments)
          .flat()
          .map((equipment) => ({ value: equipment.id, count: equipment.counter })),
        clientId: client?.value,
      },
    };

    if (deal?.id) {
      editDeal({
        clientId: values?.clientId?.value || deal?.client?.id,
        statusId: values?.statusId?.value,
        paymentTypeId: values?.paymentTypeId?.value,
        id: deal?.id,
        dealId: deal?.id,
      })
        .then(() => {
          if (car?.filter((item) => item?.n)?.length) {
            addItemsToDeal({
              cars: car?.map((car) => ({ value: String(car.id) })),
              dealId: String(deal?.id),
              step: 2,
              clientId: values?.clientId?.value || deal?.client?.id,
            })
              .then(() => {
                nextStep();
              })
              .catch((err) => alert(err));
          } else {
            setStep({ dealId: String(deal?.id), step: 2 }).then(() => {
              update();
            });
          }
        })
        .finally(() => setLoading(false));
    } else {
      createDeal(payload)
        .then((res) => {
          nextStep(res?.data);
          navigate(`/deals/deal/${res?.data}/main-info`);
        })
        .finally(() => setLoading(false));
    }
  };

  const handleCarDelete = (item) => {
    if (item?.n) {
      const newCars = car.filter((car) => car.id !== item.id);
      setCar(newCars);
    } else {
      removeItemsToDeal({
        cars: [{ value: String(item?.orderId) }],
        dealId: String(deal?.id),
      }).then(() => {
        update();
      });
    }
  };
  const handleEquipmentDelete = (item) => {
    if (item?.n) {
      const newCars = equipments.filter((car) => car.id !== item.id);
      setEquipments(newCars);
    } else {
      removeItemsToDeal({
        cars: [{ value: String(item?.orderId) }],
        dealId: String(deal?.id),
      }).then(() => {
        update();
      });
    }
  };

  useEffect(() => {
    setCar(cars);
  }, [cars]);

  useEffect(() => {
    if (location?.state?.car) {
      onCarsFormSubmit([location?.state?.car]);
    }
  }, [location]);

  const isFormValid = () => {
    if (!client) {
      alert.error('Выберите клиента');
      return false;
    }
    if (!cart?.length) {
      alert.error('Добавьте оборудование');
      return false;
    }
    if (!paymentType) {
      alert.error('Выберите тип оплаты');
      return false;
    }

    return true;
  };

  if (loading) return <LoaderInline />;

  return (
    <>
      <PickEquipmentsForSale
        isOpen={carsModal}
        onRequestClose={() => setCarsModal(false)}
        onSubmit={onCarsFormSubmit}
        onCancel={() => setCarsModal(false)}
        defaultQueryParams={{ dop: true }}
      />

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 15,
          marginBottom: 20,
        }}
      >
        <SelectClients value={client} onChange={setClient} />

        {/* <AddButton text="Добавить автомобили" onClick={() => setCarsModal(true)} />
        <AddButton text="Добавить доп.оборудование" onClick={() => setEquipmentsModal(true)} /> */}
        <SelectPaymentTypes label="Способ оплаты" value={paymentType} onChange={setPaymentType} dop />
      </div>

      <Button text="Сохранить и продолжить" fill onClick={handleSubmit} />

      <DealCreateCarsModal
        isOpen={carsModal}
        onRequestClose={() => setCarsModal(false)}
        onSubmit={onCarsFormSubmit}
        onCancel={() => setCarsModal(false)}
        defaultQueryParams={{ dop: true }}
      />

      {/* <DealCreateEquipmentsModal
        isOpen={equipmentsModal}
        onRequestClose={() => setEquipmentsModal(false)}
        onSubmit={(_, equipments) => {
          onEquipmentsFormSubmit(Object.keys(equipments).map((key) => equipments[key]));
        }}
        onCancel={() => setEquipmentsModal(false)}
        //TODO хардкод пока не продуман интерфейс для кейса с несколькими машинами в сделке
        containerId={car?.[0]?.container?.id}
        carId={car?.[0]?.id}
      /> */}
    </>
  );
};

export default DealCreationMainInfo;
