import React from 'react';
import styles from './clientCheck.module.css';

// icons
import refresh from '../../assets/buttonIcons/refresh.svg';
import complete from '../../assets/complete.svg';
import reject from '../../assets/reject.svg';
import pending from '../../assets/pending.svg';

const statuses = {
  'pending': pending,
  'success': complete,
  'reject': reject,
}

const colors = {
  'pending': '#6A6A6A',
  'success': '#349D64',
  'reject': '#EE7171',
}

const ClientCheck = ({ list = [], onRefresh }) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>Проверки по клиенту</p>
      <div className={styles.grid}>
        {list.map((item, index) => {
          return (
            <div className={styles.column}>
              <div className={styles.bank}>{item?.title}</div>
              <div style={{ color: colors[item?.status] }}>
                <img src={statuses[item?.status]} alt="Icon" />
                <p className={styles.response} style={{lineHeight: 1}}>
                  {item?.statusLabel}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <button
        className={styles.refresh}
        onClick={() => {
          onRefresh && onRefresh();
        }}
      >
        <img src={refresh} alt="Icon" />
        <p>Обновить</p>
      </button>
    </div>
  );
};

export default ClientCheck;
