import api from '../../plugins/api';

const uploadDocumentToCar = ({ carId, typeId, file, name }) => {
  const formData = new FormData();
  formData.append('carId', carId);
  formData.append('typeId', typeId);
  formData.append('file', file);
  formData.append('name', name);

  return api.post('/api/crm/cars/uploadDocument', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default uploadDocumentToCar;
