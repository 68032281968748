import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGlobalFilter, usePagination, useTable, useSortBy } from 'react-table';
import { CLIENTS, NOTARY_CLIENTS } from '../../../constants/columns';
import { clientOptions } from '../../../constants/filterOptions';
import { setFilters } from '../../../utils/setFilters';

// components
import Empty from '../../../components/Empty/Empty';
import Header from '../../../components/Header/Header';
import Table from '../../../components/Table/Table';
import TableTabs from '../../../components/TableTabs/TableTabs';
import HookForm from '../../../components/HookForm/HookForm';

// icons
import getClients from '../../../api/clients/getClients';
import clientsIcon from '../../../assets/sidebar/clients.svg';
import ClientsFilter from '../../../components/Filters/ClientsFilter';
const tableStyle = { backgroundColor: 'white' };

const NotaryClients = () => {
  const [params] = useSearchParams();
  const [columns, setColumns] = React.useState(NOTARY_CLIENTS);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = React.useState([]);
  const [isFetchAll, setFetchAll] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    pageSize: 10,
  });

  const fetchData = (params) => {
    setLoading(true);
    getClients(params)
      .then((res) => {
        setClients(res.data.users);
        setPagination({
          ...res.data.pagination,
          page: res.data.pagination?.pageNumber,
          pageSize: pagination.pageSize,
        });
        setDataCount(res.data.pagination?.count);
      })
      .finally(() => setLoading(false));
  };

  const table = useTable(
    {
      columns,
      data: clients,
      manualPagination: true,
      pageCount: pagination.totalPages,
      page: pagination.page - 1,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { setGlobalFilter, page, setPageSize } = table;

  const [queryParams, setQueryParams] = useState({});

  React.useEffect(() => {
    if (!isFetchAll) {
      fetchData({ page: pagination.page, pageSize: pagination.pageSize, ...Object.fromEntries(params)});
    }
  }, [pagination.page, pagination.pageSize, queryParams, params]);

  const handleNavigate = (index) => {
    const client = clients.filter((item) => item.id === index);
    navigate(`${client[0].id}/main-data`, { state: client[0] });
  };

  return (
    <>
      <br />
      <br />
      {!page.length && !loading ? (
        <Empty />
      ) : (
        <Table
          p={pagination}
          table={table}
          style={tableStyle}
          handleNavigate={handleNavigate}
          pagination
          sort
          setPagination={setPagination}
          setFetchAll={setFetchAll}
          isFetchAll={isFetchAll}
          setData={setClients}
          loading={loading}
          setLoading={setLoading}
          columns={columns}
          setColumns={setColumns}
          columnsSettingsKey="clients"
          queryParams={queryParams}
          api={'/api/crm/clients/getClients'}
          response={'users'}
          isSortable
          dataCount={dataCount}
        />
      )}
    </>
  );
};

export default NotaryClients;
