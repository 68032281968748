import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import createComplectationOptions from '../../../api/cars/createComplectationOptions';

// components
import Form from '../../../components/Form/Form';
import Header from '../../../components/Header/Header';

// icons
import icon from '../../../assets/sidebar/cars.svg';

const formStyles = {
  option: { display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr' },
};

const CarOptionsCreate = () => {
  const navigate = useNavigate();
  const [option, setOption] = useState([
    { type: 'input', value: undefined, label: 'Название', placeholder: 'Укажите название' },
    { type: 'selectComplectations', value: 6 },
  ]);

  const createOptionCar = () => {
    createComplectationOptions({
      title: option[0].value,
      complectationId: option[1].value,
      type_id: 1,
    }).then(() => navigate('/cars/options'));
  };

  return (
    <>
      <Header title={'Новая опция'} icon={icon} />
      <div style={{ paddingBottom: '20px' }}>
        <Form
          inputs={option}
          title={'Опции'}
          grid={formStyles.option}
          action={'Сохранить'}
          handlerSetState={setOption}
          handleSubmit={createOptionCar}
          border
        />
      </div>
    </>
  );
};

export default CarOptionsCreate;
