import React, { useState } from 'react';
import axios from '../../../plugins/axios';
import styles from './clientMainData.module.css';
import getBirthDateFromIIN from '../../../utils/getBirthDateFromIIN';
import { get } from 'react-hook-form';
import editProfile from '../../../api/clients/editProfile';
import api from '../../../plugins/api';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import checkCrmSidebarNotifications from '../../../api/notifications/checkCrmSidebarNotifications';
import getClients from '../../../api/clients/getClients';

// components
import ClientCheck from '../../../components/ClientCheck/ClientCheck';
import HookForm from '../../../components/HookForm/HookForm';
import Datepicker from '../../../components/Inputs/Datepicker/Datepicker';
import SelectPhoto from '../NewClient/ClientCreate/SelectPhoto/SelectPhoto';
import Select from '../../../components/Select/Select';
import SelectPhoneType from '../../../components/Selects/SelectPhoneType';
import SelectAddressType from '../../../components/Selects/SelectAddressType';
import SelectSocialType from '../../../components/Selects/SelectSocialType';
import SelectClientType from '../../../components/Selects/SelectClientType';
import SelectCity from '../../../components/Selects/SelectCity';
import Alert from '../../../components/Alert/Alert';
import Loader from '../../../components/Loader/Loader';

// icons
import phoneIcon from '../../../assets/input/phone.svg';

import { iin, phone } from '../../../constants/mask';
import SelectClientID from '../../../components/Selects/SelectClientD';
import SelectClientIssuer from '../../../components/Selects/SelectClientDocumentType';
import {
  CLIENT_DOCUMENT_ISSUED_BY,
  CLIENT_DOCUMENT_TYPE,
  CLIENT_NOTARIAL_VERIFICATION_APPROVED,
  CLIENT_NOTARIAL_VERIFICATION_OPTIONS,
} from '../../../constants';
import editClientDocuments from '../../../api/clients/editClientDocuments';
import editClient from '../../../api/clients/editClient';
import { toast } from 'react-toastify';
import Button from '../../../components/Button/Button';

const formStyles = {
  mainData: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a a ' 'b b b ' 'k k c' 'd d d'` },
  contacts: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  },
  document: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '20px',
  },
  comment: { display: 'grid', gap: '20px' },
};

const ClientMainData = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [photo, setPhoto] = useState();
  const [uploadPhoto, setUploadPhoto] = useState();
  const [isDisableSelectPhoto, setDisableSelectPhoto] = useState(true);
  const [mainData, setMainData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [comment, setComment] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editClientLoading, setEditClientLoading] = useState(false);
  const [document, setDocument] = useState([]);
  const [vu, setVu] = useState([]);
  const [client, setClient] = useState(null);

  const init = () => {
    setLoading(true)
    getClients({ id })
      .then((res) => {
        const client = res.data.users[0];
        // const [day, month, year] = getBirthDateFromIIN(client.iin).split('.');
        const mainData = [
          {
            name: 'surname',
            label: 'Фамилия',
            placeholder: 'Фамилия клиента',
            defaultValue: client.surname,
          },
          { name: 'name', label: 'Имя', placeholder: 'Имя клиента', defaultValue: client.name },
          {
            name: 'middlename',
            label: 'Отчество',
            placeholder: 'Отчество (если есть)',
            defaultValue: client.middlename,
          },
          {
            name: 'iin',
            label: 'ИИН',
            placeholder: '_ _ _ _ _ _ _ _ _ _ _',
            mask: iin,
            defaultValue: client.iin,
          },
          {
            component: Datepicker,
            name: 'born',
            label: 'Дата рождения',
            placeholder: 'дд-мм-гггг',
            defaultValue: client.born,
          },
          {
            component: Select,
            label: 'Язык',
            placeholder: 'Выберите из списка',
            name: 'languageId',
            options: [
              { value: 1, label: 'Казақша' },
              { value: 2, label: 'Русский' },
            ],
            defaultValue: { value: client?.language?.id, label: client?.language?.title },
          },
          {
            name: 'phoneNumber',
            label: 'Номер телефона',
            placeholder: '+7(___) ___-__-__',
            mask: phone,
            defaultValue: client.phone[1] === '7' ? client.phone : client.phone.substring(1),
            icon: phoneIcon,
          },
          {
            component: SelectClientType,
            name: 'vip',
            defaultValue: client.vip ? { value: 1, label: 'VIP' } : { value: 2, label: 'Посредник' },
          },
          {
            component: SelectCity,
            name: 'city',
            label: 'Город',
            defaultValue: { value: client?.city?.id, label: client?.city?.title },
          },
        ];

        const contacts = [
          {
            name: 'email',
            label: 'E-mail',
            placeholder: 'Укажите адрес электронной почты клиента',
            defaultValue: client?.email,
          },
          {
            name: 'url',
            label: 'URL сайта',
            placeholder: 'Сайт компании клиента',
            defaultValue: client?.siteUrl,
          },
        ];
        client.phones.forEach((phoneItem, index) => {
          contacts.push(
            {
              component: SelectPhoneType,
              name: `phoneType_${index}`,
              defaultValue: [
                { value: 1, label: 'Мобильный' },
                { value: 2, label: 'Домашний' },
                { value: 3, label: 'Корпоративный' },
              ].filter((item) => item.value === phoneItem.typeId)[0],
            },
            {
              name: `phoneNumber_${index}`,
              label: 'Номер телефона',
              placeholder: '+7(___) ___-__-__',
              mask: phone,
              defaultValue: phoneItem.number.substring(1),
              icon: phoneIcon,
            }
          );
        });
        client.addresses.forEach((address, index) => {
          contacts.push(
            {
              component: SelectAddressType,
              name: `addressType_${index}`,
              defaultValue: [
                { value: 1, label: 'Домашний' },
                { value: 2, label: 'Корпоративный' },
              ].filter((item) => item.value === address.typeId)[0],
            },
            {
              name: `address_${index}`,
              label: 'Адрес',
              placeholder: 'Укажите адрес',
              defaultValue: address.address,
            }
          );
        });
        client.socials.forEach((social, index) => {
          contacts.push(
            {
              component: SelectSocialType,
              name: `socialType_${index}`,
              defaultValue: [
                { value: 1, label: 'Facebook' },
                { value: 2, label: 'VK' },
                { value: 3, label: 'Instagram' },
                { value: 4, label: 'Telegram' },
              ].filter((item) => item.value === social.typeId)[0],
            },
            {
              name: `social_${index}`,
              label: '@',
              placeholder: 'ссылка',
              defaultValue: social.value,
            }
          );
        });

        const comments = [];
        client.comments.forEach((comment) => {
          comments.push({
            name: 'comment',
            label: 'Заметки/комментарии о клиенте',
            placeholder: 'Укажите любые заметки или комментарии о клиенте',
            defaultValue: comment.comment,
          });
        });

        setDocument([
          {
            component: SelectClientID,
            name: 'document_type_id',
            defaultValue: CLIENT_DOCUMENT_TYPE.find((item) => item.value === client?.document?.document_type_id),
          },
          {
            name: 'document_number',
            label: 'Номер документа',
            defaultValue: client?.document?.document_number,
          },
          {
            component: Datepicker,
            name: 'document_date',
            type: 'date',
            label: 'Дата выдачи документа',
            defaultValue: client?.document?.document_date,
          },
          {
            component: Datepicker,
            name: 'document_date_end',
            type: 'date',
            label: 'Срок действия документа',
            defaultValue: client?.document?.document_date_end,
          },
          {
            component: SelectClientIssuer,
            name: 'document_issuer',
            label: 'Кем выдан',
            defaultValue: CLIENT_DOCUMENT_ISSUED_BY.find(
              (item) => item?.value === Number(client?.document?.document_issuer)
            ),
          },
        ]);

        setVu([
          {
            name: 'document_number',
            label: 'Номер в/у',
            defaultValue: client?.driver_document?.document_number,
          },
          {
            component: Datepicker,
            name: 'document_date',
            type: 'date',
            label: 'Дата выдачи',
            defaultValue: client?.driver_document?.document_date,
          },
          {
            component: Datepicker,
            name: 'document_date_end',
            type: 'date',
            label: 'Срок действия',
            defaultValue: client?.driver_document?.document_date_end,
          },
        ]);

        setMainData(mainData);
        setContacts(contacts);
        setComment(comments);
        setPhoto(client.photo);
        setClient(client);
        checkCrmSidebarNotifications({
          notificationIds: client?.notifications.map(i=>i?.id)
        });
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    init();
  }, []);

  const onClientMainDataSave = (data) => {
    setEditClientLoading(true);
    const payload = { id };

    for (let i = 0; i < mainData.length; i++) {
      if (mainData[i].name === 'vip') {
        payload.vip = data.vip?.value === 1 ? true : false ?? mainData[i].defaultValue?.value === 1 ? true : false;
      } else if (mainData[i].name === 'phoneNumber') {
        payload.phone = data?.phoneNumber?.replace(/[^\d]/g, '') ?? '7' + mainData[i]?.defaultValue;
      } else {
        payload[mainData[i].name] = mainData[i].value
          ? mainData[i].value?.value ?? mainData[i].value
          : mainData[i].defaultValue?.value ?? mainData[i].defaultValue;
      }
    }

    editProfile(payload)
      .then(() => {
        toast('Данные клиента изменены');
      })
      .catch((error) => toast(error.response.data.errorMessage ?? JSON.stringify(error.response)))
      .finally(() => setEditClientLoading(false));
  };

  const onClientContactsSave = (data) => {
    editClient({
      id,
      address: data?.address_0,
      email: data?.email,
      url: data?.url,
    }).then(() => toast('Контакты клиента изменены'));
  };

  const onClientDocumentSave = (data) => {
    console.log(data);
    editClientDocuments({
      id,
      document_type_id: data?.document_type_id?.value,
      document_number: data?.document_number,
      document_date_end: data?.document_date_end,
      document_issuer: data?.document_issuer?.value,
      document_date: data?.document_date,
    }).then(() => toast('Документы клиента изменены'));
  };

  const onClientCommentSave = (data) => {
    // const payload = { comment: data.comment ?? comment[0].defaultValue };
  };

  const addValuesContactsForm = (array, isPhoneNumber) => {
    const formatPhoneNumber = (number) => {
      if (!number) return;
      const phoneNumber = number.replace(/[^\d]/g, '');
      const result = [];
      for (let i = phoneNumber.length; result.length <= 10; i--) {
        result.push(phoneNumber[i]);
      }
      return '7' + result.reverse().join('');
    };

    const result = [];
    for (let i = 0; i < array.length; i += 2) {
      if (array[i].value) {
        result.push({
          type: array[i].value?.value,
          value: isPhoneNumber
            ? formatPhoneNumber(array[i + 1].value) ?? formatPhoneNumber(array[i + 1].defaultValue)
            : array[i + 1].value ?? array[i + 1].defaultValue,
        });
        break;
      }
      if (array[i].defaultValue) {
        result.push({
          type: array[i].defaultValue?.value,
          value: isPhoneNumber
            ? formatPhoneNumber(array[i + 1].value) ?? formatPhoneNumber(array[i + 1].defaultValue)
            : array[i + 1].value ?? array[i + 1].defaultValue,
        });
      }
    }
    return result;
  };

  const sellCar = () => {
    navigate("/deals/create", { state: { client } })
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {editClientLoading && <Loader />}
          <div className={styles.wrapper}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <SelectPhoto
                photo={photo}
                setPhoto={setPhoto}
                setUploadPhoto={setUploadPhoto}
                style={{ flex: 1 }}
                disabled={isDisableSelectPhoto}
              />
              <p className={styles.date} style={{ margin: '20px 0' }}>
                Клиент зарегистрирован: <span>01.09.2022</span>
              </p>
              <ClientCheck
                list={[
                  {
                    title: 'Нотариус',
                    statusLabel: CLIENT_NOTARIAL_VERIFICATION_OPTIONS?.find(
                      (item) => item?.value === client?.notarial_verification
                    )?.label,
                    color: '#6A6A6A',
                    status:
                      client?.notarial_verification == 1
                        ? 'pending'
                        : client?.notarial_verification == 2
                        ? 'success'
                        : 'reject',
                  },
                ]}
                onRefresh={init}
              />
              <Button text="Продать автомобиль" fill style={{marginTop: 15}} onClick={sellCar}/>
            </div>
            <div className={styles.forms}>
              <HookForm
                title={'Данные клиента'}
                grid={formStyles.mainData}
                border
                inputs={mainData}
                style={{ margin: 0 }}
                action={'Сохранить изменения'}
                onSubmit={onClientMainDataSave}
              />
              <HookForm
                title={'Контактные данные'}
                grid={formStyles.contacts}
                border
                inputs={contacts}
                style={{ margin: 0 }}
                isClientCreate
                action={'Сохранить изменения'}
                onSubmit={onClientContactsSave}
              />
              <HookForm
                title={'Данные документов'}
                grid={formStyles.document}
                border
                inputs={document}
                style={{ margin: 0 }}
                onSubmit={onClientDocumentSave}
                action={'Сохранить изменения'}
              />
              <HookForm
                title={'Данные документов'}
                grid={formStyles.document}
                border
                inputs={vu}
                style={{ margin: 0 }}
                onSubmit={onClientDocumentSave}
                action={'Сохранить изменения'}
              />
              <HookForm
                title={'Дополнительно'}
                border
                grid={formStyles.comment}
                inputs={comment}
                style={{ margin: 0 }}
                isClientCreate
                action={'Сохранить изменения'}
                onSubmit={onClientCommentSave}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ClientMainData;
