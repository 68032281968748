import React from 'react';
import styles from './hookModalForm.module.css';

// components
import Form from '../Form/Form';
import Button from '../Button/Button';
import AddButton from '../AddButton/AddButton';
import HookForm from '../HookForm/HookForm';

// icons
import close from '../../assets/x.svg';

const HookModalForm = ({
  title,
  closeModal,
  mainForm,
  setMainForm,
  formTitle,
  handleSubmit,
  grid,
  actions,
  setActions,
  setForm,
  forms,
  button,
  loading,
  action,
}) => {
  const handleAddForm = (index) => {
    setActions((prev) => {
      return [...prev, (prev[index].show = false)];
    });
    setForm[index]((prev) => ({
      ...prev,
      form: prev.add,
    }));
  };

  return (
    <div className={styles.container}>
      <div style={{ width: '316px' }} />
      <div className={styles.wrapper}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1>{title}</h1>
          <img src={close} alt="Icon" onClick={closeModal} />
        </div>
        <HookForm
          handlerSetState={setMainForm}
          inputs={mainForm}
          title={formTitle}
          grid={grid}
          onSubmit={handleSubmit}
          action={action}
        />
        {forms && (
          <>
            {forms[0].form.length ? (
              <HookForm handlerSetState={setForm[0]} inputs={forms[0].form} grid={forms[0].style} />
            ) : (
              ''
            )}
            {forms[1].form.length ? (
              <HookForm handlerSetState={setForm[1]} inputs={forms[1].form} grid={forms[1].style} />
            ) : (
              ''
            )}
            {forms[2].form.length ? (
              <HookForm handlerSetState={setForm[2]} inputs={forms[2].form} grid={forms[2].style} />
            ) : (
              ''
            )}
            {forms[3].form.length ? (
              <HookForm handlerSetState={setForm[3]} inputs={forms[3].form} grid={forms[3].style} />
            ) : (
              ''
            )}
          </>
        )}
        {actions && (
          <div className={styles.actions}>
            {actions.map((item, index) => {
              if (item.show && !forms[index].form.length) {
                return <AddButton key={index} text={item.text} onClick={() => handleAddForm(index)} />;
              }
              return null;
            })}
          </div>
        )}
        {button && <Button text={button} fill onClick={handleSubmit} disabled={loading} />}
      </div>
    </div>
  );
};

export default HookModalForm;
