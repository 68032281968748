import React from 'react';
import styles from './user.module.css';
import { useSelector } from 'react-redux';

// icons
import arrow from '../../assets/header/arrowUser.svg';
import exit from '../../assets/sidebar/exit.svg';
import { ReactComponent as Exit } from '../../assets/sidebar/exit.svg';
import { colors } from '../../constants/colors';
import logout from '../../helpers/logout';

const User = () => {
  const user = useSelector((state) => state.user);

  function test() {
    console.log(user);
  }

  return (
    <div className={styles.user}>
      <div className={styles.name}>
        <p>
          {user?.surname} {user?.name} {user?.middlename}
        </p>
        <p onClick={test}>{user.role?.title}</p>
      </div>
      {/* <img
        alt="User"
        className={styles.avatar}
      /> */}
      {/* <img src={arrow} alt="Arrow" className={styles.arrow} /> */}
      <Exit className={styles.arrow} fill={colors?.cameo} style={{cursor: 'pointer'}} onClick={logout}/>
    </div>
  );
};

export default User;
