import React from 'react';
import Select from '../Select/Select';

export const COLORS = [
  { value: '#FFFFFF', label: 'Белый' },
  { value: '#000000', label: 'Чёрный' },
  { value: '#808080', label: 'Серый' },
  { value: '#FF0000', label: 'Красный' },
  { value: '#FFA500', label: 'Оранжевый' },
  { value: '#FFFF00', label: 'Жёлтый' },
  { value: '#00FF00', label: 'Зелёный' },
  { value: '#00bfff', label: 'Голубой' },
  { value: '#0000ff', label: 'Синий' },
  { value: '#800080', label: 'Фиолетовый' },
  { value: '#964b00', label: 'Коричневый' },
  { value: '#FFEFDF', label: 'Бежевый' },
];

const SelectColor = ({ label, ...props }) => {
  return <Select options={COLORS} label={label || 'Цвет'} placeholder={'Выберите из списка'} {...props} />;
};

export default SelectColor;
