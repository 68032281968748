import React, { useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { CAR_DEAL } from '../../constants/columns';

// components
import EmptyData from '../../components/EmptyData/EmptyData';
import Table from '../../components/Table/Table';

// icons
import icon from '../../assets/buttonIcons/addDeal.svg';

import { textStyles } from '../../constants/textStyles';

const columns = CAR_DEAL;

const CarDeal = () => {
  const [deals, setDeals] = useState([
    {
      id: 1,
      date: '01.08.2022',
      price: '8790000',
      payment_method: 'автокредит',
      payment_status: 'ожидает оплаты',
      deal_status: 'авто в салоне',
    },
  ]);

  const table = useTable(
    {
      columns,
      data: deals,
    },
    usePagination
  );

  return (
    <>
      {deals.length ? (
        <div style={{ padding: '30px 20px', backgroundColor: 'white', border: '1px solid #e0e0e0', marginTop: '20px' }}>
          <p style={textStyles.formTitle}>Сделка</p>
          <br />
          <Table table={table} />
        </div>
      ) : (
        <EmptyData
          title={'Сделка'}
          subtitle={'Это автомобиль пока не участвует ни в одной сделке'}
          action={'Создать сделку с автомобилем'}
          icon={icon}
        />
      )}
    </>
  );
};

export default CarDeal;
