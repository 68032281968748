import { store } from '..';

const isUserHavePermission = (permission) => {
  const {roleId, permissions} = store.getState()?.user;

  if (permission == 'all') return true;
  return permissions.find((p) => p.id === roleId)?.options?.find((p) => p.id === permission)?.active;
};

export default isUserHavePermission;
