import React, { useEffect, useState } from 'react';
import styles from './table.module.css';
import { useDetectClickOutside } from 'react-detect-click-outside';
import axios from '../../plugins/axios';

// components
import Pagination from '../Pagination/Pagination';
import Sort from '../Sort/Sort';
import Popover from '../Popover/Popover';
import File from '../File/File';
import ClientLink from '../ClientLink/ClientLink';
import Status from '../Status';
import Loader from '../Loader/Loader';
import LoaderInline from '../Loader/LoaderInline';
import Empty from '../Empty/Empty';

// icons
import dots from '../../assets/dots.svg';
import arrow from '../../assets/arrow.svg';
import indicator from '../../assets/indicator.svg';

import { colors } from '../../constants/colors';
import dayjs from 'dayjs';
import Checkbox from '../Inputs/Checkbox/Checkbox';
import Button from '../Button/Button';
import Counter from '../Inputs/Counter/Counter';
import { CREDIT_TYPE_ID, INSURANCE_ID, PAYMENT_TYPES_CREDIT_CARD, PAYMENT_TYPES_CREDIT_INVOICE } from '../../constants';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const Table = ({
  p,
  setPagination,
  columns,
  setColumns,
  columnsSettingsKey,
  onSubmit,
  onCancel,
  paymentType,
  deleteType,
  deleteFunc,
  withCount,
  edit = true,
  withCheckboxes,
  submitButtonLabel = 'Сохранить',
  requiredCheckboxes = [],
  minWidth = 0,
  customRow = [
    // {
    //   name: 'custom__name',
    //   component: () => <div>custom</div>,
    // }
  ],
  onDropItemToTrash,
  ...props
}) => {
  const [clue, setClue] = useState({ show: false, message: '' });
  const [isOpen, setOpen] = useState(false);
  const popoverRef = useDetectClickOutside({ onTriggered: () => setOpen(false) });
  const [rowIndex, setRowIndex] = useState(null);
  const [isSorted, setSorted] = useState(false);
  const [inlineLoading, setInlineLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, setPageSize, page, pageOptions, state, data } =
    props.table;

  const { pageSize, pageIndex } = state;
  const nextPage = () => {
    if (p.hasNextPage) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: prevPagination.page + 1,
      }));
    }
  };

  const previousPage = () => {
    if (p.hasPreviousPage) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: prevPagination.page - 1,
      }));
    }
  };

  const gotoPage = (page) => {
    if (!page) return;
    setPagination((prevPagination) => ({
      ...prevPagination,
      page,
    }));
  };

  const handlePageSize = (pageSize) => {
    setPageSize(pageSize);
    setPagination((prevPagination) => ({ ...prevPagination, pageSize, page: 1 }));
  };

  const handleShowPopover = (e) => {
    const node = e.target;
    if (node.localName === 'img') {
      setOpen(() => !isOpen);
      setRowIndex(node.id);
    }
    if (node.localName === 'p' && node.id) {
      props.setClientId && props.setClientId(node.id);
      props.setOpenClientPopup && props.setOpenClientPopup(true);
    }
  };

  const getRowBackgroundColor = (row) => {
    if (row?.original?.notifications?.length) {
      return colors.rowRedBG;
    }
    if (
      row?.original?.hasOwnProperty('additions') &&
      !row?.original?.additions?.toLowerCase().trim().includes('страховка')
    ) {
      return colors.rowRedBG;
    }
    if (row?.original?.statusPayment?.id === 2) {
      return colors.rowYellowBG;
    }
    if (row?.original?.type?.title === 'Сервис' && row?.original?.status.title === 'Новая') {
      return colors.rowYellowBG;
    }
    if (row?.original?.type?.title === 'Сервис' && row?.original?.status.title === 'Завершена не успешно') {
      return colors.rowRedBG;
    }
    if (row?.original?.type?.title === 'Тест драйв' && row?.original?.status?.title === 'Новая') {
      return colors.rowYellowBG;
    }
    if (row?.original?.type?.title === 'Тест драйв' && row?.original?.status?.title === 'Завершена не успешно') {
      return colors.rowRedBG;
    }
  };

  const [showColumnEditPopup, setShowColumnEditPopup] = useState(false);
  const ref = useDetectClickOutside({
    onTriggered: () => {
      setShowColumnEditPopup(false);
    },
  });
  const saveColumnSettings = (columnSettings) => {
    localStorage.setItem(columnsSettingsKey, JSON.stringify(columnSettings));
  };
  const getColumnSettings = () => {
    const columnSettings = localStorage.getItem(columnsSettingsKey);
    const columnSettingsObj = JSON.parse(columnSettings) || [];
    console.log(columnSettingsObj);

    if (columns) {
      const newColumns = columns.map((column) => {
        if (!columnSettings) {
          column.show = true;
        } else {
          const columnSetting = columnSettingsObj.find((columnSetting) => columnSetting.Header === column.Header);
          column.show = columnSetting ? columnSetting.show : true;
        }

        return column;
      });

      console.log(newColumns);

      setColumns(newColumns);
    }
  };
  const onChangeColumnEdit = (id, value) => {
    const newColumns = columns.map((column) => {
      if (column.Header === id) {
        column.show = value;
      }
      return column;
    });
    setColumns(newColumns);
    saveColumnSettings(newColumns);
  };

  useEffect(() => {
    getColumnSettings();
  }, []);

  const scrollHandler = (e) => {
    if (e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 100 && props.isFetchAll) {
      setFetching(true);
    }
  };

  useEffect(() => {
    const contentContainer = document.querySelector('.main-content');
    contentContainer.addEventListener('scroll', scrollHandler);
    return () => contentContainer.removeEventListener('scroll', scrollHandler);
  }, [props.isFetchAll]);

  useEffect(() => {
    if (props.isFetchAll && fetching && p.page <= p.totalPages) {
      setInlineLoading(true);
      axios
        .get(props.api, {
          params: { page: p.page, pageSize: p.pageSize, ...props.queryParams },
        })
        .then((res) => {
          if (withCount) {
            props.setData(() => [...data, ...res.data[props.response]]?.filter((item) => item.count > 0));
          } else {
            props.setData([...data, ...res.data[props.response]]);
          }
          setPagination((prev) => ({
            ...res.data.pagination,
            page: prev.page + 1,
            pageSize: prev.pageSize,
          }));
        })
        .catch((error) => {})
        .finally(() => {
          setFetching(false);
          props.setLoading(false);
          setInlineLoading(false);
        });
    }
  }, [fetching, props.isFetchAll]);

  const [selectedRows, setSelectedRows] = useState([]);
  const handleRowSelection = (row) => {
    if (selectedRows.includes(row)) {
      console.log(row, '[row]');
      setSelectedRows(selectedRows.filter((item) => item?.id !== row?.id || item?.id === paymentType));
    } else {
      console.log(row, '[row else]');
      setSelectedRows([...selectedRows, row]);
    }
  };

  const [counters, setCounters] = useState({});
  const onIncrement = (id, data) => {
    const newCounters = { ...counters };

    if (!newCounters[id]) {
      newCounters[id] = { ...data, n: 0 };
    }

    if (newCounters[id].n < data?.count) {
      newCounters[id].n = newCounters[id].n + 1;
    }

    setCounters(newCounters);
  };
  const onDecrement = (id, data) => {
    const newCounters = { ...counters };

    if (!newCounters[id]) {
      newCounters[id] = { ...data, n: 0 };
    }

    if (newCounters[id].n > 0) {
      newCounters[id].n = newCounters[id].n - 1;
    }
    setCounters(newCounters);
  };
  const [isDragging, setIsDragging] = useState(false);

  const onDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = (result) => {
    console.log('onDragEnd', result);
    setIsDragging(false);
    if (result?.destination?.droppableId == 'trash') {
      const reorderedData = Array.from(data);
      const [removed] = reorderedData.splice(result.source.index, 1);

      console.log(removed);

      onDropItemToTrash && onDropItemToTrash(removed);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      {props.sort && <Sort all={props.all} count={props.dataCount} />}
      {props.pagination && (
        <Pagination
          nextPage={nextPage}
          previousPage={previousPage}
          gotoPage={gotoPage}
          pages={pageOptions}
          currentPage={p.page}
          pageSize={pageSize}
          setPageSize={handlePageSize}
          setFetchAll={props.setFetchAll}
          setFetching={setFetching}
          setLoading={props.setLoading}
          setData={props.setData}
          data={data}
          page={page}
          table={props.table}
        />
      )}
      {props.loading ? (
        <Loader />
      ) : withCount && !data.length ? (
        <Empty title="Нет в наличии" />
      ) : (
        <>
          <div
            style={{
              width: '100%',
              overflowX: 'auto',
            }}
          >
            <Droppable droppableId="droppable" direction="vertical">
              {(provided) => (
                <table
                  {...getTableProps()}
                  {...provided.droppableProps} ref={provided.innerRef}
                  className={styles.table}
                  style={
                    props.style
                      ? {
                          minWidth,
                          ...props.style,
                        }
                      : props.invisibleHeader
                      ? { border: 'none', minWidth }
                      : {
                          minWidth,
                        }
                  }
                >
                  <thead style={props.invisibleHeader ? { opacity: '0' } : null}>
                    {headerGroups.map((headerGroup, index) => (
                      <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {onSubmit && <th></th>}
                        {headerGroup.headers.map((column, index) => {
                          if (
                            columns &&
                            !columns?.find((columnItem) => columnItem.Header === column.Header)?.show &&
                            column?.id !== 'dots'
                          )
                            return null;
                          return (
                            <th
                              {...column.getHeaderProps()}
                              key={index}
                              style={
                                index != headerGroup.headers.length - 1
                                  ? { width: headerGroup.headers[index].width }
                                  : null
                              }
                              onClick={() => (props.handleSortTable ? props.handleSortTable(column.Header) : null)}
                            >
                              <div>
                                {column?.id !== 'dots' && <p>{column.render('Header')}</p>}

                                {column?.id === 'dots' && edit && (
                                  <div style={{ width: 50 }} ref={ref}>
                                    <div
                                      onClick={() => setShowColumnEditPopup(!showColumnEditPopup)}
                                      style={{ position: 'absolute', right: 20 }}
                                    >
                                      {column.render('Header')}
                                    </div>
                                    {showColumnEditPopup && (
                                      <div className={styles.colEditModal}>
                                        {columns?.map(
                                          (column) =>
                                            column?.accessor != 'dots' && (
                                              <>
                                                <Checkbox
                                                  id={column?.Header}
                                                  label={column?.Header}
                                                  value={column?.show}
                                                  onChange={onChangeColumnEdit}
                                                />
                                              </>
                                            )
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}

                                {index != headerGroup.headers.length - 1 && column.id !== ' ' && (
                                  <button
                                    style={
                                      column.isSorted
                                        ? column.isSortedDesc
                                          ? { transform: 'rotate(180deg) translateY(-3px)' }
                                          : { transform: 'rotate(0deg)' }
                                        : null
                                    }
                                  >
                                    <img src={arrow} alt="Arrow" />
                                  </button>
                                )}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} onClick={(e) => handleShowPopover(e)} ref={popoverRef}>
                    {page.map((row, index) => {
                      prepareRow(row);
                      const rowIsSelected = selectedRows.includes(row?.original?.id);
                      return (
                        <Draggable key={row.id} draggableId={row.id} index={index} isDragDisabled={!onDropItemToTrash}>
                          {(provided) => (
                            <tr
                              {...row.getRowProps()}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                backgroundColor: getRowBackgroundColor(row),
                                ...(provided.draggableProps.style.position
                                  ? {
                                      display: 'flex',
                                      width: popoverRef.current.offsetWidth,
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      paddingTop: '20px',
                                      transitionDuration: `0.001s`,
                                    }
                                  : {}),
                              }}
                              {...row.getRowProps()}
                              key={index}
                              onClick={(e) => {
                                if (props.handleNavigate && e.target.localName === 'td' && !onSubmit) {
                                  props.handleNavigate(row.original?.id ? row.original.id : index);
                                }
                              }}
                              className={props.invisibleHeader ? styles.bottomBorder : null}
                            >
                              {onSubmit && (
                                <td>
                                  {withCheckboxes && (
                                    <Checkbox
                                      id={'id' + Math.random().toString(16).slice(2)}
                                      defaultValue={rowIsSelected}
                                      onChange={() => handleRowSelection(row?.original)}
                                    />
                                  )}
                                </td>
                              )}

                              {row.cells.map((cell, index) => {
                                if (
                                  columns &&
                                  !columns?.find((columnItem) => columnItem.Header === cell?.column?.Header)?.show
                                )
                                  return null;
                                // console.log(cell)
                                if (cell?.column?.id == 'count' && withCount) {
                                  return (
                                    <Counter
                                      value={counters[cell?.row?.original?.id]?.n || 0}
                                      onDecrement={() => onDecrement(cell?.row?.original?.id, cell?.row?.original)}
                                      onIncrement={() => onIncrement(cell?.row?.original?.id, cell?.row?.original)}
                                    />
                                  );
                                }
                                return (
                                  <td
                                    {...cell.getCellProps()}
                                    key={index}
                                    style={{ width: index === row.cells.length - 1 ? 1 : 'auto' }}
                                  >
                                    {cell.column.Header === 'Клиент' ? (
                                      <ClientLink client={cell.value} id={row?.original?.client?.id} />
                                    ) : (
                                      cell.render('Cell')
                                    )}
                                    {customRow?.find((item) => item.name === cell.column.id) &&
                                      customRow?.find((item) => item.name === cell.column.id)?.component(row?.original)}
                                    {index === row.cells.length - 1 && edit && (
                                      <button style={{ margin: '0 auto', display: 'block' }}>
                                        <img src={dots} alt="Dots" id={cell.row.id} />
                                      </button>
                                    )}
                                  </td>
                                );
                              })}

                              {props.popover && index == rowIndex && isOpen && (
                                <td style={{ padding: 0, width: 0, position: 'relative' }}>
                                  <Popover
                                    setOpen={setOpen}
                                    actions={props.popover}
                                    setActions={props.setPopover}
                                    popoverActions={props.popoverActions}
                                    deleteType={deleteType}
                                    rowIndex={rowIndex}
                                    itemId={row.original.itemId}
                                    status={row.cells[6]?.value}
                                    handleActionPopover={props.handleActionPopover}
                                    row={row.original}
                                  />
                                </td>
                              )}
                            </tr>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </tbody>
                </table>
              )}
            </Droppable>
          </div>
          {props.pagination && !inlineLoading && (
            <Pagination
              nextPage={nextPage}
              previousPage={previousPage}
              gotoPage={gotoPage}
              pages={pageOptions}
              currentPage={p.page}
              pageSize={pageSize}
              setPageSize={handlePageSize}
              setFetchAll={props.setFetchAll}
              setFetching={setFetching}
              setLoading={props.setLoading}
              setData={props.setData}
              data={data}
              page={page}
              table={props.table}
            />
          )}
          {inlineLoading && (
            <div style={{ marginTop: '10px' }}>
              <LoaderInline />
            </div>
          )}
          {onSubmit && (
            <Button
              text={submitButtonLabel}
              fill
              onClick={() => onSubmit && onSubmit(selectedRows, counters)}
              style={{ marginTop: 20 }}
            />
          )}
          {onCancel && <Button text="Отменить" onClick={onCancel} />}
        </>
      )}
      <Droppable droppableId="trash">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              width: '100%',
              height: '100px',
              background: '#fff',
              border: snapshot.isDraggingOver ? '4px dashed #d9bc92' : '4px dashed rgb(255, 214, 152)',
              position: 'fixed',
              bottom: 0,
              left: 0,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',
              opacity: isDragging ? 1 : 0,
              pointerEvents: isDragging ? 'all' : 'none',
              display: 'flex',
            }}
          >
            <p style={{ color: '#d9bc92', fontSize: 18 }}>Неудачная сделка</p>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Table;
