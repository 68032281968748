import React, { useEffect, useState } from 'react';
import SelectDocumentType from '../..//../components/Selects/SelectDocumentType';
import { textStyles } from '../..//../constants/textStyles';
import Button from '../..//../components/Button/Button';
import DocsInputNew from '../..//../components/Inputs/DocsInput/DocsInputNew';
import Loader from '../..//../components/Loader/Loader';
import LoaderInline from '../../../components/Loader/LoaderInline';
import {
  DEAL_STATUS_CONTRACT_SIGNED,
  DEAL_STATUS_DOCUMENTS_PROCESSING,
  DEAL_STATUS_WAITING_FOR_PAYMENT,
  DOCUMENT_STATUS_SIGNED,
  DOCUMENT_TYPES_CONTRACT,
  DOCUMENT_TYPES_INVOICE,
  DOCUMENT_TYPES_OPTIONS,
  DOCUMENT_TYPES_TECH_PASSPORT,
  PAYMENT_STATUS_PAID,
  PAYMENT_TYPES_PREPAYMENT_CARD,
  PAYMENT_TYPES_PREPAYMENT_INVOICE,
} from '../../../constants';
import SelectDealPaymentStatuses from '../../../components/Selects/selectDealPaymentStatuses';
import alert from '../../../helpers/alert';
import editDeal from '../../../api/deals/editDeal';
import { socket } from '../../../App';
import setStep from '../../../api/deals/setStep';
import generateAllowedDocumentsJson from '../../../utils/generateAllowedDocumentsJson';
import generateContract from '../../../api/deals/generateContract';

const DealCreatePayment = ({
  onSubmit,
  documents,
  deal,
  title = 'Документы сделки',
  setDocumentsForm,
  update,
  backHandler,
  cars,
}) => {
  const row = () => [
    {
      component: SelectDocumentType,
      name: 'fileTypeId',
      style: {
        width: '100%',
      },
      disabled: true,
      defaultValue: DOCUMENT_TYPES_OPTIONS?.find((i) => i.value === DOCUMENT_TYPES_CONTRACT),
    },
    {
      component: DocsInputNew,
      name: 'file',
      label: 'Файл документа',
      text: 'Файл документа',
      id: Math.random(),
      rules: {
        required: true,
      },
    },
  ];
  const [form, setForm] = useState([row()]);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingSignButton, setLoadingSignButton] = useState(false);
  const [loadingPaymentButton, setLoadingPaymentButton] = useState(false);

  useEffect(() => {
    if (setDocumentsForm) {
      const fields = form.map((i) => i.map((i) => i.value?.value || i.value));
      setDocumentsForm(
        fields.map((field) => ({
          dealId: deal?.id,
          fileTypeId: field[0],
          file: field[1],
        }))
      );
    }
  }, [form, setDocumentsForm, deal?.id]);

  const updatePaymentStatus = (status) => {
    setLoading(true);

    const isContractSigned = documents?.find(
      (i) => i?.typeId === DOCUMENT_TYPES_CONTRACT && i?.statusId === DOCUMENT_STATUS_SIGNED
    );
    let newStatus = DEAL_STATUS_DOCUMENTS_PROCESSING;

    if (status === PAYMENT_STATUS_PAID) {
      // для кейсов с возвратом на шаг назад
      // если договор уже подписан - статус сделки "Договор подписан", а не промежуточный "Документы обрабатываются"
      newStatus = isContractSigned ? DEAL_STATUS_CONTRACT_SIGNED : DEAL_STATUS_DOCUMENTS_PROCESSING;
    } else {
      newStatus = DEAL_STATUS_WAITING_FOR_PAYMENT;
    }

    editDeal({
      dealId: deal?.id,
      id: deal?.id,
      statusPaymentId: status,
      clientId: deal?.client?.id,
      paymentTypeId: deal?.paymentType?.id,
      statusId: newStatus,
      ...deal,
      ...generateAllowedDocumentsJson(deal?.paymentType?.id == PAYMENT_TYPES_PREPAYMENT_INVOICE ? [DOCUMENT_TYPES_INVOICE, DOCUMENT_TYPES_CONTRACT, DOCUMENT_TYPES_TECH_PASSPORT] : [DOCUMENT_TYPES_CONTRACT, DOCUMENT_TYPES_TECH_PASSPORT]),
    })
      .then(async () => {
        alert.success('Статус оплаты успешно обновлен');
        if (status === PAYMENT_STATUS_PAID) {
          // await generateContract({orderIds: cars?.map(i => i?.orderId)})
          setStep({
            dealId: deal?.id,
            step: 5,
          })
            .then(() => {
              update && update();
            })
            .catch(() => {
              alert.error('Ошибка обновления статуса сделки');
            });
        }

        update && update();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const isDealPaid = deal?.statusPayment?.id === PAYMENT_STATUS_PAID;
  const [paymentStatus, setPaymentStatus] = useState(null);

  const sendPaymentSms = () => {
    setLoadingPaymentButton(true);
    editDeal({
      dealId: deal?.id,
      id: deal?.id,
      paymentTypeId: PAYMENT_TYPES_PREPAYMENT_CARD,
      statusId: deal?.status?.id,
      ...generateAllowedDocumentsJson(deal?.paymentType?.id == PAYMENT_TYPES_PREPAYMENT_INVOICE ? [DOCUMENT_TYPES_INVOICE, DOCUMENT_TYPES_CONTRACT, DOCUMENT_TYPES_TECH_PASSPORT] : [DOCUMENT_TYPES_CONTRACT, DOCUMENT_TYPES_TECH_PASSPORT]),
      generatePaymentLink: true,
      ...deal,
    })
      .then(() => {
        alert.success('СМС успешно отправлено');
      })
      .catch(() => {
        alert.error('Ошибка отправки СМС');
      })
      .finally(() => {
        setLoadingPaymentButton(false);
      });
  };

  useEffect(() => {
    const contract = documents?.find((i) => i?.typeId === DOCUMENT_TYPES_CONTRACT);
    socket.on('listenSignatureAgreementDocumentInDeal' + contract?.id, (data) => {
      if (data?.success) {
        update && update();
      } else {
        alert.error('Ошибка подписания документа');
      }
    });

    return () => {
      socket.off('listenSignatureAgreementDocumentInDeal' + contract?.id);
    };
  }, [documents, update]);

  useEffect(() => {
    console.log('listenCreditCardPaymentChunk' + deal?.chunkId);
    socket.on('listenCreditCardPaymentChunk' + deal?.chunkId, (data) => {
      if (data?.success) {
        update && update();
      } else {
        alert.error('Ошибка оплаты');
      }
    });

    return () => {
      socket.off('listenCreditCardPaymentChunk' + deal?.chunkId);
    };
  }, [deal?.chunkId, update]);

  if (loading) {
    return (
      <div style={{ padding: '10px 20px', backgroundColor: 'white', marginTop: '20px', border: '1px solid #E0E0E0' }}>
        <LoaderInline />
      </div>
    );
  }

  return (
    <>
      {loadingPage ? (
        <Loader />
      ) : (
        <div>
          <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px', marginTop: 20, marginBottom: 20 }}>
            <p style={textStyles.formTitle}>{title}</p>
          </div>
          <div style={{ marginBottom: 40 }}>
            {documents?.map((document) => (
              <a
                href={document?.url}
                target="_blank"
                style={{
                  gap: 7,
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr',
                  alignItems: 'center',
                  marginBottom: 20,
                }}
                rel="noreferrer"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                  <path
                    d="M2 16H18V9H20V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V9H2V16ZM12 6H17L10 13L3 6H8V0H12V6Z"
                    fill="#D9BC92"
                  />
                </svg>
                <a style={{ color: '#D9BC92', fontSize: 16, fontWeight: 400 }}>
                  {document?.type?.title} - {document?.url?.split('/')?.pop()}
                </a>
                {/* <ButtonDelete /> */}
              </a>
            ))}
          </div>

          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
            <SelectDealPaymentStatuses label="Статус оплаты" onChange={setPaymentStatus} />
          </div>

          {/* {deal?.statusPayment?.id == PAYMENT_STATUS_PAID && <p>Ожидает нотариальных документов</p>} */}

          <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
            <Button text="Сохранить" fill onClick={() => updatePaymentStatus(paymentStatus?.value)} />
            {backHandler && backHandler()}
          </div>

          {deal?.paymentType?.id === PAYMENT_TYPES_PREPAYMENT_CARD && (
            <>
              <div
                style={{ display: 'flex', alignItems: 'center', columnGap: '20px', marginTop: 20, marginBottom: 20 }}
              >
                <p style={textStyles.formTitle}>Оплата счета on-line</p>
              </div>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
                <Button text="Отправить смс со ссылкой на оплату" fill onClick={sendPaymentSms} />
                <div
                  style={{
                    border: '1px solid #6A6A6A',
                    textTransform: 'uppercase',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...textStyles.fieldLabel,
                  }}
                >
                  {deal?.statusPayment?.title}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DealCreatePayment;
