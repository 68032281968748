import { combineReducers } from 'redux';
const SET_USER = 'SET_USER';
const SET_PERMISSIONS = 'SET_PERMISSIONS';

export function setUser(user) {
  return {
    type: SET_USER,
    user,
  };
}

export function setPermissions(payload) {
  return {
    type: SET_PERMISSIONS,
    payload,
  };
}

const defaultUser = {
  permissions: [],
};

function user(state = defaultUser, action) {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.user };
    case SET_PERMISSIONS:
      return { ...state, permissions: action.payload };
    default:
      return state;
  }
}

const auth = combineReducers({
  user,
});

export default auth;
