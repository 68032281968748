import React from 'react';
import { useError } from './ErrorContext';
import Modal from '../components/Modal/Modal';
import alert from './alert';
import Button from '../components/Button/Button';
export const generateCurlCommand = (error) => {
  if (!error?.config) return 'Unable to generate cURL command.';

  const { method, url, headers, data } = error.config;
  const formattedHeaders = Object.entries(headers || {})
    .map(([key, value]) => `-H "${key}: ${value}"`)
    .join(' ');

  const dataPart = data ? `-d '${JSON.stringify(data)}'` : '';

  return `curl -X ${method?.toUpperCase()} "${url}" ${formattedHeaders} ${dataPart}`;
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(
    () => alert.success('Ошибка скопирована в буфер обмена'),
    (err) => alert.error('Ошибка при копировании ошибки: ', err)
  );
};

const GlobalErrorModal = () => {
  const { error, hideError } = useError();

  if (!error) return null;

  const handleCopyCurl = () => {
    const curlCommand = generateCurlCommand(error);
    copyToClipboard(curlCommand);
  };

  const handleCopyError = () => {
    const errorInfo = JSON.stringify(error, null, 2);
    copyToClipboard(errorInfo);
  };

  return (
    <Modal
      isOpen={!!error}
      title="Произошла ошибка"
      onRequestClose={hideError}
      contentStyle={{
        maxHeight: 400,
        maxWidth: 600,
      }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}>
        <div style={{
          maxHeight: 200,
          overflow: 'auto',
          padding: 20,
          marginBottom: 20,
        }}>
          <p>{error.message}</p>
          <p>{error.detaiols}</p>
        </div>
        <div style={{
          gap: 20,
          display: 'flex',
          marginTop: 'auto'
        }}>
          <Button fill onClick={handleCopyCurl}>Скопировать cURL</Button>
          <Button fill onClick={handleCopyError}>Скопировать текст ошибки</Button>
        </div>
      </div>
    </Modal>
  );
};

export default GlobalErrorModal;
