import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../plugins/axios';
// components
import Header from '../../components/Header/Header';
import IconButton from '../../components/IconButton/IconButton';

// icons
import icon from '../../assets/sidebar/records.svg';
import list from '../../assets/buttonIcons/list.svg';
import RecordsCalendar from './RecordsCalendar/RecordsCalendar';
import CustomCalendar from './CustomCalendar/CustomCalendar';
import RecordsModal from '../../components/Modals/RecordsModal';

const ServiceRecords = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [records, setRecords] = useState([]);
  const [selectedDay, setSelectedDay] = useState({});

  return (
    <>
      <Header title="Записи на сервис - Календарь" icon={icon} />
      <div style={{ display: 'flex', columnGap: '20px', marginTop: '160px' }}>
        <RecordsCalendar
          typeId={3}
          route={'add-record-testdrive'}
          setShowModal={setShowModal}
          setSelectedDayRecords={setRecords}
          setSelectedDay={setSelectedDay}
        />
        <div>
          <IconButton width={248} text={'Вернуться к списку'} icon={list} onClick={() => navigate('/records')} />
        </div>
      </div>
      <RecordsModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title={`Записи на сервис на ${new Date(selectedDay).toLocaleDateString()}`}
        records={records}
        addRecord={() => navigate(`/records/add-record-service`)}
      />
    </>
  );
};

export default ServiceRecords;
