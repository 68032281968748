import React, { useEffect, useState } from 'react';
import styles from './deal.module.css';
import { textStyles } from '../../../constants/textStyles';
import HookForm from '../../../components/HookForm/HookForm';
import SelectClients from '../../../components/Selects/SelectClients';
import SelectDealStatuses from '../../../components/Selects/SelectDealStatuses';
import SelectPaymentTypes from '../../../components/Selects/SelectPaymentTypes';
import Modal from '../../../components/Modal/Modal';
import Cars from '../../Car/Cars';
import DealCreateCarsModal from './DealCreateCarsModal';
import SelectCreditPeriod from '../../../components/Selects/SelectCreditPeriod';
import DeleteButton from '../../../components/DeleteButton/DeleteButton';
import formatNumber from '../../../utils/formatNumber';
import ButtonEdit from '../../../components/Button/ButtonEdit';
import axios from 'axios';
import createDeal from '../../../api/deals/createDeal';
import { useLocation, useNavigate } from 'react-router-dom';
import editDeal from '../../../api/deals/editDeal';
import ButtonDelete from '../../../components/Button/ButtonDelete';
import removeItemsToDeal from '../../../api/deals/removeItemsToDeal';
import setStep from '../../../api/deals/setStep';
import addItemsToDeal from '../../../api/deals/addItemsToDeal';
import createNumberMask from '../../../utils/createNumberMask';
import SelectManager from '../../../components/Selects/SelectManager';
import LoaderInline from '../../../components/Loader/LoaderInline';
import { getErrorString } from '@microsoft/signalr/dist/esm/Utils';
import extractErrorMessage from '../../../utils/extractErrorMessage';
import alert from '../../../helpers/alert';

const DealCreationMainInfo = ({ nextStep, deal, cars, update }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const inputs = [
    {
      label: 'Клиент',
      name: 'clientId',
      component: SelectClients,
      rules: {
        required: !deal,
      },
      defaultValue: deal
        ? { value: deal?.client?.id, label: `${deal?.client?.name} ${deal?.client?.surname}` }
        : location?.state?.client
        ? {
            value: location?.state?.client?.id,
            label: `${location?.state?.client?.name} ${location?.state?.client?.surname}`,
          }
        : null,
    },
    {
      label: 'Способ оплаты',
      name: 'paymentTypeId',
      component: SelectPaymentTypes,
      rules: {
        required: !deal,
      },
      defaultValue: deal ? { value: deal?.paymentType?.id, label: deal?.paymentType?.title } : null,
    },

    {
      label: 'Ответственный менеджер',
      name: 'managerId',
      component: SelectManager,
      rules: {
        required: true,
      },
    },

    // {
    //   label: 'Первоначальный взнос',
    //   name: 'initialFee',
    //   dependency: ['paymentTypeId', 'car'],
    //   dependencyAccessor: (obj) => obj[0]?.value,
    //   placeholder: 'Введите первоначальный взнос',
    //   isCredit: true,
    //   hide: () => !car?.length,
    //   mask: createNumberMask({
    //     allowDecimal: true,
    //     prefix: '',
    //     thousandsSeparatorSymbol: ' ',
    //     decimalLimit: 0,
    //   }),
    //   rules: {
    //     valueAsNumber: true,
    //     validate: (value, getValues) => {
    //       value = value?.replace(/\s/g, '');
    //       value = parseInt(value);
    //       if (!car?.length) return true;
    //       const paymentTypeId = getValues('paymentTypeId');
    //       if (paymentTypeId?.value !== 6 && paymentTypeId?.value !== 7) {
    //         return true;
    //       }

    //       let error = true;
    //       const carPrice = car?.reduce((acc, item) => acc + item?.price, 0);
    //       if (value < (carPrice * 0.1)?.toFixed(0)) {
    //         error = `Минимальная сумма взноса 10% от стоимости автомобиля`;
    //       } else if (value > carPrice) {
    //         error = 'Сумма взноса не может превышать стоимость автомобиля';
    //       } else if (isNaN(value)) {
    //         error = 'Поле обязательно для заполнения';
    //       } else {
    //         error = true;
    //       }

    //       return error;
    //     },
    //   },
    // },
    // {
    //   label: 'Срок кредитования, месяцев',
    //   name: 'period',
    //   component: SelectCreditPeriod,
    //   dependency: 'paymentTypeId',
    //   dependencyAccessor: (obj) => obj?.value,
    //   hide: () => !car?.length,
    //   rules: {
    //     deps: ['paymentTypeId', 'car'],
    //     validate: (value, getValues) => {
    //       if (!car?.length) return true;
    //       const paymentTypeId = getValues('paymentTypeId');
    //       let error = true;

    //       if (!value && (paymentTypeId?.value === 6 || paymentTypeId?.value === 7)) {
    //         error = 'Поле обязательно для заполнения';
    //       } else {
    //         error = true;
    //       }

    //       return error;
    //     },
    //   },
    // },
  ];

  const [carsModal, setCarsModal] = useState(false);
  const openCarsModal = () => {
    setCarsModal(true);
  };

  const [car, setCar] = useState(cars);
  const onCarsFormSubmit = (cars) => {
    const currentYear = new Date().getFullYear();
    const problematicCars = cars.filter(car => car.issueYear > currentYear || isNaN(car.issueYear) || car.issueYear < 1900);
    if (problematicCars.length > 0) {
      return alert.error(`Проверьте год выпуска авто ${problematicCars.map(car => `${car.mark} ${car.model} (${car.vin})`).join(', ')}.`);
    }

    cars = cars?.map((car) => ({ ...car, n: true }));
    setCarsModal(false);
    setCar(cars);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    console.log(values);
    const payload = {
      deal: {
        ...values,
        clientId: values?.clientId?.value,
        statusId: values?.statusId?.value,
        paymentTypeId: values?.paymentTypeId?.value,
        managerId: values?.managerId?.value,
      },
      options: {
        cars: car?.map((car) => ({ value: car.id })),
        clientId: values?.clientId?.value,
      },
    };

    // if (values?.paymentTypeId?.value === 6 || values?.paymentTypeId?.value === 7) {
    //   values.initialFee = values?.initialFee?.replace(/\s/g, '');
    //   values.initialFee = parseInt(values?.initialFee);
    //   payload.credit = {
    //     period: Number(values?.period?.value),
    //     initialFee: values?.initialFee,
    //   };
    // }
    if (deal?.id) {
      editDeal({
        clientId: values?.clientId?.value || deal?.client?.id,
        statusId: values?.statusId?.value,
        paymentTypeId: values?.paymentTypeId?.value,
        id: deal?.id,
        dealId: deal?.id,
      })
        .then(() => {
          if (car?.filter((item) => item?.n)?.length) {
            addItemsToDeal({
              cars: car?.map((car) => ({ value: String(car.id) })),
              dealId: String(deal?.id),
              step: 2,
              clientId: values?.clientId?.value || deal?.client?.id,
            })
              .then(() => {
                nextStep();
              })
              .catch((err) => alert(err));
          } else {
            setStep({ dealId: String(deal?.id), step: 2 }).then(() => {
              update();
            });
          }
        })
        .finally(() => setLoading(false));
    } else {
      createDeal(payload)
        .then((res) => {
          nextStep(res?.data);
          navigate(`/deals/deal/${res?.data}/main-info`);
        })
        .catch((err) => {
          alert.error(extractErrorMessage(err?.data) || 'Произошла ошибка при создании сделки');
        })
        .finally(() => setLoading(false));
    }
  };

  const handleCarDelete = (item) => {
    if (item?.n) {
      const newCars = car.filter((car) => car.id !== item.id);
      console.log(newCars);
      setCar(newCars);
    } else {
      removeItemsToDeal({
        cars: [{ value: String(item?.orderId) }],
        dealId: String(deal?.id),
      }).then(() => {
        update();
      });
    }
  };

  useEffect(() => {
    setCar(cars);
  }, [cars]);

  useEffect(() => {
    if (location?.state?.car) {
      onCarsFormSubmit([location?.state?.car]);
    }
  }, [location]);

  const [touched, setTouched] = useState(false);

  return (
    <>
      {/* <p style={{ ...textStyles.formTitle, marginBottom: 20 }}>Основная информация о сделке</p> */}
      <HookForm
        inputs={inputs}
        title={'Основная информация о сделке'}
        grid={{ display: 'grid', gap: 20 }}
        onSubmit={(values) => {
          if (car?.length) {
            setTouched(false);
            handleSubmit(values);
          } else {
            setTouched(true);
          }
        }}
        action={'Сохранить и продолжить'}
        customData={() =>
          car?.length ? (
            <div style={{ display: 'grid', gap: 10 }}>
              <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '0 20px', alignItems: 'center' }}>
                <p>Автомобиль:</p>
                {car.map((car) => {
                  return (
                    <div
                      key={car.id}
                      style={{
                        gridColumn: '2/2',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      ID{car?.id} {car?.mark} {car?.model} {car?.complectation?.title} {car?.issueYear}{' '}
                      {car?.price ? '(' + formatNumber(car?.price) + ' ₸)' : ''}{' '}
                      <ButtonDelete onClick={() => handleCarDelete(car)} />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null
        }
        addCustomItemTitle="Добавить автомобили"
        handleAddCustomItem={openCarsModal}
        error={car?.length > 0 || !touched ? false : 'Добавьте автомобиль'}
        loading={loading}
      />
      <DealCreateCarsModal
        isOpen={carsModal}
        onRequestClose={() => setCarsModal(false)}
        onSubmit={onCarsFormSubmit}
        onCancel={() => setCarsModal(false)}
      />
    </>
  );
};

export default DealCreationMainInfo;
