import DealCredits from '../DealCredits';

const DealCreateBanksPay = ({deal, onSubmit, documents, backHandler}) => {
  return (
    <>
      <DealCredits dealId={deal?.id} deal={deal} onSubmit={onSubmit} documents={documents} selected backHandler={backHandler}/>
    </>
  );
};

export default DealCreateBanksPay;
