import React, { useCallback, useMemo, useState } from 'react';
import { EQUIPMENTS } from '../../constants/columns';
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { equipmentOptions } from '../../constants/filterOptions';
import { useNavigate } from 'react-router-dom';
import axios from '../../plugins/axios';
import checkCrmSidebarNotifications from '../../api/notifications/checkCrmSidebarNotifications';
import { setFilters } from '../../utils/setFilters';

// components
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import TableTabs from '../../components/TableTabs/TableTabs';
import Empty from '../../components/Empty/Empty';
import HookForm from '../../components/HookForm/HookForm';

// icons
import icon from '../../assets/sidebar/equipment.svg';
import { iconBucket } from '../../utils/icons';
import removeEquipments from '../../api/equipments/removeEquipments';

const columns = EQUIPMENTS;
const tableStyle = { backgroundColor: 'white' };

const Equipments = ({
  header = true,
  withCheckboxes,
  submitButton,
  cancelButton,
  onSubmit,
  onCancel,
  withCount = false,
  containerId,
  carId,
  cityId,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [equipments, setEquipments] = React.useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    pageSize: 10,
  });
  const [isFetchAll, setFetchAll] = useState(false);

  const table = useTable(
    {
      columns,
      data: equipments,
      initialState: {
        pageSize: pagination.pageSize,
      },
      disableSortRemove: true,
      manualPagination: true,
      pageCount: pagination.totalPages,
      page: pagination.page - 1,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { setGlobalFilter, page, setPageSize } = table;

  const [value, setValue] = useState('');
  const [queryParams, setQueryParams] = useState({});

  const getEquipments = useCallback(
    async (params) => {
      setLoading(true);
      let ids = [];
      await axios
        .get('/api/crm/equipments/get', { params: { ...params, containerId, cityId  } })
        .then((res) => {
          // res.data.pagination.totalPage;
          res.data.equipments.forEach((item) => {
            item.notifications.forEach((i) => {
              ids.push(i.id);
            });
          });
          if (withCount) {
            setEquipments(() => res.data.equipments.filter((item) => item.count > 0));
          } else {
            setEquipments(res.data.equipments);
          }
          setPagination({
            ...res.data.pagination,
            page: res.data.pagination?.pageNumber,
            pageSize: pagination.pageSize,
          });
          setDataCount(res.data.pagination?.count);
        })
        .catch((error) => {})
        .finally(() => setLoading(false));
      if (ids.length > 0) {
        checkCrmSidebarNotifications({ notificationIds: ids })
          .then((res) => {})
          .catch((error) => {});
      }
    },
    [pagination.pageSize, withCount, containerId]
  );

  React.useEffect(() => {
    if (!isFetchAll) {
      getEquipments({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams, count: true });
    }
  }, [pagination.page, pagination.pageSize, queryParams]);

  const handleNavigate = () => {
    navigate(`/cars/car/${carId}/equipments`);
  };
  

  const navigateToEdit = (index) => {
    const record = equipments.filter((item) => item.id === index);
    navigate(`/equipments/${record[0].id}`);
  };

  const popover = useMemo(
    () => [
      {
        title: 'Удалить',
        icon: iconBucket(),
        onClick: (row) => {
          setLoading(true);
          removeEquipments({ id: row?.id })
            .then(() => {
              getEquipments({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams, count: true });
            })
            .finally(() => setLoading(false));
        },
      },
    ],
    [getEquipments, pagination.page, pagination.pageSize, queryParams]
  );

  if (withCount) {
    return (
      <>
        <TableTabs
          setFilter={setGlobalFilter}
          options={equipmentOptions}
          value={value}
          setValue={setValue}
          filterComponent={
            <HookForm
              inputs={equipmentOptions}
              grid={{
                display: 'grid',
                gap: '10px',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
              }}
              style={{ backgroundColor: 'transparent' }}
            />
          }
          handleSearchParams={(resetFilters) =>
            setFilters(resetFilters, setFetchAll, setPageSize, setPagination, setQueryParams, equipmentOptions)
          }
          navigate={handleNavigate}
          button={'Добавить доп.оборудование'}
          inputPlaceholder={'Быстрый поиск по доп.оборудованию'}
        />
        <br />
        <Table
          table={table}
          all={'позиций'}
          style={tableStyle}
          pagination
          sort
          setPagination={setPagination}
          setFetchAll={setFetchAll}
          isFetchAll={isFetchAll}
          setData={setEquipments}
          p={pagination}
          loading={loading}
          setLoading={setLoading}
          queryParams={queryParams}
          api={'/api/crm/equipments/get'}
          response={'equipments'}
          isSortable
          withCount={withCount}
          submitButton={submitButton}
          cancelButton={cancelButton}
          onSubmit={onSubmit}
          onCancel={onCancel}
          dataCount={dataCount}
          withCheckboxes={withCheckboxes}
          handleNavigate={navigateToEdit}
        />
      </>
    );
  }

  return (
    <>
      {header && (
        <>
          <Header title="Аксессуары" icon={icon} />
          <br />
          <br />
          <br />
          <br />
        </>
      )}
      <TableTabs
        setFilter={setGlobalFilter}
        options={equipmentOptions}
        value={value}
        setValue={setValue}
        filterComponent={
          <HookForm
            inputs={equipmentOptions}
            grid={{
              display: 'grid',
              gap: '10px',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
            }}
            style={{ backgroundColor: 'transparent' }}
          />
        }
        handleSearchParams={(resetFilters) =>
          setFilters(resetFilters, setFetchAll, setPageSize, setPagination, setQueryParams, equipmentOptions)
        }
        navigate={handleNavigate}
        button={'Добавить доп.оборудование'}
        inputPlaceholder={'Быстрый поиск по доп.оборудованию'}
      />
      <br />
      {!page.length && !loading ? (
        <Empty />
      ) : (
        <Table
          table={table}
          all={'позиций'}
          style={tableStyle}
          pagination
          sort
          setPagination={setPagination}
          setFetchAll={setFetchAll}
          isFetchAll={isFetchAll}
          setData={setEquipments}
          p={pagination}
          loading={loading}
          setLoading={setLoading}
          queryParams={queryParams}
          api={'/api/crm/equipments/get'}
          response={'equipments'}
          isSortable
          withCount={withCount}
          submitButton={submitButton}
          cancelButton={cancelButton}
          onSubmit={onSubmit}
          onCancel={onCancel}
          dataCount={dataCount}
          withCheckboxes={withCheckboxes}
          popover={popover}
          handleNavigate={navigateToEdit}
        />
      )}
    </>
  );
};

export default Equipments;
