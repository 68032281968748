import React, { useEffect, useState } from 'react';
import axios from '../../plugins/axios';
// components
import Header from '../../components/Header/Header';
import Form from '../../components/Form/Form';
import Button from '../../components/Button/Button';
import Breadcrumbs from '../../components/Breadcrumbs';
import Alert from '../../components/Alert/Alert';
import Loader from '../../components/Loader/Loader';

// icons
import icon from '../../assets/sidebar/services.svg';
import { useNavigate, useParams } from 'react-router-dom';
import HookForm from '../../components/HookForm/HookForm';
import InputNumeric from '../../components/Inputs/Input/InputNumeric';
import getAdditionalServices from '../../api/services/getAdditionalServices';
import Input from '../../components/Inputs/Input/Input';
import editAdditionalServices from '../../api/services/editAdditionalServices';
import SelectCity from '../../components/Selects/SelectCity';

const formStyles = {
  service: { display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr' },
};

const EditService = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = useState({});
  const inputs = [
    {
      component: Input,
      label: 'Название',
      name: 'title',
      placeholder: 'Укажите название услуги',
      defaultValue: data?.title,
      rules: {
        required: true,
      },
    },
    {
      component: InputNumeric,
      label: 'Цена, KZT',
      name: 'price',
      placeholder: 'Укажите цену',
      defaultValue: data?.price,
      rules: {
        required: true,
      },
    },
    {
      component: SelectCity,
      label: 'Город',
      name: 'cityId',
      placeholder: 'Укажите цену',
      defaultValue: { value: data?.city?.id, label: data?.city?.title },
      rules: {
        required: true,
      },
    },
  ];

  const editService = (values) => {
    setLoading(true);
    editAdditionalServices({ id, ...values })
      .then(() => {
        navigate('/services');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAdditionalServices({ id })
      .then((res) => {
        const service = res?.data?.additionalServices?.[0];
        console.log(service);
        setData(service);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (loading) return <Loader />;

  return (
    <>
      <Header title="Новая услуга" icon={icon} />
      <Breadcrumbs navigate={'/services'} link={'Дополнительные услуги'} currentPage={'Новая услуга'} />
      <HookForm
        inputs={inputs}
        title={'Информация об услуге'}
        grid={formStyles.service}
        border
        onSubmit={editService}
        action={'Сохранить'}
      />
    </>
  );
};

export default EditService;
