import React from 'react';
import styles from './alert.module.css';

// icons
import x from '../../assets/x.svg';
import warning from '../../assets/warning.svg';

const Alert = ({ message, onClick }) => {
  return (
    <div className={styles.wrapper} style={{ top: `0px` }}>
      <div className={styles.icons}>
        <div />
        <img src={warning} alt="Icon" />
        <img
          src={x}
          alt="Icon"
          onClick={() => {
            if (onClick) onClick({});
          }}
        />
      </div>
      <div className={styles.message}>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Alert;
