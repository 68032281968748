import { useCallback, useState } from 'react';
import Select from '../Select/Select';
import getEquipmentsType from '../../api/resources/getEquipmentsType';

const SelectEquipmentType = ({ handleSetSelect, handleSetState, style, ...props }) => {
  const [data, setData] = useState([]);
  const onFocus = useCallback(() => {
    getEquipmentsType().then((res) => {
      const data = res.data.map((item) => {
        return { value: item.id, label: item.title };
      });

      setData(data);
    });
  }, []);

  return (
    <Select
      handleSetSelect={(val) => handleSetSelect && handleSetSelect(val)}
      handleSetState={handleSetState}
      options={data}
      title={'Единица измерения'}
      placeholder={'Выберите из списка'}
      onFocus={onFocus}
      style={style}
      {...props}
    />
  );
};

export default SelectEquipmentType;
