import checkBankStatus from '../../../../api/bank/checkStatusAppeal';
import Button from '../../../../components/Button/Button';
import FormHeading from '../../../../components/Typography/FormHeading';

const CheckBankStatus = ({ chunkId, bankId, setLoading = () => {}, onSuccess }) => {
  const onCheckBankStatus = async () => {
    try {
      setLoading(true);
      await checkBankStatus({ chunkId, bankId });
      onSuccess && onSuccess();
    } finally {
      setLoading(false);
    }
  };

  return (<Button onClick={onCheckBankStatus} text="Обновить" fill/>);
};

export default CheckBankStatus;
