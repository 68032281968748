import { useState } from 'react';
import editDeal from '../../../../api/deals/editDeal';
import LoaderInline from '../../../../components/Loader/LoaderInline';
import {
  DEAL_STATUS_CAR_DELIVERY,
  DEAL_STATUS_COMPLETED,
  DEAL_STATUS_DOCUMENTS_PROCESSING,
  DEAL_STATUS_NOTARIAL_DOCUMENTS_UPLOADED,
  DEAL_STATUS_WAIRING_REGISTRATION,
  DEAL_STATUS_WAITING_FOR_HALYK,
  DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
  DEAL_STATUS_WAITING_FOR_PAYMENT,
  DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
  DEAL_STEPS,
  DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
  DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
  DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
  DOCUMENT_TYPES_CONTRACT,
  DOCUMENT_TYPES_GUARANTEE_LETTER,
  DOCUMENT_TYPES_INVOICE,
  DOCUMENT_TYPES_PAYMENT_ORDER,
  DOCUMENT_TYPES_POWER_OF_ATTORNEY,
  DOCUMENT_TYPES_SPOUSE_CONSENT,
} from '../../../../constants';
import generateAllowedDocumentsJson from '../../../../utils/generateAllowedDocumentsJson';
import alert from '../../../../helpers/alert';
import setStep from '../../../../api/deals/setStep';
import Button from '../../../../components/Button/Button';
import NotarialDocuments from '../CommonBankSteps/NotarialDocuments';
import extractErrorMessage from '../../../../utils/extractErrorMessage';
import PaymentStatus from '../CommonBankSteps/PaymentStatus';
import CarRegistration from '../CommonBankSteps/CarRegistration';
import CarDelivery from '../CommonBankSteps/CarDelivery';
import InfoStep from '../CommonBankSteps/InfoStep';
import Reregistration from './Reregistration';
import carRegistration from '../../../../api/cars/registration';
import CheckBankStatus from '../CommonBankSteps/CheckBankStatus';
import WaitingForNewBankStatus from '../CommonBankSteps/WaitingForNewBankStatus';
import ContractSign from '../CommonBankSteps/ContractSign';

const ForteFlow = ({ step, deal, documents, update, cars, chosenBankAppealId, bankId }) => {
  const [loading, setLoading] = useState(false);

  const onNotarialDocumentsSuccess = (values) => {
    editDeal({
      id: deal?.id,
      dealId: deal?.id,
      statusId: DEAL_STATUS_WAIRING_REGISTRATION,
      step: DEAL_STEPS.FORTE.AUTO_REGISTRATION,
      power_of_attorney_number: values?.powerOfAttorneyNumber
        ? values?.powerOfAttorneyNumber
        : deal?.power_of_attorney_number,
      ...generateAllowedDocumentsJson([
        DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
        DOCUMENT_TYPES_INVOICE,
        DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
        DOCUMENT_TYPES_POWER_OF_ATTORNEY,
        DOCUMENT_TYPES_SPOUSE_CONSENT,
        DOCUMENT_TYPES_GUARANTEE_LETTER,
        DOCUMENT_TYPES_PAYMENT_ORDER,
      ]),
    })
      .then(() => {
        update();
      })
      .catch((err) => {
        alert.error(extractErrorMessage(err?.response?.data) || 'Ошибка при смене статуса сделки');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPaymentSubmit = (values) => {
    setLoading(true);
    editDeal({
      id: deal?.id,
      statusPaymentId: values?.status?.value,
      statusId: DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
      ...generateAllowedDocumentsJson([
        DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
        DOCUMENT_TYPES_INVOICE,
        DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
        DOCUMENT_TYPES_POWER_OF_ATTORNEY,
        DOCUMENT_TYPES_SPOUSE_CONSENT,
        DOCUMENT_TYPES_GUARANTEE_LETTER,
        DOCUMENT_TYPES_PAYMENT_ORDER,
      ]),
    })
      .then(async () => {
        await setStep({ dealId: deal?.id, step: DEAL_STEPS.FORTE.NOTARIAL_VERIFICATION });

        alert.success('Статус оплаты успешно обновлен');
        update && update();
      })
      .catch((err) => {
        alert.error(extractErrorMessage(err?.response?.data) || 'Ошибка при смене статуса сделки');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRegistrationSubmit = async (values) => {
    try {
      setLoading(true);
    await carRegistration({
      bankId,
      chunkId: deal?.chunkId,
      carId: cars?.[0]?.id,
      reg_num: '',
    });
    await setStep({ dealId: deal?.id, step: DEAL_STEPS.FORTE.WAITING_FOR_CLIENT_ACTIONS_ON_FORTE_MOBILE_APP });
    update && update();
    } catch(e) {
      alert.error(e);
      setLoading(false);
    }
  };
  const onReregistrationSubmit = async () => {
    setLoading(true);
    try {
      await carRegistration({
        bankId,
        chunkId: deal?.chunkId,
        carId: cars?.[0]?.id,
        reg_num: '',
      });
      await setStep({ dealId: deal?.id, step: DEAL_STEPS.FORTE.WAITING_FOR_CLIENT_ACTIONS_ON_FORTE_MOBILE_APP });
      update && update();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const onCarDeliverySubmit = async (values) => {};

  const goBack = (statusId, step) => {
    setLoading(true);
    editDeal({
      id: deal?.id,
      statusId,
      step,
    })
      .then(() => {
        update && update();
      })
      .finally(() => setLoading(false));
  };

  const onContractSmsSent = () => {
    alert.success("СМС со ссылкой на подписание договора успешно отправлено");
  };

  if (loading) {
    return <LoaderInline />;
  }

  if(step === DEAL_STEPS.FORTE.SIGN_CONTRACT) {
    return <ContractSign deal={deal} documents={documents} cars={cars} dealStepAfterSign={DEAL_STEPS.FORTE.WAITING_FOR_PAYMENT} setLoading={setLoading} onSuccess={onContractSmsSent} update={update}/>;
  }

  if (step === DEAL_STEPS.FORTE.WAITING_NEW_STATUS) {
    return <WaitingForNewBankStatus bankId={bankId} chunkId={deal?.chunkId} setLoading={setLoading} onSuccess={update} />;
  }

  if (step === DEAL_STEPS.FORTE.NOTARIAL_VERIFICATION) {
    return (
      <>
        <NotarialDocuments
          documents={documents}
          loading={loading}
          onSuccess={onNotarialDocumentsSuccess}
          deal={deal}
          setLoading={setLoading}
          chosenBankAppealId={chosenBankAppealId}
          bankId={bankId}
        />
        <div style={{marginTop: 20}}>
          <CheckBankStatus bankId={bankId} chunkId={deal?.chunkId} onSuccess={update} setLoading={setLoading} />
        </div>
      </>
    );
  }
  if (step === DEAL_STEPS.FORTE.WAITING_FOR_PAYMENT) {
    return (
      <>
        <PaymentStatus
          onSubmit={onPaymentSubmit}
          documents={documents}
          deal={deal}
          chunkId={deal?.chunkId}
          setLoading={setLoading}
          loading={loading}
          bankId={bankId}
        />
        <div style={{marginTop: 20}}>
            <CheckBankStatus bankId={bankId} chunkId={deal?.chunkId} onSuccess={update} setLoading={setLoading} />
        </div>
      </>
    );
  }
  if (step === DEAL_STEPS.FORTE.AUTO_REGISTRATION) {
    return (
      <>
        <CarRegistration
          onSuccess={onRegistrationSubmit}
          deal={deal}
          setLoading={setLoading}
          loading={loading}
          statusOption={[
            {
              value: DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
              label: 'Ожидает постановки ТС на учет',
            },
            {
              value: DEAL_STATUS_CAR_DELIVERY,
              label: 'Машина поставлена на учет',
            },
          ]}
        />
        <div style={{marginTop: 20}}>
            <CheckBankStatus bankId={bankId} chunkId={deal?.chunkId} onSuccess={update} setLoading={setLoading} />
        </div>
      </>
    );
  }
  if (step === DEAL_STEPS.FORTE.AUTO_REREGISTRATION) {
    return (
      <>
        <Reregistration onSubmit={onReregistrationSubmit} loading={loading} />
        <div style={{marginTop: 20}}>
          <CheckBankStatus bankId={bankId} chunkId={deal?.chunkId} onSuccess={update} setLoading={setLoading} />
        </div>
      </>
    );
  }
  if (step === DEAL_STEPS.FORTE.WAITING_FOR_FINAL_ACTIONS) {
    return (
      <div>
        <p
          style={{
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '20px',
            color: 'rgb(73, 65, 54)',
            marginBottom: '20px',
          }}
        >
          Статус
        </p>
        <Button
          text={'Ожидаем завершения действий клиента на стороне Народного банка'}
          style={{ color: 'rgb(73, 65, 54)', border: '1px solid rgb(73, 65, 54)' }}
        />
        <div style={{marginTop: 20}}>
          <CheckBankStatus bankId={bankId} chunkId={deal?.chunkId} onSuccess={update} setLoading={setLoading} />
        </div>
      </div>
    );
  }
  // if (step === DEAL_STEPS.FORTE.WAITING_FOR_CLIENT_ACTIONS_ON_FORTE_MOBILE_APP) {
  //   return (
  //     <InfoStep
  //       dealId={deal?.id}
  //       update={update}
  //       prevStatusId={deal?.status?.id}
  //       prevStep={DEAL_STEPS.FORTE.AUTO_REGISTRATION}
  //       statusText={
  //         "Для продолжения оформления кредита необходимо зарегистрироваться мобильном приложении ForteBank'a."
  //       }
  //       footer={() => (
  //         <div style={{marginTop: 20}}>
  //           <CheckBankStatus bankId={bankId} chunkId={deal?.chunkId} onSuccess={update} setLoading={setLoading} />
  //         </div>
  //       )}
  //     />
  //   );
  // }
  if (step === DEAL_STEPS.FORTE.WAITING_FOR_CLIENT_ACTIONS_ON_FORTE_MOBILE_APP) {
    return (
      <InfoStep
        dealId={deal?.id}
        update={update}
        prevStep={DEAL_STEPS.FORTE.WAITING_FOR_CLIENT_ACTIONS_ON_FORTE_MOBILE_APP}
        statusText={'Ожидаем завершения действий клиента на стороне форте банка'}
        footer={() => (
          <div style={{marginTop: 20}}>
            <CheckBankStatus bankId={bankId} chunkId={deal?.chunkId} onSuccess={update} setLoading={setLoading} />
          </div>
        )}
      />
    );
  }
};

export default ForteFlow;
