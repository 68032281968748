export const sortCarsParams = new Map([
  ['Марка', 'mark'],
  ['Модель', 'model'],
  ['Комплектация', 'complectation'],
  ['Год выпуска', 'issueYear'],
  ['Дилерский центр', 'city'],
  ['Цвет', 'color'],
  ['РРЦ, KZT', 'price'],
  ['Тип двигателя', 'engine'],
]);

export const sortDealsParams = new Map([
  ['Дата', 'date'],
  ['Клиент', 'clientId'],
  ['Сумма контракта, KZT', 'sum'],
  ['Статус сделки', 'statusId'],
  ['Статус оплаты', 'statusPaymentId'],
  ['Первоначальный взнос', 'initialFee'],
  ['Срок кредитования (мес)', 'period'],
  ['Ежемесячный платеж', 'monthlyPayment'],
  ['Страховка', 'insurance'],
]);

export const sortClientsParams = new Map([
  ['ФИО клиента', 'fio'],
  ['ИИН', 'iin'],
  ['Телефон', 'phone'],
  ['E-mail', 'email'],
  ['Город', 'city'],
  ['Язык', 'language'],
  ['Статус проверки', 'notarial_verification'],
]);

export const sortLeadsParams = new Map([
  ['Дата', 'date'],
  ['Клиент', 'client'],
  ['Менеджер', 'manager'],
  ['Марка и модель', 'markAndModel'],
  ['Источник', 'source'],
  ['Телефон', 'phone'],
  ['E-mail', 'email'],
  ['Статус', 'status'],
]);
