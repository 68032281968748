import { useCallback, useEffect, useState } from 'react';
import LoaderInline from '../../../../components/Loader/LoaderInline';
import getEquipments from '../../../../api/deals/getEquipments';
import Counter from '../../../../components/Inputs/Counter/Counter';
import formatNumber from '../../../../utils/formatNumber';
import InputWithDebounce from '../../../../components/Input/InputWithDebounce';
import Button from '../../../../components/Button/Button';
import alert from '../../../../helpers/alert';

const EquipmentsPickList = ({ car, onSubmit }) => {
  const [loading, setLoading] = useState(true);
  const [equipments, setEquipments] = useState([]);

  const updateEquipmentsList = useCallback(
    (query = {}) => {
      getEquipments({ containerId: car?.container?.id, ...query })
        .then(({ data }) => {
          setEquipments(data?.equipments?.map((item) => ({ ...item, counter: 0 })));
        })
        .finally(() => setLoading(false));
    },
    [car]
  );

  useEffect(() => {
    updateEquipmentsList();
  }, [updateEquipmentsList]);

  const onCountChange = (id, type) => {
    const newEquipments = equipments.map((equipment) => {
      if (equipment.id === id) {
        let counter = equipment.counter;

        if (type === 'increment' && counter < equipment?.count) {
          counter++;
        } else if (type === 'decrement' && counter > 0) {
          counter--;
        }

        return {
          ...equipment,
          counter,
        };
      }
      return equipment;
    });
    setEquipments(newEquipments);
  };

  const handleSubmit = () => {
    const newEquipments = equipments.filter((equipment) => equipment.counter > 0);

    if(!newEquipments.length) return alert.error('Выберите хотя бы одно доп оборудование');

    onSubmit &&
      onSubmit({
        car,
        equipments: newEquipments,
      });
  };

  return (
    <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', height: '100%' }}>
      <div>
        <p style={{ paddingBottom: 10 }}>Выбрать доп оборудование</p>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: 10, marginBottom: 15 }}>
          <InputWithDebounce
            style={{ fontSize: 12 }}
            placeholder="Поиск по доп оборудованию"
            onChange={(value) => {
              setLoading(true);
              updateEquipmentsList({ title: value });
            }}
            debounceTimer={500}
          />
          <Button style={{ fontSize: 14 }} text="Найти" fill />
        </div>
      </div>
      {loading ? (
        <LoaderInline />
      ) : (
        <>
          <div style={{ gap: 20, marginBottom: 20 }}>
            {equipments.map((equipment) => (
              <div
                key={equipment.id}
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 0.5fr 0.5fr',
                  gap: 5,
                  alignItems: 'center',
                  padding: '0 10px',
                  fontSize: 10,
                  opacity: equipment?.count === 0 ? 0.5 : 1,
                  pointerEvents: equipment?.count === 0 ? 'none' : 'auto',
                }}
              >
                <span>{equipment.title}</span>
                <span>{formatNumber(equipment.price)} тнг</span>
                <div>
                  <Counter
                    height={23}
                    value={equipment?.counter}
                    onDecrement={() => onCountChange(equipment?.id, 'decrement')}
                    onIncrement={() => onCountChange(equipment?.id, 'increment')}
                  />
                </div>
              </div>
            ))}
          </div>
          <Button text="Добавить к покупке" fill full onClick={handleSubmit} />
        </>
      )}
    </div>
  );
};

export default EquipmentsPickList;
