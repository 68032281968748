/**
 * @param {string[]} documentTypes
 * @returns {object} - JSON object with allowed documents
 * @description - Generates JSON object with allowed documents
 **/
const generateAllowedDocumentsJson = (documentTypes = []) => {
  return {
    json: JSON.stringify({
      allowed_documents: documentTypes,
    }),
  };
};

export default generateAllowedDocumentsJson;
