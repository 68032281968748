import Button from '../../../../components/Button/Button';
import DownloadFileLink from '../../../../components/DownloadFileLink/DownloadFileLink';
import HookForm from '../../../../components/HookForm/HookForm';
import SelectDealPaymentStatuses from '../../../../components/Selects/selectDealPaymentStatuses';
import { DOCUMENT_TYPES_INVOICE } from '../../../../constants';

const HalykPaymentStatus = ({ documents, onSubmit, loading, sendPaymentSms, backHandler }) => {

  return (
    <div>
      <HookForm
        title={'Статус оплаты'}
        grid={{ display: 'grid', gap: 20 }}
        action={'Сохранить и продолжить'}
        inputs={[
          {
            name: 'status',
            component: SelectDealPaymentStatuses,
          },
        ]}
        customDataTop={() => (
          <div style={{ marginBottom: 40 }}>
            {documents
              ?.filter((item) => item?.typeId === DOCUMENT_TYPES_INVOICE)
              .map((document) => (
                <DownloadFileLink document={document} />
              ))}
          </div>
        )}
        customData={() => (<Button text="Отправить СМС со ссылкой на оплату первоначального взноса" fill onClick={sendPaymentSms} />)}
        onSubmit={onSubmit}
        loading={loading}
        backHandler={backHandler}
      />
    </div>
  );
};

export default HalykPaymentStatus;
