import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import Table from '../Table/Table';
import { RECORDS } from '../../constants/columns';
import { useTable, usePagination } from 'react-table';
import Button from '../Button/Button';

const RecordsModal = ({ isOpen, onClose, title, records, addRecord }) => {
  const table = useTable(
    {
      columns: RECORDS,
      data: records,
    },
    usePagination
  );

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onRequestClose={() => onClose(false)}
      contentStyle={{
        height: "100%",
        maxHeight: 500
      }}
    >
      <div style={{ padding: '0 16px' }}>
        <Table table={table} />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
          <Button text={'Добавить запись'} fill onClick={addRecord} />
        </div>
      </div>
    </Modal>
  );
};

RecordsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RecordsModal;
