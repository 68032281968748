import { useEffect, useState } from 'react';
import getAdditionalServices from '../../api/deals/getAdditionalServices';
import getIntegratioPolesPrice from '../../api/shop/getIntegratioPolesPrice';
import formatNumber from '../../utils/formatNumber';
import Checkbox from '../Inputs/Checkbox/Checkbox';
import Loader from '../Loader/Loader';
import LoaderInline from '../Loader/LoaderInline';
import { INSURANCE_SERVICE_ID_2_5, INSURANCE_SERVICE_ID_3, INSURANCE_SERVICE_ID_3_5 } from '../../constants';


const CheckboxSelectAdditionalServices = ({ onChange = () => null, orderId }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [state, setState] = useState({});

  const getData = async () => {
    if (page != 1 && page > totalCount) return;
    getAdditionalServices({ page }).then(async (res) => {
      const newData = await Promise.all(
        res?.data?.additionalServices.map(async (eq) => {
          if (eq.id == INSURANCE_SERVICE_ID_2_5 || eq.id == INSURANCE_SERVICE_ID_3 || eq.id == INSURANCE_SERVICE_ID_3_5) {
            const res = await getIntegratioPolesPrice({ orderId, serviceId: eq.id });
            console.log(res)

            eq.price = typeof res?.data != 'number' ? 0 : res?.data;
            return eq;
          }
          return eq;
        })
      )

      
      setData((prev) => [...prev, ...newData]);

      setTotalCount(res.data.pagination.totalPages);
      setPage(page + 1);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const onCheck = (id, value, item) => {
    console.log(state);
    setState({
      ...state,
      [id]: value ? item : false,
    });
  };

  useEffect(() => {
    onChange(state);
  }, [state, onChange]);

  return (
    <>
      {!data?.length && <LoaderInline />}
      {data.map((item) => (
        <div style={{ marginBottom: 16, display: 'grid', gridTemplateColumns: 'auto 1fr' }}>
          <Checkbox
            key={item?.id}
            label={item?.title}
            id={item?.id + item?.title}
            value={state[item?.id]}
            onChange={(id, value) => onCheck(id, value, item)}
          />
          <div style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>{formatNumber(item?.price)} ₸</div>
        </div>
      ))}
    </>
  );
};

export default CheckboxSelectAdditionalServices;
