import React, { useState } from 'react';
import { DEALS, NOTARY_DEALS, NOTARY_DEALS_CREDIT } from '../../../constants/columns';
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { dealsOptions } from '../../../constants/filterOptions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setFilters } from '../../../utils/setFilters';

// components
import Header from '../../../components/Header/Header';
import Table from '../../../components/Table/Table';
import TableTabs from '../../../components/TableTabs/TableTabs';
import Empty from '../../../components/Empty/Empty';
import ClientPopup from '../../../components/ClientPopup/ClientPopup';
import HookForm from '../../../components/HookForm/HookForm';

// icons
import icon from '../../../assets/sidebar/deals.svg';
import axios from '../../../plugins/axios';
import { PERMISSION_CAN_EDIT_DEALS } from '../../../constants/roles';
import {
  DEAL_STATUS_NEED_TO_MAKE_POWER_OF_ATTORNEY,
  DEAL_STATUS_NOTARIAL_DOCUMENTS_UPLOADED,
  DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
  PAYMENT_TYPES_CREDIT_CARD,
  PAYMENT_TYPES_CREDIT_INVOICE,
} from '../../../constants';

export const FETCH_ALL_PAGESIZE = 20;
// const columns = DEALS;

const NotaryDealsCredit = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const tableStyle = { backgroundColor: 'white' };
  const [deals, setDeals] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    pageSize: 10,
  });
  const [isFetchAll, setFetchAll] = useState(false);
  const [columns, setColumns] = useState(NOTARY_DEALS_CREDIT);
  const [params] = useSearchParams();

  const table = useTable(
    {
      columns,
      data: deals,
      initialState: {
        pageSize: pagination.pageSize,
      },
      manualPagination: true,
      pageCount: pagination.totalPages,
      page: pagination.page - 1,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { setGlobalFilter, page, setPageSize } = table;

  const [value, setValue] = useState('');
  const [queryParams, setQueryParams] = useState({});

  const addDeal = () => {
    navigate('/deals/create');
  };

  const handleNavigate = (index) => {
    navigate(`/notary/deals/${deals.find((item) => item.id === index).id}/main-info`, {
      state: deals.find((item) => item.id === index),
    });
  };

  const getDeals = (params) => {
    setLoading(true);
    axios
      .get('/api/crm/deals/getDeals', {
        params: {
          paymentTypeIds: JSON.stringify([PAYMENT_TYPES_CREDIT_CARD, PAYMENT_TYPES_CREDIT_INVOICE, 2]),
          statusIds: JSON.stringify([
            DEAL_STATUS_NOTARIAL_DOCUMENTS_UPLOADED,
            DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
            DEAL_STATUS_NEED_TO_MAKE_POWER_OF_ATTORNEY,
          ]),
        },
      })
      .then((res) => {
        setDeals(res.data.deals);
        setPagination({
          ...res.data.pagination,
          page: res.data.pagination?.pageNumber,
          pageSize: pagination.pageSize,
        });
        setDataCount(res.data.pagination?.count);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (!isFetchAll) {
      getDeals({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams, ...Object.fromEntries(params) });
    }
  }, [pagination.page, pagination.pageSize, queryParams, params]);

  const [showClientPopup, setShowClientPopup] = React.useState(false);
  const [clientId, setClientId] = React.useState(null);

  return (
    <>
      <br />
      {!page.length && !loading ? (
        <Empty />
      ) : (
        <Table
          table={table}
          all={'сделок'}
          style={tableStyle}
          handleNavigate={handleNavigate}
          pagination
          sort
          setPagination={setPagination}
          setFetchAll={setFetchAll}
          isFetchAll={isFetchAll}
          setData={setDeals}
          p={pagination}
          setClientId={setClientId}
          setOpenClientPopup={setShowClientPopup}
          loading={loading}
          setLoading={setLoading}
          columns={columns}
          setColumns={setColumns}
          columnsSettingsKey="deals"
          queryParams={queryParams}
          api={'/api/crm/deals/getDeals'}
          response={'deals'}
          isSortable
          dataCount={dataCount}
        />
      )}
    </>
  );
};

export default NotaryDealsCredit;
