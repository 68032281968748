import React from 'react';
import notFound from '../../assets/notFound.svg';

const Empty = ({ title = 'По вашему запросу ничего не найдено. Попробуйте изменить параметры поиска.' }) => {
  return (
    <div style={{ marginTop: '20px' }}>
      <p
        style={{
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '20px',
          marginBottom: '22px',
          textAlign: 'center',
        }}
      >
        {title}
      </p>
      <img src={notFound} alt="Icon" style={{ display: 'block', margin: 'auto' }} />
    </div>
  );
};

export default Empty;
