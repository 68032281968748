import React, { useState } from 'react';
import styles from './clientPopup.module.css';
import getClients from '../../api/clients/getClients';
import getBirthDateFromIIN from '../../utils/getBirthDateFromIIN';

// components
import Modal from '../Modal/Modal';
import ClientCheck from '../ClientCheck/ClientCheck';
import HookForm from '../HookForm/HookForm';
import Datepicker from '../Inputs/Datepicker/Datepicker';
import SelectPhoto from '../../pages/Client/NewClient/ClientCreate/SelectPhoto/SelectPhoto';
import Select from '../Select/Select';
import SelectPhoneType from '../Selects/SelectPhoneType';
import SelectAddressType from '../Selects/SelectAddressType';
import SelectSocialType from '../Selects/SelectSocialType';
import SelectClientType from '../Selects/SelectClientType';
import SelectCity from '../Selects/SelectCity';
import Loader from '../Loader/Loader';

// icons
import phoneIcon from '../../assets/input/phone.svg';

import { phone, iin } from '../../constants/mask';

const formStyles = {
  mainData: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a a ' 'b b b ' 'k k c' 'd d d'` },
  contacts: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
  },
  comment: { display: 'grid', gap: '20px' },
};

const ClientPopup = ({ clientId, isOpen, setOpen }) => {
  const [photo, setPhoto] = useState();
  const [mainData, setMainData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [comment, setComment] = useState([]);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    getClients({ id: clientId })
      .then((res) => {
        const client = res.data.users[0];
        console.log(client);
        const [day, month, year] = getBirthDateFromIIN(client.iin).split('.');
        const mainData = [
          {
            name: 'surname',
            label: 'Фамилия*',
            placeholder: 'Фамилия клиента',
            defaultValue: client.surname,
            disabled: true,
          },
          { name: 'name', label: 'Имя*', placeholder: 'Имя клиента', defaultValue: client.name, disabled: true },
          {
            name: 'middlename',
            label: 'Отчество',
            placeholder: 'Отчество (если есть)',
            defaultValue: client.middlename,
            disabled: true,
          },
          {
            name: 'iin',
            label: 'ИИН*',
            placeholder: '_ _ _ _ _ _ _ _ _ _ _',
            mask: iin,
            defaultValue: client.iin,
            disabled: true,
          },
          {
            component: Datepicker,
            name: 'born',
            label: 'Дата рождения',
            placeholder: 'дд-мм-гггг',
            defaultValue: new Date(year, month, day),
            disabled: true,
          },
          {
            component: Select,
            label: 'Язык',
            placeholder: 'Выберите из списка',
            name: 'languageId',
            options: [
              { value: 1, label: 'Казақша' },
              { value: 2, label: 'Русский' },
            ],
            disabled: true,
            defaultValue: { value: client?.language?.id, label: client?.language?.title },
          },
          {
            name: 'phoneNumber_',
            label: 'Номер телефона',
            placeholder: '+7(___) ___-__-__',
            mask: phone,
            defaultValue: client.phone[1] === '7' ? client.phone : client.phone.substring(1),
            disabled: true,
            icon: phoneIcon,
          },
          {
            component: SelectClientType,
            name: 'vip',
            disabled: true,
            defaultValue: client.vip ? { value: 1, label: 'VIP' } : { value: 2, label: 'Посредник' },
          },
          {
            component: SelectCity,
            name: 'city',
            label: 'Город',
            disabled: true,
            defaultValue: { value: client?.city?.id, label: client?.city?.title },
          },
        ];

        const contacts = [
          {
            name: 'email',
            label: 'E-mail',
            placeholder: 'Укажите адрес электронной почты клиента',
            style: { width: '48.5%' },
            defaultValue: client.email,
            disabled: true,
          },
          {
            name: 'url',
            label: 'URL сайта',
            placeholder: 'Сайт компании клиента',
            style: { width: '49%' },
            disabled: true,
          },
        ];
        client.phones.forEach((phoneItem, index) => {
          contacts.push(
            {
              component: SelectPhoneType,
              name: `phoneType_${index}`,
              defaultValue: [
                { value: 1, label: 'Мобильный' },
                { value: 2, label: 'Домашний' },
                { value: 3, label: 'Корпоративный' },
              ].filter((item) => item.value === phoneItem.typeId)[0],
              style: { width: '30%' },
              disabled: true,
            },
            {
              name: `phoneNumber_${index}`,
              label: 'Номер телефона',
              placeholder: '+7(___) ___-__-__',
              mask: phone,
              style: { width: '67.5%' },
              defaultValue: phoneItem.number.substring(1),
              disabled: true,
              icon: phoneIcon,
            }
          );
        });
        client.addresses.forEach((address, index) => {
          contacts.push(
            {
              component: SelectAddressType,
              name: `addressType_${index}`,
              defaultValue: [
                { value: 1, label: 'Домашний' },
                { value: 2, label: 'Корпоративный' },
              ].filter((item) => item.value === address.typeId)[0],
              style: { width: '30%' },
              disabled: true,
            },
            {
              name: `address_${index}`,
              label: 'Адрес',
              placeholder: 'Укажите адрес',
              style: { width: '67.5%' },
              defaultValue: address.address,
              disabled: true,
            }
          );
        });
        client.socials.forEach((social, index) => {
          contacts.push(
            {
              component: SelectSocialType,
              name: `socialType_${index}`,
              defaultValue: [
                { value: 1, label: 'Facebook' },
                { value: 2, label: 'VK' },
                { value: 3, label: 'Instagram' },
                { value: 4, label: 'Telegram' },
              ].filter((item) => item.value === social.typeId)[0],
              style: { width: '30%' },
              disabled: true,
            },
            {
              name: `social_${index}`,
              label: '@',
              placeholder: 'ссылка',
              style: { width: '67.5%' },
              defaultValue: social.value,
              disabled: true,
            }
          );
        });

        const comments = [];
        client.comments.forEach((comment) => {
          comments.push({
            name: 'comment',
            label: 'Заметки/комментарии о клиенте',
            placeholder: 'Укажите любые заметки или комментарии о клиенте',
            defaultValue: comment.comment,
            disabled: true,
          });
        });

        setMainData(mainData);
        setContacts(contacts);
        setComment(comments);
        setPhoto(client.photo);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Modal isOpen={isOpen} onRequestClose={() => setOpen(false)}>
          <div className={styles.wrapper}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <SelectPhoto photo={photo} style={{ flex: 1 }} disabled />
              <p className={styles.date} style={{ margin: '20px 0' }}>
                Клиент зарегистрирован: <span>01.09.2022</span>
              </p>
              <ClientCheck />
            </div>
            <div className={styles.forms}>
              <HookForm
                title={'Данные клиента'}
                grid={formStyles.mainData}
                border
                inputs={mainData}
                style={{ margin: 0 }}
              />
              <HookForm
                title={'Контактные данные'}
                grid={formStyles.contacts}
                border
                inputs={contacts}
                style={{ margin: 0 }}
              />
              <HookForm
                title={'Дополнительно'}
                border
                grid={formStyles.comment}
                inputs={comment}
                style={{ marginBottom: '20px', marginTop: 0 }}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ClientPopup;
