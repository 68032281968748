import React, { useState, useEffect, useMemo, useCallback } from 'react';
import HookForm from '../../../components/HookForm/HookForm';
import DealCreateEquipmentsModal from './DealCreateEquipmentsModal';
import { textStyles } from '../../../constants/textStyles';
import styles from './deal.module.css';
import formatNumber from '../../../utils/formatNumber';
import DealCreateServicesModal from './DealCreateServicesModal';
import axios from '../../../plugins/axios';

import ButtonDelete from '../../../components/Button/ButtonDelete';
import removeItemsToDeal from '../../../api/deals/removeItemsToDeal';
import {
  CREDIT_TYPE_ID,
  INSURANCE_SERVICE_ID_3_5,
  INSURANCE_SERVICE_ID_2_5,
  INSURANCE_SERVICE_ID_3,
  INSURANCE_ID,
  PAYMENT_TYPES_CREDIT_CARD,
  PAYMENT_TYPES_CREDIT_INVOICE,
} from '../../../constants';
import getIntegratioPolesPrice from '../../../api/shop/getIntegratioPolesPrice';
import LoaderInline from '../../../components/Loader/LoaderInline';
import alert from '../../../helpers/alert';
import getAdditionalServices from '../../../api/deals/getAdditionalServices';

const inputs = [];

const DealCreateServices = ({
  onSubmit,
  paymentType,
  backHandler,
  services,
  orders,
  deal,
  update,
  setUnsavedServices,
  dealCars,
  enableInsurance,
}) => {
  const [carsModal, setCarsModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(false);

  const getServices = useCallback(async () => {
    if(!enableInsurance) return;
    setLoading(true);
    await getAdditionalServices()
      .then(async (res) => {
        const { data } = await getIntegratioPolesPrice({
          orderId: selectedOrder?.value,
          serviceId: INSURANCE_SERVICE_ID_3_5,
        });
        if (
          cars.find(
            (item) =>
              item.additionalServicesId === INSURANCE_SERVICE_ID_3_5 ||
              item.additionalServicesId === INSURANCE_SERVICE_ID_2_5 ||
              item.additionalServicesId === INSURANCE_SERVICE_ID_3
          )
        ) {
          return;
        } else {
          // if (!deal?.equipmentStepPassed) {
            setCars([...cars, { ...res.data.additionalServices.find((service) => service.id === INSURANCE_SERVICE_ID_3_5), n: true, price: data || 0 }]);
          // }
        }
      })
      .catch((error) => {
        alert('Услуги не загрузились. Страховка не добавлена');
      });
    setLoading(false);
  }, [cars, selectedOrder?.value])

  const openCarsModal = () => {
    setCarsModal(true);
  };

  useEffect(() => {
    if (selectedOrder?.value && !loaded) {
      getServices();
      setLoaded(true);
    }
  }, [selectedOrder, loaded, getServices]);

  useEffect(() => {
    setCars(services || []);
  }, [services]);

  useEffect(() => {
    if (orders?.length > 0) {
      setSelectedOrder(orders[0]);
    }
  }, [orders]);


  const onCarsFormSubmit = (values) => {
    values = values?.map((item) => ({ ...item, n: true }));
    values = values?.filter((item) => !cars?.find((car) => car.id === item.id));
    let services = [...cars];

    const insurance = values?.find(
      (item) =>
        item.id === INSURANCE_SERVICE_ID_3_5 ||
        item.id === INSURANCE_SERVICE_ID_2_5 ||
        item.id === INSURANCE_SERVICE_ID_3
    );

    if (insurance) {
      services = services?.filter(
        (item) =>
          item.id !== INSURANCE_SERVICE_ID_3_5 &&
          item.id !== INSURANCE_SERVICE_ID_2_5 &&
          item.id !== INSURANCE_SERVICE_ID_3
      );
    }

    setCarsModal(false);
    setCars([...services, ...values]);
  };

  const handleServiceDelete = (item) => {
    if (item?.n) {
      const newCars = cars.filter((car) => car.id !== item.id);
      console.log(newCars);
      setCars(newCars);
    } else {
      removeItemsToDeal({
        services: [{ value: item?.id }],
        dealId: deal?.id,
      }).then(() => {
        update();
      });
    }
  };

  useEffect(() => {
    setUnsavedServices(cars?.filter((item) => item?.n));
  }, [cars, setUnsavedServices]);

  const isDealServicesValid = useMemo(() => {
    if (deal?.paymentType?.id === PAYMENT_TYPES_CREDIT_CARD || deal?.paymentType?.id === PAYMENT_TYPES_CREDIT_INVOICE) {
      return (
        cars
          ?.filter((i) => !i?.additionalServicesId)
          ?.find(
            (item) =>
              item?.id === INSURANCE_SERVICE_ID_3_5 ||
              item?.id === INSURANCE_SERVICE_ID_2_5 ||
              item?.id === INSURANCE_SERVICE_ID_3
          ) ||
        cars
          ?.filter((i) => i?.additionalServicesId)
          ?.find(
            (item) =>
              item?.additionalServicesId === INSURANCE_SERVICE_ID_3_5 ||
              item?.additionalServicesId === INSURANCE_SERVICE_ID_2_5 ||
              item?.additionalServicesId === INSURANCE_SERVICE_ID_3
          )
      );
    }

    return true;
  }, [cars, deal]);

  if (loading) return <LoaderInline />;

  return (
    <>
      {/* <p style={{ ...textStyles.formTitle, marginBottom: 20 }}>Основная информация о сделке</p> */}

      <HookForm
        inputs={inputs}
        title={'Дополнительные услуги'}
        grid={{ display: 'grid', gap: 20 }}
        onSubmit={() => {
          // if (!isDealServicesValid) {
          //   return alert.error('Страховка обязательна для данного типа оплаты');
          // }
          onSubmit(
            cars?.filter((item) => item?.n)?.map((item) => ({ value: item?.id })),
            selectedOrder?.value
          );
        }}
        action={'Сохранить и продолжить'}
        customData={() => (
          <div style={{ display: 'grid', gap: 10 }}>
            {cars?.map((item) => {
              return (
                <div key={item.id} style={{ display: 'grid', gridTemplateColumns: '1fr auto', alignItems: 'center' }}>
                  <p style={textStyles.buttonText}>{item.title}</p>
                  <ButtonDelete onClick={() => handleServiceDelete(item)} />
                </div>
              );
            })}

            {cars?.length > 0 && (
              <>
                <hr style={{ border: '1px solid #D9D9D9', margin: '20px 0' }} />
                <p className={styles.stepsRow__value} style={{ ...textStyles.buttonText, color: '#05141F' }}>
                  {formatNumber(cars?.reduce((acc, car) => acc + car?.price, 0))} KZT
                </p>
              </>
            )}
          </div>
        )}
        addCustomItemTitle="Добавить дополнительные услуги"
        handleAddCustomItem={openCarsModal}
        backHandler={backHandler}
        loading={loading}
      />
      <DealCreateServicesModal
        isOpen={carsModal}
        onRequestClose={() => setCarsModal(false)}
        onSubmit={onCarsFormSubmit}
        onCancel={() => setCarsModal(false)}
        paymentType={paymentType}
        orderId={selectedOrder}
        cityId={dealCars[0]?.cityId}
        enableInsurance={enableInsurance}
      />
    </>
  );
};

export default DealCreateServices;
