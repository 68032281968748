import React, { useState } from 'react';
import axios from '../../plugins/axios';
import getLeadCard from '../../api/leads/getLeadCard';
import { useParams } from 'react-router-dom';
import { phone } from '../../constants/mask';

// components
import Form from '../../components/Form/Form';
import Alert from '../../components/Alert/Alert';

// icons
import phoneIcon from '../../assets/input/phone.svg';

const actions = [{ type: 'button', action: 'Создать сделку' }];
const formStyles = {
  leadInfo: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a a a' 'b b b b'` },
  other: { display: 'grid' },
  actions: { display: 'grid', gap: '20px', gridTemplateColumns: 'auto auto auto auto auto', marginBottom: '0' },
};

const LeadEdit = () => {
  const { id } = useParams();
  const [error, setError] = React.useState({});
  const [succes, setSucces] = React.useState({});
  const [leadInfo, setLeadInfo] = useState([
    { type: 'date', value: undefined, name: 'created_at', label: 'Дата' },
    { type: 'input', value: undefined, name: 'fio', label: 'Имя', placeholder: 'Укажите имя' },
    { type: 'select', value: undefined, name: 'sourceId', label: 'Источник', placeholder: 'Выберите из списка' },
    {
      type: 'input',
      value: undefined,
      name: 'phone',
      label: 'Телефон',
      placeholder: '+7(___) ___-__-__',
      icon: phoneIcon,
      mask: phone,
    },
    { type: 'input', value: '', name: 'email', label: 'Email', placeholder: 'example@gmail.com' },
    {
      type: 'selectMarks',
      value: undefined,
      name: 'markId',
    },
    {
      type: 'selectModels',
      value: undefined,
      name: 'modelId',
      params: () => leadInfo[5].value,
    },
    {
      type: 'select',
      label: 'Статус',
      value: undefined,
      name: 'statusId',
      placeholder: 'Выберите из списка',
      options: [],
    },
  ]);

  const [leadId, setLeadId] = React.useState(null);

  const handleSubmit = () => {
    // let object = new Object();
    // leadInfo.map((item) => {
    //   if (item) {
    //     if (item.value.value) {
    //       object[item.name] = item.value.value;
    //     } else {
    //       object[item.name] = item.value;
    //     }
    //   }
    // });
    // axios.post('/api/crm/leads/editLead', { ...object, id: parseInt(id) }).then((res) => {});
    console.log(rerender);
  };

  const init = async () => {
    await axios
      .get('/api/crm/leads/getLeadStatuses')
      .then((res) => {
        leadInfo[7].options = res.data.map((item) => {
          return { value: item.id, label: item.title };
        });
      })
      .catch((error) => {});
    await axios
      .get('/api/crm/leads/getLeadSources')
      .then((res) => {
        leadInfo[2].options = res.data.map((item) => {
          return { value: item.id, label: item.title };
        });
      })
      .catch((error) => {});
    await getLead();
    setRerender(rerender + 1);
  };

  const [rerender, setRerender] = React.useState(0);

  React.useEffect(() => {
    init();
  }, []);

  const getLead = async () => {
    await getLeadCard({ id: id }).then((res) => {
      leadInfo[0].placeholder = new Date(res.data.createdAt);
      leadInfo[1].placeholder = res.data.client.fullName;
      leadInfo[2].placeholder = res.data.source.title;
      leadInfo[3].placeholder = res.data.client.phone;
      leadInfo[4].placeholder = res.data.client.email;
      leadInfo[5].placeholder = res.data.mark?.name;
      leadInfo[6].placeholder = res.data.model?.name;
      leadInfo[7].placeholder = res.data.status?.title;
    });
    setLeadInfo([...leadInfo]);
  };

  const [other, setOther] = useState([
    {
      type: 'input',
      value: undefined,
      name: 'comment',
      label: 'Заметки/комментарии о лиде',
      placeholder: 'Оставьте комментарий о лиде',
    },
  ]);

  const addLeadComment = () => {
    axios
      .post('/api/crm/leads/addLeadComment', { comment: other[0].value, leadId: leadId })
      .then((res) => {})
      .catch((error) => {});
  };

  return (
    <>
      {rerender > 0 && (
        <div>
          <Form
            inputs={leadInfo}
            handlerSetState={setLeadInfo}
            title={'Информация о лиде'}
            grid={formStyles.leadInfo}
            action={'Сохранить информацию о лиде'}
            handleSubmit={handleSubmit}
            border
          />
          {leadId != null && (
            <Form
              inputs={other}
              title={'Дополнительно'}
              grid={formStyles.other}
              handleSubmit={addLeadComment}
              action={'Сохранить'}
              border
            />
          )}
          <Form inputs={actions} title={'Действия'} grid={formStyles.actions} border />
          {error.message && <Alert message={error.message} onClick={setError} />}
          {succes.message && <Alert message={succes.message} onClick={setSucces} />}
        </div>
      )}
    </>
  );
};

export default LeadEdit;
