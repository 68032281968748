import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getDealCars from '../../api/deals/getDealCars';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import CheckboxSelectAdditionalServices from '../Selects/CheckboxSelectAdditionalServices';
import Select from '../Select/Select';
import CheckboxSelectEquipments from '../Selects/CheckboxSelectEquipments';
import formatNumber from '../../utils/formatNumber';
import Equipments from '../../pages/Equipments/Equipments';

const SelectAdditionalEquipmentsModal = ({ isOpen, onClose, onSubmit = () => null, dealId }) => {
  const [services, setServices] = useState({});
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleSubmit = () => {
    onSubmit(selectedOrder?.value, services);
  };

  useEffect(() => {
    getDealCars({ id: dealId }).then((res) => {
      const options = res?.data?.cars?.map((item) => {
        return {
          label: `${item.mark} ${item.model} (VIN ${item?.vin}, ${item?.complectation?.title}, ${
            item?.complectation?.engine?.title
          }, ${formatNumber(item?.container?.toPrice)} ₸)`,
          value: item?.orderId,
        };
      });

      setSelectedOrder(options[0]);
      setOrders(options);
    });
  }, [dealId]);

  return (
    <Modal
      isOpen={isOpen}
      title="Аксессуары"
      onRequestClose={() => onClose(false)}
      width={688}
      contentStyle={{
        height: '100%',
        maxHeight: 500,
      }}
    >
      <div
        style={{
          padding: '0 16px',
          marginBottom: 24,
        }}
      >
        {orders.length > 1 && (
          <div style={{ marginBottom: 20 }}>
            <Select title="Автомобиль" onChange={setSelectedOrder} options={orders} value={selectedOrder} />
          </div>
        )}

        <CheckboxSelectEquipments onChange={setServices} />
      </div>
      <Button
        fill
        text="Добавить"
        onClick={handleSubmit}
        style={{ maxWidth: 250, width: '100%', margin: '0 auto', display: 'block' }}
      />
    </Modal>
  );
};

SelectAdditionalEquipmentsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  dealId: PropTypes.number.isRequired,
};

export default SelectAdditionalEquipmentsModal;
