import React, { useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { CLIENT_RECORDS } from '../../constants/columns';
import axios from '../../plugins/axios';
// components
import EmptyData from '../../components/EmptyData/EmptyData';
import Loader from '../../components/Loader/Loader';

// icons
import icon from '../../assets/header/newClient.svg';
import { useNavigate, useParams } from 'react-router-dom';

const columns = CLIENT_RECORDS;

const ClientRecords = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  const table = useTable(
    {
      columns,
      data: records,
    },
    usePagination
  );
  const init = async () => {
    let items = [...records];
    await axios
      .get('/api/crm/services/getServiceRecords?all=true&userId=' + id)
      .then((res) => {
        let services = '';
        items = res.data.services.map((item) => {
          item.valuesToServices.forEach((item) => {
            services != '' ? (services = services + ', ' + item.title) : (services = item.title);
          });

          return {
            id: item.id,
            date: new Date(item.date).toLocaleDateString(),
            services: services,
            phone: item.phone,
            address: 'г. Алматы, ул. Елебекова 10/1',
            time: new Date(item.date).toLocaleTimeString(),
            status: item.status.title,
          };
        });
      })
      .finally(() => setLoading(false));
    setRecords([...items]);
  };
  React.useEffect(() => {
    init();
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <EmptyData
          title={`Записи клиента`}
          subtitle={'У этого клиента нет записей'}
          action={'Записать клиента'}
          func={() => {
            navigate('/records/add-record-service?userId=' + id);
          }}
          icon={icon}
          table={table}
          data={records}
          invisibleHeader
        />
      )}
    </>
  );
};

export default ClientRecords;
