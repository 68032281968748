import { useState } from 'react';
import getGuaranteeDocument from '../../../../api/bank/getGuaranteeDocument';
import Button from '../../../../components/Button/Button';
import extractErrorMessage from '../../../../utils/extractErrorMessage';
import alert from '../../../../helpers/alert';

const HalykGetGuaranteeLetter = ({ appealId, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const getDocument = async () => {
    setLoading(true);
    try {
      await getGuaranteeDocument(appealId);
      onSuccess && onSuccess();
    } catch (e) {
      alert.error(extractErrorMessage(e?.response?.data) || e?.response?.data || 'Ошибка при получении Гарантийного письма');
    } finally {
      setLoading(false);
    }
  };

  return (
      <Button fill disabled={loading} onClick={getDocument} text="Повторно запросить гарантийное письмо" />
  );
};

export default HalykGetGuaranteeLetter;
