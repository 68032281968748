import { useEffect, useState } from "react";
import HookForm from "../../../components/HookForm/HookForm";
import getContainerCard from "../../../api/containers/getContainerCard";
import { useParams } from "react-router-dom";
import alert from "../../../helpers/alert";
import SelectMarks from "../../../components/Selects/SelectMarks";
import SelectModels from "../../../components/Selects/SelectModels";
import DocsInputNew from "../../../components/Inputs/DocsInput/DocsInputNew";
import editContainer from "../../../api/containers/editContainer";
import Header from "../../../components/Header/Header";
const formStyles = {
    carInfo: { display: 'grid', gap: '20px', gridTemplateColumns: `1fr 1fr 1fr 1fr` },
    dealerInfo: { display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr' },
    other: { display: 'grid', gap: '20px' },
  };
  
const CarContainerEdit = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);

      const save = async (values) => {
        setLoading(true)
        try {
            await editContainer({
                containerId: id,
                photo: values?.photo
            });
            alert.success("Изменения успешно сохранены")
            update();
        } catch(e) {
            alert.error(e)
        } finally {
            setLoading(false)
        }
      }

      const update = async () => {
        setLoading(true)
        try {
            const res = await getContainerCard({ id });
            const container = res?.data?.container;

            setData([
                {
                    name: 'mark',
                    component: SelectMarks,
                    label: 'Марка',
                    placeholder: 'Выберите из списка',
                    defaultValue: { value: container?.mark?.id, label: container?.mark?.name },
                    disabled: true,
                  },
                  {
                    name: 'model',
                    component: SelectModels,
                    dependency: 'mark',
                    dependencyAccessor: (obj) => obj?.value,
                    label: 'Модель',
                    placeholder: 'Выберите из списка',
                    defaultValue: { value: container?.model?.id, label: container?.model?.name },
                    disabled: true,
                  },
                  {
                    name: 'photo',
                    component: DocsInputNew,
                    label: 'Фото',
                    text: 'Фото',
                    id: Math.random(),
                    showImage: true,
                    imageUrl: container?.url,
                  }
            ])
        } catch(e) {
            alert.error(e);
        } finally {
            setLoading(false)
        }
      }

      useEffect(() => {
        update();
      }, [])

    return(<>
        <Header title="Редактировать модель" />
        <HookForm inputs={data} border grid={formStyles.carInfo} onSubmit={save} loading={loading} action="Сохранить изменения" />
    </>)
}

export default CarContainerEdit;