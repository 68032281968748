import api from '../../plugins/api';

const getAdditionalServices = (params = {}) => {
  return api.get('/api/crm/additional-services/getAdditionalServices', {
    ...params,
    pageSize: 100,
  });
};

export default getAdditionalServices;
