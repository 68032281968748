import styles from './chatPersonal.module.css';

const ChatDateLabel = ({ date }) => {
  return (
    <div className={styles.ChatDateLabel}>
      <div className={styles.ChatDateLabel__label}>{date}</div>
    </div>
  );
};
export default ChatDateLabel;
