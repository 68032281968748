import editDeal from '../../../../api/deals/editDeal';
import setStep from '../../../../api/deals/setStep';
import DownloadFileLink from '../../../../components/DownloadFileLink/DownloadFileLink';
import HookForm from '../../../../components/HookForm/HookForm';
import Select from '../../../../components/Select/Select';
import SelectDealPaymentStatuses from '../../../../components/Selects/selectDealPaymentStatuses';
import {
  DEAL_STATUS_CAR_ISSUED,
  DEAL_STEPS,
  DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
  DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
  DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
  DOCUMENT_TYPES_CONTRACT,
  DOCUMENT_TYPES_GUARANTEE_LETTER,
  DOCUMENT_TYPES_INVOICE,
  DOCUMENT_TYPES_POLES,
  DOCUMENT_TYPES_POWER_OF_ATTORNEY,
  DOCUMENT_TYPES_SPOUSE_CONSENT,
  DOCUMENT_TYPES_TECH_PASSPORT,
} from '../../../../constants';
import alert from '../../../../helpers/alert';
import generateAllowedDocumentsJson from '../../../../utils/generateAllowedDocumentsJson';

const CarDelivery = ({ loading, deal, setLoading, onSuccess }) => {
  const onCarDeliverySubmit = async (values) => {
    if (!values?.status) {
      alert.error('Необходимо выбрать статус сделки');
      return;
    }
    setLoading(true);
    try {
      await editDeal({
        id: deal?.id,
        dealId: deal?.id,
        statusId: values?.status?.value,
        ...generateAllowedDocumentsJson([
          DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
          DOCUMENT_TYPES_GUARANTEE_LETTER,
          DOCUMENT_TYPES_INVOICE,
          DOCUMENT_TYPES_TECH_PASSPORT,
          DOCUMENT_TYPES_CONTRACT,
          DOCUMENT_TYPES_POLES,
          DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
          DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
          DOCUMENT_TYPES_POWER_OF_ATTORNEY,
          DOCUMENT_TYPES_SPOUSE_CONSENT,
        ]),
      });

      await setStep({ dealId: deal?.id, step: DEAL_STEPS.FINISH });
      setLoading(false);
      return onSuccess();
    } catch (e) {
      alert.error('Ошибка при смене статуса сделки');
      setLoading(false);
      return;
    }
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <HookForm
          title={'Выдача ТС'}
          grid={{ display: 'grid', gap: 20 }}
          action={'Сохранить и продолжить'}
          inputs={[
            {
              label: 'Статус сделки',
              name: 'status',
              component: Select,
              options: [
                {
                  value: DEAL_STATUS_CAR_ISSUED,
                  label: 'Машина выдана',
                },
              ],
              required: true,
            },
          ]}
          onSubmit={onCarDeliverySubmit}
          loading={loading}
        />
      </div>
    </>
  );
};

export default CarDelivery;
