import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import Header from '../../../components/Header/Header';
import Form from '../../../components/Form/Form';
import Button from '../../../components/Button/Button';
import Breadcrumbs from '../../../components/Breadcrumbs';

// icons
import icon from '../../../assets/sidebar/report.svg';

const formStyles = {
  report: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a a a' 'c c b b'` },
};

const ReportCreate = () => {
  const [report, setReport] = useState([
    {
      type: 'input',
      label: 'Дата',
      name: 'date',
      value: new Date().toLocaleDateString(),
      placeholder: 'Укажите название услуги',
      disabled: true,
    },
    { type: 'date', label: 'Отчетный период с', value: null, placeholder: 'дд-мм-гггг' },
    { type: 'date', label: 'Отчетный период с', value: null, placeholder: 'дд-мм-гггг' },
    { type: 'select', label: 'Раздел', value: null, placeholder: 'Выберите из списка' },
    {
      type: 'input',
      label: 'Название отчета',
      value: null,
      placeholder: 'Укажите название отчета',
      style: { gridArea: 'c' },
    },
    {
      type: 'select',
      label: 'Ответственный менеджер',
      value: null,
      placeholder: 'Выберите из списка',
      style: { gridArea: 'b' },
    },
  ]);

  return (
    <>
      <Header title="Новый отчет" icon={icon} />
      <Breadcrumbs navigate={'/reports'} link={'Мои отчеты'} currentPage={'Новый отчет'} />
      <Form inputs={report} title={'Отчет'} grid={formStyles.report} border />
      <Button text={'Сформировать отчет'} fill />
    </>
  );
};

export default ReportCreate;
