import { useEffect, useState } from 'react';
import getDealStatuses from '../../api/deals/getDealStatuses';
import Select from '../Select/Select';
import { DEAL_STATUSES } from '../../constants';

const SelectDealStatusesOptional = ({ handleSetSelect, handleSetState, style, optionalStatuses, ...props }) => {
  const [statuses, setStatuses] = useState([]);
  useEffect(() => {
    const newStatuses = optionalStatuses?.map((item) => {
      return DEAL_STATUSES.find((option) => option.value === item);
    });
    setStatuses(newStatuses);
  }, [optionalStatuses]);

  return (
    <Select
      handleSetSelect={(val) => handleSetSelect && handleSetSelect(val)}
      handleSetState={handleSetState}
      options={statuses}
      title={'Статус сделки'}
      placeholder={'Выберите из списка'}
      style={style}
      {...props}
    />
  );
};

export default SelectDealStatusesOptional;
