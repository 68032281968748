import { useEffect, useState } from 'react';
import getEquipments from '../../api/resources/getEquipments';
import formatNumber from '../../utils/formatNumber';
import Checkbox from '../Inputs/Checkbox/Checkbox';
import LoaderInline from '../Loader/LoaderInline';
import Counter from '../Inputs/Counter/Counter';

const CheckboxSelectEquipments = ({ onChange = () => null }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [state, setState] = useState({});

  const [counters, setCounters] = useState({});

  const onIncrement = (id, data) => {
    const newCounters = { ...counters };

    if (!newCounters[id]) {
      newCounters[id] = { ...data, n: 0 };
    }

    if (newCounters[id].n < data?.count) {
      newCounters[id].n = newCounters[id].n + 1;
    }

    setCounters(newCounters);
  };

  const onDecrement = (id, data) => {
    const newCounters = { ...counters };

    if (!newCounters[id]) {
      newCounters[id] = { ...data, n: 0 };
    }

    if (newCounters[id].n > 0) {
      newCounters[id].n = newCounters[id].n - 1;
    }
    setCounters(newCounters);
  };

  const getData = () => {
    if (page != 1 && page > totalCount) return;
    getEquipments({ page }).then((res) => {
      setData((prev) => [...prev, ...res?.data?.equipments]);
      setTotalCount(res.data.pagination.totalPages);
      setPage(page + 1);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const onCheck = (id, value, item) => {
    console.log(state);
    setState({
      ...state,
      [id]: value ? item : false,
    });
  };

  useEffect(() => {
    onChange(counters);
  }, [counters, onChange]);

  return (
    <>
      {!data?.length && <LoaderInline />}
      {data.map((item) => (
        <div style={{ marginBottom: 16, display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center' }}>
          {console.log(item)}
          <div>{item.title}</div>
          {/* <Checkbox
            key={item?.id}
            label={item?.title}
            id={item?.id + item?.title}
            value={state[item?.id]}
            onChange={(id, value) => onCheck(id, value, item)}
          /> */}
          <Counter
            value={counters[item.id]?.n || 0}
            onDecrement={() => onDecrement(item.id, item)}
            onIncrement={() => onIncrement(item.id, item)}
          />
          <div style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>{formatNumber(item?.price)} ₸</div>
        </div>
      ))}
    </>
  );
};

export default CheckboxSelectEquipments;
