import React, { useState } from 'react';
import axios from '../../plugins/axios';
// components
import Header from '../../components/Header/Header';
import Form from '../../components/Form/Form';
import Button from '../../components/Button/Button';
import Breadcrumbs from '../../components/Breadcrumbs';
import Alert from '../../components/Alert/Alert';
import Loader from '../../components/Loader/Loader';

// icons
import icon from '../../assets/sidebar/services.svg';
import { useNavigate } from 'react-router-dom';
import alert from '../../helpers/alert';

const formStyles = {
  service: { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' },
};

const AddService = () => {
  const navigate = useNavigate();
  const [error, setError] = React.useState({});
  const [succes, setSucces] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [service, setService] = useState([
    { type: 'input', value: undefined, label: 'Название', name: 'title', placeholder: 'Укажите название услуги' },
    { type: 'input', label: 'Цена, KZT', name: 'price', placeholder: 'Укажите цену', inputType: 'number' },
    {
      type: 'input',
      label: 'Заметки/комментарии об услуге',
      name: 'comment',
      placeholder: 'Оставьте комментарий об услуге',
    },    
    {
      type: 'selectCity',
      label: 'Город',
      name: 'cityId',
    },
  ]);
  const addService = () => {
    setLoading(true);
    let object = new Object();
    service.map((item) => {
      object[item.name] = item.value;
    });
    axios
      .post('/api/crm/additional-services/addAdditionalService', { additionalService: object, comment: object.comment })
      .then((res) => {
        setSucces({ message: 'Услуга добавлена' });
        setTimeout(() => {
          navigate('/services');
        }, 1000);
      })
      .catch((error) => {
        alert.error(error.response.data.errorMessage ?? JSON.stringify(error.response))
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      {loading && <Loader />}
      <Header title="Новая услуга" icon={icon} />
      <Breadcrumbs navigate={'/services'} link={'Дополнительные услуги'} currentPage={'Новая услуга'} />
      <Form
        handlerSetState={setService}
        inputs={service}
        title={'Информация об услуге'}
        grid={formStyles.service}
        border
      />
      <Button onClick={() => addService()} text={'Сохранить'} fill disabled={loading} />
      {error.message && <Alert message={error.message} onClick={setError} />}
      {succes.message && <Alert message={succes.message} />}
    </>
  );
};

export default AddService;
