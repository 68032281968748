import React, { useState } from 'react';
import { RECORDS } from '../../constants/columns';
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { equipmentOptions } from '../../constants/filterOptions';
import { useNavigate } from 'react-router-dom';
import axios from '../../plugins/axios';

// components
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import TableTabs from '../../components/TableTabs/TableTabs';
import Empty from '../../components/Empty/Empty';

// icons
import icon from '../../assets/sidebar/records.svg';
import eye from '../../assets/popover/eye.svg';
import bucket from '../../assets/popover/delete.svg';
import edit from '../../assets/popover/edit.svg';
import x from '../../assets/popover/x.svg';
import accept from '../../assets/popover/accept.svg';

const tableStyle = { backgroundColor: 'white' };

const popoverActions = [
  { title: 'Просмотреть запись', icon: eye },
  { title: 'Редактировать запись', icon: edit },
  { title: 'Подтвердить запись', icon: accept },
  { title: 'Отменить запись', icon: x },
  { title: 'Удалить запись', icon: bucket },
];

const Records = () => {
  const [columns, setColumns] = useState(RECORDS);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [popover, setPopover] = useState(popoverActions);
  const [records, setRecords] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    pageSize: 10,
  });
  const [isFetchAll, setFetchAll] = useState(false);

  const table = useTable(
    {
      columns,
      data: records,
      initialState: {
        pageSize: pagination.pageSize,
      },
      manualPagination: true,
      disableSortRemove: true,
      pageCount: pagination.totalPages,
      page: pagination.page - 1,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { setGlobalFilter, page, setPageSize } = table;

  const [value, setValue] = useState('');
  const [queryParams, setQueryParams] = useState({});

  const addRecord = () => {
    navigate('/records/add-record-service');
  };

  const handleActionPopover = (rowIndex, actionIndex, action) => {
    if (action === popoverActions[0].title) {
      navigate(`/records/service/${records[rowIndex].id}`);
    }
  };

  const init = async (params) => {
    setLoading(true);
    await axios
      .get('/api/crm/services/getServiceRecords', { params: { ...params } })
      .then((res) => {
        setRecords(res.data.services);
        setPagination({
          ...res.data.pagination,
          page: res.data.pagination?.pageNumber,
          pageSize: pagination.pageSize,
        });
        setDataCount(res.data.pagination?.count);
      })
      .finally(() => setLoading(false));
  };

  const handleNavigate = (index) => {
    const record = records.filter((item) => item.id === index);
    navigate(`/records/service/${record[0].id}`);
  };

  React.useEffect(() => {
    if (!isFetchAll) {
      init({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams });
    }
  }, [pagination.page, pagination.pageSize, queryParams]);

  return (
    <>
      <Header title="Записи" icon={icon} />
      <br />
      <br />
      <br />
      <br />
      <TableTabs
        setFilter={setGlobalFilter}
        options={equipmentOptions}
        value={value}
        setValue={setValue}
        navigate={addRecord}
        button={'Добавить запись'}
        inputPlaceholder={'Быстрый поиск по записям'}
      />
      <br />
      {!page.length && !loading ? (
        <Empty />
      ) : (
        <Table
          table={table}
          all={'записей'}
          pagination
          sort
          style={tableStyle}
          popover={popover}
          popoverActions={popoverActions}
          setPopover={setPopover}
          handleActionPopover={handleActionPopover}
          setPagination={setPagination}
          setFetchAll={setFetchAll}
          isFetchAll={isFetchAll}
          setData={setRecords}
          p={pagination}
          handleNavigate={handleNavigate}
          loading={loading}
          setLoading={setLoading}
          columns={columns}
          setColumns={setColumns}
          columnsSettingsKey="records"
          queryParams={queryParams}
          api={'/api/crm/services/getServiceRecords'}
          response={'services'}
          isSortable
          dataCount={dataCount}
        />
      )}
    </>
  );
};

export default Records;
