import DownloadFileLink from '../../../../components/DownloadFileLink/DownloadFileLink';
import HookForm from '../../../../components/HookForm/HookForm';
import Select from '../../../../components/Select/Select';
import SelectDealPaymentStatuses from '../../../../components/Selects/selectDealPaymentStatuses';
import { DEAL_STATUS_CAR_ISSUED, DOCUMENT_TYPES_INVOICE } from '../../../../constants';

const HalykCarDelivery = ({ onSubmit, loading }) => {
  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <HookForm
          title={'Выдача ТС'}
          grid={{ display: 'grid', gap: 20 }}
          action={'Сохранить и продолжить'}
          inputs={[
            {
              label: 'Статус сделки',
              name: 'status',
              component: Select,
              options: [
                {
                  value: DEAL_STATUS_CAR_ISSUED,
                  label: 'Машина выдана',
                },
              ],
              required: true,
            },
          ]}
          onSubmit={onSubmit}
          loading={loading}
        />
      </div>
    </>
  );
};

export default HalykCarDelivery;
