import React, { useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { EQUIPMENTS } from '../../constants/columns';
import { useOutletContext, useParams } from 'react-router-dom';
// components
import EmptyData from '../../components/EmptyData/EmptyData';
import Loader from '../../components/Loader/Loader';

// icons
import icon from '../../assets/buttonIcons/addDeal.svg';
import getDealEquipments from '../../api/deals/getDealEquipments';
import SelectAdditionalServicesModal from '../../components/Modals/SelectAdditionalServicesModal';
import addAdditionalServicesToDeal from '../../api/deals/addAdditionalServicesToDeal';
import SelectAdditionalEquipmentsModal from '../../components/Modals/SelectAdditionalEquipmentsModal';
import addItemsToDeal from '../../api/deals/addItemsToDeal';
import bucketIcon from '../../assets/popover/delete.svg';
import axios from '../../plugins/axios';
const columns = EQUIPMENTS;

const popover = [{ title: 'Удалить оборудование', icon: bucketIcon }];

const DealEquipments = () => {
  const { id } = useParams();
  const [equipments, setEquipments] = useState([]);
  const [equipmentsCount, updateCounters] = useOutletContext();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const table = useTable(
    {
      columns,
      data: equipments,
      disableSortRemove: true,
    },
    usePagination
  );

  const { setPageSize } = table;

  const init = async () => {
    let array = [];
    await getDealEquipments({ id: id })
      .then((res) => {
        res.data.forEach((item) => {
          array.push({
            id: item.equipmentId,
            itemId: item.id,
            title: item.name,
            unit: item.type.title,
            count: item.count,
            price: item.price,
          });
        });
      })
      .finally(() => setLoading(false));
    setEquipments([...array]);
    setPageSize(array.length);
  };

  React.useEffect(() => {
    init();
  }, []);

  const handleAddAdditionalServicesSubmit = (orderId, equipments) => {
    equipments = Object.values(equipments).map((equipment) => ({
      value: String(equipment.id),
      count: Number(equipment.n),
    })).filter(item => item.count)

    addItemsToDeal({
      cars: [{ orderId: String(orderId), value: String(orderId) }],
      dealId: String(id),
      equipments,
    })
      .then(() => {
        setShowModal(false);
        updateCounters();
        init();
      })
      .catch((err) => alert(err));
  };

  const handleActionPopover = async (dealIndex, actionIndex, title, itemId, type) => {
    if (type === 'equipments') {
      axios
        .post('/api/crm/deals/removeItemsToDeal', {
          services: [],
          equipments: [{ value: itemId }],
          cars: [],
          dealId: id,
        })
        .then((res) => {
          updateCounters();
          init();
        })
        .catch((error) => {});
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <EmptyData
            title={`Аксессуары   (${equipments.length})`}
            subtitle={'К этой услуге не добавлено никакого дополнительного оборудования.'}
            action={'Добавить Аксессуары'}
            icon={icon}
            table={table}
            data={equipments}
            deleteType={'equipments'}
            func={() => setShowModal(true)}
            popover={popover}
            handleActionPopover={handleActionPopover}
          />
          <SelectAdditionalEquipmentsModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            onSubmit={handleAddAdditionalServicesSubmit}
            dealId={id}
          />
        </>
      )}
    </>
  );
};

export default DealEquipments;
