import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import HookForm from '../HookForm/HookForm';

const EditLeadModal = ({ isOpen, onClose, title, inputs, handleSubmit }) => {
  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onRequestClose={() => onClose(false)}
      contentStyle={{
        height: '100%',
        maxHeight: 500,
      }}
    >
      <div style={{ padding: '0 16px' }}>
        <HookForm inputs={inputs} />
        <div style={{ position: 'absolute', bottom: 20, right: 20 }}>
          <Button text={'Сохранить запись'} fill onClick={handleSubmit} />
        </div>
      </div>
    </Modal>
  );
};

EditLeadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditLeadModal;
