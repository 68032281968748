import axios from './axios';
import { store } from '../index';
import { Cookies } from 'react-cookie';
import { getGlobalShowError, useError } from '../helpers/ErrorContext';
const cookies = new Cookies();

const defaultHeaders = async (accessToken) => {
  return {
    'Content-Type': 'application/json',
    // Authorization: `Bearer ${store?.getState()?.user?.token}`,
    Authorization: `Bearer ${cookies.get('access_token')}`,
  };
};

const IGNORE_URLS = [
  'createCreditAppeal',
];

export default class api {
  static onError(error) {
    console.log(error.response);
    const showError = getGlobalShowError();
    if (error.response?.data?.errorMessage) {
      console.log(error.response.data.errorMessage);
    }
    if (!IGNORE_URLS.some((url) => error?.config?.url?.includes(url))) {
      showError({
        message: error.response.data.errorMessage,
        details: error.response.data,
        config: error.config,
      });
    }

    return Promise.reject(error);
  }

  static async post(path, data, config = {}) {
    const headers = await defaultHeaders();
    return axios
      .post(`${path}`, data, { headers, ...config })
      .then((res) => {
        console.info('🪲 POST', path, data, res?.data);
        return res;
      })
      .catch(api.onError);
  }

  static async uploadFile(path, data, config = {}) {
    // const headers = await defaultHeaders();

    return axios
      .post(`${path}`, data, {
        // headers,
        ...config,
        'Content-Type': 'multipart/form-data',
      })
      .catch(api.onError);
  }

  static async put(path, data, config = {}) {
    const headers = await defaultHeaders();

    return axios.put(`${path}`, data, { headers, ...config }).catch(api.onError);
  }

  static async patch(path, data, config = {}) {
    const headers = await defaultHeaders();

    return axios.patch(`${path}`, data, { headers, ...config }).catch(api.onError);
  }

  static async delete(path, id) {
    const headers = await defaultHeaders();
    const url = [path, id].join('');

    return axios.delete(`${url}`, { headers }).catch(api.onError);
  }

  static async get(path, params = {}, config = {}) {
    const headers = await defaultHeaders();
    return axios
      .get(`${path}`, { headers, params, ...config })
      .then((res) => {
        console.info('🪲 GET', path, params, res?.data);
        return res;
      })
      .catch(api.onError);
  }
}
