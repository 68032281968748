import React, { createContext, useContext, useState } from 'react';

const ErrorContext = createContext();

let globalShowError = null;

export const useError = () => useContext(ErrorContext);

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const showError = (errorDetails) => setError(errorDetails);
  const hideError = () => setError(null);

  globalShowError = showError;

  return (
    <ErrorContext.Provider value={{ error, showError, hideError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const getGlobalShowError = () => globalShowError;
