import React from 'react';
import Select from '../Select/Select';

export const WORK_STATUSES = [
    { value: "0", label: "Обычный клиент с ГЦВП" },
    { value: "9", label: "Клиент с выпиской БВУ" },
    { value: "3", label: "Военнослужащий" },
    { value: "7", label: "Пенсионер (в т.ч. по выслуге лет)" },
    { value: "10", label: "Работающий пенсионер (в т.ч. по выслуге лет)" },
    { value: "6", label: "Обычный клиент со справкой c работы" },
];

const SelectWorkStatus = ({ ...props }) => {
  return <Select options={WORK_STATUSES} label={'Статус клиента'} placeholder={'Выберите из списка'} {...props} />;
};

export default SelectWorkStatus;
