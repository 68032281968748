import React from 'react';
import Select from '../Select/Select';

export const MARITAL_STATUSES = [
  { value: 1, label: 'Холост/не замужем' },
  { value: 2, label: 'Женат/Замужем' },
  { value: 3, label: 'Разведен/разведена' },
  { value: 4, label: 'Вдовец/вдова' },
  { value: 5, label: 'Гражданский брак' },
];

const SelectFamilyStatus = ({ ...props }) => {
  return <Select options={MARITAL_STATUSES} label={'Семейное положение'} placeholder={'Выберите из списка'} {...props} />;
};

export default SelectFamilyStatus;
