import React from 'react';
import Select from '../Select/Select';

const options = [
  { value: 1, label: 'VIP' },
  { value: 2, label: 'Посредник' },
];

const SelectClientType = ({ ...props }) => {
  return <Select options={options} label={'Тип клиента'} placeholder={'Выберите из списка'} {...props} />;
};

export default SelectClientType;
