import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

// components
import Form from '../../components/Form/Form';
import Loader from '../../components/Loader/Loader';

// icons
import phone from '../../assets/input/phone.svg';
import edit from '../../assets/editForm.svg';
import getLeadCard from '../../api/leads/getLeadCard';
import checkCrmSidebarNotifications from '../../api/notifications/checkCrmSidebarNotifications';

const formStyles = {
  leadInfo: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a a a' 'b b b b'` },
  other: { display: 'grid' },
  actions: { display: 'grid', gap: '20px', gridTemplateColumns: 'auto auto auto auto auto', marginBottom: '0' },
};

const other = [{ type: 'input', label: 'Заметки/комментарии о лиде', disabled: true }];

const LeadMainInfo = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const [lead, setLead] = React.useState();
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    getLeadCard({ id: id })
      .then((res) => {
        setLead(res.data);
        if (res.data.notifications.length > 0) {
          let ids = res.data.notifications.map((item) => {
            return item.id;
          });
          checkCrmSidebarNotifications({ notificationIds: ids })
            .then((res) => {})
            .catch((error) => {});
        }
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  }, []);

  const leadInfo = [
    { type: 'input', value: new Date(lead?.createdAt).toLocaleDateString(), label: 'Дата', disabled: true },
    { type: 'input', value: lead?.fio, label: 'Имя', disabled: true },
    { type: 'input', value: lead?.source.title, label: 'Источник', disabled: true },
    { type: 'input', value: lead?.phone, label: 'Телефон', icon: phone, disabled: true },
    { type: 'input', value: lead?.email, label: 'Email', disabled: true },
    { type: 'input', value: lead?.mark?.name, label: 'Марка автомобиля', disabled: true },
    { type: 'input', value: lead?.model?.name, label: 'Модель автомобиля', disabled: true },
    { type: 'input', value: lead?.status.title, label: 'Статус', disabled: true },
  ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Form
            inputs={leadInfo}
            title={'Информация о лиде'}
            grid={formStyles.leadInfo}
            border
            status={state?.status}
            edit={edit}
          />
          <Form inputs={other} title={'Дополнительно'} grid={formStyles.other} border edit={edit} />
        </>
      )}
    </>
  );
};

export default LeadMainInfo;
