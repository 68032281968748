import React, { useEffect, useState } from 'react';
import styles from '../deal.module.css';
import DealCreationSteps from '../DealCreationSteps/DealCreationSteps';
import Header from '../../../../components/Header/Header';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import Button from '../../../../components/Button/Button';

import leads from '../../../../assets/sidebar/deals.svg';
import DealCreationMainInfo from './DealCreateMainInfo';
import DealCreateEquipment from '../DealCreateEquipment';
import DealCreateServices from '../DealCreateServices';
import DealCreateDocs from '../DealCreateDocs';
import DealCreateOther from '../DealCreateOther';
import Alert from '../../../../components/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import addItemsToDeal from '../../../../api/deals/addItemsToDeal';
import getDealCars from '../../../../api/deals/getDealCars';
import formatNumber from '../../../../utils/formatNumber';
import { textStyles } from '../../../../constants/textStyles';
import addDealComment from '../../../../api/deals/addDealComment';
import setStep from '../../../../api/deals/setStep';
import LoaderInline from '../../../../components/Loader/LoaderInline';
import DealCreateCreditAppeal from '../DealCreateCreditAppeal';
import {
  DEAL_STATUS_WAITING_FOR_PAYMENT,
  PAYMENT_TYPES_CREDIT_CARD,
  PAYMENT_TYPES_CREDIT_INVOICE,
  PAYMENT_TYPES_PREPAYMENT_CARD,
} from '../../../../constants';
import uploadDocumentToDeal from '../../../../api/deals/uploadDocumentToDeal';
import createCreditAppeal from '../../../../api/creditPlatform/createCreditAppeal';
import DealCreateBanks from '../DealCreateBanks';
import DealCreateBanksPay from '../DealCreateBanksPay';
import generateUserAgreement from '../../../../api/creditPlatform/generateUserAgreement';
import DealCreatePayment from '../DealCreatePayment';
import editDeal from '../../../../api/deals/editDeal';
import removeItemsToDeal from '../../../../api/deals/removeItemsToDeal';
import DealCreateSummary from './DealCreateSummary';
const DealCreateSellEquipment = ({
  deal: savedDeal,
  cars,
  equipments,
  services,
  update,
  documents,
  loading,
  setLoading,
}) => {
  const [state, setState] = useState({});
  const [sum, setSum] = useState(0);
  const [currentStep, setCurrentStep] = useState(savedDeal?.step || 1);
  const [succes, setSucces] = React.useState({});

  const [deal, setDeal] = useState(savedDeal);
  const [orders, setOrders] = useState([]);
  const [unsavedEquipments, setUnsavedEquipments] = useState([]);
  const [unsavedServices, setUnsavedServices] = useState([]);
  const [agreementModal, setAgreementModal] = React.useState(false);

  const isCreditDeal =
    deal?.paymentType?.id === PAYMENT_TYPES_CREDIT_CARD || deal?.paymentType?.id === PAYMENT_TYPES_CREDIT_INVOICE;

  useEffect(() => {
    if (savedDeal?.id) {
      getDealCars({ id: savedDeal?.id }).then((res) => {
        const options = res?.data?.cars?.map((item) => {
          return {
            label: `${item.mark} ${item.model} (VIN ${item?.vin}, ${item?.complectation?.title}, ${
              item?.complectation?.engine?.title
            }, ${formatNumber(item?.container?.toPrice)} ₸)`,
            value: item?.orderId,
          };
        });

        setOrders(options);
      });
    }
    setDeal(savedDeal);
  }, [savedDeal]);

  const onSubmitStep1 = () => {
    update && update();
  };
  const onEquipmentSubmit = (equipments, orderId) => {
    addItemsToDeal({
      cars: [{ orderId: String(orderId), value: String(orderId) }],
      dealId: String(deal?.id),
      equipments,
      step: 3,
    })
      .then(() => {
        setUnsavedEquipments([]);
        update();
        setCurrentStep(3);
      })
      .catch((err) => alert(err));
  };
  const onSubmitStep3 = (services, orderId) => {
    addItemsToDeal({
      cars: [{ orderId: String(orderId), value: String(orderId) }],
      dealId: String(savedDeal?.id),
      services,
    })
      .then(async () => {
        setUnsavedServices([]);
        await editDeal({
          dealId: savedDeal?.id,
          id: savedDeal?.id,
          statusId: DEAL_STATUS_WAITING_FOR_PAYMENT,
          paymentTypeId: savedDeal?.paymentType?.id,
          generatePaymentLink: true,
          equipmentStepPassed: true,
        });
        setStep({ dealId: savedDeal?.id, step: 4 }).then(() => {
          update();
        });
      })
      .catch((err) => alert(err));
  };
  const onSubmitStep4 = () => {
    update();
  };

  const handleCreateCreditSubmit = (values, documents) => {
    documents.map((doc) => uploadDocumentToDeal(doc));
    return createCreditAppeal({
      ...values,
      MaritalStatus: values?.MaritalStatus?.value,
    })
      .then(() => {
        setStep({ dealId: deal?.id, step: 5 }).then(() => {
          update();
        });
      })
      .catch(() => {
        setAgreementModal(true);
        // alert('Для отправки заявки клиенту необходимо подписать согласие на обработку персональных данных');
      });
  };

  const onSubmitStep5 = (values) => {
    addDealComment({
      managerId: String(values?.managerId?.value),
      dealId: String(savedDeal?.id),
      comment: values?.comment,
    }).then(() => {
      setStep({ dealId: savedDeal?.id, step: 10 }).then(() => {
        update();
      });
    });
  };

  const onBankSelect = () => {
    setStep({ dealId: savedDeal?.id, step: 6 }).then(() => {
      update();
    });
  };

  const dealComplete = () => {
    setStep({ dealId: savedDeal?.id, step: 10 }).then(() => {
      update();
    });
  };

  const backHandler = (e) => {
    e.preventDefault();
    // setCurrentStep(currentStep - 1);
    setStep({ dealId: deal?.id, step: currentStep - 1 }).then(() => {
      update && update();
    });
  };

  const removeEquipment = (id) => {
    setLoading(true);
    removeItemsToDeal({
      equipments: [{ value: id }],
      dealId: deal?.id,
    })
      .then(() => {
        update();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const removeService = (id) => {
    setLoading(true);
    removeItemsToDeal({
      services: [{ value: id }],
      dealId: deal?.id,
    })
      .then(() => {
        update();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const sum = savedDeal?.sum || 0;
    const sum2 =
      unsavedEquipments?.reduce((acc, item) => {
        return acc + item?.price * item?.n;
      }, 0) || 0;
    const sum3 =
      unsavedServices?.reduce((acc, item) => {
        return acc + item?.price;
      }, 0) || 0;
    setSum(sum + sum2 + sum3);
  }, [savedDeal, cars, equipments, unsavedEquipments, unsavedServices]);

  useEffect(() => {
    setCurrentStep(savedDeal?.step || 1);
  }, [savedDeal]);

  const updateCurrentStep = (step) => {
    setStep({ dealId: savedDeal?.id, step }).then(() => {
      update();
    });
  };

  const back = () => {
    return (
      <Button
        text={'Назад'}
        disabled={loading}
        onClick={backHandler}
        style={{ color: '#BABABA', border: '1px solid #BABABA', width: '140px' }}
      />
    );
  };

  return (
    <>
      <Header title={'Новая сделка'} icon={leads} />
      <Breadcrumbs navigate={'/deals'} link={'Сделки'} currentPage={'Новая сделка'} />
      <div className={styles.DealCreate} style={{ gridTemplateColumns: '1fr' }}>
        <div className={styles.DealCreateForm}>
          {loading ? (
            <LoaderInline />
          ) : (
            <>
              {currentStep === 1 && (
                <DealCreationMainInfo nextStep={onSubmitStep1} deal={deal} cars={cars} update={update} />
              )}
              {currentStep === 2 && (
                <DealCreateSummary
                  currentStep={currentStep}
                  setCurrentStep={updateCurrentStep}
                  state={state}
                  sum={sum}
                  deal={savedDeal}
                  equipments={equipments}
                  services={services}
                  cars={cars}
                  removeEquipment={removeEquipment}
                  removeService={removeService}
                  update={update}
                  backHandler={back}
                />
              )}
              {currentStep === 3 && (
                <DealCreateServices
                  onSubmit={onSubmitStep3}
                  paymentType={savedDeal?.paymentType?.id}
                  deal={deal}
                  services={services}
                  orders={orders}
                  update={update}
                  setUnsavedServices={setUnsavedServices}
                  setLoading={setLoading}
                  backHandler={back}
                />
              )}
              {currentStep === 4 && !isCreditDeal && (
                <DealCreatePayment
                  onSubmit={onSubmitStep4}
                  deal={deal}
                  documents={documents}
                  update={update}
                  backHandler={back}
                />
              )}
              {currentStep === 4 && isCreditDeal && (
                <DealCreateCreditAppeal
                  onSubmit={handleCreateCreditSubmit}
                  deal={deal}
                  documents={documents}
                  orders={orders}
                  agreementModal={agreementModal}
                  setAgreementModal={setAgreementModal}
                  backHandler={back}
                />
              )}

              {currentStep === 5 && !isCreditDeal && (
                <DealCreateDocs
                  onSubmit={onSubmitStep4}
                  deal={deal}
                  documents={documents}
                  update={update}
                  backHandler={back}
                />
              )}
              {currentStep === 6 && !isCreditDeal && (
                <DealCreateOther
                  onSubmit={onSubmitStep5}
                  deal={deal}
                  update={update}
                  documents={documents}
                  backHandler={back}
                  cars={cars}
                />
              )}
              {currentStep === 5 && isCreditDeal && (
                <DealCreateBanks deal={deal} onSubmit={onBankSelect} backHandler={back} />
              )}
              {currentStep === 6 && isCreditDeal && (
                <DealCreateBanksPay deal={deal} onSubmit={dealComplete} documents={documents} backHandler={back} />
              )}
            </>
          )}
        </div>
      </div>

      {succes.message && <Alert message={succes.message} onClick={setSucces} />}
    </>
  );
};

export default DealCreateSellEquipment;
