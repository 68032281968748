import React from 'react';
import styles from './cardTemplate.module.css';
import MaskedTextInput from 'react-text-mask';
import logo from '../../../assets/authLogo.svg';
import axios from '../../../plugins/axios';
const CardTemplate = (props) => {
  const [card, setCard] = React.useState({
    cardNumber: '',
    dateEnd: '',
    cvc: '',
  });
  const payment = () => {
    axios
      .post('/api/bank/orderCreditCardPayment', {
        ...card,
        chunkId: props.chunkId,
        seperately: true,
      })
      .then((res) => {})
      .catch((error) => {});
  };

  React.useEffect(() => {
    props.setConfirm(true);
  }, []);
  return (
    <div className={styles.cardTemplate}>
      <div className={styles.mockCard}>
        <img src={logo} style={{ width: 90, position: 'absolute' }} alt="" />
        <div className={styles.mockCardItem}>
          {card.cardNumber && card.cardNumber != '' ? card.cardNumber : '0000 0000 0000 0000'}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div className={styles.mockCardItem} style={{ marginTop: 36 }}>
            {card.dateEnd && card.dateEnd != '' ? card.dateEnd : '00/00'}
          </div>
          <div className={styles.mockCardItem} style={{ marginTop: 36 }}>
            {card.cvc && card.cvc != '' ? card.cvc : '000'}
          </div>
        </div>
      </div>
      <div className={styles.cardTemplateItem}>
        <p className={styles.cardTemplateItemTitle}>Номер карты</p>
        <MaskedTextInput
          style={{ letterSpacing: 5 }}
          className={styles.cardInput}
          type="text"
          onChange={(val) => {
            setCard({
              ...card,
              cardNumber:
                val.target.value != ''
                  ? val.target.value
                      .replace(/[\s._%]/g, '')
                      .match(/.{1,4}/g)
                      .join(' ')
                  : val.target.value,
            });
          }}
          mask={[
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
        />
      </div>
      <div className={styles.cardTemplateItem}>
        <p className={styles.cardTemplateItemTitle}>Имя владельца карты</p>
        <input className={styles.cardInput} type="text" />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className={styles.cardTemplateItem} style={{ maxWidth: 120 }}>
          <p className={styles.cardTemplateItemTitle}>Действует до</p>
          <MaskedTextInput
            onChange={(val) => {
              setCard({ ...card, dateEnd: val.target.value });
            }}
            mask={[/\d/, /\d/, '/', /\d/, /\d/]}
            className={styles.cardInput}
            type="text"
          />
        </div>
        <div className={styles.cardTemplateItem} style={{ maxWidth: 120, marginLeft: 28 }}>
          <p className={styles.cardTemplateItemTitle}>CVV</p>
          <MaskedTextInput
            onChange={(val) => {
              setCard({ ...card, cvc: val.target.value });
            }}
            className={styles.cardInput}
            type="text"
            mask={[/\d/, /\d/, /\d/]}
          />
        </div>
      </div>

      <button
        disabled={card.cardNumber === '' || card.cvc.length < 3 || card.dateEnd.length < 5}
        className={styles.cardButton}
        onClick={() => payment()}
      >
        Оплатить
      </button>
    </div>
  );
};

export default CardTemplate;
