import React, { useState } from 'react';
import axios from '../../../plugins/axios';
import { phone } from '../../../constants/mask';

// components
import Form from '../../../components/Form/Form';
import Alert from '../../../components/Alert/Alert';

// icons
import phoneIcon from '../../../assets/input/phone.svg';
import HookForm from '../../../components/HookForm/HookForm';
import Input from '../../../components/Inputs/Input/Input';
import Datepicker from '../../../components/Inputs/Datepicker/Datepicker';
import Select from '../../../components/Inputs/Select/Select';
import SelectMarks from '../../../components/Selects/SelectMarks';
import SelectModels from '../../../components/Selects/SelectModels';
import SelectStatuses from '../../../components/Selects/SelectStatuses';
import SelectSources from '../../../components/Selects/SelectSources';
import alert from '../../../helpers/alert';

const actions = [{ type: 'button', action: 'Создать сделку' }];
const formStyles = {
  leadInfo: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a a a' 'b b b b'` },
  other: { display: 'grid' },
  actions: { display: 'grid', gap: '20px', gridTemplateColumns: 'auto auto auto auto auto', marginBottom: '0' },
};

const LeadCreate = () => {
  const [error, setError] = React.useState({});
  const [succes, setSucces] = React.useState({});
  const [leadInfo, setLeadInfo] = useState([
    { type: 'date', value: new Date(), name: 'created_at', label: 'Дата', component: Datepicker },
    { type: 'input', name: 'fio', label: 'Имя', placeholder: 'Укажите имя' },
    {
      name: 'sourceId',
      label: 'Источник',
      placeholder: 'Выберите из списка',
      component: SelectSources,
    },
    {
      type: 'input',
      name: 'phone',
      label: 'Телефон',
      placeholder: '+7(___) ___-__-__',
      icon: phoneIcon,
      mask: phone,
    },
    { type: 'input', name: 'email', label: 'Email', placeholder: 'example@gmail.com' },
    { type: 'selectMarks', name: 'mark', component: SelectMarks },
    {
      type: 'selectModels',
      name: 'model',
      dependency: 'mark',
      dependencyAccessor: (obj) => obj?.value,
      component: SelectModels,
    },
    {
      type: 'select',
      label: 'Статус',
      name: 'statusId',
      placeholder: 'Выберите из списка',
      component: SelectStatuses,
    },
  ]);

  const [leadId, setLeadId] = React.useState(null);

  const handleSubmit = (data) => {
    axios
      .post('/api/crm/leads/createLead', {
        ...data,
        markId: data?.mark?.value,
        modelId: data?.model?.value,
        sourceId: data?.sourceId?.value,
        statusId: data?.statusId?.value,
      })
      .then((res) => {
        setLeadId(res.data);
        setSucces({ message: 'Лид создан' });
      })
      .catch((error) => {
        alert.error(JSON.stringify(error.response.data))
      });
  };

  const [other, setOther] = useState([
    {
      type: 'input',
      value: null,
      name: 'comment',
      label: 'Заметки/комментарии о лиде',
      placeholder: 'Оставьте комментарий о лиде',
    },
  ]);

  const addLeadComment = () => {
    axios
      .post('/api/crm/leads/addLeadComment', { comment: other[0].value, leadId: leadId })
      .then((res) => {})
      .catch((error) => {});
  };

  return (
    <>
      <HookForm
        inputs={leadInfo}
        handlerSetState={setLeadInfo}
        title={'Информация о лиде'}
        grid={formStyles.leadInfo}
        action={'Сохранить информацию о лиде'}
        onSubmit={handleSubmit}
        border
      />
      {leadId != null && (
        <Form
          inputs={other}
          title={'Дополнительно'}
          grid={formStyles.other}
          handleSubmit={addLeadComment}
          action={'Сохранить'}
          border
        />
      )}
      <Form inputs={actions} title={'Действия'} grid={formStyles.actions} border />
      {error.message && <Alert message={error.message} onClick={setError} />}
      {succes.message && <Alert message={succes.message} onClick={setSucces} />}
    </>
  );
};

export default LeadCreate;
