import React, { useState } from 'react';
import styles from './clientCreate.module.css';
import createClient from '../../../../api/clients/createClient';
import { useNavigate } from 'react-router-dom';

// components
import HookForm from '../../../../components/HookForm/HookForm';
import Datepicker from '../../../../components/Inputs/Datepicker/Datepicker';
import SelectPhoto from './SelectPhoto/SelectPhoto';
import Select from '../../../../components/Select/Select';
import SelectPhoneType from '../../../../components/Selects/SelectPhoneType';
import AddButton from '../../../../components/AddButton/AddButton';
import DeleteButton from '../../../../components/DeleteButton/DeleteButton';
import SelectAddressType from '../../../../components/Selects/SelectAddressType';
import SelectSocialType from '../../../../components/Selects/SelectSocialType';
import SelectClientType from '../../../../components/Selects/SelectClientType';
import Button from '../../../../components/Button/Button';
import Alert from '../../../../components/Alert/Alert';
import Loader from '../../../../components/Loader/Loader';

import { phone, iin } from '../../../../constants/mask';
import SelectClientID from '../../../../components/Selects/SelectClientD';
import SelectClientDocumentType from '../../../../components/Selects/SelectClientDocumentTypes';
import SelectClientIssuer from '../../../../components/Selects/SelectClientDocumentType';
import alert from '../../../../helpers/alert';
import historyObject from '../../../../utils/historyObject';
import verifyClient from '../../../../api/clients/verification';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const formStyles = {
  mainData: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a a ' 'b b b ' 'k k c' 'd d d'` },
  contacts: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  },
  document: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '20px',
  },
  comment: { display: 'grid', gap: '20px' },
};

const addFormInputs = (e, btn, form, setForm) => {
  e.preventDefault();
  e.stopPropagation();
  const btnIndex = form.map((inputs) => inputs.name).indexOf(btn);
  if (btn === 'addPhone') {
    form.splice(btnIndex, 0, {
      component: SelectPhoneType,
      name: `phoneType_${btnIndex}`,
      defaultValue: { value: 1, label: 'Мобильный' },
    });
    form.splice(btnIndex + 1, 0, {
      name: `phoneNumber_${btnIndex}`,
      label: 'Номер телефона',
      placeholder: '+7(___) ___-__-__',
      mask: phone,
    });
    form.splice(btnIndex + 2, 0, {
      component: DeleteButton,
      name: `deleteBtn_${btnIndex}`,
      onClick: (e) =>
        deleteFormInputs(e, form.map((inputs) => inputs.name).indexOf(`deleteBtn_${btnIndex}`), form, setForm),
    });
    setForm([...form]);
  }
  if (btn === 'addAddress') {
    form.splice(btnIndex, 0, {
      component: SelectAddressType,
      name: `addressType_${btnIndex}`,
      defaultValue: { value: 1, label: 'Домашний' },
    });
    form.splice(btnIndex + 1, 0, {
      name: `address_${btnIndex}`,
      label: 'Адрес',
      placeholder: 'Укажите адрес',
    });
    form.splice(btnIndex + 2, 0, {
      component: DeleteButton,
      name: `deleteBtn_${btnIndex}`,
      style: { marginTop: 'auto' },
      onClick: (e) =>
        deleteFormInputs(e, form.map((inputs) => inputs.name).indexOf(`deleteBtn_${btnIndex}`), form, setForm),
    });
    setForm([...form]);
  }
  if (btn === 'addSocial') {
    form.splice(btnIndex, 0, {
      component: SelectSocialType,
      name: `socialType_${btnIndex}`,
      defaultValue: { value: 2, label: 'VK' },
    });
    form.splice(btnIndex + 1, 0, {
      name: `social_${btnIndex}`,
      label: 'URL',
      placeholder: 'ссылка',
    });
    form.splice(btnIndex + 2, 0, {
      component: DeleteButton,
      name: `deleteBtn_${btnIndex}`,
      style: { marginTop: 'auto' },
      onClick: (e) =>
        deleteFormInputs(e, form.map((inputs) => inputs.name).indexOf(`deleteBtn_${btnIndex}`), form, setForm),
    });
    setForm([...form]);
  }
};

const deleteFormInputs = (e, inputsIndex, form, setForm) => {
  e.preventDefault();
  e.stopPropagation();
  form.splice(inputsIndex - 2, 3);
  setForm([...form]);
};

const ClientCreate = () => {
  const user = useSelector(state => state?.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [succes, setSucces] = useState({});
  const [photo, setPhoto] = useState();
  const [uploadPhoto, setUploadPhoto] = useState();
  const [mainData, setMainData] = useState([
    {
      name: 'surname',
      label: 'Фамилия',
      placeholder: 'Фамилия клиента',
      rules: {
        required: true,
      },
    },
    {
      name: 'name',
      label: 'Имя',
      placeholder: 'Имя клиента',
      rules: {
        required: true,
      },
    },
    { name: 'middlename', label: 'Отчество', placeholder: 'Отчество (если есть)' },
    {
      name: 'iin',
      label: 'ИИН',
      placeholder: '_ _ _ _ _ _ _ _ _ _ _',
      mask: iin,
      rules: {
        required: true,
      },
    },
    {
      component: Datepicker,
      name: 'born',
      label: 'Дата рождения',
      placeholder: 'дд-мм-гггг',
      rules: {
        required: true,
      },
    },
    {
      component: Select,
      label: 'Язык',
      placeholder: 'Выберите из списка',
      name: 'languageId',
      options: [
        { value: 1, label: 'Казақша' },
        { value: 2, label: 'Русский' },
      ],
      rules: {
        required: true,
      },
    },
    {
      name: 'phoneNumber',
      label: 'Номер телефона',
      placeholder: '+7(___) ___-__-__',
      mask: phone,
      rules: {
        required: true,
      },
    },
    // {
    //   label: 'Компания',
    //   name: 'company',
    //   placeholder: 'Название компании',
    // },
    // { component: SelectClientType, name: 'vip' },
  ]);

  const [contacts, setContacts] = useState([
    {
      name: 'email',
      label: 'E-mail',
      placeholder: 'Укажите адрес электронной почты клиента',
    },
    {
      name: 'url',
      label: 'URL сайта',
      placeholder: 'Сайт компании клиента',
    },
    {
      component: SelectAddressType,
      name: 'addressType',
      defaultValue: { value: 1, label: 'Домашний' },
    },
    {
      name: 'address',
      label: 'Адрес',
      placeholder: 'Укажите адрес',
    },
    {
      name: 'addAddress',
      component: AddButton,
      text: 'Добавить адрес',
      onClick: (e) => addFormInputs(e, 'addAddress', contacts, setContacts),

      style: { gridColumn: '1 / span 2' },
    },
  ]);
  const [documents, setDocuments] = useState([
    {
      component: SelectClientID,
      name: 'document_type_id',
      rules: {
        required: true,
      },
    },
    {
      name: 'document_number',
      label: 'Номер документа',
      rules: {
        required: true,
      },
    },
    {
      component: Datepicker,
      name: 'document_date',
      type: 'date',
      label: 'Дата выдачи документа',
      defaultValue: null,
      rules: {
        required: true,
      },
    },
    {
      component: Datepicker,
      name: 'document_date_end',
      type: 'date',
      label: 'Срок действия документа',
      defaultValue: null,
      rules: {
        required: true,
      },
    },
    {
      component: SelectClientIssuer,
      name: 'document_issuer',
      label: 'Кем выдан',
      rules: {
        required: true,
      },
    },
  ]);
  const [vu, setVu] = useState([
    {
      name: 'document_number',
      label: 'Номер в/у',
    },
    {
      component: Datepicker,
      name: 'document_date',
      type: 'date',
      label: 'Дата выдачи',
    },
    {
      component: Datepicker,
      name: 'document_date_end',
      type: 'date',
      label: 'Срок действия',
    },
  ]);

  const [comment, setComment] = useState([
    {
      name: 'comment',
      label: 'Заметки/комментарии о клиенте',
      placeholder: 'Укажите любые заметки или комментарии о клиенте',
    },
  ]);

  const addClient = async () => {
    setLoading(true);

    try {
      //TODO: refactor using HookForm
      const mainForm = {};
      for (let i = 0; i < mainData.length; i++) {
        if (mainData[i]?.rules?.required && !mainData[i]?.value) {
          throw new Error('Заполните все обязательные поля');
        }

        if (mainData[i].name === 'vip') {
          mainForm.vip = mainData[i].value?.value === 1 ? true : false;
        } else if (mainData[i].name === 'phoneNumber') {
          mainForm.phone = mainData[i].value.replace(/[^\d]/g, '');
        } else {
          mainForm[mainData[i].name] = mainData[i].value?.value ?? mainData[i].value;
        }
      }
      for (let i = 0; i < documents.length; i++) {
        if (documents[i]?.rules?.required && !documents[i]?.value) {
          throw new Error('Заполните все обязательные поля');
        }
      }
      if(mainForm.iin?.replace(/\D/g, '').length != 12) {
        throw new Error('Проверьте правильность ИИН');
      }

      const addressesFilter = contacts.filter((item) => item.name.includes('address'));
      const mobileNumbersFilter = contacts.filter((item) => item.name.includes('phone'));
      const socialsFilter = contacts.filter((item) => item.name.includes('social'));
      const addresses = addValuesContactsForm(addressesFilter);
      const mobileNumbers = addValuesContactsForm(mobileNumbersFilter, true);
      const socials = addValuesContactsForm(socialsFilter);
      const contactsForm = {
        email: contacts[0]?.value,
        url: contacts[1]?.value,
        addresses,
        mobileNumbers,
        socials,
      };

      const commentForm = comment[0].value;
      const docs = {
        document_type_id: documents[0]?.value?.value,
        document_number: documents[1]?.value,
        document_date: documents[2]?.value,
        document_date_end: documents[3]?.value,
        document_issuer: documents[4]?.value?.value,
      };
      const docs_vu = {
        document_number: vu[0]?.value,
        document_date: vu[1]?.value,
        document_date_end: vu[2]?.value,
      };

      // return console.log({ mainForm, contactsForm, commentForm, documents: docs, driver_document: docs_vu })

      
      // const passClientValidation = user.options?.find((i) => i?.name === 'set_client')?.value;

      // if(passClientValidation !== true) {
        const isClientValid = await verifyClient({
          iin: mainForm.iin,
          phone: mainForm.phone,
          name: mainForm.name,
          surname: mainForm.surname,
          middlename: mainForm.middlename,
          born: dayjs(mainForm.born).format('YYYY-MM-DD'),
          document_number: docs.document_number,
          document_type_id: docs.document_type_id,
          document_date_end: dayjs(docs.document_date_end).format('YYYY-MM-DD'),
          document_date: dayjs(docs.document_date).format('YYYY-MM-DD'),
          document_issuer: docs.document_issuer,
        });
  
        if (isClientValid?.data !== true) {
          throw new Error('Ошибка валидации данных');
        }
      // }

      const payload = new FormData();
      payload.append(
        'form',
        JSON.stringify({ mainForm, contactsForm, commentForm, document: docs, driver_document: docs_vu })
      );
      payload.append('photo', uploadPhoto);
      createClient(payload)
        .then(() => {
          setSucces({ message: 'Клиент создан' });

          if (window.history.length > 2) {
            historyObject.back();
          } else {
            navigate('/');
          }
        })
        .catch((error) => alert.error(error.response.data.errorMessage ?? JSON.stringify(error.response)))
        .finally(() => setLoading(false));
    } catch (e) {
      setLoading(false);
      if (e.response?.status === 400) {
        return alert.error(e?.response?.data?.errorMessage || 'Ошибка валидации данных');
      }

      alert.error(e.message || 'Ошибка валидации данных');
    }
  };

  const addValuesContactsForm = (array, isPhoneNumber) => {
    const result = [];
    for (let i = 0; i < array.length; i += 2) {
      if (array[i + 1].value) {
        result.push({
          type: array[i].value?.value ?? array[i].defaultValue?.value,
          value: isPhoneNumber ? array[i + 1].value.replace(/[^\d]/g, '') : array[i + 1].value,
        });
      }
    }
    return result;
  };

  return (
    <>
      {loading && <Loader />}
      <div className={styles.wrapper}>
        <SelectPhoto photo={photo} setPhoto={setPhoto} setUploadPhoto={setUploadPhoto} />
        <div className={styles.forms}>
          <HookForm
            title={'Данные клиента'}
            grid={formStyles.mainData}
            border
            inputs={mainData}
            style={{ margin: 0 }}
          />
          <HookForm
            title={'Контактные данные'}
            grid={formStyles.contacts}
            border
            inputs={contacts}
            style={{ margin: 0 }}
          />
          <HookForm
            title={'Данные документов'}
            grid={formStyles.contacts}
            border
            inputs={documents}
            style={{ margin: 0 }}
          />
          <HookForm title={'Данные документов'} grid={formStyles.contacts} border inputs={vu} style={{ margin: 0 }} />
          <HookForm title={'Дополнительно'} border grid={formStyles.comment} inputs={comment} style={{ margin: 0 }} />
          <div>
            <Button text={'Создать клиента'} fill onClick={addClient} disabled={loading} />
          </div>
        </div>
      </div>
      {error.message && <Alert message={error.message} onClick={setError} />}
      {succes.message && <Alert message={succes.message} />}
    </>
  );
};

export default ClientCreate;

