import checkBankStatus from '../../../../api/bank/checkStatusAppeal';
import Button from '../../../../components/Button/Button';
import FormHeading from '../../../../components/Typography/FormHeading';
import CheckBankStatus from './CheckBankStatus';

const WaitingForNewBankStatus = ({ chunkId, bankId, setLoading, onSuccess }) => {
  return (
    <div>
      <FormHeading>Ожидание действий на стороне банка</FormHeading>
      <CheckBankStatus bankId={bankId} onSuccess={onSuccess} chunkId={chunkId} setLoading={setLoading}/>
    </div>
  );
};

export default WaitingForNewBankStatus;
