import { useCallback, useState } from 'react';
import getEquipments from '../../api/deals/getEquipments';
import Select from '../Select/Select';

const SelectEquipments = ({ handleSetSelect, handleSetState, style, ...props }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const onFocus = useCallback(() => {
    if (page != 1 && page > totalCount) return;
    getEquipments({ page }).then((res) => {
      const equipments = res.data.equipments.map((item) => {
        return { value: item.id, label: item.title };
      });
      setData((prev) => [...prev, ...equipments]);
      setTotalCount(res.data.pagination.totalPages);
      setPage((prev) => prev + 1);
    });
  }, [page, totalCount]);

  return (
    <Select
      handleSetSelect={(val) => handleSetSelect && handleSetSelect(val)}
      handleSetState={handleSetState}
      options={data}
      title={'Оборудование'}
      placeholder={'Выберите из списка'}
      onFocus={onFocus}
      style={style}
      isMulti
      isSearchable
      {...props}
    />
  );
};

export default SelectEquipments;
