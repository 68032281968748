import React from 'react';
import styles from './modalForm.module.css';

// components
import Form from '../Form/Form';
import Button from '../Button/Button';
import AddButton from '../AddButton/AddButton';

// icons
import close from '../../assets/x.svg';

const ModalForm = ({
  title,
  closeModal,
  mainForm,
  formTitle,
  handleSubmit,
  handlerSetState,
  handleSetState,
  grid,
  handleSetStateAuto,
  actions,
  setActions,
  setForm,
  forms,
  button,
  action,
  loading,
}) => {
  const handleAddForm = (index) => {
    setActions((prev) => {
      return [...prev, (prev[index].show = false)];
    });
    setForm[index]((prev) => ({
      ...prev,
      form: [prev.add],
    }));
  };

  const handleDeleteForm = (e, index, sectionId) => {
    e.preventDefault();
    const filteredArray = forms[sectionId].form.filter((item, idx) => idx !== index);
    setForm[sectionId]((prev) => ({
      ...prev,
      form: filteredArray,
      value: [],
    }));

    if (forms[sectionId].form.length) {
      setActions((prev) => {
        return [...prev, (prev[sectionId].show = true)];
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1>{title}</h1>
        <img src={close} alt="Icon" onClick={closeModal} />
      </div>
      {
        <Form
          handlerSetState={handleSetState}
          inputs={mainForm}
          title={formTitle}
          handleSubmit={handleSubmit}
          grid={grid}
          action={action}
        />
      }
      {forms && (
        <>
          <section>
            {forms[0].form.map((item, index) => (
              <div>
                <Form
                  key={index}
                  handlerSetState={setForm[0]}
                  handleSetState={handlerSetState}
                  inputs={forms[0].form[index]}
                  title={index === 0 ? forms[0].title : false}
                  grid={forms[0].style}
                  addFormButton={index === forms[0].form.length - 1 ? forms[0].action : false}
                  addForm={() => {
                    setForm[0]((prev) => ({
                      ...prev,
                      form: [...prev.form, prev.add],
                    }));
                  }}
                  deleteForm={(e) => handleDeleteForm(e, index, 0)}
                  isModalForm
                  modalFormKeys={forms[0]}
                />
              </div>
            ))}
          </section>
          <section>
            {forms[1].form.map((item, index) => (
              <Form
                key={index}
                handlerSetState={setForm[1]}
                handleSetState={handlerSetState}
                inputs={forms[1].form[index]}
                title={index === 0 ? forms[1].title : false}
                grid={forms[1].style}
                addFormButton={index === forms[1].form.length - 1 ? forms[1].action : false}
                addForm={() => {
                  setForm[1]((prev) => ({
                    ...prev,
                    form: [...prev.form, prev.add],
                  }));
                }}
                deleteForm={(e) => handleDeleteForm(e, index, 1)}
                isModalForm
                modalFormKeys={forms[1]}
              />
            ))}
          </section>
          <section>
            {forms[2].form.map((item, index) => (
              <Form
                key={index}
                handlerSetState={setForm[2]}
                handleSetState={handlerSetState}
                inputs={forms[2].form[index]}
                title={index === 0 ? forms[2].title : false}
                grid={forms[2].style}
                addFormButton={index === forms[2].form.length - 1 ? forms[2].action : false}
                addForm={() => {
                  setForm[2]((prev) => ({
                    ...prev,
                    form: [...prev.form, prev.add],
                  }));
                }}
                deleteForm={(e) => handleDeleteForm(e, index, 2)}
                isModalForm
                modalFormKeys={forms[2]}
              />
            ))}
          </section>
          <section>
            {forms[3].form.map((item, index) => (
              <Form
                key={index}
                handlerSetState={handlerSetState}
                handleSetState={handlerSetState}
                inputs={forms[3].form[index]}
                title={index === 0 ? forms[3].title : false}
                grid={forms[3].style}
                addFormButton={index === forms[3].form.length - 1 ? forms[3].action : false}
                addForm={() => {
                  setForm[3]((prev) => ({
                    ...prev,
                    form: [...prev.form, prev.add],
                  }));
                }}
                deleteForm={(e) => handleDeleteForm(e, index, 3)}
              />
            ))}
          </section>
        </>
      )}
      {actions && (
        <div className={styles.actions}>
          {actions.map((item, index) => {
            if (item.show && !forms[index].form.length) {
              return <AddButton key={index} text={item.text} onClick={() => handleAddForm(index)} />;
            }
            return null;
          })}
        </div>
      )}
      {forms && <Button text={button} fill onClick={() => handleSubmit()} disabled={loading} />}
      {/* {forms &&
        forms.map((item, index) => {
          if (forms[index].form.length) {
            if (item.form.length && item.form[0][0].value) {
              return <Button key={index} text={button} fill onClick={() => handleSubmit()} />;
            }
          }
          return null;
        })} */}
    </div>
  );
};

export default ModalForm;
