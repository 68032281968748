import { useState } from 'react';
import Button from '../../../../components/Button/Button';
import LoaderInline from '../../../../components/Loader/LoaderInline';
import FormHeading from '../../../../components/Typography/FormHeading';
import editDeal from '../../../../api/deals/editDeal';

const InfoStep = ({ dealId, headingText, statusText, prevStatusId, prevStep, update, footer }) => {
  const [loading, setLoading] = useState(false);
  const goBack = () => {
    setLoading(true);
    editDeal({
      id: dealId,
      step: prevStep,
    })
      .then(() => {
        update && update();
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoaderInline />;
  }

  return (
    <>
      {headingText ? <FormHeading>Статус</FormHeading> : null}
      <Button text={statusText} style={{ color: 'rgb(73, 65, 54)', border: '1px solid rgb(73, 65, 54)' }} />
      {footer ? footer() : null}
      {prevStatusId ? (
        <div style={{ marginTop: 10 }}>
          <Button
            text={'Назад'}
            onClick={goBack}
            style={{ color: '#BABABA', border: '1px solid #BABABA', width: '140px' }}
            fill
          />
        </div>
      ) : null}
    </>
  );
};

export default InfoStep;
