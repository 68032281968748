import Button from '../../../../components/Button/Button';
import DownloadFileLink from '../../../../components/DownloadFileLink/DownloadFileLink';
import HookForm from '../../../../components/HookForm/HookForm';
import DocsInputNew from '../../../../components/Inputs/DocsInput/DocsInputNew';
import FormHeading from '../../../../components/Typography/FormHeading';
import { DOCUMENT_TYPES_GUARANTEE_LETTER } from '../../../../constants';

const HalykNotarialDocuments = ({ documents, backHandler, onSubmit, loading }) => {
  // const guaranteeLetter = documents?.find((doc) => doc?.type?.id === DOCUMENT_TYPES_GUARANTEE_LETTER);

  return (
    <div>
      {/* {guaranteeLetter && (
        <>
          <FormHeading>Скачать гарантийное письмо</FormHeading>
          <DownloadFileLink document={guaranteeLetter} />
        </>
      )} */}

      <HookForm
        title={'Документы'}
        hint="Проконсультируйте клиента какие именно доверенности и согласия надо сделать. Нужно чтобы клиент запрашивал равнозначность доверенности, это необходимо для того, чтобы можно было выписать доверенность у любого нотариуса.   Так же нужно описать на кого нужно сделать доверенность на постановку на учет. Выписать доверенность на регистрационные действия нужно на ФИО, ИИН человека."
        grid={{ display: 'grid', gap: 20 }}
        action={'Сохранить и продолжить'}
        inputs={[
          {
            text: 'Согласие супруга (супруги) на внесудебную реализацию авто',
            label: 'Загрузить документ',
            name: 'согласие',
            id: 'Согласие супруга (супруги) на внесудебную реализацию авто',
            component: DocsInputNew,
            required: true,
          },
          {
            text: 'Доверенность постановки на учет',
            label: 'Загрузить документ',
            name: 'доверенность',
            id: 'Доверенность постановки на учет',
            component: DocsInputNew,
          },
          {
            label: 'Номер доверенности',
            name: 'powerOfAttorneyNumber',
          },
        ]}
        onSubmit={onSubmit}
        loading={loading}
        backHandler={backHandler}
      />
    </div>
  );
};

export default HalykNotarialDocuments;
