import getClients from '../../api/clients/getClients';
import { useCallback, useEffect, useState } from 'react';
import Select from '../Select/Select';

const SelectManager = ({ handleSetSelect, handleSetState, style, ...props }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchClients = useCallback(async (page) => {
    setIsFetching(true);
    try {
      const res = await getClients({ roleId: 2, page });
      const marks = res.data.users.map((item) => ({
        value: item.id,
        label: `${item.name} ${item.surname}`,
      }));

      setData((prevData) => [...prevData, ...marks]);
      if (res.data.users.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      setHasMore(false);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    fetchClients(page);
  }, [fetchClients, page]);

  const handleScroll = (event) => {
    const { target } = event;
    if (
      target.scrollTop + target.clientHeight >= target.scrollHeight &&
      !isFetching &&
      hasMore
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const onFocus = useCallback(() => {
    if (data.length === 0) {
      fetchClients(1);
    }
  }, [data, fetchClients]);

  return (
    <Select
      handleSetSelect={(val) => handleSetSelect && handleSetSelect(val)}
      handleSetState={handleSetState}
      options={data}
      placeholder={'Выберите из списка'}
      onFocus={onFocus}
      onMenuScrollToBottom={handleScroll}
      style={style}
      {...props}
    />
  );
};

export default SelectManager;
