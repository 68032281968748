import React, { useEffect, useState } from 'react';
import { MARKS } from '../../../constants/columns';
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { carsOptions } from '../../../constants/filterOptions';
import { useNavigate } from 'react-router-dom';
import getModels from '../../../api/cars/getModels';

// components
import Header from '../../../components/Header/Header';
import Table from '../../../components/Table/Table';
import TableTabs from '../../../components/TableTabs/TableTabs';
import Empty from '../../../components/Empty/Empty';

// icons
import icon from '../../../assets/sidebar/cars.svg';

const columns = MARKS;

const CarModels = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [models, setModels] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    pageSize: 10,
  });
  const [isFetchAll, setFetchAll] = useState(false);

  const table = useTable(
    {
      columns,
      data: models,
      initialState: {
        pageSize: pagination.pageSize,
      },
      manualPagination: true,
      pageCount: pagination.totalPages,
      page: pagination.page - 1,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { setGlobalFilter, page, setPageSize } = table;

  const [value, setValue] = useState('');
  const [queryParams, setQueryParams] = useState({});

  const handleNavigate = () => {
    navigate('/cars/models/create');
  };

  const init = (page) => {
    setLoading(true);
    getModels({ ...page })
      .then((res) => {
        setModels(res.data.models);
        setPagination({
          ...res.data.pagination,
          page: res.data.pagination?.pageNumber,
          pageSize: pagination.pageSize,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!isFetchAll) {
      init({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams });
    }
  }, [pagination.page, pagination.pageSize, queryParams]);

  return (
    <>
      <Header title="Модели" icon={icon} />
      <TableTabs
        setFilter={setGlobalFilter}
        options={carsOptions}
        value={value}
        setValue={setValue}
        navigate={handleNavigate}
        button={'Добавить модель'}
        inputPlaceholder={'Быстрый поиск по моделям'}
      />
      <br />
      {!page.length && !loading ? (
        <Empty />
      ) : (
        <Table
          table={table}
          all={'марок'}
          p={pagination}
          loading={loading}
          pagination
          setPagination={setPagination}
          setFetchAll={setFetchAll}
          isFetchAll={isFetchAll}
          setData={setModels}
          setLoading={setLoading}
          queryParams={queryParams}
          api={'/api/crm/cars/getModels'}
          response={'models'}
        />
      )}
    </>
  );
};

export default CarModels;
