import React from 'react';
import styles from './notaryForm.module.css';
import Checkbox from '../Inputs/Checkbox/Checkbox';

import { textStyles } from '../../constants/textStyles';

const NotaryForm = ({ title, form = [] }) => {
  return (
    <div className={styles.wrapper}>
      <div>
        <p style={{ ...textStyles.formTitle, marginBottom: '16px' }}>{title}</p>
      </div>
      <div className={styles.grid}>
        {form.map((item, index) =>
          item.isCheckbox ? (
            <div key={index} style={{ display: 'flex', columnGap: '8px', paddingTop: '20px' }}>
              <Checkbox id={'id' + Math.random().toString(16).slice(2)} value={item.defaultValue} disabled={true} />
              <p>{item.label}</p>
            </div>
          ) : (
            <div key={index} style={{ display: 'flex', columnGap: '4px' }}>
              {item.label && (
                <div className={styles.gridItem}>
                  <p>{item.label}</p>
                </div>
              )}
              {item.labelIcon && (
                <div className={styles.gridItem}>
                  <p className={styles.labelIcon}>{item.labelIcon}</p>
                </div>
              )}
              <div className={styles.gridItem} style={{display: 'flex', alignItems: 'center'}}>
                {item.label === 'Цвет' ? (
                  <div
                    style={{ backgroundColor: item.defaultValue, width: '13px', height: '13px', borderRadius: '100%' }}
                  />
                ) : (
                  <p>{item.defaultValue}</p>
                )}
                {item.component ? item.component : ''}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default NotaryForm;
