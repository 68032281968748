import React, { useState } from 'react';

// components
import Header from '../../components/Header/Header';
import Breadcrumbs from '../../components/Breadcrumbs';

// icons
import icon from '../../assets/sidebar/equipment.svg';
import axios from '../../plugins/axios';
import { useNavigate, useParams } from 'react-router-dom';
import HookForm from '../../components/HookForm/HookForm';
import InputNumeric from '../../components/Inputs/Input/InputNumeric';
import SelectEquipmentType from '../../components/Selects/SelectEquipmentType';
import StrToNum from '../../utils/StrToNum';
import SelectCity from '../../components/Selects/SelectCity';

const formStyles = {
  equipment: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a a a' 'b b c c' 'e e e e' 'd d d d'` },
  other: { display: 'grid', gap: '20px' },
};

const AddEquipments = ({ close, id }) => {
  const navigate = useNavigate();

  const [equipment, setEquipment] = useState([
    {
      type: 'input',
      name: 'title',
      label: 'Название',
      placeholder: 'Укажите название дополнительного оборудования',
      value: null,
      style: { gridArea: 'a' },
      rules: { required: true },
    },
    {
      component: SelectEquipmentType,
      name: 'typeId',
      label: 'Единица измерения',
      placeholder: 'Выберите из списка',
      rules: { required: true },
    },
    {
      component: InputNumeric,
      name: 'count',
      label: 'Количество',
      placeholder: 'Укажите количество',
      value: null,
      rules: { required: true },
    },
    {
      component: InputNumeric,
      name: 'price',
      label: 'Цена, KZT',
      placeholder: 'Укажите цену',
      value: null,
      style: { gridArea: 'c' },
      rules: { required: true },
    },
    {
      component: SelectCity,
      name: 'cityId',
      label: 'Город',
      placeholder: 'Выберите из списка',
      style: { gridArea: 'e' },
      rules: { required: true },
    },
    {
      value: null,
      name: 'comment',
      label: 'Заметки/комментарии о доп.оборудовании',
      placeholder: 'Оставьте комментарий об оборудовании',
      style: { gridArea: 'd' },
    },
  ]);
  const addEquipment = (values) => {
    axios
      .post('/api/crm/equipments/add', {
        equipment: {
          title: values?.title,
          containerId: id,
          count: StrToNum(values?.count),
          price: StrToNum(values?.price),
          typesId: values?.typeId?.value,
          cityId: values?.cityId?.value,
        },
        comment: values?.comment,
      })
      .then((res) => {
        close();
      })
      .catch((error) => {});
  };

  return (
    <>
      <Header title="Новое доп.оборудование" icon={icon} />
      <Breadcrumbs
        navigate={'/equipments'}
        link={'Аксессуары'}
        currentPage={'Новые аксессуары'}
      />

      <HookForm
        inputs={equipment}
        handlerSetState={setEquipment}
        title={'Информация о дополнительном оборудовании'}
        grid={formStyles.equipment}
        border
        onSubmit={addEquipment}
        action={'Сохранить'}
      />
    </>
  );
};

export default AddEquipments;
