import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';

// components
import Header from '../../../components/Header/Header';
import Navbar from '../../../components/Navbar/Navbar';
import Breadcrumbs from '../../../components/Breadcrumbs';

// icons
import leads from '../../../assets/sidebar/deals.svg';
import getDealItemsCount from '../../../api/deals/getDealItemsCount';
import Loader from '../../../components/Loader/Loader';
import { PAYMENT_TYPES_CREDIT_CARD, PAYMENT_TYPES_CREDIT_INVOICE, PAYMENT_TYPES_PREPAYMENT_CARD, PAYMENT_TYPES_PREPAYMENT_INVOICE } from '../../../constants';
import getCrmSidebarNotificationsCount from '../../../api/sidebar/getCrmSidebarNotificationsCount';

const NotaryDealNavigation = ({ to }) => {
  const { id } = useParams();
  const [equipmentsCount, setEquipmentsCount] = useState({});

  const { pathname } = useLocation();
  const [items, setItems] = useState([
    { text: '100% оплата', navigate: `/notary/deals/prepayment` },
    { text: 'Кредит', navigate: `/notary/deals/credit` },
  ]);

  const init = async () => {
    getCrmSidebarNotificationsCount().then((res) => {
      setItems([
        { text: '100% оплата', navigate: `/notary/deals/prepayment`, new: res?.data?.dealAbsolutePayment},
        { text: 'Кредит', navigate: `/notary/deals/credit`, new: res?.data?.dealCredit },
      ]);
    })
  };

  const [deal, setDeal] = useState(null);

  React.useEffect(() => {
    init();
    update();
  }, [id]);

  const update = async () => {
  };

  return (
    <>
      <Header title={id ? `Сделка ID ${id}` : 'Новая сделка'} icon={leads} />
      <Breadcrumbs navigate={'/deals'} link={'Сделки'} currentPage={id ? `Сделка ID ${id}` : 'Новая сделка'} />
      <Navbar items={items} />
      <Outlet context={[equipmentsCount, init, deal]} />
    </>
  );
};

export default NotaryDealNavigation;
