import carRegistration from '../../../../api/cars/registration';
import editDeal from '../../../../api/deals/editDeal';
import setStep from '../../../../api/deals/setStep';
import {
  DEAL_STATUS_CAR_DELIVERY,
  DEAL_STATUS_WAIRING_REGISTRATION,
  DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
  DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
  DEAL_STEPS,
  DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
  DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
  DOCUMENT_TYPES_GUARANTEE_LETTER,
  DOCUMENT_TYPES_INVOICE,
  DOCUMENT_TYPES_PAYMENT_ORDER,
  DOCUMENT_TYPES_POWER_OF_ATTORNEY,
  DOCUMENT_TYPES_SPOUSE_CONSENT,
} from '../../../../constants';
import alert from '../../../../helpers/alert';
import extractErrorMessage from '../../../../utils/extractErrorMessage';
import generateAllowedDocumentsJson from '../../../../utils/generateAllowedDocumentsJson';
import CarRegistration from '../CommonBankSteps/CarRegistration';
import CheckBankStatus from '../CommonBankSteps/CheckBankStatus';
import ContractSign from '../CommonBankSteps/ContractSign';
import NotarialDocuments from '../CommonBankSteps/NotarialDocuments';
import PaymentStatus from '../CommonBankSteps/PaymentStatus';

const AltynFlow = ({ step, update, setLoading, cars, deal, bankId, loading, documents, chosenBankAppealId }) => {
  const onRegistrationSubmit = async (values) => {
    try {
      setLoading(true);
      await carRegistration({
        bankId,
        chunkId: deal?.chunkId,
        carId: cars?.[0]?.id,
        reg_num: '',
      });
      await setStep({ dealId: deal?.id, step: 10 });
      update && update();
    } catch (e) {
      console.log(e);
      alert.error(e);
      setLoading(false);
    }
  };

  const onPaymentSubmit = (values) => {
    setLoading(true);
    editDeal({
      id: deal?.id,
      statusPaymentId: values?.status?.value,
      statusId: DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
      ...generateAllowedDocumentsJson([
        DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
        DOCUMENT_TYPES_INVOICE,
        DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
        DOCUMENT_TYPES_POWER_OF_ATTORNEY,
        DOCUMENT_TYPES_SPOUSE_CONSENT,
        DOCUMENT_TYPES_GUARANTEE_LETTER,
        DOCUMENT_TYPES_PAYMENT_ORDER,
      ]),
    })
      .then(async () => {
        await setStep({ dealId: deal?.id, step: DEAL_STEPS.ALTYN.AUTO_REREGISTRATION });

        alert.success('Статус оплаты успешно обновлен');
        update && update();
      })
      .catch((err) => {
        alert.error(extractErrorMessage(err?.response?.data) || 'Ошибка при смене статуса сделки');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onContractSmsSent = () => {
    alert.success("СМС со ссылкой на подписание договора успешно отправлено");
  };

  
  if (step === DEAL_STEPS.ALTYN.WAITING_FOR_PAYMENT) {
    return (
      <>
        <PaymentStatus
          onSubmit={onPaymentSubmit}
          documents={documents}
          deal={deal}
          chunkId={deal?.chunkId}
          setLoading={setLoading}
          loading={loading}
          bankId={bankId}
        />
        <div style={{ marginTop: 20 }}>
          <CheckBankStatus bankId={bankId} chunkId={deal?.chunkId} onSuccess={update} setLoading={setLoading} />
        </div>
      </>
    );
  }

  if(step === DEAL_STEPS.ALTYN.SIGN_CONTRACT) {
    return <ContractSign deal={deal} documents={documents} cars={cars} dealStepAfterSign={DEAL_STEPS.ALTYN.WAITING_FOR_PAYMENT} setLoading={setLoading} onSuccess={onContractSmsSent} update={update}/>;
  }

  if (step === DEAL_STEPS.ALTYN.AUTO_REREGISTRATION) {
    return (
      <>
        <CarRegistration
          onSuccess={onRegistrationSubmit}
          deal={deal}
          setLoading={setLoading}
          loading={loading}
          statusOption={[
            {
              value: DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
              label: 'Ожидает постановки ТС на учет',
            },
            {
              value: DEAL_STATUS_CAR_DELIVERY,
              label: 'Машина поставлена на учет',
            },
          ]}
        />
        <div style={{ marginTop: 20 }}>
          <CheckBankStatus bankId={bankId} chunkId={deal?.chunkId} onSuccess={update} setLoading={setLoading} />
        </div>
      </>
    );
  }
};

export default AltynFlow;
