import React, { useState } from 'react';
import createRecord from '../../../api/services/createRecord';
import { useNavigate } from 'react-router-dom';

// components
import Header from '../../../components/Header/Header';
import HookForm from '../../../components/HookForm/HookForm';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Alert from '../../../components/Alert/Alert';
import Loader from '../../../components/Loader/Loader';
import SelectClients from '../../../components/Selects/SelectClients';
import SelectServiceStatuses from '../../../components/Selects/SelectServiceStatuses';
import SelectSources from '../../../components/Selects/SelectSources';
import Select from '../../../components/Select/Select';
import SelectMarks from '../../../components/Selects/SelectMarks';
import SelectModels from '../../../components/Selects/SelectModels';
import Datepicker from '../../../components/Inputs/Datepicker/Datepicker';

// icons
import icon from '../../../assets/sidebar/records.svg';
import alert from '../../../helpers/alert';

const formStyles = {
  record: {
    display: 'grid',
    gap: '20px',
    gridTemplateAreas: `'a a k k' 'b b b b' 'c c v v' 'd d d d' 'f f f f' 'n n n n'`,
  },
};

const AddRecordTestdrive = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = React.useState({});
  const [succes, setSucces] = React.useState({});
  const [service, setService] = useState([
    { name: 'date', label: 'Дата', value: '01.11.2022', disabled: true },
    { name: 'id', label: 'ID', value: '35567809', disabled: true },
    { name: 'userId', component: SelectClients, style: { gridArea: 'k' } },
    {
      name: 'status',
      component: SelectServiceStatuses,
      label: 'Статус записи',
      placeholder: 'Выберите из списка',
    },
    { name: 'sourceId', component: SelectSources, label: 'Источник', placeholder: 'Выберите из списка' },
    { name: 'created_at', type: 'date', value: new Date(), label: 'Дата', component: Datepicker },
    {
      name: 'time',
      component: Select,
      label: 'Время',
      placeholder: 'Выберите из списка',
      options: [
        { value: '09:00', label: '09:00' },
        { value: '10:30', label: '10:30' },
        { value: '12:00', label: '12:00' },
        { value: '13:30', label: '13:30' },
        { value: '15:00', label: '15:00' },
        { value: '16:30', label: '16:30' },
      ],
    },
    {
      name: 'service',
      component: Select,
      label: 'Услуга',
      placeholder: 'Выберите из списка',
      options: [{ value: 1, label: 'Тест-драйв' }],
      style: { gridArea: 'c' },
    },
    { name: 'mark', component: SelectMarks },
    {
      name: 'model',
      component: SelectModels,
      dependency: 'mark',
      dependencyAccessor: (obj) => obj?.value,
    },
    {
      name: 'address',
      component: Select,
      label: 'Адрес дилерского центра',
      placeholder: 'Выберите из списка',
      style: { gridArea: 'd' },
    },
    {
      name: 'manager',
      component: Select,
      label: 'Ответственный менеджер',
      placeholder: 'Выберите из списка',
      style: { gridArea: 'f' },
    },
    {
      name: 'comment',
      label: 'Заметки/комментарии о записи',
      placeholder: 'Оставьте ваш комментарий',
      style: { gridArea: 'n' },
    },
  ]);

  const addRecord = (data) => {
    setLoading(true);
    const day = new Date(data.created_at).toLocaleDateString().split('.').reverse().join('-');
    const time = data?.time?.value;
    const payload = {
      sourceId: data?.sourceId?.value,
      markId: data?.mark?.value,
      modelId: data?.model?.value,
      date:
        day === 'Invalid Date'
          ? new Date().toLocaleDateString().split('.').reverse().join('-') + ' ' + time + ':00'
          : day + ' ' + time + ':00',
      typeId: data?.service?.value,
      userId: data?.userId?.value,
    };
    createRecord(payload)
      .then((res) => {
        setSucces({ message: 'Запись добавлена' });
        setTimeout(() => {
          navigate('/records');
        }, 1000);
      })
      .catch((error) => {
        alert.error(error.response.data.errorMessage ?? JSON.stringify(error.response))
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading && <Loader />}
      <div style={{ paddingBottom: '20px' }}>
        <Header title="Новая запись на тест-драйв" icon={icon} />
        <Breadcrumbs navigate={'/records'} link={'Записи'} currentPage={'Новая запись'} />
        <HookForm
          inputs={service}
          title={'Информация о записи'}
          handlerSetState={setService}
          grid={formStyles.record}
          border
          action={'Сохранить запись'}
          onSubmit={addRecord}
        />
      </div>
      {error.message && <Alert message={error.message} onClick={setError} />}
      {succes.message && <Alert message={succes.message} />}
    </>
  );
};

export default AddRecordTestdrive;
