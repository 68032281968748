import React from 'react';
import Select from '../Select/Select';

const options = [
  { value: 1, label: 'Мобильный' },
  { value: 2, label: 'Домашний' },
  { value: 3, label: 'Корпоративный' },
];

const SelectPhoneType = ({ ...props }) => {
  return <Select options={options} label={'Телефон'} placeholder={'Выберите из списка'} {...props} />;
};

export default SelectPhoneType;
