import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './payments.module.css';
import CardTemplate from './Template/CardTemplate';
import ConfirmPaymentTemplate from './Template/ConfirmPaymentTemplate';
const Payments = () => {
  const { id } = useParams();
  const [confirm, setConfirm] = React.useState(null);
  return (
    <>
      <div className={styles.payments}>
        <div className={styles.cardTemplateWrapper}>
          {confirm === null ? (
            <CardTemplate setConfirm={setConfirm} chunkId={id} />
          ) : (
            <ConfirmPaymentTemplate confirm={confirm} />
          )}
        </div>
      </div>
    </>
  );
};

export default Payments;
