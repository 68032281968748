import { useState } from 'react';
import getWdCar from '../../api/cars/getWdCar';
import Select from '../Select/Select';
import { PAYMENT_TYPES_OPTIONS } from '../../constants';

const PAYMENT_TYPES_PREPAYMENT_INVOICE = 4;
const PAYMENT_TYPES_PREPAYMENT_CARD = 5;
const SelectPaymentTypes = ({ handleSetSelect, handleSetState, style, dop, ...props }) => {
  return (
    <Select
      handleSetSelect={(val) => handleSetSelect && handleSetSelect(val)}
      handleSetState={handleSetState}
      options={
        dop
          ? [
              { value: PAYMENT_TYPES_PREPAYMENT_INVOICE, label: 'Предоплата (счет на оплату)' },
              { value: PAYMENT_TYPES_PREPAYMENT_CARD, label: 'Предоплата (оплата картой)' },
            ]
          : PAYMENT_TYPES_OPTIONS
      }
      placeholder={'Выберите из списка'}
      style={style}
      {...props}
    />
  );
};

export default SelectPaymentTypes;
