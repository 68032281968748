import { useEffect, useState } from 'react';
import getDocumentHistory from '../../api/documents/getDocumentHistory';
import { useParams } from 'react-router-dom';
import extractErrorMessage from '../../utils/extractErrorMessage';
import alert from '../../helpers/alert';
import LoaderInline from '../../components/Loader/LoaderInline';
import dayjs from 'dayjs';
import DownloadFileLink from '../../components/DownloadFileLink/DownloadFileLink';
import { BANKS } from '../../constants';

const History = () => {
  const [document, setDocument] = useState(null);
  const [documentHistory, setDocumentHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const { documentId } = useParams();

  const init = async () => {
    setLoading(true);
    try {
      if (window.location.hostname === 'test-broker.doscar.kz') {
        const currentUrl = window.location.href;
        const redirectUrl = currentUrl.replace('test-broker', 'broker');
        window.location = redirectUrl;
      }

      const { data } = await getDocumentHistory(documentId);

      //редирект на пдф для фридома
      if (data?.document?.serialNumberId === BANKS.FREEDOM) {
        return window.location = data?.document?.url;
      }

      data.history = data?.history?.map((item) => ({
        ...item,
        createdAt: dayjs(item?.createdAt).format('DD.MM.YYYY hh:mm:ss'),
      }));

      setDocumentHistory(data?.history);
      setDocument(data?.document);
    } catch (e) {
      alert.error(extractErrorMessage(e?.response?.data) || 'Ошибка при получении документа');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [documentId]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '90vh',
        }}
      >
        <LoaderInline />
      </div>
    );
  }

  return (
    <div
      style={{
        maxWidth: 1200,
        margin: '0 auto',
        padding: 20,
      }}
    >
      <h2 style={{ fontSize: 20, marginBottom: 20 }}>Проверка подленности документа</h2>
      <DownloadFileLink document={document} />
      <ul
        style={{
          listStyle: 'decimal',
          paddingLeft: 15,
        }}
      >
        {documentHistory?.map((item) => (
          <li style={{ marginBottom: 10 }}>
            <strong>{item?.createdAt}</strong> - <span>{item?.message}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default History;
