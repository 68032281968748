import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';

// components
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import Breadcrumbs from '../../components/Breadcrumbs';

// icons
import leads from '../../assets/sidebar/deals.svg';
import getDealItemsCount from '../../api/deals/getDealItemsCount';
import getDealCard from '../../api/deals/getDealCard';
import DealCreate from './DealCreate/DealCreate';
import getDealCars from '../../api/deals/getDealCars';
import getDealEquipments from '../../api/deals/getDealEquipments';
import getDealServices from '../../api/deals/getDealServices';
import getDealDocuments from '../../api/deals/getDealDocuments';
import Loader from '../../components/Loader/Loader';
import DealCreateSellEquipment from './DealCreate/SellEquipment/DealCreateSellEquipment';
import { DOCUMENT_TYPES_PAYMENT_ORDER } from '../../constants';

const DealNavigation = ({ to }) => {
  const { id } = useParams();
  const [equipmentsCount, setEquipmentsCount] = useState({});

  const { pathname } = useLocation();
  const [items, setItems] = useState(
    pathname != '/deals/create'
      ? [
          { text: 'Основная информация', navigate: id ? `/deals/deal/${id}/main-info` : '/deals/create' },
          { text: 'Автомобили', navigate: id ? `/deals/deal/${id}/autos` : '/deals/autos', count: 0 },
          {
            text: 'Доп.оборудование',
            navigate: id ? `/deals/deal/${id}/equipments` : '/deals/equipments',
            count: 0,
            disabled: true,
          },
          {
            text: 'Доп.услуги',
            navigate: id ? `/deals/deal/${id}/services` : '/deals/services',
            count: 0,
            disabled: true,
          },
          { text: 'Документы', navigate: id ? `/deals/deal/${id}/documents` : '/deals/documents', count: 0 },
          { text: 'Банки', navigate: id ? `/deals/deal/${id}/credit` : '/deals/documents', count: 0, disabled: true },
        ]
      : [{ text: 'Основная информация', navigate: id ? `/deals/deal/${id}/main-info` : '/deals/create' }]
  );

  // useEffect(() => {
  //   setItems(
  //     pathname != '/deals/create'
  //       ? [
  //           { text: 'Основная информация', navigate: id ? `/deals/deal/${id}/main-info` : '/deals/create' },
  //           { text: 'Автомобили', navigate: id ? `/deals/deal/${id}/autos` : '/deals/autos', count: 0 },
  //           { text: 'Доп.оборудование', navigate: id ? `/deals/deal/${id}/equipments` : '/deals/equipments', count: 0, disabled: true },
  //           { text: 'Доп.услуги', navigate: id ? `/deals/deal/${id}/services` : '/deals/services', count: 0, disabled: true },
  //           { text: 'Документы', navigate: id ? `/deals/deal/${id}/documents` : '/deals/documents', count: 0 },
  //           { text: 'Банки', navigate: id ? `/deals/deal/${id}/credit` : '/deals/documents', count: 0, disabled: true  },
  //         ]
  //       : [{ text: 'Основная информация', navigate: id ? `/deals/deal/${id}/main-info` : '/deals/create' }]
  //   );
  // }, [id]);

  const init = async () => {
    await getDealItemsCount({ id: id }).then((res) => {
      console.log(items);
      if (res.data) {
        const newItems =
          pathname != '/deals/create'
            ? [
                { text: 'Основная информация', navigate: id ? `/deals/deal/${id}/main-info` : '/deals/create' },
                { text: 'Автомобили', navigate: id ? `/deals/deal/${id}/autos` : '/deals/autos', count: 0 },
                {
                  text: 'Доп.оборудование',
                  navigate: id ? `/deals/deal/${id}/equipments` : '/deals/equipments',
                  count: 0,
                  disabled: true,
                },
                {
                  text: 'Доп.услуги',
                  navigate: id ? `/deals/deal/${id}/services` : '/deals/services',
                  count: 0,
                  disabled: true,
                },
                { text: 'Документы', navigate: id ? `/deals/deal/${id}/documents` : '/deals/documents', count: 0 },
                {
                  text: 'Банки',
                  navigate: id ? `/deals/deal/${id}/credit` : '/deals/documents',
                  count: 0,
                  disabled: true,
                },
              ]
            : [{ text: 'Основная информация', navigate: id ? `/deals/deal/${id}/main-info` : '/deals/create' }];
        newItems[1].count = res.data.cars;
        newItems[2].count = res.data.equipment;
        newItems[3].count = res.data.additionalService;
        newItems[4].count = res.data.document;
        newItems[5].count = res.data.banks;
        equipmentsCount.equipments = res.data.equipment;
        equipmentsCount.services = res.data.additionalService;
        setEquipmentsCount(equipmentsCount);

        if (res.data.cars > 0) {
          newItems[2].disabled = false;
          newItems[3].disabled = false;
          newItems[5].disabled = false;
        }

        setItems(newItems);
      }
    });
  };

  const [deal, setDeal] = useState(null);
  const [cars, setCars] = useState(null);
  const [equipments, setEquipments] = useState(null);
  const [services, setServices] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [filteredDocuments, setFilteredDocuments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  React.useEffect(() => {
    init();
    update();
  }, [id]);

  const update = async () => {
    setLoading(true);
    await getDealCard({ id }).then((res) => {
      setDeal(res?.data);
    });
    await getDealCars({ id }).then((res) => {
      setCars(res?.data?.cars);
    });
    await getDealEquipments({ id }).then((res) => {
      setEquipments(
        res?.data?.map((item) => ({
          ...item,
          title: item?.name,
        }))
      );
    });
    await getDealServices({ id }).then((res) => {
      setServices(
        res?.data?.map((item) => ({
          ...item,
          title: item?.name,
        }))
      );
    });
    await getDealDocuments({ id }).then((res) => {
      setDocuments(res?.data);
    });
    setLoading(false);
    setLoadingPage(false);
  };

  useEffect(() => {
    if (!deal?.json) return;
    const alwaysAllowedDocuments = [DOCUMENT_TYPES_PAYMENT_ORDER];
    try {
      const allowedDocuments = JSON.parse(deal?.json)?.allowed_documents;

      const filteredDocuments = documents?.filter((item) => {
        return [...alwaysAllowedDocuments, ...allowedDocuments]?.includes(item?.type?.id);
      });

      setFilteredDocuments(filteredDocuments);
    } catch (e) {
      setFilteredDocuments(alwaysAllowedDocuments);
    }
  }, [deal, documents]);

  if (loadingPage) return <Loader />;

  if (deal?.only_dop && deal?.step != 10) {
    return (
      <DealCreateSellEquipment
        deal={deal}
        cars={cars}
        equipments={equipments}
        services={services}
        documents={documents}
        update={update}
        loading={loading}
        setLoading={setLoading}
      />
    );
  }

  if (deal?.step != 10) {
    return (
      <DealCreate
        deal={deal}
        cars={cars}
        equipments={equipments}
        services={services}
        documents={filteredDocuments}
        update={update}
        loading={loading}
        setLoading={setLoading}
      />
    );
  }

  return (
    <>
      <Header title={id ? `Сделка ID ${id}` : 'Новая сделка'} icon={leads} />
      <Breadcrumbs navigate={'/deals'} link={'Сделки'} currentPage={id ? `Сделка ID ${id}` : 'Новая сделка'} />
      <Navbar items={items} />
      <Outlet context={[equipmentsCount, init, deal]} />
    </>
  );
};

export default DealNavigation;
