import api from '../../plugins/api';

const editClientDocuments = ({ born, document_number, document_date, document_issuer, address, document_type_id, document_date_end, id }) => {
  return api.post('/api/setDocument', {
    id,
    born,
    document_number,
    document_date,
    document_issuer,
    address,
    document_type_id, 
    document_date_end,
  });
};

export default editClientDocuments;
