import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

// components
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import Breadcrumbs from '../../components/Breadcrumbs';

// icons
import leads from '../../assets/sidebar/cars.svg';

const CarNavigation = () => {
  const { id } = useParams();
  const { state } = useLocation();

  const [items, setItems] = useState([
    { text: 'Основные данные', navigate: id ? `/cars/car/${id}/main-data` : '/cars/create' },
    { text: 'Доп оборудование', navigate: id ? `/cars/car/${id}/equipments` : '/cars/create' },
    { text: 'Документы', navigate: id ? `/cars/car/${id}/documents` : '/cars/documents' },
    { text: 'Сделки', navigate: id ? `/cars/car/${id}/deals` : '/cars/deals' },
  ]);

  return (
    <>
      <Header title={id ? `ID ${id}` : 'Новый автомобиль'} icon={leads} />
      <Breadcrumbs
        navigate={'/cars'}
        link={'Автомобили'}
        currentPage={id ? `ID ${id}` : 'Новый автомобиль'}
      />
      <Navbar items={items} />
      <Outlet />
    </>
  );
};

export default CarNavigation;
