import React from 'react';
import styles from './loader.module.css';

const LoaderInline = () => {
  return (
    <div className={styles.wrapperInline}>
      <div className={`${styles.loader} ${styles.loaderInline}`}>
        <span></span>
      </div>
    </div>
  );
};

export default LoaderInline;
