import api from '../../plugins/api';

const uploadDocumentToDeal = ({ dealId, fileTypeId, file, statusId = 1, signed = false }) => {
  const formData = new FormData();
  formData.append('dealId', dealId);
  formData.append('fileTypeId', fileTypeId);
  formData.append('file', file);
  formData.append('statusId', statusId);
  formData.append('signed', signed);

  return api.post('/api/crm/deals/uploadDocumentToDeal', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export default uploadDocumentToDeal;