import Input from '../../Inputs/Input/Input';
import styles from './chatPersonal.module.css';

import send from '../../../assets/chat/send-plane-line.svg';
import attach from '../../../assets/chat/attach.svg';
import Button from '../../Button/Button';

const ChatInput = () => {
  return (
    <div className={styles.ChatInput}>
      <div className={styles.ChatInput__wrapper}>
        <input className={styles.ChatInput__input} placeholder="Начните писать сообщение"/>
        <img src={send} className={styles.ChatInput__send} />
      </div>
      <Button
        fill
        style={{
          padding: 10,
          lineHeight: 0,
        }}
      >
        <img src={attach} />
      </Button>
    </div>
  );
};

export default ChatInput;
