import ChatUserAvatar from '../ChatUserAvatar';
import styles from './chatPersonal.module.css';

const ChatMessage = () => {
    return(
        <div className={styles.ChatMessage}>
            <ChatUserAvatar style={{width: 32, height: 32}}/>
            <div className={styles.ChatMessage__message}>
                <p>
                Значимость этих проблем настолько очевидна, что высококачественный прототип будущего проекта обеспечивает актуальность распределения внутренних резервов и ресурсов. 
                </p>
                <span>15:32</span>
            </div>
        </div>
    )
}

export default ChatMessage;