import HookForm from '../../../../components/HookForm/HookForm';
import DocsInputNew from '../../../../components/Inputs/DocsInput/DocsInputNew';
import Select from '../../../../components/Select/Select';
import { DEAL_STATUS_WAITING_FOR_HALYK, DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS } from '../../../../constants';
import { textStyles } from '../../../../constants/textStyles';

const HalykCarRegistration = ({ onSubmit, loading }) => {
  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <HookForm
          title={'Регистрация ТС'}
          grid={{ display: 'grid', gap: 20 }}
          action={'Сохранить и продолжить'}
          inputs={[
            {
              label: 'Статус сделки',
              name: 'status',
              component: Select,
              options: [
                {
                  value: DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
                  label: 'Ожидает постановки ТС на учет',
                },
                {
                  value: DEAL_STATUS_WAITING_FOR_HALYK,
                  label: 'Машина поставлена на учет',
                },
              ],
              required: true,
            },
          ]}
          onSubmit={onSubmit}
          loading={loading}
        />
      </div>
    </>
  );

  //now only changing status
  return (
    <div>
      <HookForm
        title={'Документы'}
        grid={{ display: 'grid', gap: 20 }}
        action={'Сохранить и продолжить'}
        inputs={[
          {
            label: 'ГРНЗ',
            name: 'ГРНЗ',
            id: 'ГРНЗ',
            rules: {
              required: true,
            },
          },
          {
            text: 'Техпаспорт',
            label: 'Загрузить документ',
            name: 'Техпаспорт',
            id: 'Техпаспорт',
            component: DocsInputNew,
            rules: {
              required: true,
            },
          },
        ]}
        onSubmit={onSubmit}
        loading={loading}
      />
    </div>
  );
};

export default HalykCarRegistration;
