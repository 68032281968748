import React from 'react';
import styles from './deleteButton.module.css';

// icons
import cart from '../../assets/buttonIcons/delete.svg';

const DeleteButton = ({ onClick, style = {} }) => {
  return (
    <button className={styles.button} style={style} onClick={onClick}>
      <img src={cart} alt="Icon" />
    </button>
  );
};

export default DeleteButton;
