import api from '../../plugins/api';

/**
 * Adds items to a deal.
 *
 * @param {Object} data
 * @param {Number} data.dealId
 * @param {Array} data.cars
 * @param {Number} data.clientId
 * @param {Array} data.equipments
 * @param {Array} data.services
 * @param {Number} data.step
 *
 * @returns {Promise} - A Promise that resolves to the result of the API call.
 */
const addItemsToDeal = ({cars,clientId,equipments, dealId, services, step}) => {
  return api.post('/api/crm/deals/addItemsToDeal', {
    dealId,
    cars,
    clientId,
    equipments,
    services,
    step,
  });
};

export default addItemsToDeal;
