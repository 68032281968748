import React, { useState } from 'react';

// components
import Form from '../../../components/Form/Form';

const formStyles = {
  document: { display: 'grid', gap: '20px', gridTemplateColumns: `auto 1fr auto 44px` },
};

const NewDealDocs = () => {
  const [document, setDocument] = useState({
    form: [
      [
        { type: 'select', label: 'Тип документа', placeholder: 'Выберите из списка' },
        { type: 'input', label: 'Название документа', placeholder: 'Например, “Договор купли-продажи Бекпулатов”' },
        { type: 'document', text: 'Файл документа', label: 'Загрузить документ' },
        { type: 'delete' },
      ],
    ],
  });

  const handleAddForm = () => {
    setDocument((prev) => ({
      ...prev,
      form: [...prev.form, prev.form[0]],
    }));
  };

  const handleDeleteForm = (e, formIndex) => {
    e.preventDefault();
    if (document.form.length > 1) {
      const filteredArray = document.form.filter((item, idx) => idx !== formIndex);
      setDocument((prev) => ({
        ...prev,
        form: filteredArray,
      }));
    }
  };

  return (
    <div style={{ padding: '10px 20px', backgroundColor: 'white', marginTop: '20px', border: '1px solid #E0E0E0' }}>
      {document.form.map((item, index) => (
        <Form
          key={index}
          inputs={document.form[index]}
          title={index === 0 ? 'Документы' : false}
          grid={formStyles.document}
          addFormButton={index === document.form.length - 1 ? 'Добавить еще документ к сделке' : false}
          addForm={handleAddForm}
          deleteForm={(e) => handleDeleteForm(e, index)}
        />
      ))}
    </div>
  );
};

export default NewDealDocs;
