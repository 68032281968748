import React, { useState, useEffect } from 'react';
import createModel from '../../../api/cars/createModel';
import { useNavigate } from 'react-router-dom';

// components
import Form from '../../../components/Form/Form';
import Header from '../../../components/Header/Header';

// icons
import icon from '../../../assets/sidebar/cars.svg';

const formStyles = {
  marks: { display: 'grid', gap: '20px', gridTemplateColumns: `1fr 1fr` },
};

const CarModelsCreate = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState([
    { type: 'selectMarks', value: undefined },
    { type: 'input', value: undefined, label: 'Модель', placeholder: 'Укажите модель' },
  ]);

  const handleSubmit = () => {
    if (!form[0].value || !form[1].value) return;

    const mark = form[0].value;
    let model = form[1].value;
    model = model.charAt(0).toUpperCase() + model.slice(1);
    createModel({ markId: mark, name: model })
      .then(() => navigate('/cars/models'))
      .catch((err) => alert(err));
  };

  return (
    <>
      <Header title={'Новая модель'} icon={icon} />
      <div style={{ paddingBottom: '20px' }}>
        <Form
          inputs={form}
          handlerSetState={setForm}
          title={'Марка и модель автомобиля'}
          grid={formStyles.marks}
          border
          action={'Сохранить'}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

export default CarModelsCreate;
