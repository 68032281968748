import api from '../../plugins/api';

const getComplectations = (params) => {
  return api.get('/api/shop/getComplectations', {
    ...params,
    all: true,
  });
};

export default getComplectations;
