import React from 'react';
import MaskedInput from 'react-text-mask';
import styles from './auth.module.css';
import Cookies from 'universal-cookie';
import login from '../../api/auth/login';

// icons
import logo from '../../assets/authLogo.svg';
import eye from '../../assets/input/eye.svg';

import { phone } from '../../constants/mask';

const Auth = () => {
  const cookies = new Cookies();
  const [auth, setAuth] = React.useState({
    phone: '',
    password: '',
  });
  const [showPassword, setShowPassword] = React.useState(false);

  const signIn = async (e) => {
    e.preventDefault();
    await login(auth)
      .then((res) => {
        cookies.set('access_token', res.data.access_token, { path: '/' });
        window.location.href = window.location.origin;
      })
      .catch((error) => {});
  };

  return (
    <div className={styles.background}>
      <h1>Car Market Place (Бэк-офис)</h1>
      <div className={styles.form}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <form onSubmit={signIn}>
          <div>
            <MaskedInput
              onChange={(e) =>
                setAuth({
                  ...auth,
                  phone: e.target.value.replace(/[^\d]/g, ''),
                })
              }
              placeholder="+7(___)___-__-__"
              mask={phone}
            />
          </div>
          <div className={styles.midInput}>
            <input
              onChange={(e) => setAuth({ ...auth, password: e.target.value })}
              placeholder="Пароль"
              type={showPassword ? 'text' : 'password'}
            />
            <a style={{ cursor: 'pointer' }} onClick={() => setShowPassword((prev) => !prev)}>
              <img src={eye} alt="Icon" className={styles.icon} />
            </a>
          </div>
          <button className={styles.login}>Войти</button>
        </form>
      </div>
    </div>
  );
};

export default Auth;
