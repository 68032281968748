import React from 'react';
import styles from './input.module.css';
import MaskedTextInput from 'react-text-mask';
import { colors } from '../../constants/colors';
const Input = ({ title, label, placeholder, type, required, style, handleSetState, mask, error, ...props }) => {
  return (
    <label style={style} className={styles.customInput}>
      <p>
        {title || label}
        {required === true ? '*' : ''}
      </p>
      {mask ? (
        <MaskedTextInput
          type="text"
          placeholder={placeholder}
          onChange={(event) => {
            handleSetState(event.target.value.replace(/[^\d]/g, ''));
          }}
          mask={mask}
          style={error ? {color: colors.red} : {}}
          {...props}
        />
      ) : (
        <input
          onChange={(event) => handleSetState(event.target.value)}
          placeholder={placeholder}
          type={type ? type : 'text'}
          style={error ? {borderColor: colors.red} : {}}
          {...props}
        />
      )}
    </label>
  );
};

export default Input;
