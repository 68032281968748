import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header/Header';
import icon from '../../../assets/sidebar/report.svg';
import styles from './permissions.module.css';
import Button from '../../../components/Button/Button';
import PermissionRow from './PermissionRow';
import getRoles from '../../../api/roles/getRoles';
import editRoles from '../../../api/roles/editRoles';
import { useDispatch } from 'react-redux';
import { setPermissions as setUserPermissions } from '../../../store/user';
import alert from '../../../helpers/alert';
import LoaderInline from '../../../components/Loader/LoaderInline';

const Permissions = () => {
  const [state, setState] = useState({});
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onSave = () => {
    setLoading(true);

    Promise.all(
      Object.keys(state).map((roleId) => {
        return editRoles({
          roleId,
          options: Object.keys(state[roleId]).map((permissionId) => ({
            id: permissionId,
            active: state[roleId][permissionId].value == 'Да',
          })),
        });
      })
    )
      .then(() => {
        getRoles().then((res) => {
          dispatch(setUserPermissions(res.data));
        });
        alert.success('Роли успешно обновлены');
      })
      .catch(() => {
        alert.error('Ошибка при обновлении ролей');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getRoles().then((res) => {
      setRoles(res.data);
      setPermissions(res.data[0].options);
    });
  }, []);

  const handleChange = (role, permission, value) => {
    setState({
      ...state,
      [role]: {
        ...state[role],
        [permission]: value,
      },
    });
  };

  return (
    <>
      <Header title="Настройка ролей" icon={icon} />

      {loading ? (
        <LoaderInline />
      ) : (
        <>
          <table className={styles.table}>
            <thead>
              <tr className={styles.cell}>
                <th className={styles.cell}>Роль</th>
                {Object.keys(permissions).map((permission) => (
                  <th className={styles.cell} key={permission}>
                    {permissions[permission].title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {roles.map((role) => (
                <PermissionRow
                  key={role}
                  role={role}
                  state={state}
                  permissions={permissions}
                  handleChange={handleChange}
                />
              ))}
            </tbody>
          </table>

          <div className={styles.right}>
            <Button fill text="Сохранить" onClick={onSave} />
          </div>
        </>
      )}
    </>
  );
};

export default Permissions;
