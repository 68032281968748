import styles from './chatPersonal.module.css';
import ChatInput from './ChatInput';
import ChatHistory from './ChatHistory';
import ChatUser from '../ChatUser';
const ChatPersonal = () => {
  return (
    <div className={styles.ChatPersonal}>
      <div className={styles.ChatPersonal__header}>
        <ChatUser />
      </div>
      <ChatHistory />
      <ChatInput />
    </div>
  );
};

export default ChatPersonal;
