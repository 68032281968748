import React, { useCallback, useEffect, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { CARS } from '../../constants/columns';
import { useOutletContext, useParams } from 'react-router-dom';
import { RiErrorWarningLine } from 'react-icons/ri';
import styles from './deal.module.css';

// components
import EmptyData from '../../components/EmptyData/EmptyData';
import Loader from '../../components/Loader/Loader';

// icons
import icon from '../../assets/buttonIcons/addDeal.svg';
import getDealCars from '../../api/deals/getDealCars';
import SelectCarModal from '../../components/Modals/SelectCarModal';
import addItemsToDeal from '../../api/deals/addItemsToDeal';
import getDealCard from '../../api/deals/getDealCard';
import getCreditAppeal from '../../api/deals/getCreditAppeal';
import formatNumber from '../../utils/formatNumber';
import { textStyles } from '../../constants/textStyles';
import { BANK_CREDIT_ACCEPTED, BANK_CREDIT_REJECTED, BANK_CREDIT_STATUSES } from '../../constants/bankCreditStatuses';
import Button from '../../components/Button/Button';
import initialFeeCreditCardPayment from '../../api/deals/initialFeeCreditCardPayment';
import Alert from '../../components/Alert/Alert';
import extractErrorMessage from '../../utils/extractErrorMessage';
import {
  BANKS,
  DOCUMENT_TYPES_INVOICE,
  INSURANCE_SERVICE_ID_2_5,
  INSURANCE_SERVICE_ID_3,
  INSURANCE_SERVICE_ID_3_5,
  PAYMENT_TYPES_CREDIT_CARD,
  PAYMENT_TYPES_CREDIT_INVOICE,
} from '../../constants';
import initialFeeCheck from '../../api/deals/check';
import generateCheck from '../../api/deals/generateCheck';
import selectCreditAppeal from '../../api/creditPlatform/selectCreditAppeal';
import getDealDocuments from '../../api/deals/getDealDocuments';
import alert from '../../helpers/alert';
import LoaderInline from '../../components/Loader/LoaderInline';
import cancelCreditAppeal from '../../api/creditPlatform/forte/cancelCreditAppeal';
import checkBankStatus from '../../api/bank/checkStatusAppeal';
import CheckBankStatus from './DealCreate/CommonBankSteps/CheckBankStatus';
import DealConfigureSteps from './DealConfigureSteps';
import removeItemsToDeal from '../../api/deals/removeItemsToDeal';

const columns = CARS;

const getMock = (appeal) => ({
  ...appeal,
  statusId: BANK_CREDIT_ACCEPTED,
  bank: {
    id: BANKS.TEST,
    name: 'Test Bank',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg',
    body: '',
    api: 'http://localhost:8003',
  },
});

const PrintStyles = () => (
  <style>
    {`
      #printableArea {
        display: none;
      }
      @media print {
        body {
          visibility: hidden;
        }

        #sidebar {
          display: none;
        }
        #DealCreateForm {
          position: static;
        }

        #printableArea {
          display: block;
          visibility: visible;
          position: absolute;
          left: 0;
          top: 0;
        }
        #printableArea button {
          display: none;
        }
      }
    `}
  </style>
);

const DealCredits = ({ dealId, deal: data, onSubmit, documents, backHandler, services }) => {
  const context = useParams();
  const id = context?.id || dealId;
  const [cars, setCars] = useState([]);
  let [equipmentsCount, updateCounters, deal] = useOutletContext() || [];
  deal = deal || data;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [appeals, setAppeals] = useState([]);
  const [succes, setSucces] = useState({});
  const [subsidy, setSubsidy] = useState(null);
  const [chosenBankId, setChosenBankId] = useState(null);

  useEffect(() => {
    try {
      const newSubsidy = JSON.parse(deal?.creditAppel?.json)?.subsidy;

      setSubsidy(newSubsidy);
    } catch (e) {}
  }, [deal]);

  const init = async () => {
    const { data } = await getDealCard({ id });

    // try {
    //   await checkBankStatus({ chunkId: data?.chunkId, bankId: BANKS.FORTE })
    // } catch (e) {
    //   alert.error('Ошибка при обновлении статуса заявки Forte банка');
    // }

    getCreditAppeal({ chunkId: data?.chunkId })
      .then((res) => {
        // const chosenBank = res?.data?.appeals?.find((appeal) => appeal?.chosen);
        // if (chosenBank) {
        //   return setAppeals([chosenBank]);
        // }

        // if (res.data?.appeals?.find((item) => item?.selected)) {
        //   return setAppeals([res.data?.appeals?.find((item) => item?.selected), getMock(res.data?.appeals[3])]);
        // }
        const chosenBank = res?.data?.appeals?.find((appeal) => appeal?.selected);
        setChosenBankId(chosenBank?.bank?.id);
        setAppeals(res.data?.appeals);
      })
      .finally(() => setLoadingPage(false));
  };

  React.useEffect(() => {
    init();
  }, []);

  const handeSubmit = async (cars) => {
    setLoading(true);
    try {
      cars = cars.map(({ value }) => ({ value }));

      const { data } = await getDealCard({ id });

      addItemsToDeal({
        dealId: String(id),
        clientId: data?.client?.id,
        cars,
      })
        .then(() => {
          setShowModal(false);
          updateCounters();
          init();
        })
        .catch((err) => alert(err))
        .finally(() => setLoading(false));
    } catch (e) {
      setLoading(false);
    }
  };

  const table = useTable(
    {
      columns,
      data: cars,
      disableSortRemove: true,
    },
    usePagination
  );

  const cardPayment = (appeal) => {
    setLoadingPage(true);
    initialFeeCreditCardPayment({
      bankId: appeal?.bank?.id,
      chunkId: deal?.chunkId,
    })
      .then(() => {
        setSucces({ message: 'Смс со ссылкой на оплату отправлено на номер телефона клиента' });
        onSubmit();
      })
      .catch((err) => {
        if (extractErrorMessage(err?.response?.data) == 'Заказ с указанным external_id уже существует') {
          return onSubmit();
        }
        setSucces({ message: extractErrorMessage(err?.response?.data) });
      })
      .finally(() => setLoadingPage(false));
  };
  const getInvoice = (appeal) => {
    getDealDocuments({ id: deal?.id })
      .then((res) => {
        setLoadingPage(true);
        const url = res?.data?.find((item) => item?.typeId === DOCUMENT_TYPES_INVOICE);
        if (url) {
          window.open(url?.url, '_blank');
          onSubmit();
        }
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  const removeInsuranceFromDeal = async () => {
    const insuranceServices = services.filter(service => [INSURANCE_SERVICE_ID_2_5, INSURANCE_SERVICE_ID_3, INSURANCE_SERVICE_ID_3_5].includes(service.additionalServicesId));

    if(insuranceServices?.length == 0) {
      return;
    }

    for (const service of insuranceServices) {
      await removeItemsToDeal({
        services: [{ value: service.id }],
        dealId: deal?.id,
      });
    }
  }

  const chooseBank = async (appeal, offerType) => {
    try {
      setLoadingPage(true);

      if(appeal?.bank?.id !== BANKS.FORTE) {
        await removeInsuranceFromDeal();
      }

      await selectCreditAppeal({ appealId: appeal?.id, offerType });
      if (appeal?.bank?.id !== BANKS.FORTE) {
        const forteAppeal = appeals.find((appeal) => appeal?.bank?.id === BANKS.FORTE);
        if (forteAppeal) {
          cancelCreditAppeal(forteAppeal?.id);
        }
      }
      await generateCheck({
        bankId: appeal.bankId,
        chunkId: data.chunkId,
      });

      onSubmit(appeal?.bankId);
    } catch (e) {
      setLoadingPage(false);
      alert.error(e?.response?.data?.message || 'Ошибка при выборе банка');
    }
  };

  const updateAppeals = () => {
    setLoadingPage(true);
    init();
  };

  const printForClient = () => {
    window.print();
  };

  return (
    <>
      <div className={dealId ? '' : styles.credit}>
        <div className={styles.credit__main_info}>
          <div className="deal__card-title">
            <h3
              className={styles.title}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Ответы от банков
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
                onClick={updateAppeals}
              >
                <path
                  d="M5.463 4.43301C7.27756 2.86067 9.59899 1.99666 12 2.00001C17.523 2.00001 22 6.47701 22 12C22 14.136 21.33 16.116 20.19 17.74L17 12H20C20.0001 10.4316 19.5392 8.89781 18.6747 7.58927C17.8101 6.28072 16.5799 5.25517 15.1372 4.64013C13.6944 4.0251 12.1027 3.84771 10.56 4.13003C9.0172 4.41234 7.59145 5.14191 6.46 6.22801L5.463 4.43301ZM18.537 19.567C16.7224 21.1393 14.401 22.0034 12 22C6.477 22 2 17.523 2 12C2 9.86401 2.67 7.88401 3.81 6.26001L7 12H4C3.99987 13.5684 4.46075 15.1022 5.32534 16.4108C6.18992 17.7193 7.42007 18.7449 8.86282 19.3599C10.3056 19.9749 11.8973 20.1523 13.44 19.87C14.9828 19.5877 16.4085 18.8581 17.54 17.772L18.537 19.567Z"
                  fill="#D9BC92"
                />
              </svg>
              <PrintStyles />
            </h3>
          </div>
          {/* {appeals[0]?.orders?.map((order) => {
                  return (
                    <p style={textStyles.fieldLabel}>
                      {order?.mark?.name} {order?.model?.name} {order?.complectation} / {order?.issueYear}
                    </p>
                  );
                })} */}
          {/* <p style={textStyles.fieldLabel}>Стоимость, тенге: {formatNumber(appeals[0]?.fullPrice)}</p>
                <p style={textStyles.fieldLabel}>
                  Запрашиваемый первоначальный взнос, тенге: {formatNumber(appeals[0]?.initialFee)}
                </p>
                <p style={textStyles.fieldLabel}>
                  Запрашиваемый срок кредитования: {formatNumber(appeals[0]?.period)} м.
                </p> */}
        </div>
        {loadingPage ? (
          <LoaderInline />
        ) : (
          appeals.map((item) => {
            return (
              <div className={styles.bank}>
                <div className={styles.bank__row}>
                  <div className={styles.bank__img}>
                    <img src={item?.bank?.logo} />
                  </div>
                  <p className={styles.bank_name}>{item?.bank?.name}</p>
                  <p
                    className={`${styles.status} ${item?.statusId == BANK_CREDIT_ACCEPTED && styles.status__accepted} ${
                      item?.statusId == BANK_CREDIT_REJECTED && styles.status__rejected
                    }`}
                  >
                    {BANK_CREDIT_STATUSES[item?.statusId]}
                  </p>
                </div>
                <div style={{ padding: '0 20px' }}>
                  {item?.bank?.id == BANKS.HALYK ? (
                    <p className={styles.sub} style={{ marginBottom: 5 }}>
                      Субсидия: {subsidy ? 'Да' : 'Нет'}
                    </p>
                  ) : null}
                  <p className={styles.sub} style={{ marginBottom: 5 }}>
                    bankUniqueId: {item?.bankUniqueId}
                  </p>
                  <p className={styles.sub} style={{ marginBottom: 5 }}>
                    bankOwnerId: {item?.bankOwnerId}
                  </p>
                </div>
                {item?.errorMessage ? (
                  <div style={{ padding: '0 20px', marginBottom: 20 }}>
                    <p className={styles.sub} style={{ color: '#EE7171' }}>
                      {item?.errorMessage}
                    </p>
                  </div>
                ) : (
                  <div style={{ padding: '0 20px', marginTop: 20, marginBottom: 20 }}>
                    <CheckBankStatus
                      bankId={item?.bankId}
                      onSuccess={updateAppeals}
                      chunkId={deal?.chunkId}
                      setLoading={setLoadingPage}
                    />
                  </div>
                )}
                {(item?.statusId == BANK_CREDIT_ACCEPTED) && (
                  <>
                    <div>
                      <p className={styles.sub}>Клиенту одобрен кредит на следующих условиях:</p>
                      {item?.other_conditions?.length ? (
                        item?.other_conditions?.map((condition) => {
                          return (
                            <>
                              <div style={{ background: '#F7F7F7', padding: '10px 20px', marginBottom: 20 }}>
                                <div className={styles.bank__info} style={{ marginBottom: 20 }}>
                                  <div
                                    className={styles.bank__info_row}
                                    style={{
                                      gridColumn: '1 / span 2',
                                    }}
                                  >
                                    <p className={styles.bank__info_title}>Сумма займа</p>
                                    <p className={styles.bank__info_sub}>{formatNumber(condition?.amount)}</p>
                                  </div>
                                  <div className={styles.bank__info_row}>
                                    <p className={styles.bank__info_title}>Первоначальный взнос, тенге</p>
                                    <p className={styles.bank__info_sub}>
                                      {formatNumber(condition?.initialFee)}
                                    </p>
                                  </div>
                                  {condition?.kasko_amount || item?.kasko_amount ? (
                                    <div className={styles.bank__info_row}>
                                      <p className={styles.bank__info_title}>Страхование КАСКО</p>
                                      <p className={styles.bank__info_sub}>
                                        {condition?.initialFee
                                          ? formatNumber(condition?.kasko_amount)
                                          : formatNumber(item?.kasko_amount)}
                                      </p>
                                    </div>
                                  ) : null}
                                  {condition?.ns_amount || item?.ns_amount ? (
                                    <div className={styles.bank__info_row}>
                                      <p className={styles.bank__info_title}>Страхование НС</p>
                                      <p className={styles.bank__info_sub}>
                                        {condition?.initialFee
                                          ? formatNumber(condition?.ns_amount)
                                          : formatNumber(item?.ns_amount)}
                                      </p>
                                    </div>
                                  ) : null}

                                  <div className={styles.bank__info_row}>
                                    <p className={styles.bank__info_title}>Срок кредитования:</p>
                                    <p className={styles.bank__info_sub}>{condition?.term} м.</p>
                                  </div>
                                  <div className={styles.bank__info_row}>
                                    <p className={styles.bank__info_title}>Процентная ставка (годовых):</p>
                                    <p className={styles.bank__info_sub}>{condition?.rate}%</p>
                                  </div>
                                  <div className={styles.bank__info_row}>
                                    <p className={styles.bank__info_title}>Ежемесячный платеж, тенге:</p>
                                    <p className={styles.bank__info_sub}>{formatNumber(condition?.payment)}</p>
                                  </div>
                                </div>

                                {condition?.conditions?.length ? (
                                  <>
                                    <div
                                      style={{
                                        display: 'grid',
                                        gap: 10,
                                        gridTemplateColumns: '20px 1fr',
                                        marginBottom: 20,
                                      }}
                                    >
                                      <RiErrorWarningLine height={20} width={20} color="#D9BC92" />
                                      <p
                                        style={{
                                          color: '#05141F',
                                          fontSize: 14,
                                          fontWeight: 600,
                                        }}
                                      >
                                        Вы можете выбрать этот вариант в случае погашения кредитов по следующим
                                        договорам:
                                      </p>
                                    </div>
                                    {condition?.conditions?.map((openedOffer) => {
                                      return (
                                        <>
                                          <div
                                            className={styles.bank__info}
                                            style={{ background: '#fff', padding: 10, marginBottom: 20 }}
                                          >
                                            <div className={styles.bank__info_row}>
                                              <p className={styles.bank__info_title}>Номер договора</p>
                                              <p className={styles.bank__info_sub} style={{ wordBreak: 'break-all' }}>
                                                {openedOffer?.contractNumber}
                                              </p>
                                            </div>
                                            <div className={styles.bank__info_row}>
                                              <p className={styles.bank__info_title}>Ежемесячный платеж, тенге:</p>
                                              <p className={styles.bank__info_sub}>
                                                {formatNumber(openedOffer?.monthlyInstalmentAmountKZ)}
                                              </p>
                                            </div>
                                            <div className={styles.bank__info_row}>
                                              <p className={styles.bank__info_title}>Банк:</p>
                                              <p className={styles.bank__info_sub}>{openedOffer?.finInstiut}</p>
                                            </div>
                                            <div className={styles.bank__info_row}>
                                              <p className={styles.bank__info_title}>Остаток погашению, тенге:</p>
                                              <p className={styles.bank__info_sub}>
                                                {formatNumber(openedOffer?.outstandingAmountKZ)}
                                              </p>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </>
                                ) : null}

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    marginTop: 20,
                                  }}
                                >
                                  {/* {item?.selected && deal?.paymentType?.id == PAYMENT_TYPES_CREDIT_CARD && (
                                        <Button
                                          text="Оплатить картой"
                                          fill
                                          onClick={() => cardPayment(item, condition)}
                                        />
                                      )}
                                      {item?.selected && deal?.paymentType?.id == PAYMENT_TYPES_CREDIT_INVOICE && (
                                        <Button text="Перейти в документы" fill onClick={() => getInvoice(item)} />
                                      )} */}
                                  {
                                    <Button
                                      text="Выбрать предложение"
                                      fill
                                      onClick={() => chooseBank(item, condition?.offerType)}
                                    />
                                  }
                                </div>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <>
                          <div className={styles.bank__info}>
                            {item?.loan_amount ? (
                              <div className={styles.bank__info_row} style={{ gridColumn: '1 / span 2' }}>
                                <p className={styles.bank__info_title}>Сумма займа</p>
                                <p className={styles.bank__info_sub}>{formatNumber(item?.loan_amount)}</p>
                              </div>
                            ) : null}

                            <div className={styles.bank__info_row}>
                              <p className={styles.bank__info_title}>Первоначальный взнос, тенге</p>
                              <p className={styles.bank__info_sub}>{formatNumber(item?.initialFee)}</p>
                            </div>
                            <div className={styles.bank__info_row}>
                              <p className={styles.bank__info_title}>Срок кредитования:</p>
                              <p className={styles.bank__info_sub}>{item?.period} м.</p>
                            </div>
                            {item?.kasko_amount ? (
                              <div className={styles.bank__info_row}>
                                <p className={styles.bank__info_title}>Страхование КАСКО</p>
                                <p className={styles.bank__info_sub}>{formatNumber(item?.kasko_amount)}</p>
                              </div>
                            ) : null}
                            {item?.ns_amount ? (
                              <div className={styles.bank__info_row}>
                                <p className={styles.bank__info_title}>Страхование НС</p>
                                <p className={styles.bank__info_sub}>{formatNumber(item?.ns_amount)}</p>
                              </div>
                            ) : null}
                            <div className={styles.bank__info_row}>
                              <p className={styles.bank__info_title}>Процентная ставка (годовых):</p>
                              <p className={styles.bank__info_sub}>{item?.percent}%</p>
                            </div>
                            <div className={styles.bank__info_row}>
                              <p className={styles.bank__info_title}>Ежемесячный платеж, тенге:</p>
                              <p className={styles.bank__info_sub}>{formatNumber(item?.monthlyPayment)}</p>
                            </div>
                            {item?.bank?.id == BANKS.HALYK ? (
                              <div className={styles.bank__info_row}>
                                <p className={styles.bank__info_title}>Субсидия:</p>
                                <p className={styles.bank__info_sub}>{subsidy ? 'Да' : 'Нет'}</p>
                              </div>
                            ) : null}
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 20 }}>
                            {/* {item?.selected && deal?.paymentType?.id == PAYMENT_TYPES_CREDIT_CARD && (
                                  <Button text="Оплатить картой" fill onClick={() => cardPayment(item)} />
                                )}
                                {item?.selected && deal?.paymentType?.id == PAYMENT_TYPES_CREDIT_INVOICE && (
                                  <Button text="Перейти в документы" fill onClick={() => getInvoice(item)} />
                                )} */}
                            {<Button text="Выбрать предложение" fill onClick={() => chooseBank(item)} />}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            );
          })
        )}

        <div id="printableArea">
          <h3
            className={styles.title}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 20,
            }}
          >
            Ответы от банков
          </h3>
          {appeals
            ?.filter((appeal) => appeal?.statusId == BANK_CREDIT_ACCEPTED)
            .map((item) => {
              return (
                <div className={styles.bank}>
                  <div className={styles.bank__row}>
                    <div className={styles.bank__img}>
                      <img src={item?.bank?.logo} />
                    </div>
                    <p className={styles.bank_name}>{item?.bank?.name}</p>
                    <p
                      className={`${styles.status} ${
                        item?.statusId == BANK_CREDIT_ACCEPTED && styles.status__accepted
                      } ${item?.statusId == BANK_CREDIT_REJECTED && styles.status__rejected}`}
                    >
                      {BANK_CREDIT_STATUSES[item?.statusId]}
                    </p>
                  </div>
                  {item?.errorMessage && (
                    <div style={{ padding: '0 20px', marginBottom: 20 }}>
                      <p className={styles.sub} style={{ color: '#EE7171' }}>
                        {item?.errorMessage}
                      </p>
                    </div>
                  )}
                  {(item?.statusId == BANK_CREDIT_ACCEPTED) && (
                    <>
                      <div>
                        <p className={styles.sub}>Клиенту одобрен кредит на следующих условиях:</p>
                        {item?.other_conditions?.length ? (
                          item?.other_conditions?.map((condition) => {
                            return (
                              <>
                                <div style={{ background: '#F7F7F7', padding: '10px 20px', marginBottom: 20 }}>
                                  <div className={styles.bank__info} style={{ marginBottom: 20 }}>
                                    <div
                                      className={styles.bank__info_row}
                                      style={{
                                        gridColumn: '1 / span 2',
                                      }}
                                    >
                                      <p className={styles.bank__info_title}>Сумма займа</p>
                                      <p className={styles.bank__info_sub}>{formatNumber(condition?.amount)}</p>
                                    </div>
                                    <div className={styles.bank__info_row}>
                                      <p className={styles.bank__info_title}>Первоначальный взнос, тенге</p>
                                      <p className={styles.bank__info_sub}>
                                        {condition?.initialFee
                                          ? formatNumber(condition?.initialFee)
                                          : formatNumber(item?.initialFee)}
                                      </p>
                                    </div>
                                    <div className={styles.bank__info_row}>
                                      <p className={styles.bank__info_title}>Срок кредитования:</p>
                                      <p className={styles.bank__info_sub}>{condition?.term} м.</p>
                                    </div>
                                    <div className={styles.bank__info_row}>
                                      <p className={styles.bank__info_title}>Процентная ставка (годовых):</p>
                                      <p className={styles.bank__info_sub}>{condition?.rate}%</p>
                                    </div>
                                    <div className={styles.bank__info_row}>
                                      <p className={styles.bank__info_title}>Ежемесячный платеж, тенге:</p>
                                      <p className={styles.bank__info_sub}>{formatNumber(condition?.payment)}</p>
                                    </div>
                                  </div>

                                  {condition?.conditions?.length ? (
                                    <>
                                      <div
                                        style={{
                                          display: 'grid',
                                          gap: 10,
                                          gridTemplateColumns: '20px 1fr',
                                          marginBottom: 20,
                                        }}
                                      >
                                        <RiErrorWarningLine height={20} width={20} color="#D9BC92" />
                                        <p
                                          style={{
                                            color: '#05141F',
                                            fontSize: 14,
                                            fontWeight: 600,
                                          }}
                                        >
                                          Вы можете выбрать этот вариант в случае погашения кредитов по следующим
                                          договорам:
                                        </p>
                                      </div>
                                      {condition?.conditions?.map((openedOffer) => {
                                        return (
                                          <>
                                            <div
                                              className={styles.bank__info}
                                              style={{ background: '#fff', padding: 10, marginBottom: 20 }}
                                            >
                                              <div className={styles.bank__info_row}>
                                                <p className={styles.bank__info_title}>Номер договора</p>
                                                <p className={styles.bank__info_sub} style={{ wordBreak: 'break-all' }}>
                                                  {openedOffer?.contractNumber}
                                                </p>
                                              </div>
                                              <div className={styles.bank__info_row}>
                                                <p className={styles.bank__info_title}>Ежемесячный платеж, тенге:</p>
                                                <p className={styles.bank__info_sub}>
                                                  {formatNumber(openedOffer?.monthlyInstalmentAmountKZ)}
                                                </p>
                                              </div>
                                              <div className={styles.bank__info_row}>
                                                <p className={styles.bank__info_title}>Банк:</p>
                                                <p className={styles.bank__info_sub}>{openedOffer?.finInstiut}</p>
                                              </div>
                                              <div className={styles.bank__info_row}>
                                                <p className={styles.bank__info_title}>Остаток погашению, тенге:</p>
                                                <p className={styles.bank__info_sub}>
                                                  {formatNumber(openedOffer?.outstandingAmountKZ)}
                                                </p>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </>
                                  ) : null}

                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      marginTop: 20,
                                    }}
                                  >
                                    {/* {item?.selected && deal?.paymentType?.id == PAYMENT_TYPES_CREDIT_CARD && (
                                        <Button
                                          text="Оплатить картой"
                                          fill
                                          onClick={() => cardPayment(item, condition)}
                                        />
                                      )}
                                      {item?.selected && deal?.paymentType?.id == PAYMENT_TYPES_CREDIT_INVOICE && (
                                        <Button text="Перейти в документы" fill onClick={() => getInvoice(item)} />
                                      )} */}
                                    {
                                      <Button
                                        text="Выбрать предложение"
                                        fill
                                        onClick={() => chooseBank(item, condition?.offerType)}
                                      />
                                    }
                                  </div>
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <>
                            <div className={styles.bank__info}>
                              <div className={styles.bank__info_row}>
                                <p className={styles.bank__info_title}>Сумма займа</p>
                                <p className={styles.bank__info_sub}>{formatNumber(item?.fullPrice)}</p>
                              </div>
                              <div className={styles.bank__info_row}>
                                <p className={styles.bank__info_title}>Первоначальный взнос, тенге</p>
                                <p className={styles.bank__info_sub}>{formatNumber(item?.initialFee)}</p>
                              </div>
                              <div className={styles.bank__info_row}>
                                <p className={styles.bank__info_title}>Срок кредитования:</p>
                                <p className={styles.bank__info_sub}>{item?.period} м.</p>
                              </div>
                              <div className={styles.bank__info_row}>
                                <p className={styles.bank__info_title}>Процентная ставка (годовых):</p>
                                <p className={styles.bank__info_sub}>{item?.percent}%</p>
                              </div>
                              <div className={styles.bank__info_row}>
                                <p className={styles.bank__info_title}>Ежемесячный платеж, тенге:</p>
                                <p className={styles.bank__info_sub}>{formatNumber(item?.monthlyPayment)}</p>
                              </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 20 }}>
                              {/* {item?.selected && deal?.paymentType?.id == PAYMENT_TYPES_CREDIT_CARD && (
                                  <Button text="Оплатить картой" fill onClick={() => cardPayment(item)} />
                                )}
                                {item?.selected && deal?.paymentType?.id == PAYMENT_TYPES_CREDIT_INVOICE && (
                                  <Button text="Перейти в документы" fill onClick={() => getInvoice(item)} />
                                )} */}
                              {<Button text="Выбрать предложение" fill onClick={() => chooseBank(item)} />}
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
        </div>
        <div style={{ marginBottom: 20 }}>
          <Button onClick={printForClient} fill>
            Распечатать для клиента
          </Button>
        </div>
      </div>

      <DealConfigureSteps bankId={chosenBankId} dealId={deal?.id} update={onSubmit} />
      {succes.message && <Alert message={succes.message} onClick={setSucces} />}
    </>
  );
};

export default DealCredits;
