import React, { useMemo, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { CAR_EQUIPMENTS } from '../../constants/columns';
import { useNavigate } from 'react-router-dom';

// components
import EmptyData from '../../components/EmptyData/EmptyData';
import Table from '../../components/Table/Table';

// icons
import icon from '../../assets/buttonIcons/addDeal.svg';

import { textStyles } from '../../constants/textStyles';
import getEquipments from '../../api/resources/getEquipments';
import getItems from '../../api/cars/getItems';
import { useParams } from 'react-router-dom';
import { iconBucket } from '../../utils/icons';
import removeEquipments from '../../api/equipments/removeEquipments';
import Modal from '../../components/Modal/Modal';
import AddEquipments from '../Equipments/AddEquipments';
import Button from '../../components/Button/Button';
import LoaderInline from '../../components/Loader/LoaderInline';

const columns = CAR_EQUIPMENTS;

const CarEquipments = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [equipments, setEquipments] = useState([]);
  const [loading, setLoading] = useState(true);

  const table = useTable(
    {
      columns,
      data: equipments,
    },
    usePagination
  );
  const init = async () => {
    setLoading(true);
    await getItems({ id: id })
      .then((res) => {
        setContainerId(res.data.cars[0].containerId);
        getEquipments({ containerId: res.data.cars[0].containerId })
          .then((res) => {
            let array = res.data.equipments.map((item) => {
              return {
                id: item.id,
                price: item.price,
                count: item.count,
                name: item.title,
              };
            });
            setEquipments([...array]);
          })
          .catch((err) => {});
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const popover = [{ title: 'Удалить', icon: iconBucket() }];
  const handleActionPopover = async (index) => {
    let getEq = equipments[index];
    removeEquipments({ id: getEq.id }).then(() => {
      init();
    });
  };
  React.useEffect(() => {
    init();
  }, []);
  const [containerId, setContainerId] = React.useState(null);

  const [isOpen, setIsOpen] = React.useState(false);

  const navigateToEdit = (index) => {
    const eqs = equipments.filter((item) => item.id === index);
    navigate(`/cars/car/${containerId}/equipments/${eqs[0].id}/edit`, { state: { data: eqs[0] } });
  };

  if (loading) {
    return (
      <div
        style={{
          padding: '40px 0',
        }}
      >
        <LoaderInline />
      </div>
    );
  }

  return (
    <>
      {equipments.length ? (
        <div style={{ padding: '30px 20px', backgroundColor: 'white', border: '1px solid #e0e0e0', marginTop: '20px' }}>
          <p style={textStyles.formTitle}>Доп оборудование</p>
          <br />
          <Button text="Добавить" onClick={() => setIsOpen(true)} style={{ marginBottom: 15 }} fill />
          <Table
            handleActionPopover={handleActionPopover}
            popover={popover}
            table={table}
            handleNavigate={navigateToEdit}
          />
        </div>
      ) : (
        <EmptyData
          title={'Доп оборудование'}
          subtitle={'Это автомобиль не имеет дополнительного оборудования'}
          action={'Добавить доп оборудование'}
          func={() => {
            setIsOpen(true);
          }}
          icon={icon}
        />
      )}
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <AddEquipments
          id={containerId}
          close={() => {
            setIsOpen(false);
            init();
          }}
        />
      </Modal>
    </>
  );
};

export default CarEquipments;
