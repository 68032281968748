import React from 'react';
import Select from '../../../components/Select/Select';
import styles from './permissions.module.css'

const PermissionCell = ({ permission, value, options, handleChange }) => (
    <td className={styles.cell}>
      <Select options={options.map(i=>({label:i,value:i}))} defaultValue={value} handleSetState={handleChange}/>
    </td>
  );

  export default PermissionCell;