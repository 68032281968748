import { useState } from 'react';
import { textStyles } from '../../../constants/textStyles';
import styles from './chatList.module.css';

const filtersInit = [
  { status: 1, name: 'Все', active: true },
  { status: 2, name: 'В работе', active: false },
  { status: 3, name: 'Новые', active: false },
  { status: 4, name: 'Отвеченные', active: false },
];

const ChatListFilter = () => {
  const [filters, setFilters] = useState(filtersInit);

  const onFilterChange = (status) => {
    const newFilters = filters.map((filter) => {
      filter.active = filter?.status === status;

      return filter;
    });

    setFilters(newFilters);
  };

  return (
    <div className={styles.ChatListFilter}>
      {filters.map(({ name, active, status }) => {
        const classNames = `${active ? styles.ChatListFilter__item_active : ''} ${styles.ChatListFilter__item}`;

        return (
          <div className={classNames} onClick={() => onFilterChange(status)}>
            {name}
          </div>
        );
      })}
    </div>
  );
};

export default ChatListFilter;
