import { useState } from 'react';
import HookForm from '../../../components/HookForm/HookForm';
import Select from '../../../components/Select/Select';
import editDeal from '../../../api/deals/editDeal';
import Button from '../../../components/Button/Button';
import { DEAL_STATUS_CAR_ISSUED, DEAL_STATUS_READY_FOR_CONCLUSION_OF_CREDIT_AGREEMENT } from '../../../constants';
import setStep from '../../../api/deals/setStep';

const DealCreatePledgeRegistration = ({ backHandler, deal, update }) => {
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);

    if (deal?.status?.id === DEAL_STATUS_READY_FOR_CONCLUSION_OF_CREDIT_AGREEMENT) {
      editDeal({
        id: deal.id,
        statusId: DEAL_STATUS_CAR_ISSUED,
      })
        .then(() => {
          setStep({
            dealId: deal.id,
            step: 10,
          })
            .then(() => {
              update && update();
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch(() => {
          setLoading(false);
        });
      return;
    }

    editDeal({
      id: deal.id,
      statusId: status ? DEAL_STATUS_READY_FOR_CONCLUSION_OF_CREDIT_AGREEMENT : deal?.status?.id,
    })
      .then(() => {
        update && update();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <HookForm
        title={'Регистрация залога'}
        grid={{ display: 'grid', gap: 20 }}
        action={deal?.status?.id === DEAL_STATUS_READY_FOR_CONCLUSION_OF_CREDIT_AGREEMENT ? 'Завершить' : 'Сохранить'}
        inputs={[]}
        onSubmit={onSubmit}
        loading={loading}
        customData={() => (
          <div
            style={{
              display: 'grid',
              gap: 20,
            }}
          >
            <div
              style={{
                display: 'grid',
                gap: 20,
                gridTemplateColumns: '1fr auto',
                alignItems: 'flex-end'
              }}
            >
              <Select
                options={[
                  {
                    value: true,
                    label: 'ТС поставлено в залог',
                  },
                  {
                    value: false,
                    label: 'Ожидает постановки в залог',
                  },
                ]}
                onChange={setStatus}
                label="Статус залога"
                placeholder="Выберите из списка"
              />
              <div>
                <Button
                  text={
                    deal?.status?.id === DEAL_STATUS_READY_FOR_CONCLUSION_OF_CREDIT_AGREEMENT
                      ? 'ТС поставлено в залог'.toUpperCase()
                      : 'Ожидает постановки в залог'.toUpperCase()
                  }
                  style={{ color: 'rgb(73, 65, 54)', border: '1px solid rgb(73, 65, 54)' }}
                />
              </div>
            </div>

            <div>
              <p
                style={{
                  fontWeight: 700,
                  fontSize: '20px',
                  lineHeight: '20px',
                  color: 'rgb(73, 65, 54)',
                  marginBottom: '20px',
                }}
              >
                Статус
              </p>
              <Button
                text={deal?.status?.title?.toUpperCase()}
                style={{ color: 'rgb(73, 65, 54)', border: '1px solid rgb(73, 65, 54)' }}
              />
            </div>
          </div>
        )}
        backHandler={backHandler}
      />
    </div>
  );
};

export default DealCreatePledgeRegistration;
