import React, { useState } from 'react';

// components
import ModalForm from '../../../components/ModalForm/ModalForm';
import EmptyData from '../../../components/EmptyData/EmptyData';

// icons
import icon from '../../../assets/buttonIcons/addDeal.svg';

const formStyles = {
  record: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a k k' 'b b b b' 'c c v v' 'd d d d' 'f f f f'` },
};

const NewClientsRecord = () => {
  const [showModal, setShowModal] = useState(false);
  const [record, setRecord] = useState([
    { type: 'input', label: 'Дата', value: '01.11.2022', disabled: true },
    { type: 'input', label: 'ID', value: '35567809', disabled: true },
    { type: 'select', label: 'Клиент', placeholder: 'Выберите из списка', style: { gridArea: 'k' } },
    { type: 'select', label: 'Статус записи', placeholder: 'Выберите из списка' },
    { type: 'select', label: 'Источник', placeholder: 'Выберите из списка' },
    { type: 'date', label: 'Дата', placeholder: 'дд-мм-гггг' },
    { type: 'select', label: 'Время', placeholder: 'Выберите из списка' },
    { type: 'select', label: 'Услуга', placeholder: 'Выберите из списка', style: { gridArea: 'c' } },
    { type: 'select', label: 'Адрес дилерского центра', placeholder: 'Выберите из списка', style: { gridArea: 'v' } },
    { type: 'select', label: 'Ответственный менеджер', placeholder: 'Выберите из списка', style: { gridArea: 'd' } },
    {
      type: 'input',
      label: 'Заметки/комментарии о записи',
      placeholder: 'Оставьте ваш комментарий',
      style: { gridArea: 'f' },
    },
  ]);

  return (
    <>
      <EmptyData
        title={'Записи (0)'}
        subtitle={'Записей пока нет'}
        action="Добавить новую запись"
        icon={icon}
        showModal={() => setShowModal(true)}
      />
      {showModal && (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            display: 'flex',
          }}
        >
          <div style={{ width: '316px' }} />
          <ModalForm
            title={'Новая сделка с клиентом'}
            closeModal={() => setShowModal(false)}
            mainForm={record}
            formTitle={'Информация о сделке'}
            grid={formStyles.record}
            action={'Сохранить запись'}
          />
        </div>
      )}
    </>
  );
};

export default NewClientsRecord;
