import React from 'react';
import Select from '../Select/Select';

const options = [
  { value: 1, label: 'Домашний' },
  { value: 2, label: 'Корпоративный' },
];

const SelectAddressType = ({ ...props }) => {
  return <Select options={options} label={'Тип адреса'} placeholder={'Выберите из списка'} {...props} />;
};

export default SelectAddressType;
