import { useState, useEffect, useCallback } from 'react';
import Select from '../Select/Select';

const defaultFormatOption = (item) => {
  return { value: item.id, label: item.title };
};

const SelectWithFetch = ({
  handleSetSelect,
  handleSetState,
  style,
  fetch,
  formatOption = defaultFormatOption,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const onFocus = useCallback(() => {
    setIsLoading(true);
    fetch().then((res) => {
      const options = res.data.map(formatOption);
      setOptions(options);
    }).finally(() => setIsLoading(false));
  }, [fetch, formatOption]);

  return (
    <Select
      handleSetSelect={(val) => handleSetSelect && handleSetSelect(val)}
      handleSetState={handleSetState}
      options={options}
      onFocus={onFocus}
      style={style}
      isLoading={isLoading}
      {...props}
    />
  );
};

export default SelectWithFetch;
