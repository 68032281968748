import React from 'react';
import styles from './confirmPaymentTemplate.module.css';
import success from '../../../assets/success.png';
import error from '../../../assets/error.png';
const ConfirmPaymentTemplate = (props) => {
  return (
    <>
      {props.confirm ? (
        <div className={styles.confirmPaymentWrapper}>
          <img src={success} alt="" />
          <p>Оплата прошла успешно</p>
        </div>
      ) : (
        <div className={styles.confirmPaymentWrapper}>
          <img src={error} alt="" />
          <p>Оплата не прошла</p>
        </div>
      )}
    </>
  );
};

export default ConfirmPaymentTemplate;
