import styles from './chatList.module.css';
import ChatListFilter from './ChatListFilter';
import ChatListItem from './ChatListItem';

const chats = [
  {
    name: 'Бекпулатов Данияр',
    lastMessage: 'Добрый день',
    seen: true,
    active: true,
  },
  {
    name: 'Бекпулатов Данияр',
    lastMessage: 'Добрый день',
    seen: false,
    active: false,
  },
  {
    name: 'Бекпулатов Данияр',
    lastMessage: 'Добрый день',
    seen: false,
    active: false,
  },
  {
    name: 'Бекпулатов Данияр',
    lastMessage: 'Добрый день',
    seen: true,
    active: false,
  },
  {
    name: 'Бекпулатов Данияр',
    lastMessage: 'Добрый день',
    seen: false,
    active: false,
  },
  {
    name: 'Бекпулатов Данияр',
    lastMessage: 'Добрый день',
    seen: false,
    active: false,
  },
  {
    name: 'Бекпулатов Данияр',
    lastMessage: 'Добрый день',
    seen: false,
    active: false,
  },
];

const ChatList = () => {
  return (
    <div className={styles.ChatList}>
      <ChatListFilter />
      {chats.map((chat) => (
        <ChatListItem {...chat} />
      ))}
    </div>
  );
};

export default ChatList;
