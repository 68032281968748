import Button from '../../../components/Button/Button';
import { textStyles } from '../../../constants/textStyles';

const ClientRegistrationApprove = ({ onSubmit }) => {
  return (
    <div>
      <h1
        style={{
          ...textStyles.formTitle,
          marginBottom: 20,
        }}
      >
        Клиент указал, что машина поставлена на учет
      </h1>

      <Button text={'Подтвердить'} fill onClick={onSubmit} />
    </div>
  );
};

export default ClientRegistrationApprove;
