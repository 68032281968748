import React from 'react';
import styles from './emptyData.module.css';
import { useNavigate, useParams } from 'react-router-dom';

// components
import IconButton from '../IconButton/IconButton';

import { textStyles } from '../../constants/textStyles';
import Table from '../Table/Table';

const EmptyData = ({
  title,
  subtitle,
  action,
  icon,
  showModal,
  table,
  data,
  invisibleHeader,
  deleteFunc,
  func,
  deleteType,
  navigate,
  popover,
  handleActionPopover,
  pagination,
  setPagination,
  p = {},
}) => {
  return (
    <>
      <div className={styles.wrapper}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: invisibleHeader ? 0 : '30px',
          }}
        >
          <p style={textStyles.formTitle}>{title}</p>
          {action && <IconButton text={action} icon={icon} width={297} onClick={func} />}
        </div>
        {data?.length ? (
          <Table
            table={table}
            invisibleHeader={invisibleHeader ? true : false}
            handleNavigate={navigate}
            popover={popover}
            deleteType={deleteType}
            handleActionPopover={handleActionPopover}
            pagination={pagination}
            setPagination={setPagination}
            p={p}
          />
        ) : (
          <p className={styles.status}>{subtitle}</p>
        )}
      </div>
    </>
  );
};

export default EmptyData;
