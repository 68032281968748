// First we need to import axios.js
import axios from 'axios';
import Cookies from 'universal-cookie';
import { apiUrl } from '../constants';
import historyObject from '../utils/historyObject';

const cookies = new Cookies();

// Next we make an 'instance' of it
const instance = axios.create({
  baseURL: apiUrl,
});

// Where you would set stuff like your 'Authorization' header, etc ...

instance.defaults.headers.common = {
  Authorization: `Bearer ${cookies.get('access_token')}`,
};

//   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiMSIsIm5iZiI6MTY2NzM4ODE1OCwiZXhwIjoxNjY3Mzg4MjE4LCJpc3MiOiJ1c2VyIiwiYXVkIjoidXNlciJ9.vtNf8FMq2VQwVwmfSsMOdjHYeKF-TiUX_Y-6tlTpw2o";
// instance.defaults.headers.common["Content-Type"] = "application/json";
// Access-Control-Allow-Credentials

// Also add/ configure interceptors && all the other cool stuff

// instance.interceptors.request...
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401 || error.response?.data?.errorMessage == 'unauthorize') {
      historyObject.push('/authorization');
      return;
    }
    if (error.response?.data?.errorMessage) {
      // alert(error.response.data.errorMessage);
    }
    return Promise.reject(error);
  }
);
export default instance;
