import React from 'react';
import styles from './file.module.css'

// icons
import file from '../../assets/file.svg';

const File = () => {
  return (
    <div className={styles.file}>
      <img src={file} alt="Icon" />
      <span>Файл.PDF</span>
    </div>
  );
};

export default File;
