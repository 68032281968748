import api from '../../plugins/api';

const removeItemsToDeal = ({services = [], equipments = [], cars = [], dealId, orderId}) => {
  return api.post('/api/crm/deals/removeItemsToDeal', {
    services,
    equipments,
    cars,
    dealId,
    orderId
  });
};

export default removeItemsToDeal;
