import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

// components
import Header from '../../components/Header/Header';
import Form from '../../components/Form/Form';
import Button from '../../components/Button/Button';
import Breadcrumbs from '../../components/Breadcrumbs';

// icons
import icon from '../../assets/sidebar/records.svg';
import edit from '../../assets/editForm.svg';

const formStyles = {
  record: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a k k' 'b b b b' 'c c v v' 'd d d d' 'f f f f'` },
};

const RecordService = () => {
  const { id } = useParams();

  const [service, setService] = useState([
    { type: 'input', label: 'Дата', value: '01.11.2022', disabled: true },
    { type: 'input', label: 'ID', value: '35567809', disabled: true },
    { type: 'select', label: 'Клиент', placeholder: 'Выберите из списка', style: { gridArea: 'k' }, disabled: true },
    { type: 'select', label: 'Статус записи', placeholder: 'Выберите из списка', disabled: true },
    { type: 'select', label: 'Источник', placeholder: 'Выберите из списка', disabled: true },
    { type: 'date', label: 'Дата', placeholder: 'дд-мм-гггг', disabled: true },
    { type: 'select', label: 'Время', placeholder: 'Выберите из списка', disabled: true },
    { type: 'select', label: 'Услуга', placeholder: 'Выберите из списка', style: { gridArea: 'c' }, disabled: true },
    {
      type: 'select',
      label: 'Адрес дилерского центра',
      placeholder: 'Выберите из списка',
      style: { gridArea: 'v' },
      disabled: true,
    },
    {
      type: 'select',
      label: 'Ответственный менеджер',
      placeholder: 'Выберите из списка',
      style: { gridArea: 'd' },
      disabled: true,
    },
    {
      type: 'input',
      label: 'Заметки/комментарии о записи',
      placeholder: 'Оставьте ваш комментарий',
      style: { gridArea: 'f' },
      disabled: true,
    },
  ]);

  return (
    <div style={{ paddingBottom: '20px' }}>
      <Header title={`Запись ID ${id}`} icon={icon} />
      <Breadcrumbs navigate={'/records'} link={'Записи'} currentPage={`Запись ID ${id}`} />
      <Form inputs={service} title={'Информация о записи'} grid={formStyles.record} border edit={edit} />
      {/* <Button text={'Сохранить запись'} fill /> */}
    </div>
  );
};

export default RecordService;
