import React, { useEffect } from 'react';
import LoaderInline from '../../components/Loader/LoaderInline';

const Sign = () => {
  useEffect(() => {
    const [url, params] = window.location.href?.split("?");
    window.location.href = `doscar://sign?${params}`;
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
      }}
    >
      <LoaderInline />
    </div>
  );
};

export default Sign;
