import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import HookForm from '../HookForm/HookForm';
import CarMainData from '../../pages/Car/CarMainData';

const DealCarModal = ({ isOpen, onClose, title, containerId, inputs, handleSubmit }) => {
  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onRequestClose={() => onClose(false)}
      contentStyle={{
        height: '100%',
        maxHeight: 500,
      }}
    >
      <div style={{ padding: '0px' }}>
        <CarMainData containerId={containerId} />
      </div>
    </Modal>
  );
};

DealCarModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DealCarModal;
