import React from 'react';
import styles from './addFileButton.module.css';

const AddFileButton = ({ label, onChange, placeholder, value, id, style }) => {
  const [file, setFile] = React.useState(null);
	return (
    <div className={styles.input} style={style}>
      <label htmlFor={id}>{file?.name || label}</label>
      <input
        type="file"
        id={id}
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e.target.files[0]);
          setFile(e.target.files[0]);
        }}
        onClick={(e) => (e.target.value = null)}
        hidden
      />
    </div>
  );
};

export default AddFileButton;
