import React from 'react';
import styles from './form.module.css';

// components
import Datepicker from '../Inputs/Datepicker/Datepicker';
import Input from '../Inputs/Input/Input';
import Status from '../Status';
// import Select from '../Inputs/Select/Select';
import Button from '../Button/Button';
import DeleteButton from '../DeleteButton/DeleteButton';
import DocsInput from '../Inputs/DocsInput/DocsInput';
import AddButton from '../AddButton/AddButton';
import Checkbox from '../Inputs/Checkbox/Checkbox';
import File from '../File/File';
import Select from '../../components/Select/Select';
import SelectMarks from '../Selects/SelectMarks';
import SelectModels from '../Selects/SelectModels';
import SelectContainers from '../Selects/SelectContainers';
import SelectCarcase from '../Selects/SelectCarcase';
import SelectEngine from '../Selects/SelectEngine';
import SelectWd from '../Selects/SelectWd';
import SelectComplectations from '../Selects/SelectComplectations';
import SelectClients from '../Selects/SelectClients';
import SelectServices from '../Selects/SelectServices';
import SelectEquipments from '../Selects/SelectEquipments';
import SelectCars from '../Selects/SelectCars';
import SelectDealStatuses from '../Selects/SelectDealStatuses';
import SelectDealPaymentType from '../Selects/SelectDealPaymentType';
import SelectDealPaymentStatuses from '../Selects/selectDealPaymentStatuses';
import SelectCity from '../Selects/SelectCity';

// icons
import icon from '../../assets/buttonIcons/delete.svg';

import { textStyles } from '../../constants/textStyles';
import { colors } from '../../constants/colors';
import { json } from 'react-router-dom';
import SelectCarTransmissions from '../Selects/SelectCarTransmissions';
import SelectModelsOld from '../Selects/SelectModelOld';
import SelectColor from '../Selects/SelectColor';
import SelectPower from '../Selects/SelectPower';

const Form = ({
  inputs,
  title,
  status,
  grid,
  action,
  addFormButton,
  handleSubmit,
  border,
  handleSetSelect,
  addForm,
  deleteForm,
  showBtn,
  addFormStyle,
  handlerSetState,
  edit,
  onChange,
  isModalForm,
  modalFormKeys,
}) => {
  React.useEffect(() => {}, []);
  return (
    <div className={border ? styles.borderWrapper : styles.wrapper}>
      <div className={styles.header}>
        {/* {title && (
          <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px' }}>
            <p style={textStyles.formTitle}>{title}</p>
            {edit && <img src={edit} alt="Icon" />}
          </div>
        )}
        {status && (
          <div style={{ marginLeft: 'auto' }}>
            <Status status={status} color={colors.dimGray} />
          </div>
        )} */}
      </div>
      <form style={grid}>
        {inputs.map((item, index) => {
          switch (item.type) {
            case 'select':
              return (
                <div
                  onClick={() => {
                    console.log(inputs[index].options);
                  }}
                  style={item.style}
                  key={index}
                >
                  <Select
                    handleSetState={(val) => {
                      item.value = val.value;
                      handlerSetState(inputs);
                      if (item.action) {
                        item.action(val);
                      }
                    }}
                    handleSetSelect={(val) => {
                      if (item.searchable) item.searchable(val);
                      if (handleSetSelect) {
                        handleSetSelect({ type: item.selectType, val: val });
                      }
                    }}
                    isSearchable={item.isSearchable}
                    options={item.options}
                    key={index}
                    title={item.label}
                    placeholder={item.placeholder}
                    defaultValue={item.value}
                    style={item.style}
                    disabled={item.disabled}
                  />
                </div>
              );
            case 'selectDealStatuses':
              return (
                <SelectDealStatuses
                  key={index}
                  style={item.style}
                  handleSetState={(val) => {
                    item.value = val.value;
                    handlerSetState(inputs);
                  }}
                />
              );
            case 'selectDealPaymentTypes':
              return (
                <SelectDealPaymentType
                  key={index}
                  style={item.style}
                  handleSetState={(val) => {
                    item.value = val.value;
                    handlerSetState(inputs);
                  }}
                />
              );
            case 'selectDealPaymentStatuses':
              return (
                <SelectDealPaymentStatuses
                  key={index}
                  style={item.style}
                  handleSetState={(val) => {
                    item.value = val.value;
                    handlerSetState(inputs);
                  }}
                />
              );
            case 'selectMarks':
              return (
                <SelectMarks
                  key={index}
                  style={item.style}
                  handleSetState={(val) => {
                    item.value = val.value;
                    handlerSetState(inputs);
                  }}
                />
              );
            case 'selectModels':
              return (
                <SelectModels
                  key={index}
                  style={item.style}
                  handleSetState={(val) => {
                    item.value = val.value;
                    handlerSetState(inputs);
                  }}
                  params={undefined ?? item.params}
                />
              );
            case 'selectModelsOld':
              return (
                <SelectModelsOld
                  key={index}
                  style={item.style}
                  handleSetState={(val) => {
                    item.value = val.value;
                    handlerSetState(inputs);
                  }}
                  params={undefined ?? item.params}
                />
              );
            case 'selectContainers':
              return (
                <SelectContainers
                  key={index}
                  style={item.style}
                  handleSetState={(val) => {
                    item.value = val.value;
                    handlerSetState(inputs);
                  }}
                />
              );
            case 'selectCity':
              return (
                <SelectCity
                  key={index}
                  style={item.style}
                  handleSetState={(val) => {
                    item.value = val.value;
                    handlerSetState(inputs);
                  }}
                  placeholder={'Выберите из списка'}
                  label={'Город'}
                />
              );
            case 'selectClients':
              return (
                <SelectClients
                  key={index}
                  style={item.style}
                  handleSetState={(val) => {
                    item.value = val.value;
                    handlerSetState(inputs);
                  }}
                />
              );
            case 'selectCars':
              return (
                <SelectCars
                  key={index}
                  style={item.style}
                  handleSetState={(val) => {
                    if (isModalForm) {
                      modalFormKeys.value = val;
                    } else {
                      handlerSetState(inputs);
                    }
                  }}
                />
              );
            case 'selectCarcase':
              return (
                <SelectCarcase
                  key={index}
                  style={item.style}
                  handleSetState={(val) => {
                    item.value = val.value;
                    handlerSetState(inputs);
                  }}
                />
              );
            case 'selectEngine':
              return (
                <SelectEngine
                  key={index}
                  style={item.style}
                  handleSetState={(val) => {
                    item.value = val.value;
                    handlerSetState(inputs);
                  }}
                />
              );
            case 'selectWd':
              return (
                <SelectWd
                  key={index}
                  style={item.style}
                  handleSetState={(val) => {
                    item.value = val.value;
                    handlerSetState(inputs);
                  }}
                />
              );
            case 'selectComplectations':
              return (
                <SelectComplectations
                  key={index}
                  style={item.style}
                  handleSetState={(val) => {
                    item.value = val.value;
                    handlerSetState(inputs);
                  }}
                  params={undefined ?? item.params}
                />
              );
            case 'selectServices':
              return (
                <SelectServices
                  key={index}
                  style={item.style}
                  handleSetState={(val) => {
                    item.value = [];
                    item.value.push(val);
                    if (isModalForm) {
                      modalFormKeys.value = val;
                    } else {
                      handlerSetState(inputs);
                    }
                  }}
                />
              );
            case 'selectEquipments':
              return (
                <SelectEquipments
                  key={index}
                  style={item.style}
                  handleSetState={(val) => {
                    item.value = [];
                    item.value.push(val);
                    if (isModalForm) {
                      modalFormKeys.value = val;
                    } else {
                      handlerSetState(inputs);
                    }
                  }}
                />
              );
              case 'selectTransmissions':
                return (
                  <SelectCarTransmissions
                    key={index}
                    style={item.style}
                    handleSetState={(val) => {
                      item.value = val.value;
                      handlerSetState(inputs);
                    }}
                  />
                );
              case 'selectPower':
                return (
                  <SelectPower
                    key={index}
                    style={item.style}
                    handleSetState={(val) => {
                      item.value = val.value;
                      handlerSetState(inputs);
                    }}
                  />
                );
            case 'selectColor':
              return (
                <SelectColor
                  key={index}
                  style={item.style}
                  label={item.label}
                  value={item.value}
                  handleSetState={(val) => {
                    item.value = val.value;
                    handlerSetState(inputs);
                  }}
                />
              );
            case 'input':
              return (
                <Input
                  key={index}
                  label={item.label}
                  placeholder={item.placeholder}
                  disabled={item.disabled}
                  onChange={(val) => {
                    item.value = val.target.value;
                    if (handlerSetState) handlerSetState(inputs);
                    if (onChange) onChange(index, val.target.value);
                  }}
                  value={item.value}
                  icon={item.icon}
                  color={item.color}
                  style={item.style}
                  inputType={item.inputType}
                  mask={item.mask}
                  format={item.format}
                  maxLength={item?.maxLength}
                />
              );
            case 'date':
              return (
                <Datepicker
                  key={index}
                  label={item.label}
                  defaultValue={item.value}
                  placeholder={item.placeholder}
                  disabled={item.disabled}
                  value={item.value}
                  icon={item.icon}
                  onChange={(val) => {
                    item.value = val;
                    if (handlerSetState) handlerSetState(inputs);
                  }}
                  style={item.style}
                />
              );
            case 'document':
              return (
                <DocsInput
                  key={index}
                  text={item.text}
                  label={item.label}
                  placeholder={item.placeholder}
                  disabled={item.disabled}
                  onChange={(val) => {
                    item.value = val.target.files[0];
                    if (handlerSetState) handlerSetState([...inputs]);
                  }}
                  value={item.value}
                  id={item.id}
                />
              );
            case 'file':
              return <File key={index} />;
            case 'button':
              return (
                <Button style={showBtn === false ? { display: 'none' } : ''} key={index} text={item.action} fill />
              );
            case 'checkbox':
              return <Checkbox key={index} label={item.label} id={index} value={item.value} style={item.style} />;
            case 'delete':
              return <DeleteButton key={index} icon={icon} onClick={deleteForm} />;
            default:
              return null;
          }
        })}
      </form>
      {action && showBtn != false && (
        <Button text={action} onClick={() => handleSubmit && handleSubmit(inputs)} fill={true} />
      )}
      {addFormButton && <AddButton text={addFormButton} onClick={addForm} style={addFormStyle} />}
    </div>
  );
};

export default Form;
