import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

// components
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';
import Breadcrumbs from '../../components/Breadcrumbs';

// icons
import leads from '../../assets/sidebar/leads.svg';

const LeadsNavigation = () => {
  const { id } = useParams();
  const items = [
    { text: 'Основная информация', navigate: id ? `/leads/lead/${id}/main-info` : '/leads/create' },
    { text: 'Клиент', navigate: id ? `/leads/lead/${id}/client` : '/leads/client' },
  ];

  return (
    <>
      <Header title={id ? `Лид - ID ${id}` : 'Новый лид'} icon={leads} />
      <Breadcrumbs navigate={'/leads'} link={'Лиды'} currentPage={id ? `Лид - ID ${id}` : 'Лиды'} />
      <Navbar items={items} />
      <Outlet />
    </>
  );
};

export default LeadsNavigation;
