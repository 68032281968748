import React, { useState } from 'react';
import { CARS } from '../../constants/columns';
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { carsOptions } from '../../constants/filterOptions';
import { useNavigate } from 'react-router-dom';
import { setFilters } from '../../utils/setFilters';
import { sortCarsParams } from '../../constants/sortParams';

// components
import Header from '../../components/Header/Header';
import Table from '../../components/Table/Table';
import TableTabs from '../../components/TableTabs/TableTabs';
import Empty from '../../components/Empty/Empty';
import HookForm from '../../components/HookForm/HookForm';
// import styles from '/components/TableTabs/tableTabs.module.css';
import Button from '../../components/Button/Button';
// icons
import icon from '../../assets/sidebar/cars.svg';
import getItems from '../../api/cars/getItems';
import bucketIcon from '../../assets/popover/delete.svg';
import { PERMISSION_CAN_DELETE_DEALS, PERMISSION_CAN_EDIT_AUTO_AND_EQUIPMENTS } from '../../constants/roles';
import isUserHavePermission from '../../utils/isUserHavePermission';
import axios from 'axios';
import { apiAdminUrl } from '../../constants';
import alert from '../../helpers/alert';
import removeCar from '../../api/cars/removeCar';
import extractErrorMessage from '../../utils/extractErrorMessage';

const tableStyle = { backgroundColor: 'white' };

let sortCount = 1;

const Cars = ({
  header = true,
  withCheckboxes,
  submitButton,
  cancelButton,
  onSubmit,
  onCancel,
  defaultQueryParams = {},
}) => {
  const [columns, setColumns] = React.useState(CARS);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [cars, setCars] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    pageSize: 10,
  });
  const [isFetchAll, setFetchAll] = useState(false);

  const table = useTable(
    {
      columns,
      data: cars,
      initialState: {
        pageSize: pagination.pageSize,
      },
      manualPagination: true,
      pageCount: pagination.totalPages,
      page: pagination.page - 1,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { setGlobalFilter, page, setPageSize } = table;

  const [value, setValue] = useState('');
  const [queryParams, setQueryParams] = useState(defaultQueryParams);

  const addCar = () => {
    navigate('/cars/create');
  };

  const handleNavigate = (index) => {
    if (!isUserHavePermission(PERMISSION_CAN_EDIT_AUTO_AND_EQUIPMENTS)) {
      return;
    }

    console.log(cars, index, cars[index - 1]);
    navigate(`car/${index}/main-data`, { state: cars.find((item) => item.id === index) });
  };

  const init = async (page) => {
    setLoading(true);
    await getItems(page)
      .then((res) => {
        setCars(res.data.cars);
        setPagination({
          ...res.data.pagination,
          page: res.data.pagination?.pageNumber,
          pageSize: pagination.pageSize,
        });
        setDataCount(res.data.pagination?.count);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (!isFetchAll) {
      init({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams });
    }
  }, [pagination.page, pagination.pageSize, queryParams]);

  const exportStock = () => {
    axios
      .post(apiAdminUrl + '/api/importStock')
      .then((res) => {
        alert.success('Склад успешно экспортирован');
        const link = document.createElement('a');
        link.href = res.data.url;
        link.setAttribute('download', 'cars.xlsx');
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        alert.error('Ошибка при экспорте авто');
      });
  };
  const handleActionPopover = async (carIndex, actionIndex, title, id) => {
    console.log(carIndex, actionIndex);
  };

  const sortBy = (value) => {
    queryParams.sortBy = sortCarsParams.get(value);
    if (!sortCarsParams.get(value) || (sortCount > 1 && sortCarsParams.get(value) != queryParams.sortBy)) {
      sortCount = 1;
      return;
    }
    if (sortCount === 3) {
      sortCount = 1;
    }
    if (sortCarsParams.get(value) === queryParams.sortBy && sortCount === 1) {
      queryParams.sortBy = sortCarsParams.get(value);
      queryParams.sort = 'ask';
      sortCount++;
      console.log('asd');
    } else if (sortCarsParams.get(value) === queryParams.sortBy && sortCount === 2) {
      queryParams.sortBy = sortCarsParams.get(value);
      queryParams.sort = 'desc';
      sortCount++;
      console.log('asd');
    }
    setFetchAll(false);
    setQueryParams({ ...queryParams });
    console.log(sortCount, '[count]');
  };

  const popover = [
    {
      title: 'Удалить автомобиль',
      icon: bucketIcon,
      onClick: async (row) => {
        removeCar({ id: row?.id }).then(() => {
          init({ page: pagination.page, pageSize: pagination.pageSize, ...queryParams });
        }).catch((err) => {
          alert.error(extractErrorMessage(err?.response?.data) || 'Ошибка при удалении автомобиля');
        })
      },
      permission: PERMISSION_CAN_DELETE_DEALS,
    },
  ];

  
  const onFastSearch = (value) => {
    setPagination({
      ...pagination,
      page: 1,
    })

    setQueryParams({
      ...queryParams,
      all: value,
    })
  }

  return (
    <>
      {header && (
        <>
          <Header title="Автомобили" icon={icon} />
          <br />
          <br />
          <br />
          <br />
        </>
      )}
      <TableTabs
        setFilter={onFastSearch}
        value={value}
        setValue={setValue}
        filterComponent={
          <HookForm
            inputs={carsOptions}
            grid={{
              display: 'grid',
              gap: '10px',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
            }}
            style={{ backgroundColor: 'transparent' }}
          />
        }
        handleSearchParams={(resetFilters) =>
          setFilters(resetFilters, setFetchAll, setPageSize, setPagination, setQueryParams, carsOptions)
        }
        navigate={addCar}
        button={'Добавить автомобиль'}
        inputPlaceholder={'Быстрый поиск по автомобилям'}
        permission={PERMISSION_CAN_EDIT_AUTO_AND_EQUIPMENTS}
      />
      <Button
        style={{ marginTop: 15, marginBottom: 15 }}
        onClick={() => {
          exportStock();
        }}
        text={'Экспорт в Excel'}
        fill={true}
      />
      <br />
      {!page.length && !loading ? (
        <Empty />
      ) : (
        <Table
          table={table}
          all={'автомобилей'}
          style={tableStyle}
          handleNavigate={handleNavigate}
          pagination
          sort
          setPagination={setPagination}
          setFetchAll={setFetchAll}
          isFetchAll={isFetchAll}
          setData={setCars}
          p={pagination}
          loading={loading}
          setLoading={setLoading}
          columns={columns}
          setColumns={setColumns}
          columnsSettingsKey="cars"
          queryParams={queryParams}
          api={'/api/shop/getItems'}
          response={'cars'}
          withCheckboxes={withCheckboxes}
          submitButton={submitButton}
          cancelButton={cancelButton}
          onSubmit={onSubmit}
          onCancel={onCancel}
          popover={popover}
          handleActionPopover={handleActionPopover}
          dataCount={dataCount}
          handleSortTable={sortBy}
        />
      )}
    </>
  );
};

export default Cars;

