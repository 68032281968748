import Select from '../Select/Select';

export const PERIODS = [
  {
    label: '12 (1 год)',
    value: '12',
  },
  {
    label: '24 (2 года)',
    value: '24',
  },
  {
    label: '36 (3 года)',
    value: '36',
  },
  {
    label: '48 (4 года)',
    value: '48',
  },
  {
    label: '60 (5 лет)',
    value: '60',
  },
  {
    label: '72 (6 лет)',
    value: '72',
  },
  {
    label: '84 (7 лет)',
    value: '84',
  },
];

const SelectCreditPeriod = ({
  handleSetSelect,
  handleSetState,
  dependantValue,
  dependencyAccessor,
  style,
  hide,
  ...props
}) => {
  if (dependencyAccessor && dependencyAccessor(dependantValue) !== 6 && dependencyAccessor(dependantValue) !== 7) return null;
  if (hide && hide()) return null;

  return (
    <Select
      handleSetSelect={(val) => handleSetSelect && handleSetSelect(val)}
      handleSetState={handleSetState}
      options={PERIODS}
      placeholder={'Выберите из списка'}
      style={style}
      {...props}
    />
  );
};

export default SelectCreditPeriod;
