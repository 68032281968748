import styles from './chat.module.css';
import ChatUserAvatar from './ChatUserAvatar';

const ChatUser = () => {
    return(<div className={styles.ChatUser}>
        <ChatUserAvatar/>
        <div className={styles.ChatUser__wrapper}>
            <p className={styles.ChatUser__name}>Бекпулатов Данияр</p>
            <p className={styles.ChatUser__last_message}>Lorem ipsum dolor sit amet</p>
        </div>
    </div>)
}

export default ChatUser;