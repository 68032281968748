import Button from '../../../components/Button/Button';
import { textStyles } from '../../../constants/textStyles';

const ClientPaymentApprove = ({ onSubmit }) => {
  return (
    <div>
      <h1
        style={{
          ...textStyles.formTitle,
          marginBottom: 20,
        }}
      >
        Клиент указал статус оплаты первоначального взноса как "Оплачено"
      </h1>

      <Button text={'Подтвердить'} fill onClick={onSubmit} />
    </div>
  );
};

export default ClientPaymentApprove;
