import initialFeeCreditCardPayment from '../../../../api/deals/initialFeeCreditCardPayment';
import Button from '../../../../components/Button/Button';
import DownloadFileLink from '../../../../components/DownloadFileLink/DownloadFileLink';
import HookForm from '../../../../components/HookForm/HookForm';
import SelectDealPaymentStatuses from '../../../../components/Selects/selectDealPaymentStatuses';
import { DOCUMENT_TYPES_INVOICE, PAYMENT_STATUS_PAID } from '../../../../constants';
import alert from '../../../../helpers/alert';

const PaymentStatus = ({ documents, onSubmit, loading, backHandler, setLoading, bankId, chunkId }) => {
  const onPaymentSubmit = (values) => {
    if (values?.status?.value != PAYMENT_STATUS_PAID) return;

    onSubmit(values);
  };

  const sendPaymentSms = () => {
    setLoading(true);
    initialFeeCreditCardPayment({
      bankId: bankId,
      chunkId: chunkId,
    })
      .then(() => {
        alert.success('Смс со ссылкой на оплату отправлено на номер телефона клиента');
      })
      .catch((err) => {
        alert.error(err?.response?.data?.message || 'Не удалось отправить смс со ссылкой на оплату');
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <HookForm
        title={'Статус оплаты'}
        grid={{ display: 'grid', gap: 20 }}
        action={'Сохранить и продолжить'}
        inputs={[
          {
            name: 'status',
            component: SelectDealPaymentStatuses,
          },
        ]}
        customDataTop={() => (
          <div>
            {documents
              ?.filter((item) => item?.typeId === DOCUMENT_TYPES_INVOICE)
              .map((document) => (
                <DownloadFileLink document={document} />
              ))}
          </div>
        )}
        customData={() => (
          <Button text="Отправить СМС со ссылкой на оплату первоначального взноса" fill onClick={sendPaymentSms} />
        )}
        onSubmit={onPaymentSubmit}
        submitButtonFull
        loading={loading}
        backHandler={backHandler}
      />
    </div>
  );
};

export default PaymentStatus;
