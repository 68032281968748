import React, { useEffect, useState } from 'react';
import { MARKS } from '../../../constants/columns';
import { useTable, useGlobalFilter, usePagination, useSortBy } from 'react-table';
import { carsOptions } from '../../../constants/filterOptions';
import { useNavigate } from 'react-router-dom';
import getMarks from '../../../api/cars/getMarks';

// components
import Header from '../../../components/Header/Header';
import Table from '../../../components/Table/Table';
import TableTabs from '../../../components/TableTabs/TableTabs';
import Empty from '../../../components/Empty/Empty';

// icons
import icon from '../../../assets/sidebar/cars.svg';
import notFound from '../../../assets/notFound.svg';

const columns = MARKS;

const CarMarks = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [marks, setMarks] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPages: 1,
    hasPreviousPage: false,
    hasNextPage: false,
    pageSize: 10,
  });

  const table = useTable(
    {
      columns,
      data: marks,
      disableSortRemove: true,
      initialState: {
        pageSize: pagination.pageSize,
      },
      manualPagination: true,
      pageCount: pagination.totalPages,
      page: pagination.page - 1,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { setGlobalFilter, page } = table;

  const [value, setValue] = useState('');

  const handleNavigate = () => {
    navigate('/cars/marks/create');
  };

  useEffect(() => {
    getMarks()
      .then((res) => {
        setMarks(res.data.marks);
        setPagination({
          ...res.data.pagination,
          page: res.data.pagination?.pageNumber,
          pageSize: pagination.pageSize,
        });
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Header title="Марки" icon={icon} />
      <TableTabs
        setFilter={setGlobalFilter}
        options={carsOptions}
        value={value}
        setValue={setValue}
        navigate={handleNavigate}
        button={'Добавить марку'}
        inputPlaceholder={'Быстрый поиск по маркам'}
      />
      <br />
      {!page.length && !loading ? (
        <Empty />
      ) : (
        <Table table={table} all={'марок'} loading={loading} pagination setPagination={setPagination} p={pagination} />
      )}
    </>
  );
};

export default CarMarks;
