import api from '../../plugins/api';

const createCreditAppeal = (payload, files) => {
  console.log(payload)
  const formData = new FormData();
  
  Object.keys(payload).forEach((key) => {
    if(key == 'contactPersons') {
      payload[key]?.forEach((item, index) => {
          Object.keys(item).forEach(key => {
            formData.append(`contactPersons[${index}][${key}]`, item[key]);
          });
      });
      return;
    }
    if(key == 'bankIds') {
      payload[key]?.forEach((item, index) => {
        formData.append(`bankIds[${index}]`, item);
      });
      return;
    }

    if (['contactPersonLastName', 'contactPersonPhone', 'contactPersonType', 'residentBool', 'documentGivedBy',
      'contactPersonLastName2', 'contactPersonPhone2', 'contactPersonType2', 'residentBool2', 'documentGivedBy2'
    ].includes(key)) {
      return;
    }

    formData.append(key, payload[key])
  });
  Object.keys(files).forEach((key) => formData.append(key, files[key]));
  return api.post('/api/crm/creditPlatform/createCreditAppeal', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default createCreditAppeal;
