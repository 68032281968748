import React, { useState } from 'react';

// components
import HookModalForm from '../../../components/HookModalForm/HookModalForm';
import EmptyData from '../../../components/EmptyData/EmptyData';
import Datepicker from '../../../components/Inputs/Datepicker/Datepicker';
import SelectClients from '../../../components/Selects/SelectClients';
import SelectDealStatuses from '../../../components/Selects/SelectDealStatuses';
import SelectDealPaymentTypes from '../../../components/Selects/SelectDealPaymentTypes';
import SelectDealPaymentStatuses from '../../../components/Selects/selectDealPaymentStatuses';
import SelectCars from '../../../components/Selects/SelectCars';
import SelectEquipments from '../../../components/Selects/SelectEquipments';
import SelectServices from '../../../components/Selects/SelectServices';
import DeleteButton from '../../../components/DeleteButton/DeleteButton';

// icons
import icon from '../../../assets/buttonIcons/addDeal.svg';

const formStyles = {
  dealInfo: { display: 'grid', gap: '20px', gridTemplateAreas: `'a a m m' 'b b b b' 'c c c c'` },
  autoForm: { display: 'grid', columnGap: '20px', gridTemplateColumns: `auto 44px 0px` },
};

const NewClientsDeal = () => {
  const [showModal, setShowModal] = useState(false);

  const [dealInfo, setDealInfo] = useState([
    { name: 'createdAt', type: 'date', value: new Date(), label: 'Дата', component: Datepicker, disabled: true },
    { name: 'id', type: 'input', label: 'ID', value: '35567809', disabled: true },
    {
      name: 'userId',
      component: SelectClients,
      label: 'Клиент',
      placeholder: 'Выберите из списка',
      style: { gridArea: 'm' },
    },
    { name: 'statusId', component: SelectDealStatuses, label: 'Статус сделки', placeholder: 'Выберите из списка' },
    {
      name: 'paymentTypeId',
      component: SelectDealPaymentTypes,
      label: 'Форма оплаты',
      placeholder: 'Выберите из списка',
    },
    {
      name: 'statusPaymentId',
      component: SelectDealPaymentStatuses,
      label: 'Статус оплаты',
      placeholder: 'Выберите из списка',
    },
    { name: 'sum', type: 'input', label: 'Сумма сделки, KZT', placeholder: 'Автоматический расчет', disabled: true },
    {
      name: 'comment',
      type: 'input',
      label: 'Заметки/комментарии о сделке',
      placeholder: 'Ваш комментарий',
      style: { gridArea: 'c' },
    },
  ]);

  const [autoForm, setAutoForm] = useState({
    style: formStyles.autoForm,
    form: [],
    add: [
      { name: 'cars', component: SelectCars, label: 'Автомобиль', placeholder: 'Выберите из списка' },
      {
        name: 'deleteCars',
        component: DeleteButton,
        onClick: () => {
          autoForm.form.length = 0;
          setAutoForm(autoForm);
          setActions((prev) => [...prev, (prev[0].show = true)]);
        },
      },
    ],
  });

  const [equipmentForm, setEquipmentForm] = useState({
    style: formStyles.autoForm,
    form: [],
    add: [
      {
        name: 'equipments',
        component: SelectEquipments,
        label: 'Оборудование',
        placeholder: 'Выберите из списка',
        isMulti: true,
        isSearchable: true,
      },
      {
        name: 'deleteEquipments',
        component: DeleteButton,
        onClick: () => {
          equipmentForm.form.length = 0;
          setEquipmentForm(equipmentForm);
          setActions((prev) => [...prev, (prev[1].show = true)]);
        },
      },
    ],
  });

  const [serviceForm, setServiceForm] = useState({
    style: formStyles.autoForm,
    form: [],
    add: [
      {
        name: 'services',
        component: SelectServices,
        label: 'Услуги',
        placeholder: 'Выберите из списка',
        isMulti: true,
        isSearchable: true,
      },
      {
        name: 'deleteServices',
        component: DeleteButton,
        onClick: () => {
          serviceForm.form.length = 0;
          setServiceForm(serviceForm);
          setActions((prev) => [...prev, (prev[2].show = true)]);
        },
      },
    ],
  });

  const [documentForm, setDocumentForm] = useState({
    style: formStyles.autoForm,
    form: [],
    add: [{ type: 'select', value: '', label: 'Документ', placeholder: 'Выберите из списка' }, { type: 'delete' }],
  });

  const [actions, setActions] = useState([
    { show: true, text: 'Добавить автомобиль' },
    { show: true, text: 'Добавить аксессуары' },
    { show: true, text: 'Добавить дополнительную услугу' },
    // { show: true, text: 'Добавить документ к сделке' },
  ]);

  const createDeal = (data) => {
    console.log(data);
  };

  return (
    <>
      <EmptyData
        title={'Сделки (0)'}
        subtitle={'Этот клиент пока не учатсвует ни в одной сделке'}
        action="Добавить новую сделку"
        icon={icon}
        showModal={() => setShowModal(true)}
      />
      {showModal && (
        <HookModalForm
          title={'Новая сделка с клиентом'}
          closeModal={() => setShowModal(false)}
          mainForm={dealInfo}
          setMainForm={setDealInfo}
          formTitle={'Информация о сделке'}
          grid={formStyles.dealInfo}
          forms={[autoForm, equipmentForm, serviceForm, documentForm]}
          actions={actions}
          button={'Сохранить сделку'}
          setActions={setActions}
          setForm={[setAutoForm, setEquipmentForm, setServiceForm, setDocumentForm]}
          handleSubmit={createDeal}
        />
      )}
    </>
  );
};

export default NewClientsDeal;
