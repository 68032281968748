import { useCallback, useEffect } from 'react';
import { useState, useRef } from 'react';
import getContainers from '../../api/cars/getContainers';
import Select from '../Select/Select';

const SelectContainers = ({ handleSetSelect, handleSetState, style, ...props }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const onFocus = useCallback(() => {
    if (page != 1 && page > totalCount) return;
    getContainers({ page }).then((res) => {
      const containers = res.data.containers.map((item) => {
        return { value: item.id, label: item.mark.name + ' ' + item.model.name };
      });
      setData((prev) => [...prev, ...containers]);
      setTotalCount(res.data.pagination.totalPages);
      setPage((prev) => prev + 1);
    });
  }, [page, totalCount]);
  const handleGetSelectVar = (val) => {
    if (val != '' && val != null) getContainers({ markAndModel: val });
  };

  return (
    <Select
      handleSetSelect={(val) => {
        handleSetSelect && handleSetSelect(val);
        handleGetSelectVar(val);
      }}
      handleSetState={handleSetState}
      options={data}
      title={'Марка и модель'}
      placeholder={'Выберите из списка'}
      onFocus={onFocus}
      style={style}
      isSearchable
      setPage={setPage}
      page={page}
      totalCount={totalCount}
      {...props}
    />
  );
};

export default SelectContainers;
