export const ROLE_USER = 1; //Пользователь
export const ROLE_MANAGER = 2; //Менеджер по продажам
export const ROLE_ACCOUNTANT = 3; //Бухгалтер
export const ROLE_LOGIST = 4; //Логист ДЦ
export const ROLE_ADMIN = 5; //Администратор
export const ROLE_NOTARY = 6; //Нотариус
export const ROLE_ROP = 7; //РОП

export const ROLES = {
  [ROLE_USER]: 'Пользователь',
  [ROLE_MANAGER]: 'Менеджер по продажам',
  [ROLE_ACCOUNTANT]: 'Бухгалтер',
  [ROLE_LOGIST]: 'Логист ДЦ',
  [ROLE_ADMIN]: 'Администратор',
  [ROLE_NOTARY]: 'Нотариус',
  [ROLE_ROP]: 'РОП',
};

export const PERMISSION_CAN_CREATE_LEADS = 1; //Может создавать лиды
export const PERMISSION_CAN_EDIT_DEALS = 2; //Может изменять сделки
export const PERMISSION_CAN_CHANGE_STATUSES = 3; //Может менять статусы
export const PERMISSION_CAN_ASSIGN_MANAGER = 4; //Назначать другого менеджера
export const PERMISSION_CAN_SEE_ALL_DEALS = 5; //Может видеть все сделки
export const PERMISSION_CAN_DELETE_DEALS = 6; //Может удалять сделки
export const PERMISSION_CAN_SEE_ALL_LEADS = 7; //Может видеть все лиды
export const PERMISSION_CAN_SEE_SERVICES = 8; //Может видеть услуги
export const PERMISSION_CAN_SEE_RECORDS = 10; //Может видеть записи
export const PERMISSION_CAN_SEE_REPORTS = 11; //Может видеть отчеты
export const PERMISSION_CAN_SEE_PERMISSIONS = 9; //Может видеть права доступа
export const PERMISSION_CAN_EDIT_AUTO_AND_EQUIPMENTS = 12; //Может редактировать авто и оборудование
