import React, { useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { CARS } from '../../constants/columns';
import { useOutletContext, useParams } from 'react-router-dom';

// components
import EmptyData from '../../components/EmptyData/EmptyData';
import Loader from '../../components/Loader/Loader';

// icons
import icon from '../../assets/buttonIcons/addDeal.svg';
import getDealCars from '../../api/deals/getDealCars';
import SelectCarModal from '../../components/Modals/SelectCarModal';
import addItemsToDeal from '../../api/deals/addItemsToDeal';
import getDealCard from '../../api/deals/getDealCard';
import bucketIcon from '../../assets/popover/delete.svg';
import axios from '../../plugins/axios';
const popover = [{ title: 'Удалить авто', icon: bucketIcon }];

const DealCarsModal = ({ dealId }) => {
  const [cars, setCars] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const init = () => {
    getDealCars({ id: dealId })
      .then((res) => {
        let array = res.data.cars.map((item) => {
          return { ...item, itemId: item.orderId };
        });
        setCars(array);
      })
      .finally(() => setLoadingPage(false));
  };

  React.useEffect(() => {
    init();
  }, []);

  const handeSubmit = async (cars) => {
    setLoading(true);
    try {
      cars = cars.map(({ value }) => ({ value }));

      const { data } = await getDealCard({ id: dealId });

      addItemsToDeal({
        dealId: String(dealId),
        clientId: data?.client?.id,
        cars,
      })
        .then(() => {
          setShowModal(false);
          init();
        })
        .catch((err) => alert(err))
        .finally(() => setLoading(false));
    } catch (e) {
      setLoading(false);
    }
  };

  const table = useTable(
    {
      columns: CARS,
      data: cars,
      disableSortRemove: true,
    },
    usePagination
  );

  const handleActionPopover = async (dealIndex, actionIndex, title, itemId, type) => {
    if (type === 'cars') {
      axios
        .post('/api/crm/deals/removeItemsToDeal', {
          services: [],
          equipments: [],
          cars: [{ value: itemId }],
          dealId,
        })
        .then((res) => {
          window.location.href = window.location.href;
        })
        .catch((error) => {});
    }
  };

  return (
    <>
      {loadingPage ? (
        <Loader />
      ) : (
        <>
          <EmptyData
            title={`Автомобили (${cars.length})`}
            subtitle={'Это автомобиль пока не участвует ни в одной сделке'}
            action={'Добавить автомобиль к сделке'}
            icon={icon}
            popover={popover}
            deleteType={'cars'}
            handleActionPopover={handleActionPopover}
            table={table}
            data={cars}
            func={() => setShowModal(true)}
          />
          <SelectCarModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            onSubmit={handeSubmit}
            dealId={dealId}
            loading={loading}
          />
        </>
      )}
    </>
  );
};

export default DealCarsModal;
