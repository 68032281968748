import React from 'react';
import Select from '../Select/Select';

const options = [
  { value: 1, label: 'Facebook' },
  { value: 2, label: 'VK' },
  { value: 3, label: 'Instagram' },
  { value: 4, label: 'Telegram' },
];

const SelectSocialType = ({ ...props }) => {
  return <Select options={options} label={'Соц.сеть'} placeholder={'Выберите из списка'} {...props} />;
};

export default SelectSocialType;
