import DownloadFileLink from '../../../../components/DownloadFileLink/DownloadFileLink';
import HookForm from '../../../../components/HookForm/HookForm';
import DocsInputNew from '../../../../components/Inputs/DocsInput/DocsInputNew';
import { DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES, DOCUMENT_TYPES_GUARANTEE_LETTER } from '../../../../constants';

const FreedomClientDocuments = ({ onSubmit, loading, documents, backHandler }) => {
  documents = documents?.filter(
    (item) =>
      item?.typeId === DOCUMENT_TYPES_GUARANTEE_LETTER || item?.typeId === DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES
  );
  return (
    <div>
      <HookForm
        title={'Документы'}
        grid={{ display: 'grid', gap: 20 }}
        action={'Сохранить и продолжить'}
        customDataTop={() => documents?.map((document) => <DownloadFileLink document={document} />)}
        inputs={[
          {
            text: 'Заверение об обстоятельствах',
            label: 'Загрузить документ',
            name: 'doc',
            id: 'doc',
            rules: {
              required: true,
            },
            component: DocsInputNew,
          },
        ]}
        onSubmit={onSubmit}
        loading={loading}
        backHandler={backHandler}
      />
    </div>
  );
};

export default FreedomClientDocuments;
