import { colors } from "./colors";

export const textStyles = {
  buttonText: {
    fontFamily: "Exo 2",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    color: colors.cameo,
  },
  fieldLabel: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    color: colors.blackPearl,
  },
  formTitle: {
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "20px",
    color: colors.armadillo,
  },
  headerTitle: {
    fontWeight: "600",
    fontSize: "32px",
    lineHeight: "38px",
    color: colors.blackPearl,
  },
  inputPlaceholder: {
    fontFamily: "Exo 2",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    color: colors.lightGrey,
  },
  sideBarItem: {
    fontFamily: "Exo 2",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
    color: "white",
  },
  sideBarCount: {
    fontFamily: "Exo 2",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    color: colors.cameo,
  },
};
